import { DOCUMENT } from '@angular/common'
import { Component, EventEmitter, HostBinding, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core'
import {
  DeviceInfoService,
  Logger,
  LoggerService,
  ScreenProperties,
  ScrollToService,
  WindowRef,
} from '@maprix/core'
import { remove } from 'lodash'
import { Observable, Subscription } from 'rxjs'
import { first } from 'rxjs/operators'
import { fadeInAnimation } from '../../../../shared/animations/fade-in.animation'
import { HttpError } from '../../../../shared/models/http/http-error'
import { CriteriaType } from '../../../../shared/models/prototype/criteria-type.enum'
import { ExpertiseCriteria } from '../../../../shared/models/prototype/expertise-criteria.model'
import { FeedbackStyle } from '../../../../shared/models/prototype/feedback-style.enum'
import { UploadPrototype } from '../../../../shared/models/prototype/prototype.model'
import { AuthUser } from '../../../../shared/models/user/auth-user'
import { AuthService } from '../../../../shared/services/auth/auth.service'
import { ExpertiseService } from '../../../../shared/services/expertise/expertise.service'
import { AvailableExpertise } from '../../../community/components/available-expertise.model'
import { AudienceChoice } from './audience-choice.model'

const CHOICES: AudienceChoice[] = [
  {
    id: 2,
    label: 'Private',
    icon: 'audience-community',
    title: 'PROTOTYPE.STEP_4.PRIVATE.COMMUNITY.TITLE',
    selectOverlay: 'PROTOTYPE.STEP_4.PRIVATE.COMMUNITY.SELECT_OVERLAY',
    description: 'PROTOTYPE.STEP_4.PRIVATE.COMMUNITY.DESCRIPTION',
    feedbackStyle: FeedbackStyle.COMMUNITY,
  },
  {
    id: 3,
    label: 'Private',
    icon: 'audience-groups',
    title: 'PROTOTYPE.STEP_4.PRIVATE.SELECTED_COLLEAGUES.TITLE',
    selectOverlay: 'PROTOTYPE.STEP_4.PRIVATE.SELECTED_COLLEAGUES.SELECT_OVERLAY',
    description: 'PROTOTYPE.STEP_4.PRIVATE.SELECTED_COLLEAGUES.DESCRIPTION',
    feedbackStyle: FeedbackStyle.GROUP,
  },
  {
    id: 4,
    label: 'Private',
    icon: 'audience-experts',
    title: 'PROTOTYPE.STEP_4.PRIVATE.EXPERTS.TITLE',
    selectOverlay: 'PROTOTYPE.STEP_4.PRIVATE.EXPERTS.SELECT_OVERLAY',
    description: 'PROTOTYPE.STEP_4.PRIVATE.EXPERTS.DESCRIPTION',
    feedbackStyle: FeedbackStyle.CRITERIA,
  },
]

@Component({
  selector: 'scs-upload-step-audience',
  templateUrl: './upload-step-audience.component.html',
  styleUrls: ['./upload-step-audience.component.scss'],
  animations: [fadeInAnimation],
})
export class UploadStepAudienceComponent implements OnInit, OnDestroy {
  @HostBinding('class') classes = 'wizard-step wizard-step--audience'

  @Input() prototype: UploadPrototype
  @Input() valid: boolean
  @Input() submitWorker: Observable<any>
  @Output() proceed: EventEmitter<void> = new EventEmitter<void>()
  @Output() revalidate: EventEmitter<void> = new EventEmitter<void>()

  availableChoices: AudienceChoice[]
  selectedChoice: AudienceChoice | null
  expertiseList: ExpertiseCriteria[] = []

  private window: Window
  private logger: Logger
  private deviceInfoSubscription: Subscription
  private isXsScreen = false

  constructor(
    loggerService: LoggerService,
    windowRef: WindowRef,
    deviceInfo: DeviceInfoService,
    private authService: AuthService,
    private expertiseService: ExpertiseService,
    private scrollToService: ScrollToService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.logger = loggerService.getInstance('UploadStepAudienceComponent')
    this.window = windowRef.nativeWindow

    this.deviceInfoSubscription = deviceInfo.screenChanges.subscribe((props: ScreenProperties) => {
      this.isXsScreen = props.width === 'xs'
    })
  }

  ngOnInit() {
    this.authService.authUserChanges.pipe(first()).subscribe(
      (authUser: AuthUser) => {
        if (authUser && authUser.sub !== undefined) {
          this.initExpertise()
          this.availableChoices = CHOICES
          this.selectedChoice = this.prototype.choice
          if (!this.prototype.criterias) {
            this.prototype.criterias = []
          }
          if (!this.prototype.groups) {
            this.prototype.groups = []
          }
        }
      },
      (error: any) => {
        throw new Error('Could not get currently logged in user, needs one to work.')
      }
    )
  }

  ngOnDestroy() {
    this.deviceInfoSubscription.unsubscribe()
  }

  selectChoice(selectedChoice: AudienceChoice, element: HTMLElement) {
    this.selectedChoice = selectedChoice
    this.prototype.feedbackStyle = selectedChoice.feedbackStyle
    // TODO ML LOW? remove this and handle translate in service for final modal or at least remove it before sending
    this.prototype.choice = selectedChoice
    this.revalidate.emit()
    if (this.isXsScreen) {
      this.scrollToElement(element)
    }
  }

  onRevalidate() {
    this.revalidate.emit()
  }

  onProceed() {
    this.proceed.emit()
  }

  onToggleExpertiseSelection(ec: ExpertiseCriteria, checked: boolean) {
    if (checked) {
      if (this.prototype.criterias === undefined || this.prototype.criterias === null) {
        this.prototype.criterias = []
      }
      this.prototype.criterias.push(ec)
    } else {
      remove(<ExpertiseCriteria[]>this.prototype.criterias, e => e.expertise.id === ec.expertise.id)
    }
    // validate prototype
    this.revalidate.emit()
  }

  isExpertiseChecked(ec: ExpertiseCriteria): boolean {
    const index: number = (<ExpertiseCriteria[]>this.prototype.criterias).findIndex(
      e => e.expertise.id === ec.expertise.id
    )
    return index > -1
  }

  // private functions

  private initExpertise(): void {
    this.expertiseService.getAvailable().subscribe(
      (res: AvailableExpertise[]) => {
        if (res !== null) {
          this.expertiseList = this.transformExpertise(res).sort(
            (a, b) => a.expertise.sortNumber - b.expertise.sortNumber
          )
          if (this.expertiseList.length === 0) {
            this.prototype.criterias = []
          } else if (this.prototype.criterias && this.prototype.criterias.length) {
            // check if the criteria is still available, if not remove it from the prototype
            this.prototype.criterias.forEach(ec => {
              if (!this.expertiseList.find(ecl => ecl.expertise.id === (<ExpertiseCriteria>ec).expertise.id)) {
                remove(
                  <ExpertiseCriteria[]>this.prototype.criterias,
                  e => e.expertise.id === (<ExpertiseCriteria>ec).expertise.id
                )
              }
            })
          }
        } else {
          this.prototype.criterias = []
        }
      },
      (error: HttpError) => {
        this.logger.debug('could not fetch available expertises: ', error)
      }
    )
  }

  private transformExpertise(res: AvailableExpertise[]): ExpertiseCriteria[] {
    return <ExpertiseCriteria[]>res.map(item => {
      return { type: CriteriaType.EXPERTISE, expertise: item.expertise, count: item.count }
    })
  }

  private scrollToElement(element: HTMLElement): void {
    setTimeout(() => {
      const body: HTMLElement = this.document.documentElement || this.document.body
      const scrollY = this.window.pageYOffset || body.scrollTop
      const y: number = element.getBoundingClientRect().top + scrollY
      // FIXME MW LOW extract the distance from header height (provide variable via css-to-js)
      this.scrollToService.scrollTo(y - 54, 400) // subtract the header height
    }, 200)
  }
}
