import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms'
import { MaxFileSizeError } from './max-file-size-error.model'

export const ERR_KEY_MAX_FILE_SIZE = 'maxSize'

export interface MaxFileSizeValidatorFn extends ValidatorFn {
  (c: AbstractControl): { [key: string]: MaxFileSizeError } | null
}

export function createFileSizeValidator(maxSize: number): MaxFileSizeValidatorFn {
  return (c: FormControl): { maxSize: MaxFileSizeError } | null => {
    const files: FileList = c.value

    // check for a value
    if (!files) {
      return null
    } else {
      const valid: File[] = []
      const invalid: File[] = []

      // check for correct file size
      for (let i = 0; i < files.length; i++) {
        const file: File = files.item(i)

        if (file.size <= maxSize) {
          valid.push(file)
        } else {
          invalid.push(file)
        }
      }

      if (valid.length === files.length) {
        return null
      } else {
        return {
          maxSize: {
            valid,
            invalid,
            maxSize,
          },
        }
      }
    }
  }
}
