import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { CheckboxModule, StateButtonModule } from '@maprix/components'
import { LoopingCoreModule } from '../../../looping-core.module'
import { InlineMessageModule } from '../inline-message/inline-message.module'
import { TagInputModule } from '../tag-input/tag-input-module'
import { EnterpriseInviteModalComponent } from './enterprise-invite-modal.component'
import { EnterpriseInviteComponent } from './enterprise-invite.component'

@NgModule({
  imports: [
    CommonModule,
    LoopingCoreModule,
    TagInputModule,
    FormsModule,
    InlineMessageModule,
    StateButtonModule,
    CheckboxModule,
  ],
  declarations: [EnterpriseInviteComponent, EnterpriseInviteModalComponent],
  exports: [],
  entryComponents: [EnterpriseInviteModalComponent],
})
export class EnterpriseInviteModule {}
