import { Component, EventEmitter, Input, OnInit, Output, SecurityContext } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { LoggerService, ScrollToService, TranslateService } from '@maprix/core'
import { Observable } from 'rxjs'
import { HttpError } from '../../../shared/models/http/http-error'
import { LoginStateData } from '../models/login-state-data'
import { BaseLoginStepComponent } from './base/base-login-step.component'

@Component({
  selector: 'scs-register-privacy-policy',
  templateUrl: './register-privacy-policy.component.html',
})
export class RegisterPrivacyPolicyComponent extends BaseLoginStepComponent implements OnInit {
  @Input() data: LoginStateData
  @Input() worker$: Observable<any>

  // two way binding for error
  @Input() error: HttpError | string
  @Output() errorChange: EventEmitter<HttpError | string> = new EventEmitter<HttpError | string>()

  @Output() back: EventEmitter<null> = new EventEmitter<null>()
  @Output() next: EventEmitter<null> = new EventEmitter<null>()

  trustedContent: SafeHtml

  constructor(
    loggerService: LoggerService,
    private translateService: TranslateService,
    private domSanitizer: DomSanitizer,
    private scrollToService: ScrollToService
  ) {
    super(loggerService.getInstance('RegisterPrivacyPolicyComponent'))
  }

  ngOnInit() {
    this.translateService
      .get(`PRIVACY_POLICY.POLICY`, {
        customerName:
          this.data.loginInfo && this.data.loginInfo.organization.name ? this.data.loginInfo.organization.name : '',
      })
      .subscribe((terms: string) => {
        this.trustedContent = this.domSanitizer.sanitize(SecurityContext.HTML, terms)
      })

    this.scrollToService.scrollTo()
  }
}
