import { NgModule, Optional, SkipSelf } from '@angular/core'
import { StyleService } from './style.service'

@NgModule({
  imports: [],
  providers: [StyleService],
})
export class StyleModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: StyleModule
  ) {
    if (parentModule) {
      throw new Error('StyleModule is already loaded. Import it only once')
    }
  }
}
