import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { FormControlErrorDisplayStrategy, NotificationCenterService } from '@maprix/components'
import { LoggerService, ScrollToService } from '@maprix/core'
import { Observable } from 'rxjs'
import { Regex } from '../../../shared/helpers/regex'
import { HttpError } from '../../../shared/models/http/http-error'
import { LoginStateData } from '../models/login-state-data'
import { ResetableLoginStepWithFormComponent } from './base/resetable-login-step-with-form.component'

@Component({
  selector: 'scs-login-reset-password',
  templateUrl: 'login-reset-password.component.html',
})
export class LoginResetPasswordComponent extends ResetableLoginStepWithFormComponent implements OnInit, OnChanges {
  @Input() data: LoginStateData
  @Input() worker$: Observable<any>

  // two way binding for error
  @Input() error: HttpError | string
  @Output() errorChange: EventEmitter<HttpError | string> = new EventEmitter<HttpError | string>()

  @Output() next: EventEmitter<null> = new EventEmitter<null>()
  errorDisplayStrategy: any = FormControlErrorDisplayStrategy

  constructor(
    loggerService: LoggerService,
    formBuilder: FormBuilder,
    private notificationCenter: NotificationCenterService,
    private scrollToService: ScrollToService
  ) {
    super(loggerService.getInstance('LoginResetPassword'), formBuilder)
  }

  ngOnInit(): void {
    super.ngOnInit()

    this.form.get('password').valueChanges.subscribe(() => {
      this.resetError()
    })

    this.scrollToService.scrollTo()
  }

  ngOnChanges(changes: SimpleChanges): void {
    const workerChange: SimpleChange = changes['worker$']
    if (workerChange && workerChange.currentValue) {
      ;(<Observable<void>>workerChange.currentValue)
        // can not be done in login service, because we wanna wait until the user is also logged in to display the message
        .subscribe(() => this.notificationCenter.success('LOGIN.RESET_PASSWORD.MSG_SUCCESS'))
    }
  }

  buildForm(): FormGroup {
    return this.formBuilder.group({
      password: ['', Validators.compose([Validators.required, Validators.pattern(Regex.PATTERN_PASSWORD)])],
    })
  }

  fillViewDataToModel(): void {
    this.data.loginData._newPassword = this.form.get('password').value
  }
}
