import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms'
import { AmountError } from './amount-error.model'

export const ERR_KEY_WRONG_AMOUNT = 'wrongAmount'

export interface AmountValidatorFn extends ValidatorFn {
  (c: AbstractControl): { wrongAmount: AmountError } | null
}

export function createAmountValidator(maxAmount: number): AmountValidatorFn {
  return (c: FormControl): { wrongAmount: AmountError } | null => {
    const files: FileList = c.value

    // check for a value
    if (!files) {
      return null
    }

    if (files.length <= maxAmount) {
      return null
    } else {
      return {
        wrongAmount: {
          files,
          actual: files.length,
          maxAmount,
        },
      }
    }
  }
}
