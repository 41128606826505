import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { Logger, LoggerService, TranslateService } from '@maprix/core'
import { isEmpty, snakeCase } from 'lodash'
import { Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators'
import { Enums } from '../../helpers/enums'
import { PagedLoader } from '../../services/http/paged-loader'
import { PagedLoaderFactory } from '../../services/http/paged-loader-factory.service'
import { Sorting } from '../table/models/sorting.model'

export interface Filter<F> {
  id: string
  labelKey: string
  value: F
}

export enum SortDirection {
  NONE,
  ASC,
  DESC,
}

export interface Sort<S> {
  columnId: string
  direction: SortDirection
  value: S
}

export interface SortWithLabel<S> extends Sort<S> {
  label: Observable<string>
}

export interface FilterWithCount<F> extends Filter<F> {
  count: number
}

/**
 *  typescript aot build does not work if this is a static method within the TitleBarComponent
 * @param sorting
 * @param labelKeyPrefix
 * @param translateService
 */
export function generateSortLabelsMobile<T>(
  sorting: Sorting<T>,
  labelKeyPrefix: string,
  translateService: TranslateService
) {
  sorting.options.forEach(sort => {
    ;(<SortWithLabel<T>>sort).label = translateService
      .get([
        'COMMONS.SORTING.PREFIX',
        labelKeyPrefix + snakeCase(sort.columnId).toUpperCase(),
        'COMMONS.SORTING.SIGN_' + Enums.fromNumber(SortDirection, sort.direction),
      ])
      .pipe(map(translated => (<string[]>translated).join(' ')))
  })
}

/*
 * A title bar can contain four elements
 *  - title
 *  - filter (toggle buttons)
 *  - search
 *  - additional criteria (select)
 *
 *
 *  any -> type of the item returned from http request
 *  any -> type of filter
 */
@Component({
  selector: 'scs-title-bar',
  templateUrl: './title-bar.component.html',
})
export class TitleBarComponent implements OnInit, OnChanges, OnDestroy {
  private static CONTROL_NAME_SEARCH = 'search'
  private static CONTROL_NAME_FILTER = 'filter'
  private static CONTROL_NAME_SORT = 'sort'

  @Input() titleKey: string
  @Input() filters: Observable<Array<Filter<any>>>
  @Input() dataUrl: Observable<string>
  @Input() additionalCriteria: Array<Observable<{ key: string; value: string | null }>> | null
  @Input() searchable: boolean
  @Input() sorting: Sorting<any>
  @Input() total: number

  @Input() tableSortChanges: Observable<any>

  @Output()
  pagedLoaderChanged: EventEmitter<PagedLoader<any, any, any>> = new EventEmitter<PagedLoader<any, any, any>>()
  @Output() filterChanged: EventEmitter<any> = new EventEmitter<any>()

  form: FormGroup

  private logger: Logger
  private pagedLoader: PagedLoader<any, any, any>

  private filterChanges: Observable<any> | null
  private searchChanges: Observable<string> | null
  // private additionalCriteriaChanges: Observable<{key: string, value: string|null}>[] | null;
  private sortChanges: Observable<any> | null

  constructor(
    loggerService: LoggerService,
    private formBuilder: FormBuilder,
    private pagedLoaderFactory: PagedLoaderFactory
  ) {
    this.logger = loggerService.getInstance('TitleBarComponent')

    this.form = this.formBuilder.group({})
  }

  ngOnInit(): void {
    if (this.dataUrl === undefined) {
      throw new Error('please provide a data url observable using the [dataUrl] input on the TitleBar component')
    }

    // if (this.titleKey === undefined) {
    //   throw new Error('please provide a titleKey using the [titleKey] input on the TitleBar component');
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.normalizeBoolean(changes, 'searchable')

    let updatePagedLoader = false

    if (changes.hasOwnProperty('searchable')) {
      updatePagedLoader = updatePagedLoader || true
      if (changes['searchable'].currentValue) {
        const control: FormControl = new FormControl()
        this.form.addControl(TitleBarComponent.CONTROL_NAME_SEARCH, control)
        this.searchChanges = control.valueChanges.pipe(distinctUntilChanged(), debounceTime(150))
      } else {
        this.form.removeControl(TitleBarComponent.CONTROL_NAME_SEARCH)
        this.searchChanges = null
      }
    }

    if (changes.hasOwnProperty('filters')) {
      updatePagedLoader = updatePagedLoader || true
      if (changes['filters'].currentValue && !isEmpty(changes['filters'].currentValue)) {
        const control: FormControl = new FormControl()
        this.form.addControl(TitleBarComponent.CONTROL_NAME_FILTER, control)

        this.filterChanges = control.valueChanges.pipe(
          distinctUntilChanged(),
          tap(filter => this.filterChanged.emit(filter))
        )

        this.filters.subscribe(filters => {
          this.logger.debug('got filters', filters)
          // select the first filter
          this.form.get(TitleBarComponent.CONTROL_NAME_FILTER).setValue(filters[0].value)
        })
      } else {
        this.form.removeControl(TitleBarComponent.CONTROL_NAME_FILTER)
        this.filterChanges = null
      }
    }

    if (changes.hasOwnProperty('sorting')) {
      updatePagedLoader = updatePagedLoader || true
      if (changes['sorting'].currentValue && !isEmpty(changes['sorting'].currentValue)) {
        this.logger.info('got soring', this.sorting)
        const control: FormControl = new FormControl()
        this.form.addControl(TitleBarComponent.CONTROL_NAME_SORT, control)

        this.sortChanges = control.valueChanges.pipe(startWith(this.sorting.default), distinctUntilChanged())

        control.setValue(this.sorting.default.toString())
      } else {
        this.form.removeControl(TitleBarComponent.CONTROL_NAME_SORT)
        this.sortChanges = null
      }
    }

    if (changes.hasOwnProperty('tableSortChanges')) {
      if (changes['tableSortChanges'].currentValue) {
        changes['tableSortChanges'].currentValue.subscribe((currentTableSort: any) => {
          this.form.get(TitleBarComponent.CONTROL_NAME_SORT).setValue(currentTableSort)
        })
      } else {
        // unsubscribe
      }
    }

    if (updatePagedLoader) {
      this.createOrResetPagedLoader()
    }
  }

  ngOnDestroy(): void {}

  setFilter(filter: Filter<any>): void {
    this.logger.debug('filter changed to', filter)
    this.form.get(TitleBarComponent.CONTROL_NAME_FILTER).setValue(filter.value)
  }

  private normalizeBoolean(changes: SimpleChanges, ...fields: string[]): void {
    fields.forEach((field: string) => {
      if (changes[field] && changes[field].currentValue === '') {
        this[field] = true
        changes[field].currentValue = true
      }
    })
  }

  private createOrResetPagedLoader() {
    const pagedLoader = this.pagedLoaderFactory.createPagedLoader<any, any, any>(
      this.dataUrl,
      this.searchChanges,
      this.filterChanges,
      this.sortChanges,
      this.additionalCriteria
    )

    this.pagedLoaderChanged.emit(pagedLoader)
    this.pagedLoader = pagedLoader
  }
}
