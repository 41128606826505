import { DOCUMENT } from '@angular/common'
import { Directive, HostListener, Inject } from '@angular/core'

/*
 * iOS is too stupid too blur the last focused element in the form, when submitting using the return key,
 * that's why the keyboard popups on every touch interaction, so we do it manually
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'form [scs-blur-on-submit]',
})
export class BlurOnSubmitDirective {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  @HostListener('submit')
  onSubmit() {
    ;(<any>this.document.activeElement).blur()
  }
}
