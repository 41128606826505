import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Logger, LoggerService } from '@maprix/core'
import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { AuthService } from './auth.service'

/**
 * Ensures user is logged out when accessing this route.
 */
@Injectable()
export class CanActivateLoggedOutGuard implements CanActivate {
  private logger: Logger

  constructor(loggerService: LoggerService, private authService: AuthService, private router: Router) {
    this.logger = loggerService.getInstance('CanActivateLoggedOutGuard')
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.authService.isLoggedIn()) {
      return true
    } else {
      return this.authService.logout().pipe(
        first(),
        map((res): boolean => {
          return true
        })
      )
    }
  }
}
