import { OEmbedResourceMetaData } from '../../services/oembed/o-embed-resource-meta-data.model'
import { OEmbedType } from '../../services/oembed/o-embed-type.enum'
import { OEmbedProvider } from './o-embed-provider.enum'
import { PrototypeResourceType } from './prototype-resource-type.enum'
import { ResourceMetaData } from './resource-meta-data.model'

export const O_EMBED_RESOURCE_META_DATA: OEmbedResourceMetaData[] = [
  {
    urlRegexp: /^((?:http|https)?:\/\/)?(?:www\.)?(?:(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11}))/,
    prototypeResourceType: PrototypeResourceType.O_EMBED,
    oEmbedProvider: OEmbedProvider.YOUTUBE,
    oEmbedType: OEmbedType.VIDEO,
  },
  {
    urlRegexp: /^((?:http|https)?:\/\/)?(?:www\.)?(?:vimeo.com\/)(?:([\w]+)\/?)+/,
    prototypeResourceType: PrototypeResourceType.O_EMBED,
    oEmbedProvider: OEmbedProvider.VIMEO,
    oEmbedType: OEmbedType.VIDEO,
  },
  {
    urlRegexp: /^((?:http|https)?:\/\/)?(?:www\.)?(?:soundcloud\.com|snd\.sc)\/(.*)/,
    prototypeResourceType: PrototypeResourceType.O_EMBED,
    oEmbedProvider: OEmbedProvider.SOUNDCLOUD,
    oEmbedType: OEmbedType.AUDIO,
  },
  {
    urlRegexp: /^((?:http|https)?:\/\/)?(?:www\.)?(?:mixcloud\.com)\/(.*)/,
    prototypeResourceType: PrototypeResourceType.O_EMBED,
    oEmbedProvider: OEmbedProvider.MIXCLOUD,
    oEmbedType: OEmbedType.AUDIO,
  },
]

export const RESOURCE_META_DATA: ResourceMetaData[] = [
  ...O_EMBED_RESOURCE_META_DATA,
  {
    // tslint:disable-next-line
    urlRegexp: /^(?:(?:https|http):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?/,
    prototypeResourceType: PrototypeResourceType.IMAGE,
  },
  {
    // tslint:disable-next-line
    urlRegexp: /^(?:(?:https|http):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?/,
    prototypeResourceType: PrototypeResourceType.URL,
  },
]
