import { DOCUMENT } from '@angular/common'
import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core'
import { Modal2, MODAL2_DATA, Modal2Ref, NotificationCenterService } from '@maprix/components'
import { Logger, LoggerService } from '@maprix/core'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { OEmbedResource } from '../../models/resource/o-embed-resource.model'
import { PrototypeResourceType } from '../../models/resource/prototype-resource-type.enum'
import { PrototypeResource } from '../../models/resource/prototype-resource.model'
import { OEmbedResourceMetaData } from '../../services/oembed/o-embed-resource-meta-data.model'
import { OEmbedService } from '../../services/oembed/o-embed.service'
import { ResourceViewerData } from './resource-viewer-data.model'

@Component({
  selector: 'scs-resource-viewer-modal',
  templateUrl: './resource-viewer-modal.component.html',
  styleUrls: ['./resource-viewer-modal.component.scss'],
})
@Modal2({
  titleKey: 'RESOURCE_VIEWER.PREVIEW_MODAL_TITLE',
  size: 'lg',
  closeOnClickBackdrop: false,
  facet: 'resource-viewer',
})
export class ResourceViewerModalComponent implements OnInit {
  images: string[] = []

  type: PrototypeResourceType
  typesView: any = PrototypeResourceType
  htmlResponses: Array<Observable<string>> = []
  ready = true
  showLoader = true
  currentIndex: number

  private logger: Logger

  constructor(
    loggerService: LoggerService,
    private oembedService: OEmbedService,
    private notificationCenterService: NotificationCenterService,
    private modal: Modal2Ref<ResourceViewerModalComponent, void>,
    @Inject(MODAL2_DATA) public modalData: ResourceViewerData,
    @Inject(DOCUMENT) private document: Document,
    private eRef: ElementRef,
  ) {
    this.logger = loggerService.getInstance('ResourceViewerModalComponent')
  }

  ngOnInit() {
    this.currentIndex = this.modalData.index !== undefined ? this.modalData.index : 0
    if (this.modalData.resources !== undefined && this.modalData.resources[0] !== undefined) {
      this.type = this.modalData.resources[0].type
      if (this.type === PrototypeResourceType.IMAGE) {
        this.modalData.resources.forEach((resource: PrototypeResource) => {
          this.images.push(resource.image!.retrievalUrl)
        })
      } else if (this.type === PrototypeResourceType.O_EMBED) {
        const resources: OEmbedResource[] = <OEmbedResource[]>this.modalData.resources
        resources.forEach((oembedResource: OEmbedResource) => {
          const metaData: OEmbedResourceMetaData | undefined = this.oembedService.getOEmbedResourceMetaDataByUrl(oembedResource.oEmbed.url)
          if (metaData) {
            this.htmlResponses.push(
              this.oembedService.getOEmbedResponse(oembedResource.oEmbed.url, metaData)
                .pipe(
                  map(appHttpResponse => appHttpResponse.body.html),
                  catchError(err => {
                    // we catch any error, because we handle all errors inside this component
                    // return an empty value, show a message and close the modal
                    this.notificationCenterService.error('RESOURCE_VIEWER.LOAD_ERROR')
                    this.modal.close(null)
                    return of('')
                  }),
                ),
            )
          }
        })
      }
    } else {
      this.logger.warn('No resources passed, pass at least one resource to be displayed.')
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.modal.close(null);
    } 
  }

  /*
   * host event listener for keydown events
   */
  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 39:
        // ArrowRight
        this.nextResource()
        break
      case 37:
        // ArrowLeft
        this.previousResource()
        break
      default:
      //    do nothing
    }
  }

  nextResource() {
    if (this.currentIndex !== this.modalData.resources.length - 1) {
      this.onChangeIndex(1)
    }
  }

  previousResource() {
    if (this.currentIndex !== 0) {
      this.onChangeIndex(-1)
    }
  }

  closeModal() {
    this.modal.close(null);
  }

  setIndex(newIndex: number): void {
    this.currentIndex = this.sanitizeIndex(newIndex)
  }

  sanitizeIndex(newIndex: number): number {
    return Math.max(Math.min(this.modalData.resources.length - 1, newIndex), 0)
  }

  onLoadingComplete(complete: boolean): void {
    this.showLoader = !complete
  }

  private onChangeIndex(operator: number): void {
    this.ready = false
    this.showLoader = true
    // use timeout so next resource is properly initialized -> oembed viewer
    setTimeout(() => {
      this.currentIndex = this.sanitizeIndex(this.currentIndex + operator)
      // NOTE RENDERER2 invokeElementMethod was removed in Renderer2 -> we use native element method for now
      // this.renderer.invokeElementMethod(this.document.activeElement, 'blur');
      ;(<HTMLElement>this.document.activeElement).blur()
      this.ready = true
    }, 0)
  }
}
