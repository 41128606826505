import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { IconModule } from '@maprix/components'
import { AvatarModule } from '../avatar/avatar.module'
import { FileSelectorModule } from '../file-selector/file-selector.module'
import { InlineMessageModule } from '../inline-message/inline-message.module'
import { LoginAvatarComponent } from './login-avatar.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AvatarModule,
    FileSelectorModule,
    InlineMessageModule,
    IconModule,
  ],
  declarations: [LoginAvatarComponent],
  exports: [LoginAvatarComponent],
})
export class LoginAvatarModule {}
