import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core'
import { Logger, LoggerService } from '@maprix/core'
import { getOrientation } from '../../helpers/image-utils'
/*
 * Directive to set css style and transform element orientation based on image exif data
 * you can find test images here: https://github.com/recurser/exif-orientation-examples
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[scs-image-orientation]',
})
export class ImageOrientationDirective implements OnChanges {
  // tslint:disable-next-line
  @Input('scs-image-orientation') image: File | null

  private logger: Logger
  private el: HTMLElement

  constructor(el: ElementRef, loggerService: LoggerService, private renderer: Renderer2) {
    this.logger = loggerService.getInstance('ImageOrientationDirective')
    this.el = el.nativeElement
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['image'] && changes['image'].currentValue) {
      this.renderer.setStyle(this.el, 'transform', '')
      this.updateComponent()
    }
  }

  private updateComponent() {
    if (this.image) {
      getOrientation(this.image, (orientation: number) => {
        // transform element according to calculated orientation
        switch (orientation) {
          case 1: // already correct
            break
          case 2: // Flip X
            this.renderer.setStyle(this.el, 'transform', 'scaleX(-1)')
            break
          case 3: // 180 deg
            this.renderer.setStyle(this.el, 'transform', 'rotate(180deg)')
            break
          case 4: // Flip Y
            this.renderer.setStyle(this.el, 'transform', 'scaleY(-1)')
            break
          case 5: // Flip X 90 deg
            this.renderer.setStyle(this.el, 'transform', 'scaleX(-1) rotate(90deg)')
            break
          case 6: // 90 deg
            this.renderer.setStyle(this.el, 'transform', 'rotate(90deg)')
            break
          case 7: // Flip Y 90 deg
            this.renderer.setStyle(this.el, 'transform', 'scaleY(-1) rotate(90deg)')
            break
          case 8: // 270 deg
            this.renderer.setStyle(this.el, 'transform', 'rotate(270deg)')
            break
          case -1: // exif not defined
            break
          case -2: // not jpeg
            break
          default: // ignore
        }
      })
    }
  }
}
