import { Injectable } from '@angular/core'
import { Logger, LoggerService } from '@maprix/core'

@Injectable()
export class ScriptLoader {
  private static ELEMENT = 'script'
  private loadedScripts: string[] = []
  private logger: Logger

  constructor(loggerService: LoggerService) {
    this.logger = loggerService.getInstance('ScriptLoader')
  }

  addScriptToHead(url: string, async?: boolean) {
    this.logger.debug('adding script to head with url : %s', url)
    const element: HTMLScriptElement = <HTMLScriptElement>document.createElement(ScriptLoader.ELEMENT)
    element.src = url
    element.type = 'text/javascript'
    element.async = async || false
    const firstScriptElem = document.getElementsByTagName(ScriptLoader.ELEMENT)[0]
    if (firstScriptElem !== null) {
      firstScriptElem.parentNode!.insertBefore(element, firstScriptElem)
      this.loadedScripts.push(url)
    } else {
      throw new Error('There is no <script> tag')
    }
  }

  isLoaded(url: string): boolean {
    return this.loadedScripts.indexOf(url) !== -1
  }
}
