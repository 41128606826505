import { Injectable } from '@angular/core'
import {
  Headers,
  Http,
  Request,
  RequestMethod,
  RequestOptions,
  RequestOptionsArgs,
  Response,
  ResponseContentType,
} from '@angular/http'
import { Router } from '@angular/router'
import {
  NotificationCenterService,
  NotificationMessage,
  NotificationMessageOptions,
  NotificationSeverity,
} from '@maprix/components'
import { ClientIdService, Logger, LoggerService, RuntimeConfiguration } from '@maprix/core'
import { Observable, of, Subscription, throwError, timer } from 'rxjs'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'
import { MomentDateConverter } from '../../helpers/moment-date-converter'
import { HttpError } from '../../models/http/http-error'
import { HttpErrorCodes } from '../../models/http/http-error-codes'
import { MicroService } from '../config/runtime-configuration/micro-service.enum'
import { DataStore } from '../data-store/data-store.service'
import { AppHttpResponse } from './app-http-response.model'
import { ContentType } from './content-type.enum'

@Injectable()
export class AppHttp {
  private static NOTIFICATION_ID_CLIENT_OFFLINE = 'client-offline-id'
  private static NOTIFICATION_ID_UNAVAILABLE = 'system-unavailable-id'
  private static CLIENT_ID_HEADER_NAME = 'X-FLUX-Client-Id'
  private static ERROR_HEADER_NAME = 'X-FLUX-Error'
  private static CONTENT_TYPE_HEADER = 'content-type'
  private static HEADER_FIELD_APP_CLOUD_ERROR = 'X-Cf-Routererror'
  private static URI_MONITORING_AVAILABLE = 'public/monitoring/available'

  logoutHandler: () => void

  private logger: Logger
  private monitoringPollingSub: Subscription | null
  private clientOfflineDisplayed = false

  private static buildAppHttpResponse<T>(response: Response, body: any): AppHttpResponse<T> {
    return { status: response.status, body, originalResponse: response }
  }

  private static isNotEmptyResponse(response: Response) {
    return response.ok && response.status !== 204
  }

  private static transformRequestBody(body: any): void {
    MomentDateConverter.transformRequestBody(body)
  }

  private static transformResponseBody(body: any): void {
    MomentDateConverter.transformResponseBody(body)
  }

  /**
   * Returns the content type enum based on header options.
   */
  private static detectContentTypeFromHeader(headers): ContentType {
    // content-type:application/json; charset=utf-8 so we split the encoding away
    switch (headers.get(AppHttp.CONTENT_TYPE_HEADER).split(';')[0]) {
      case 'application/json':
        return ContentType.JSON
      case 'application/x-www-form-urlencoded':
        return ContentType.FORM
      case 'multipart/form-data':
        return ContentType.FORM_DATA
      case 'text/plain':
      case 'text/html':
        return ContentType.TEXT
      case 'application/octet-stream':
        return ContentType.BLOB
      default:
        return ContentType.TEXT
    }
  }

  constructor(
    loggerService: LoggerService,
    private http: Http,
    private runtimeConfiguration: RuntimeConfiguration,
    private tokenStore: DataStore,
    private clientIdService: ClientIdService,
    private router: Router,
    private notificationCenterService: NotificationCenterService
  ) {
    this.logger = loggerService.getInstance('AppHttp')
  }

  get<T>(url: string, options?: RequestOptionsArgs): Observable<AppHttpResponse<T>> {
    return this.requestHelper<T>({ url, method: RequestMethod.Get }, options)
  }

  post<T>(url: string, body: any, options?: RequestOptionsArgs): Observable<AppHttpResponse<T>> {
    return this.requestHelper<T>({ url, body, method: RequestMethod.Post }, options)
  }

  put<T>(url: string, body: any, options?: RequestOptionsArgs): Observable<AppHttpResponse<T>> {
    return this.requestHelper<T>({ url, body, method: RequestMethod.Put }, options)
  }

  delete(url: string, options?: RequestOptionsArgs): Observable<AppHttpResponse<void>> {
    return this.requestHelper<void>({ url, method: RequestMethod.Delete }, options)
  }

  head<T>(url: string, options?: RequestOptionsArgs): Observable<AppHttpResponse<T>> {
    return this.requestHelper<T>({ url, method: RequestMethod.Head }, options)
  }

  handle400warn = (error: HttpError, translateNameInterpolations?: any, options?: NotificationMessageOptions) => {
    if (options) {
      options.severity = NotificationSeverity.WARNING
      this.handle400(error, translateNameInterpolations, options)
    } else {
      this.handle400(error, translateNameInterpolations, { severity: NotificationSeverity.WARNING })
    }
  }

  handle400 = (error: HttpError, translateNameInterpolations?: any, options?: NotificationMessageOptions) => {
    // ERROR 4XX -> ignore 404er with header property 'X-Cf-Routererror' set (app cloud error) => handled before
    if (
      error.status >= 400 &&
      error.status < 500 &&
      !(error.status === 404 && error.headers.has(AppHttp.HEADER_FIELD_APP_CLOUD_ERROR))
    ) {
      this.logger.warn('handle 400er http error: ', error)
      // could possibly be an error without code (not from our backend)
      const key: string = error.code
        ? HttpErrorCodes.getKeyForCode(error.code)
        : HttpErrorCodes.KEY_PREFIX + HttpErrorCodes.DEFAULT_CLIENT_EXCEPTION
      const builder: NotificationMessage = this.notificationCenterService.builder()
      if (options) {
        // options could be passed but without the severity -> set default ERROR severity
        if (!options.severity) {
          options.severity = NotificationSeverity.ERROR
        }
        options.key = key
        builder.withOptions(options)
      } else {
        // no options passed -> set default ERROR severity and key
        builder.setSeverity(NotificationSeverity.ERROR)
        builder.setKey(key, translateNameInterpolations)
      }
      builder.show()
    }
  }

  private requestHelper<T>(
    requestOptions: RequestOptionsArgs,
    currentOptions?: RequestOptionsArgs
  ): Observable<AppHttpResponse<T>> {
    let request: Request
    // default response mapper is json
    let responseMapper: (response: Response | HttpError) => AppHttpResponse<T | Blob> = this.jsonResponseMapper
    let options: RequestOptions = new RequestOptions(requestOptions)

    if (currentOptions) {
      options = options.merge(currentOptions)
    }

    options = this.addCustomOptions(options)

    request = new Request(options)

    // ContentType is new private api inside angular
    if (<ContentType>request.detectContentType() === ContentType.JSON) {
      this.logger.debug('Request body is of type JSON --> transforming body : %O', options.body)
      AppHttp.transformRequestBody(options.body)
      request = new Request(options)
    }

    if (request.responseType === ResponseContentType.Blob) {
      responseMapper = this.blobResponseMapper
    }

    return <any>this.http.request(request).pipe(catchError(error => this.handleError(error)), map(responseMapper))
  }

  private jsonResponseMapper = <T>(response: Response): AppHttpResponse<T> => {
    let body = null
    if (
      AppHttp.isNotEmptyResponse(response) &&
      AppHttp.detectContentTypeFromHeader(response.headers) === ContentType.JSON
    ) {
      body = response.json()
      if (body) {
        AppHttp.transformResponseBody(body)
      }
    }
    return AppHttp.buildAppHttpResponse<T>(response, body)
  }

  private blobResponseMapper = (response: Response): AppHttpResponse<Blob> => {
    let body: Blob | null = null
    if (AppHttp.isNotEmptyResponse(response)) {
      this.logger.debug('Got BLOB response')
      body = response.blob()
    }

    return AppHttp.buildAppHttpResponse<Blob>(response, body)
  }

  private addCustomOptions(options: RequestOptions): RequestOptions {
    // only include jwt and clientId if the url is a microservice url
    // avoid sending the token to other endpoints (loading svg, templates, translations)
    // it's important as we do not trust third party services like youtube, loggly etc.
    // let newOptions = new RequestOptions(options);
    if (this.runtimeConfiguration.isMicroServiceUrl(options.url)) {
      // headers object not null
      if (!options.headers) {
        options.headers = new Headers()
      }

      // add CLIENT-ID
      options.headers.append(AppHttp.CLIENT_ID_HEADER_NAME, this.clientIdService.getClientId())

      // token if present
      const token: string = this.tokenStore.getToken()
      if (token) {
        options.headers.set('Authorization', 'Token ' + token)
      }
    }
    return options
  }

  /**
   * handles an http error. We return a new observable where an error was thrown with the HttpError, so any
   * error handler registered on a http request observable will retrieve this for special handling
   *
   * @param err
   * @param obs
   * @returns {ErrorObservable}
   */
  private handleError = (err: Response): Observable<HttpError> => {
    return of(err).pipe(
      switchMap<Response, HttpError>(response => {
        const httpError: HttpError = new HttpError()
        httpError.headers = err.headers
        httpError.status = err.status

        if (this.runtimeConfiguration.isMicroServiceUrl(response.url)) {
          // Works only on responses from our backend
          return of(err.json()).pipe(
            switchMap<Blob | any, Partial<HttpError>>(errorObject => {
              if (errorObject instanceof Blob) {
                return this.readBlobAsJson(errorObject)
              } else {
                return of(errorObject)
              }
            }),
            map<Partial<HttpError>, HttpError>(partialError => {
              httpError.message = partialError.message
              // if there is no error code we try to get it from a header field
              httpError.code = partialError.code || err.headers.get(AppHttp.ERROR_HEADER_NAME)
              httpError.details = partialError.details
              return httpError
            })
          )
        } else {
          httpError.message = `Http Error: Status: ${err.toString()}`
          return of(httpError)
        }
      }),
      tap(errorHttp => {
        // handle client offline detection (only when client offline wasn't checked before and monitoring polling is not active)
        if (errorHttp.status === 0 && !this.clientOfflineDisplayed && !this.monitoringPollingSub) {
          this.logger.warn('possible client offline http error', errorHttp)
          this.handleClientOffline()
        } else if (errorHttp.status === 401 && (err.url.indexOf('login') === -1 || err.url.indexOf('logout') === -1)) {
          // unauthorized and we did not call login or logout endpoint
          this.handleUnauthorized(err)
        } else if (errorHttp.status === 404 && errorHttp.headers.has(AppHttp.HEADER_FIELD_APP_CLOUD_ERROR)) {
          // check for 404er with header field "X-Cf-Routererror: unknown_route"
          this.logger.error('Appcloud X-Cf-Routererror: ', errorHttp.headers.get(AppHttp.HEADER_FIELD_APP_CLOUD_ERROR))
          this.handleErrorAndAvailability(errorHttp)
        } else if (errorHttp.status >= 500 && errorHttp.status < 600) {
          this.handle500(errorHttp)
        }
      }),
      switchMap<HttpError, HttpError>(httpError => throwError(httpError))
    )
  }

  private handle500(httpError: HttpError) {
    this.logger.error('http error', httpError)
    this.handleErrorAndAvailability(httpError)
  }

  private handleErrorAndAvailability(httpError?: HttpError) {
    this.checkAvailability().subscribe(
      (available: boolean) => {
        if (available) {
          this.logger.debug('monitoring says systems are all available')
          // system is available so we display the received error code or the general application error
          if (httpError && httpError.code) {
            this.notificationCenterService.error(HttpErrorCodes.getKeyForCode(httpError.code))
          } else {
            this.notificationCenterService.error(HttpErrorCodes.KEY_PREFIX + HttpErrorCodes.GENERAL_APPLICATION_ERROR)
          }
        } else {
          // not all services available
          this.startAvailabilityPolling()
        }
      },
      () => {
        // monitoring not available
        this.startAvailabilityPolling()
      }
    )
  }

  private handleUnauthorized(resp: Response) {
    // we only clear the token - what should happen is the responsibility of the current active route
    if (this.logoutHandler) {
      this.logoutHandler()
    }
  }

  private handleClientOffline() {
    this.clientOfflineDisplayed = true
    // For every response with status 0 and url 0 we check clients general internet connectivity. If client is offline we check the system availability.
    // If the system is partly or completely offline we start monitoring polling
    this.head('/assets/icon/ALWAYS_ONLINE_DO_NOT_DELETE.svg?_=' + new Date().getTime()).subscribe(
      response => {
        // client is ONLINE. -> now check system status
        this.clientOfflineDisplayed = false
        this.notificationCenterService.remove(AppHttp.NOTIFICATION_ID_CLIENT_OFFLINE)
        this.handleErrorAndAvailability()
      },
      error => {
        // client is offline -> show message but do not check system status yet
        this.notificationCenterService
          .builder()
          .setId(AppHttp.NOTIFICATION_ID_CLIENT_OFFLINE)
          .setDuration(5000)
          .setSeverity(NotificationSeverity.ERROR)
          .setKey('UNAVAILABLE.CLIENT_OFFLINE')
          .show()
          .subscribe(
            () => {},
            () => {},
            () => {
              this.clientOfflineDisplayed = false
              // recheck again
              this.handleClientOffline()
            }
          )
      }
    )
  }

  // MONITORING

  private checkAvailability(): Observable<boolean> {
    return this.get<boolean>(
      this.runtimeConfiguration.getUrlForRestService(MicroService.MONITORING) + AppHttp.URI_MONITORING_AVAILABLE
    ).pipe(map((response: AppHttpResponse<boolean>) => response.body))
  }

  private startAvailabilityPolling() {
    if (!this.monitoringPollingSub) {
      this.notificationCenterService
        .builder()
        .setId(AppHttp.NOTIFICATION_ID_UNAVAILABLE)
        .setSticky(true)
        .setSeverity(NotificationSeverity.ERROR)
        .setKey('UNAVAILABLE.SERVICES_UNAVAILABLE')
        .show()

      this.monitoringPollingSub = timer(5000, 5000).subscribe(() => {
        this.checkAvailability()
          .pipe(filter(available => available))
          .subscribe((updatedAvailability: boolean) => {
            this.notificationCenterService.remove(AppHttp.NOTIFICATION_ID_UNAVAILABLE)
            if (this.monitoringPollingSub) {
              this.monitoringPollingSub.unsubscribe()
            }
            this.monitoringPollingSub = null
            this.notificationCenterService.success('UNAVAILABLE.SERVICES_AVAILABLE')
            this.router.navigateByUrl('/home')
          })
      })
    }
  }

  private readBlobAsJson = <T>(blob: Blob): Observable<T> => {
    return Observable.create(obs => {
      if (!(blob instanceof Blob)) {
        obs.error(new Error('`blob` must be an instance of File or Blob.'))
        return
      }

      const reader = new FileReader()

      reader.onerror = err => obs.error(err)
      reader.onabort = err => obs.error(err)
      reader.onload = () => obs.next(JSON.parse(reader.result))
      reader.onloadend = () => obs.complete()

      return reader.readAsText(blob, 'utf-8')
    })
  }
}
