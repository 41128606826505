import { LanguageSwitcherItem } from '../../components/language-switcher/language-switcher-item.model'

export class LocaleHelper {
  static defaultLocale = 'en-US'
  private static availableLocaleKeys = ['en-US', 'de-CH', 'fr-CH', 'it-CH']
  private static availableLocaleLabels: { [key: string]: string } = {
    ['en-US']: 'English',
    ['de-CH']: 'Deutsch',
    ['fr-CH']: 'Français',
    ['it-CH']: 'Italiano',
  }

  private static localStorageKey = 'NG_TRANSLATE_LANG_KEY'
  private static browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage']

  static isPossibleLocale(locale: string): boolean {
    return LocaleHelper.availableLocaleKeys.includes(locale)
  }

  static isPossibleLanguage(lang: string): boolean {
    const languages = LocaleHelper.availableLocaleKeys.map(LocaleHelper.localeToLanguage)
    return languages.includes(lang)
  }

  static getLanguageSwitcherItems(): LanguageSwitcherItem[] {
    return LocaleHelper.availableLocaleKeys.map((locale: string) => {
      return {
        locale,
        language: LocaleHelper.localeToLanguage(locale),
        label: LocaleHelper.availableLocaleLabels[locale],
      }
    })
  }

  static matchWithAvailableLanguageKeys(proposedLanguage: string): string {
    // exact match
    return (
      LocaleHelper.availableLocaleKeys.find((availableLanguage): boolean => {
        return availableLanguage.toLowerCase() === proposedLanguage.toLowerCase()
      }) ||
      // best possible match 'language only' de-CH, de-DE --> de
      LocaleHelper.availableLocaleKeys.find((availableLanguage): boolean => {
        return LocaleHelper.localeToLanguage(availableLanguage) === LocaleHelper.localeToLanguage(proposedLanguage)
      }) ||
      // Fallback
      LocaleHelper.defaultLocale
    )
  }

  private static localeToLanguage(locale: string): string {
    return locale.split('-')[0]
  }
}
