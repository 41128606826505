import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ImageOrientationDirective } from './image-orientation.directive'

@NgModule({
  imports: [CommonModule],
  declarations: [ImageOrientationDirective],
  exports: [ImageOrientationDirective],
})
export class ImageOrientationModule {}
