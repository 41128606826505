import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { IconModule, LoadingIndicatorModule, Modal2Module } from '@maprix/components'
import { LoopingCoreModule } from '../../../looping-core.module'
import { EmbedIframeModule } from '../embed-iframe/embed-iframe.module'
import { ResourceViewerModalComponent } from './resource-viewer-modal.component'
import { ResourceViewerUrlModalComponent } from './resource-viewer-url-modal.component'
import { ResourceViewerComponent } from './resource-viewer.component'
import { ResourceViewerService } from './resource-viewer.service'

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    Modal2Module,
    EmbedIframeModule,
    LoopingCoreModule,
    LoadingIndicatorModule,
  ],
  declarations: [ResourceViewerModalComponent, ResourceViewerUrlModalComponent, ResourceViewerComponent],
  entryComponents: [ResourceViewerModalComponent, ResourceViewerUrlModalComponent],
  exports: [ResourceViewerComponent],
})
export class ResourceViewerModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ResourceViewerModule,
      providers: [ResourceViewerService],
    }
  }
}
