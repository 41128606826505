import { Injectable } from '@angular/core'
import { Modal2Service } from '@maprix/components'
import { Logger, LoggerService } from '@maprix/core'
import { PrototypeResourceType } from '../../models/resource/prototype-resource-type.enum'
import { UrlResource } from '../../models/resource/url-resource.model'
import { ResourceViewerData } from './resource-viewer-data.model'
import { ResourceViewerModalComponent } from './resource-viewer-modal.component'
import { ResourceViewerUrlModalComponent } from './resource-viewer-url-modal.component'

@Injectable()
export class ResourceViewerService {
  private logger: Logger

  constructor(loggerService: LoggerService, private modalService: Modal2Service) {
    this.logger = loggerService.getInstance('ResourceViewerService')
  }

  open(data: ResourceViewerData): void {
    if (data.resources[0] !== undefined && data.resources[0].type !== PrototypeResourceType.URL) {
      data.index = data.index !== undefined ? data.index : 0
      this.modalService.open<ResourceViewerModalComponent, void>(ResourceViewerModalComponent, {data})
    } else if (
      data.index !== undefined &&
      data.resources[data.index] !== undefined &&
      data.resources[data.index].type === PrototypeResourceType.URL
    ) {
      const urlResource: UrlResource = <UrlResource>data.resources[data.index]
      this.modalService.open<ResourceViewerUrlModalComponent, void>(ResourceViewerUrlModalComponent, {data: { url: urlResource.url }})
    } else {
      this.logger.warn('Either no resources or no index for url type was passed.')
    }
  }

}
