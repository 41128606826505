import { ModuleWithProviders } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CanActivateEnterpriseLoggedInGuard } from '../../shared/services/auth/can-activate-enterprise-logged-in.guard'
import { CanActivateRequestedUserGuard } from '../../shared/services/auth/can-activate-requested-user.guard'
import { UploadComponent } from './upload.component'

export const routing: ModuleWithProviders = RouterModule.forChild([
  {
    path: 'upload',
    canActivate: [CanActivateRequestedUserGuard, CanActivateEnterpriseLoggedInGuard],
    component: UploadComponent,
  },
])
