import { Component, Input, OnInit } from '@angular/core'
import { Logger, LoggerService } from '@maprix/core'
import { OEmbedProvider } from '../../models/resource/o-embed-provider.enum'
import { OEmbedResource } from '../../models/resource/o-embed-resource.model'
import { PrototypeResourceType } from '../../models/resource/prototype-resource-type.enum'
import { PrototypeResource } from '../../models/resource/prototype-resource.model'

import { ResourceViewerService } from './resource-viewer.service'

@Component({
  selector: 'scs-resource-viewer',
  templateUrl: './resource-viewer.component.html',
  styleUrls: ['./resource-viewer.component.scss'],
})
export class ResourceViewerComponent implements OnInit {
  @Input() index: number
  @Input() resources: PrototypeResource[]

  type: PrototypeResourceType
  typesView: any = PrototypeResourceType
  isVideo = false
  isAudio = false
  private logger: Logger

  constructor(loggerService: LoggerService, private resourceViewerService: ResourceViewerService) {
    this.logger = loggerService.getInstance('ResourceViewerComponent')
  }

  ngOnInit() {
    if (this.resources !== undefined && this.resources[0] !== undefined) {
      this.type = this.resources[0].type
      if (this.type === PrototypeResourceType.O_EMBED && (<OEmbedResource>this.resources[0]).oEmbed) {
        this.isVideo =
          (<OEmbedResource>this.resources[0]).oEmbed.oEmbedProvider === OEmbedProvider.VIMEO ||
          (<OEmbedResource>this.resources[0]).oEmbed.oEmbedProvider === OEmbedProvider.YOUTUBE
        this.isAudio =
          (<OEmbedResource>this.resources[0]).oEmbed.oEmbedProvider === OEmbedProvider.SOUNDCLOUD ||
          (<OEmbedResource>this.resources[0]).oEmbed.oEmbedProvider === OEmbedProvider.MIXCLOUD
      }
    } else {
      this.logger.warn('No resources passed, pass at least one resource to be displayed.')
    }
  }

  openResource(event: Event) {
    // used for the variant selection, so variant gets not selected
    event.stopPropagation()
    this.resourceViewerService.open({ index: this.index, resources: this.resources })
  }
}
