import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { AccordionContentDirective } from './accordion-content.directive'
import { AccordionGroupComponent } from './accordion-group.component'
import { AccordionHeaderDirective } from './accordion-header.directive'
import { AccordionComponent } from './accordion.component'

@NgModule({
  imports: [CommonModule],
  declarations: [AccordionGroupComponent, AccordionContentDirective, AccordionHeaderDirective, AccordionComponent],
  exports: [AccordionGroupComponent, AccordionContentDirective, AccordionHeaderDirective, AccordionComponent],
})
export class AccordionModule {}
