import { Directive, TemplateRef } from '@angular/core'

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[scs-tab-header]',
})
export class TabHeaderDirective {
  // only catch the templateRef to query inside TabComponent
  constructor(public templateRef: TemplateRef<any>) {}
}
