import { Injectable } from '@angular/core'
import { ProjectStage, RuntimeConfiguration, ServiceType, ServiceTypeName, UrlType, WindowRef } from '@maprix/core'
import { isEmpty } from 'lodash'
import { environment } from '../../../../../environments/environment'
import { Enums } from '../../../helpers/enums'
import { MicroService } from './micro-service.enum'

/* ----------------------------------------------------------------------
 *
 * NON PROD CONSTANTS
 *
 ---------------------------------------------------------------------- */
const ANALYTICS_NON_PROD = 'GTM-TJRTFSG'

/* ----------------------------------------------------------------------
 *
 * PROD CONSTANTS
 *
 ---------------------------------------------------------------------- */
const ANALYTICS_PROD = 'GTM-TJRTFSG'

/* ----------------------------------------------------------------------
 *
 * SAME FOR ALL ENVIRONMENTS
 *
 ---------------------------------------------------------------------- */
const LOGGLY_TOKEN = 'd9a65332-093a-4fc5-be4d-25b7f3e3c79a'

function getQueryVariable(variable, location) {
  const query = location.search.substring(1)
  const vars = query.split('&')
  for (const v of vars) {
    const pair = v.split('=')
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1])
    }
  }
  return null
}

@Injectable()
export class RuntimeConfigurationService extends RuntimeConfiguration {
  private static SERVICES = [
    // new ServiceType(ServiceTypeName.REST, 'http:', 'https:', 'rest/'),
    new ServiceType(ServiceTypeName.REST, 'http:', 'https:', 'rest/'),
    new ServiceType(ServiceTypeName.WEBSOCKET, 'ws:', 'wss:', 'ws'),
  ]
  // private static ENDPOINT_NAMING_PATTERN = '{{protocol}}//scs-flux-{{microService}}-{{projectStage}}.looping.com/{{uri}}'
  // private static ENDPOINT_NAMING_PATTERN_VARIANT = '{{protocol}}//scs-flux-{{microService}}-{{appVariant}}-{{projectStage}}.looping.com/{{uri}}'
  private static ENDPOINT_NAMING_PATTERN = '{{protocol}}//{{hostname}}/{{uri}}'
  private static ENDPOINT_NAMING_PATTERN_VARIANT = '{{protocol}}//{{hostname}}/{{uri}}'
  // private static ENDPOINT_NAMING_PATTERN_LOCAL = '{{protocol}}//{{hostname}}:{{port}}/{{uri}}'
  private static ENDPOINT_NAMING_PATTERN_LOCAL = '{{protocol}}//{{hostname}}/{{uri}}'
  private static DEFAULT_APP_NAME = 'scs-flux-client'
  private static DEFAULT_APP_VARIANT = ''
  private static DEFAULT_BASE_PORT = 8080

  // Values for all envs the same
  logglyToken: string = LOGGLY_TOKEN

  protected endpointMap: Map<number, Map<ServiceType, string>> = new Map<number, Map<ServiceType, string>>()
  projectStage: ProjectStage
  urlType: UrlType
  appName: string
  appVariant: string

  private static replace(stringWithTokens: string, context: any): string {
    let stringReplaced = stringWithTokens
    for (const replaceKey in context) {
      if (context.hasOwnProperty(replaceKey)) {
        const regex = new RegExp('{{' + replaceKey + '}}')
        stringReplaced = stringReplaced.replace(regex, context[replaceKey])
      }
    }
    return stringReplaced
  }

  constructor(windowRef: WindowRef) {
    super()
    this.determinateEnv(windowRef.nativeWindow)
  }

  getUrlForRestService(microService: MicroService): string {
    return this.endpointMap.get(microService)!.get(RuntimeConfigurationService.SERVICES[0])!
  }

  getUrlForWebSocketService(microService: MicroService): string {
    return this.endpointMap.get(microService)!.get(RuntimeConfigurationService.SERVICES[1])!
  }

  isMicroServiceUrl(url: string | null): boolean {
    return url === null
      ? false
      : Enums.getValues(MicroService).some(elem => {
          return url.indexOf(this.getUrlForRestService(elem)) !== -1
        })
  }

  isProduction() {
    return this.projectStage === ProjectStage.PRODUCTION
  }

  getAnalyticsId(): string {
    return ANALYTICS_PROD
    // if (this.isProduction()) {
    //   return ANALYTICS_PROD
    // } else {
    //   return ANALYTICS_NON_PROD
    // }
  }

  private determinateEnv(window: Window) {
    const location: Location = window.location
    console.log('LOCATION', location);

    // defaults
    this.appName = RuntimeConfigurationService.DEFAULT_APP_NAME
    this.appVariant = RuntimeConfigurationService.DEFAULT_APP_VARIANT
    this.projectStage = ProjectStage.DEVELOPMENT

    // parse hostname
    const parts: string[] = location.hostname.split('.')

    if (parts.length > 1) {
      if (isFinite(parseInt(parts[0], 10))) {
        // using ip address assuming dev this.
        this.urlType = UrlType.IP
      } else {
        this.urlType = UrlType.DNS
        // split the subdomain string (scs-flux-client-development.scapp.io, www.looping.com)
        const subdomainParts: string[] = parts[0].split('-')
        // if there is more than one value (NOT www --> prod)
        if (subdomainParts.length > 1) {
          this.projectStage = Enums.fromString(ProjectStage, subdomainParts[subdomainParts.length - 1])
          // remove project stage and join for name (used for logging and service urls)
          subdomainParts.splice(-1, 1)
          if (subdomainParts.length > 3) {
            this.appVariant = subdomainParts.splice(-1, 1)[0]
          }
        } else {
          this.projectStage = ProjectStage.PRODUCTION
        }
      }
    } else {
      this.urlType = UrlType.LOCAL
    }

    // detect unit tests
    if (window['karma'] || window['__karma__']) {
      this.projectStage = ProjectStage.UNIT_TEST
    }

    // detect e2e tests
    if (environment.e2etest) {
      this.projectStage = ProjectStage.E2ETEST
      this.urlType = UrlType.DNS
    }

    // detect e2e tests nightly to use dev systems
    if (environment.e2e) {
      this.projectStage = ProjectStage.DEVELOPMENT
      this.urlType = UrlType.DNS
    }

    const overrideProjectStage: string | null = getQueryVariable('projectStage', location)
    if (overrideProjectStage != null) {
      this.projectStage = Enums.fromString(ProjectStage, overrideProjectStage)
    }

    const overrideUrlType: string | null = getQueryVariable('urlType', location)
    if (overrideUrlType != null) {
      this.urlType = Enums.fromString(UrlType, overrideUrlType)
    }

    Enums.getNames(MicroService).forEach(serviceName => {
      console.log('SERVICE NAME', serviceName);
      let serviceUrls: Map<ServiceType, string>
      const host = ''

      if (this.urlType !== UrlType.DNS) {
        const context = {
          port: (
            RuntimeConfigurationService.DEFAULT_BASE_PORT + Enums.fromString(MicroService, serviceName)
          ).toString(),
          hostname: location.hostname,
        }

        //NGTI
        if (serviceName === 'AUTH') {
          context.hostname = 'auth.feedback.rready.com';
        } else if (serviceName === 'MONITORING') {
          context.hostname = 'monitoring.feedback.rready.com';
        } else if (serviceName === 'CORSPROXY') {
          context.hostname = 'corsproxy.feedback.rready.com'
        } else if (serviceName === 'WEBSCREENSHOT') {
          context.hostname = 'webscreenshot.feedback.rready.com'
        } else {
          context.hostname = 'main.feedback.rready.com';
        }


        // serviceUrls = this.getServiceUrls(
        //   RuntimeConfigurationService.ENDPOINT_NAMING_PATTERN_LOCAL,
        //   context,
        //   location.protocol === 'https'
        // )
        serviceUrls = this.getServiceUrls(
          RuntimeConfigurationService.ENDPOINT_NAMING_PATTERN_LOCAL,
          context,
          location.protocol === 'https'
        )
      } else {
        // const context = {
        //   microService: serviceName.toLowerCase(),
        //   projectStage: Enums.fromNumber(ProjectStage, this.projectStage).toLowerCase(),
        //   appVariant: this.appVariant,
        // }
        const context = {
          port: (
            RuntimeConfigurationService.DEFAULT_BASE_PORT + Enums.fromString(MicroService, serviceName)
          ).toString(),
          hostname: location.hostname,
        }

        // if (isEmpty(this.appVariant)) {
        //   serviceUrls = this.getServiceUrls(RuntimeConfigurationService.ENDPOINT_NAMING_PATTERN, context)
        // } else {
        //   serviceUrls = this.getServiceUrls(RuntimeConfigurationService.ENDPOINT_NAMING_PATTERN_VARIANT, context)
        // }

        //NGTI
        if (serviceName === 'AUTH') {
          context.hostname = 'auth.feedback.rready.com';
        } else if (serviceName === 'MONITORING') {
          context.hostname = 'monitoring.feedback.rready.com';
        } else if (serviceName === 'CORSPROXY') {
          context.hostname = 'corsproxy.feedback.rready.com'
        } else if (serviceName === 'WEBSCREENSHOT') {
          context.hostname = 'webscreenshot.feedback.rready.com'
        } else {
          context.hostname = 'main.feedback.rready.com';
        }

        serviceUrls = this.getServiceUrls(
          RuntimeConfigurationService.ENDPOINT_NAMING_PATTERN_LOCAL,
          context,
          location.protocol === 'https'
        )

        // if (isEmpty(this.appVariant)) {
        //   serviceUrls = this.getServiceUrls(RuntimeConfigurationService.ENDPOINT_NAMING_PATTERN_LOCAL, context)
        // } else {
        //   serviceUrls = this.getServiceUrls(RuntimeConfigurationService.ENDPOINT_NAMING_PATTERN_LOCAL, context)
        // }
      }


      // console.log('SERVICE URL', serviceUrls);
      this.endpointMap.set(Enums.fromString(MicroService, serviceName), serviceUrls)
    })
  }

  private getServiceUrls(pattern: string, context: any, secure: boolean = true): Map<ServiceType, string> {
    const serviceUrls: Map<ServiceType, string> = new Map<ServiceType, string>()
    RuntimeConfigurationService.SERVICES.forEach((service: ServiceType) => {
      // allow protocol
      context.protocol = secure ? service.protocolSecure : service.protocolSecure
      context.uri = service.uri

      serviceUrls.set(service, RuntimeConfigurationService.replace(pattern, context))
    })

    return serviceUrls
  }
}
