import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { CheckboxModule, LoadingIndicatorModule, Modal2Module, StateButtonModule } from '@maprix/components'
import { LoopingCoreModule } from '../../../looping-core.module'
import { InlineMessageModule } from '../inline-message/inline-message.module'
import { EditExpertisesModalComponent } from './edit-expertises-modal.component'

@NgModule({
  imports: [
    CommonModule,

    LoopingCoreModule,
    CheckboxModule,
    StateButtonModule,
    InlineMessageModule,
    LoadingIndicatorModule,
    Modal2Module,
  ],
  declarations: [EditExpertisesModalComponent],
  entryComponents: [EditExpertisesModalComponent],
})
export class EditExpertisesModalModuleModule {}
