import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { LoadingIndicatorModule, Modal2Module, StateButtonModule } from '@maprix/components'
import { LoopingCoreModule } from '../../../looping-core.module'
import { InlineMessageModule } from '../inline-message/inline-message.module'
import { PrivacyPolicyModalComponent } from './privacy-policy-modal.component'
import { PrivacyPolicyService } from './privacy-policy.service'

@NgModule({
  imports: [CommonModule,
    LoopingCoreModule,
    InlineMessageModule,
    StateButtonModule,
    Modal2Module,
    LoadingIndicatorModule,
  ],
  declarations: [PrivacyPolicyModalComponent],
  entryComponents: [PrivacyPolicyModalComponent],
  providers: [PrivacyPolicyService],
})
export class PrivacyPolicyModalModule {}
