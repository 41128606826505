import { PrototypeType } from '../../models/prototype/prototype-type.enum'

export type PolaroidFacet = 'base' | 'bare' | 'add' | 'feedback-variant' | 'feedback-variant-selected'

export function getPolaroidFacet(type: PrototypeType): PolaroidFacet {
  let result: PolaroidFacet

  switch (type) {
    case PrototypeType.SIMPLE_PROTOTYPE:
      result = 'feedback-variant'
      break
    default:
      result = 'base'
      break
  }

  return result
}
