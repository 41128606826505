import { NgModule, Optional, SkipSelf } from '@angular/core'
import { FileUploadService } from './file-upload.service'

@NgModule({
  imports: [],
  providers: [FileUploadService],
})
export class FileUploadModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: FileUploadModule
  ) {
    if (parentModule) {
      throw new Error('FileUploadModule is already loaded. Import it only once')
    }
  }
}
