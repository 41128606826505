import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core'
import { LoggerService, ScrollToService } from '@maprix/core'
import { Observable, Subscription } from 'rxjs'
import { LoginStateData } from '../models/login-state-data'
import { BaseLoginStepComponent } from './base/base-login-step.component'

@Component({
  selector: 'scs-forgot-password',
  templateUrl: 'login-forgot-password.component.html',
})
export class LoginForgotPasswordComponent extends BaseLoginStepComponent implements OnInit, OnChanges {
  @Input() data: LoginStateData
  @Input() worker$: Observable<any>

  @Output() back: EventEmitter<null> = new EventEmitter<null>()
  @Output() next: EventEmitter<null> = new EventEmitter<null>()

  success: string | null
  subscription: Subscription

  sent: boolean

  constructor(loggerService: LoggerService, private scrollToService: ScrollToService) {
    super(loggerService.getInstance('LoginForgotPassword'))
  }

  ngOnInit() {
    this.scrollToService.scrollTo()
  }

  ngOnChanges(changes: SimpleChanges): void {
    const worker: SimpleChange = changes['worker$']
    if (worker && worker.currentValue) {
      this.subscription = (<Observable<any>>worker.currentValue).subscribe(() => {
        this.logger.debug('sent password link')
        this.success = 'LOGIN.FORGOT_PASSWORD.MSG_SUCCESS'
        this.sent = true
      })
    }
  }

  onNext(): void {
    super.onNext()
    this.success = null
  }
}
