import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Logger, LoggerService } from '@maprix/core'
import { Observable, Subscription } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, filter, map, share, switchMap, tap } from 'rxjs/operators'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { createAmountValidator } from '../../../../shared/components/file-selector/validators/amount.validator'
import { createFileTypeValidator } from '../../../../shared/components/file-selector/validators/file-type.validator'
import { createFileSizeValidator } from '../../../../shared/components/file-selector/validators/max-file-size.validator'
import { HttpError } from '../../../../shared/models/http/http-error'
import { PrototypeResourceType } from '../../../../shared/models/resource/prototype-resource-type.enum'
import { PrototypeResource } from '../../../../shared/models/resource/prototype-resource.model'
import { DataObject } from '../../../../shared/services/file-upload/data-object.model'
import { FileUploadService } from '../../../../shared/services/file-upload/file-upload.service'
import { AppHttp } from '../../../../shared/services/http/app-http.service'
import { PrototypeResourceService } from './prototype-resource.service'

@Component({
  selector: 'scs-image-resource-upload',
  templateUrl: './image-resource-upload.component.html',
})
export class ImageResourceUploadComponent implements OnInit, OnDestroy {
  private static RESOURCE_URL_UPLOAD_CROPPED = 'secure/resources/image/croppedimage'
  private static MAX_FILE_SIZE = 8 * 1024 * 1024
  private static FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png']


  orgShortCut$ = this.authService.authUser != null ? this.authService.authUser.organization.name : '';


  @Output() prototypeResource: EventEmitter<PrototypeResource> = new EventEmitter<PrototypeResource>()

  form: FormGroup
  image: DataObject | null
  imageUrl: string | null
  croppedImage: DataObject
  showLoader = false
  urlError: any
  uploadWorker: Observable<any>

  private logger: Logger
  private uploadSubscription: Subscription | null

  constructor(
    loggerService: LoggerService,
    formBuilder: FormBuilder,
    private fileUploadService: FileUploadService,
    private resourceService: PrototypeResourceService,
    private appHttp: AppHttp,
    private authService: AuthService
  ) {
    this.logger = loggerService.getInstance('ImageResourceUploadComponent')
    const pattern = this.resourceService.getValidationRegexForUrl(PrototypeResourceType.IMAGE).source

    this.form = formBuilder.group({
      url: ['', [Validators.pattern(pattern)]],
      file: [
        '',
        [
          createAmountValidator(1),
          createFileTypeValidator(ImageResourceUploadComponent.FILE_TYPES),
          createFileSizeValidator(ImageResourceUploadComponent.MAX_FILE_SIZE),
        ],
      ],
    })
  }

  onCroppedImageChange(blob: Blob): void {
    this.logger.debug('the cropped image changed', blob)
    this.croppedImage = { data: blob }
  }

  /**
   * We always display one single message, so we use this method to return the first error key
   * and display or hide using ngIf match
   * @returns {any}
   */
  getFirstErrorKey(): string | null {
    if (this.form.valid || !this.form.get('file').errors) {
      return null
    } else {
      const errors: any = this.form.get('file').errors
      return Object.keys(errors)[0]
    }
  }

  ngOnInit() {
    this.logger.debug('Init..')

    // immediately reset url error when value changes
    this.form
      .get('url')
      .valueChanges.pipe(
        tap<string>(newUrl => {
          this.urlError = null
          this.logger.debug('newUrl')
        }),
        debounceTime(100),
        distinctUntilChanged(),
        filter<string>(url => this.form.get('url').valid && !!url && !!url.length), // valid and not empty
        switchMap(url => {
          this.showLoader = true
          const normalizedUrl = PrototypeResourceService.normalizeUrl(url)
          return this.fileUploadService.download(normalizedUrl)
          // return this.fileUploadService.downloadDirect(normalizedUrl)
        }),
        catchError((err: HttpError, caught: Observable<DataObject | null>): Observable<DataObject | null> => {
          this.logger.debug(err)
          this.showLoader = false
          this.urlError = err
          return caught
        })
      )
      .subscribe(
        // SUCCESS
        (downloadedFile: DataObject) => {
          this.logger.debug(downloadedFile)
          if (downloadedFile) {
            this.showLoader = false
            this.form.get('file').reset()
            this.setNewImage(downloadedFile)
          }
        }
      )

    this.form
      .get('file')
      .valueChanges.pipe(filter(files => files && files.length), distinctUntilChanged())
      .subscribe((files: FileList) => {
        if (this.form.get('file').valid) {
          this.form.get('url').reset()
          this.setNewImage({ data: files.item(0) })
        } else {
          this.image = null
        }
      })
  }

  ngOnDestroy() {
    this.fileUploadService.revokeObjectUrl(this.imageUrl)
    this.cancelUploadRequest()
  }

  onSubmit() {
    this.logger.debug('upload image')

    // upload the original and the cropped file and combine both to one prototype resource
    this.uploadWorker = this.fileUploadService
      .uploadMultiPart([this.image!, this.croppedImage], ImageResourceUploadComponent.RESOURCE_URL_UPLOAD_CROPPED)
      .pipe(map(response => response.body), share())

    this.uploadSubscription = this.uploadWorker.subscribe(
      resource => this.prototypeResource.emit(resource),
      this.appHttp.handle400
    )
  }

  onFileDrop(fileList: FileList) {
    this.logger.debug('got dropped fileList %o', fileList)
    // trigger value change to run the validators and display possible error messages
    this.form.get('file').setValue(fileList)
  }

  clearImage(): void {
    this.form.get('url').reset()
    this.form.get('file').reset()
    this.image = null
    this.fileUploadService.revokeObjectUrl(this.imageUrl)
    this.imageUrl = null
    this.cancelUploadRequest()
  }

  private setNewImage(dataObject: DataObject) {
    this.image = dataObject
    this.fileUploadService.revokeObjectUrl(this.imageUrl)
    this.imageUrl = this.fileUploadService.toObjectUrlSingle(dataObject.data)
    this.cancelUploadRequest()
  }

  private cancelUploadRequest(): void {
    if (this.uploadSubscription && !this.uploadSubscription.closed) {
      this.uploadSubscription.unsubscribe()
    }
  }
}
