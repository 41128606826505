import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { Modal2Service, NotificationCenterService } from '@maprix/components'
import { Logger, LoggerService } from '@maprix/core'
import { Observable, of } from 'rxjs'
import { first, map, mergeMap } from 'rxjs/operators'
import { LoginModalComponent } from '../../../routes/login/login-modal.component'
import { ApplicationRoles } from '../../models/application-roles'
import { AuthUser } from '../../models/user/auth-user'
import { AuthService } from './auth.service'

export interface CanActivateRoleConfig {
  role: ApplicationRoles
  redirectTo: string
  warningKey: string
}

const KEY_EMAIL_NOT_VERIFIED_WARNING = 'HTTP_ERROR.403:002:ACCESS_DENIED_EMAIL_NOT_VERIFIED'
const KEY_NOT_LOGGED_IN = 'NOTIFICATIONS.NOT_LOGGED_IN'
const ROUTE_LOGIN = '/login'

@Injectable()
export class CanActivateRoleService {
  private logger: Logger

  constructor(
    loggerService: LoggerService,
    private authService: AuthService,
    private modalService: Modal2Service,
    private router: Router,
    private notificationCenter: NotificationCenterService
  ) {
    this.logger = loggerService.getInstance('CanActivateRoleService')
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    config: CanActivateRoleConfig
  ): Observable<boolean> {
    if (this.authService.isLoggedIn()) {
      return this.authService.authUserChanges.pipe(
        first(),
        map((authUser: AuthUser | null): boolean => {
          if (!AuthService.hasRole(authUser, config.role)) {
            this.notificationCenter.warn(config.warningKey)
            this.router.navigateByUrl(config.redirectTo)
            return false
          } else if (!this.authService.isEmailVerified()) {
            this.notificationCenter.warn(KEY_EMAIL_NOT_VERIFIED_WARNING)
            this.router.navigateByUrl(config.redirectTo)
            return false
          } else {
            return true
          }
        })
      )
    } else {
      return this.modalService.open<LoginModalComponent, boolean>(LoginModalComponent).afterClosed.pipe(
        mergeMap(loggedIn => {
          if (loggedIn) {
            return this.authService.authUserChanges.pipe(
              first(),
              map((authUser: AuthUser | null) => {
                if (!AuthService.hasRole(authUser, config.role)) {
                  this.notificationCenter.warn(config.warningKey)
                  this.router.navigateByUrl(config.redirectTo)
                  this.logger.info(`not authorized with ${config.role} -> redirected to ${config.redirectTo}`)
                  return false
                } else {
                  this.router.navigateByUrl(state.url, { queryParamsHandling: 'preserve', preserveFragment: true })
                  return true
                }
              })
            )
          } else {
            this.notificationCenter.warn(KEY_NOT_LOGGED_IN)
            this.router.navigateByUrl(ROUTE_LOGIN)
            return of(false)
          }
        })
      )
    }
  }
}
