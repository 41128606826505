import * as moment from 'moment'
import { ProfileUser } from '../user/profile-user.model'
import { Feedback } from './feedback.model'
import { Prototype, UploadPrototype } from './prototype.model'

export enum StoredClientDataType {
  PROTOTYPE,
  FEEDBACK,
}

export interface PrototypeData {
  prototype: UploadPrototype
}

export interface FeedbackData {
  feedback: Feedback
  prototype: Prototype
}

export interface StoredClientData<T> {
  id: string
  user: ProfileUser
  clientId: string
  expiresAtDate: moment.Moment
  type: StoredClientDataType
  data: T
}
