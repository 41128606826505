import { Component, Inject, OnInit } from '@angular/core'
import { Modal2, MODAL2_DATA } from '@maprix/components'
import { LocaleService, Logger, LoggerService } from '@maprix/core'
import { first } from 'rxjs/operators'
import { FeedbackStyle } from '../../../../shared/models/prototype/feedback-style.enum'
import { Prototype } from '../../../../shared/models/prototype/prototype.model'
import { AuthService } from '../../../../shared/services/auth/auth.service'

export interface PublishedPrototypeData {
  prototype: Prototype
}

@Component({
  selector: 'scs-published-prototype-modal',
  templateUrl: './published-prototype-modal.component.html',
  styleUrls: ['./published-prototype-modal.component.scss'],
})
@Modal2({
  size: 'xs'
})
export class PublishedPrototypeModalComponent implements OnInit {
  feedbackStyleView: any = FeedbackStyle
  orgName: string

  private logger: Logger

  constructor(loggerService: LoggerService,
              authService: AuthService,
              private localeService: LocaleService,
              @Inject(MODAL2_DATA) public modalData: PublishedPrototypeData) {
    this.logger = loggerService.getInstance('PublishedPrototypeModalComponent')
    authService.authUserChanges.pipe(first()).subscribe(user => {
      this.orgName = user.organization.name ? user.organization.name : user.organization.shortcut
    })
  }

  ngOnInit() {}

  getFormatedDate(): string {
    // FIXME SG MED depending on locale
    if (this.localeService.currentLocale == 'de-CH') {
      let formattedDate = this.modalData.prototype.endDate.format('dddd, Do MMM [at] HH:mm') as string;
      formattedDate = formattedDate.replace('at', 'um');
      return formattedDate;
    }
    return this.modalData.prototype.endDate.format('dddd, Do MMM [at] HH:mm')
  }
}
