import { NgModule, Optional, SkipSelf } from '@angular/core'
import { CoreComponentsModule } from './components/core-components.module'
import { CoreServiceModule } from './services/core-service.module'

@NgModule({
  imports: [CoreServiceModule, CoreComponentsModule],
  exports: [CoreComponentsModule],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it only once in AppModule')
    }
  }
}
