import { NgModule, Optional, SkipSelf } from '@angular/core'
import { AuthOrganizationService } from './auth-organization.service'
import { OrganizationService } from './organization.service'
import { PublicOrganizationService } from './public-organization.service'

@NgModule({
  imports: [],
  providers: [OrganizationService, AuthOrganizationService, PublicOrganizationService],
})
export class OrganizationModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: OrganizationModule
  ) {
    if (parentModule) {
      throw new Error('OrganizationModule is already loaded. Import it only once')
    }
  }
}
