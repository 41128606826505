import { TagInputError } from './tag-input-error.model'

export class TagInputHttpError extends Error {
  tagInputError: TagInputError

  constructor() {
    super()

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, TagInputHttpError.prototype)
  }
}
