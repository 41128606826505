import { NgModule, Optional, SkipSelf } from '@angular/core'
import { ScriptLoader } from './script-loader.service'

@NgModule({
  imports: [],
  providers: [ScriptLoader], // additional providers
})
export class ScriptLoaderModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ScriptLoaderModule
  ) {
    if (parentModule) {
      throw new Error('ScriptLoaderModule is already loaded. Import it only once')
    }
  }
}
