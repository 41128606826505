import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IconModule, StateButtonModule } from '@maprix/components'
import { LoopingCoreModule } from '../../../looping-core.module'
import { MobileViewDirective } from './directives/mobile-view.directive'
import { TableColumnDirective } from './directives/table-column.directive'
import { TableComponent } from './table.component'

@NgModule({
  imports: [CommonModule, IconModule, LoopingCoreModule, StateButtonModule],
  declarations: [TableComponent, TableColumnDirective, MobileViewDirective],
  exports: [TableComponent, TableColumnDirective, MobileViewDirective],
})
export class TableModule {}
