import { Component, Inject } from '@angular/core'
import { Modal2, MODAL2_DATA } from '@maprix/components'
import { PrototypePreviewData } from './prototype-preview-data.model'

@Component({
  selector: 'scs-preview-prototype-modal',
  templateUrl: './preview-prototype-modal.component.html',
  styleUrls: ['./preview-prototype-modal.component.scss'],
})
@Modal2({
  size: 'xs'
})
export class PreviewPrototypeModalComponent {
  constructor(@Inject(MODAL2_DATA) public modalData: PrototypePreviewData){}
}
