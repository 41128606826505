import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Logger, LoggerService } from '@maprix/core'
import { Observable, of, Subscription, throwError } from 'rxjs'
import {
  catchError,
  concat,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  share,
  switchMap,
  tap,
} from 'rxjs/operators'
import { Enums } from '../../../../shared/helpers/enums'
import { HttpError } from '../../../../shared/models/http/http-error'
import { UploadOEmbedResource } from '../../../../shared/models/resource/o-embed-resource.model'
import { PrototypeResourceType } from '../../../../shared/models/resource/prototype-resource-type.enum'
import { PrototypeResource } from '../../../../shared/models/resource/prototype-resource.model'
import { AppHttp } from '../../../../shared/services/http/app-http.service'
import { OEmbedResourceMetaData } from '../../../../shared/services/oembed/o-embed-resource-meta-data.model'
import { OEmbedRichResponse } from '../../../../shared/services/oembed/o-embed-rich-response.model'
import { OEmbedType } from '../../../../shared/services/oembed/o-embed-type.enum'
import { OEmbedVideoResponse } from '../../../../shared/services/oembed/o-embed-video-response.model'
import { OEmbedService } from '../../../../shared/services/oembed/o-embed.service'
import { PrototypeResourceService } from './prototype-resource.service'

@Component({
  selector: 'scs-o-embed-resource-upload',
  templateUrl: './o-embed-resource-upload.component.html',
})
export class OEmbedResourceUploadComponent implements OnInit, OnDestroy {
  private static KEY_PREFIX = 'PROTOTYPE.STEP_1.OEMBED.'

  @Input() oEmbedType: OEmbedType
  @Input() platforms: OEmbedResourceMetaData[]
  @Input() platformLogo: string

  @Output() prototypeResource: EventEmitter<PrototypeResource> = new EventEmitter<PrototypeResource>()

  form: FormGroup
  htmlResponse: Observable<string>
  labelKey: string
  placeholderKey: string
  showLoader = false
  urlValid = false
  urlError: string | null
  uploadWorker: Observable<any>

  private oembedResponseSubscribtion: Subscription
  private resource: UploadOEmbedResource
  private logger: Logger
  private errorKeyPrefix: string
  private uploadSubscription: Subscription | null

  constructor(
    loggerService: LoggerService,
    private formBuilder: FormBuilder,
    private oembedService: OEmbedService,
    private resourceService: PrototypeResourceService,
    private appHttp: AppHttp
  ) {
    this.logger = loggerService.getInstance('OEmbedResourceUploadComponent')
  }

  onSubmitUpload() {
    this.logger.debug('Form submitted, resource to upload : %O', this.resource)
    this.uploadWorker = this.resourceService.uploadNonBinaryResource(this.resource).pipe(share())

    this.uploadWorker.subscribe(result => {
      this.prototypeResource.emit(result.body)
    }, this.appHttp.handle400)
  }

  ngOnDestroy(): void {
    this.oembedResponseSubscribtion.unsubscribe()
    this.cancelUploadRequest()
  }

  ngOnInit() {
    this.logger.debug('init...')
    const preKey = OEmbedResourceUploadComponent.KEY_PREFIX + Enums.fromNumber(OEmbedType, this.oEmbedType)
    this.labelKey = OEmbedResourceUploadComponent.KEY_PREFIX + Enums.fromNumber(OEmbedType, this.oEmbedType) + '.LABEL'
    this.placeholderKey =
      OEmbedResourceUploadComponent.KEY_PREFIX + Enums.fromNumber(OEmbedType, this.oEmbedType) + '.PLACEHOLDER'
    this.errorKeyPrefix = preKey + '.ERRORS.'

    this.initForm()

    const oembedResponse = this.form.get('url').valueChanges.pipe(
      tap(url => (this.urlError = null)),
      debounceTime(150),
      filter<string>(url => {
        this.urlValid = false
        // valid and not empty
        return this.form.get('url').valid && !!url && !!url.length
      }),
      distinctUntilChanged(),
      switchMap(url => {
        this.showLoader = true
        const normalizedUrl = PrototypeResourceService.normalizeUrl(url)
        const metaData = this.oembedService.getOEmbedResourceMetaDataByUrl(normalizedUrl)
        if (metaData) {
          this.resource = {
            type: PrototypeResourceType.O_EMBED,
            oEmbed: {
              oEmbedProvider: metaData.oEmbedProvider,
              url: normalizedUrl,
            },
          }
          return this.oembedService.getOEmbedResponse(normalizedUrl, metaData)
        } else {
          return throwError(`OEmbed resource metadata could not be fetched for url ${url}`)
        }
      }),
      map(appHttpResponse => appHttpResponse.body),
      catchError((err: HttpError, obs: Observable<OEmbedVideoResponse | OEmbedRichResponse>) => {
        if (err.code) {
          this.urlError = this.errorKeyPrefix + err.code
        } else {
          this.urlError = null
        }

        this.urlValid = false
        this.showLoader = false
        // we catch any error, because we handle all errors inside this component
        // return an empty value and concat with previous stream
        return of(null).pipe(concat(obs))
      }),
      share()
    )

    this.htmlResponse = oembedResponse.pipe(map(body => (body && body.html) || ''))

    this.oembedResponseSubscribtion = oembedResponse.subscribe(body => {
      if (body) {
        this.urlValid = true
        this.showLoader = false
        this.logger.debug(body)
        this.resource.oEmbed.thumbnailUrl = body.thumbnail_url
      }
    })
  }

  private cancelUploadRequest(): void {
    if (this.uploadSubscription && !this.uploadSubscription.closed) {
      this.uploadSubscription.unsubscribe()
    }
  }

  private initForm() {
    const pattern = this.resourceService.getValidationRegexForUrl(PrototypeResourceType.O_EMBED, this.oEmbedType).source
    this.form = this.formBuilder.group({
      url: ['', Validators.pattern(pattern)],
    })
  }
}
