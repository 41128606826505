import { Injectable } from '@angular/core'
import { Modal2Service } from '@maprix/components'
import { Observable } from 'rxjs'
import { PrivacyPolicyModalComponent, PrivacyPolicyModalData } from './privacy-policy-modal.component'

@Injectable()
export class PrivacyPolicyService {
  constructor(private modalService: Modal2Service) {}

  open(data: PrivacyPolicyModalData): Observable<boolean> {
    return this.modalService.open<PrivacyPolicyModalComponent, boolean>(PrivacyPolicyModalComponent, {data}).afterClosed
  }
}
