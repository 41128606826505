import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { FormControlModule, LoadingIndicatorModule, StateButtonModule } from '@maprix/components'
import { LoopingCoreModule } from '../../looping-core.module'
import { FileSelectorModule } from '../../shared/components/file-selector/file-selector.module'
import { FooterModule } from '../../shared/components/footer/footer.module'
import { ImageCropperModule } from '../../shared/components/image-cropper/image-cropper.module'
import { InlineMessageModule } from '../../shared/components/inline-message/inline-message.module'
import { LoginAvatarModule } from '../../shared/components/login-avatar/login-avatar.module'
import { AutoFocusModule } from '../../shared/directives/auto-focus/auto-focus.module'
import { BlurOnSubmitModule } from '../../shared/directives/blur-on-submit/blur-on-submit.module'
import { FileDropModule } from '../../shared/directives/file-drop/file-drop.module'
import { LoginEntComponent } from './components/login-ent.component'
import { LoginForgotPasswordComponent } from './components/login-forgot-password.component'
import { LoginImageCropperComponent } from './components/login-image-cropper.component'
import { LoginResetPasswordComponent } from './components/login-reset-password.component'
import { LoginSamlSsoComponent } from './components/login-saml-sso.component'
import { RegisterEntComponent } from './components/register-ent.component'
import { RegisterPrivacyPolicyComponent } from './components/register-privacy-policy.component'
import { RegisterTrialComponent } from './components/register-trial.component'
import { LoginStartComponent } from './components/start.component'
import { TwoFaSetupComponent } from './components/two-fa-setup.component'
import { TwoFaVerifyComponent } from './components/two-fa-verify.component'
import { LoginFlowComponent } from './login-flow.component'
import { LoginModalComponent } from './login-modal.component'
import { LoginComponent } from './login.component'
import { routing } from './login.routing'
import { LoginService } from './login.service'

@NgModule({
  imports: [
    CommonModule,
    routing,
    LoopingCoreModule,
    RouterModule,
    ReactiveFormsModule,
    FormControlModule,
    StateButtonModule,
    ImageCropperModule,
    FileDropModule,
    InlineMessageModule,
    LoginAvatarModule,
    FileSelectorModule,
    FooterModule,
    BlurOnSubmitModule,
    AutoFocusModule,
    LoadingIndicatorModule,
  ],
  declarations: [
    LoginComponent,
    LoginFlowComponent,
    LoginStartComponent,
    LoginForgotPasswordComponent,
    LoginResetPasswordComponent,
    LoginEntComponent,
    LoginSamlSsoComponent,
    RegisterEntComponent,
    LoginImageCropperComponent,
    TwoFaSetupComponent,
    TwoFaVerifyComponent,
    LoginModalComponent,
    RegisterPrivacyPolicyComponent,
    RegisterTrialComponent,
  ],
  providers: [LoginService],
  entryComponents: [LoginModalComponent],
  exports: [
    LoginComponent,
    // exports for styleguide only
    LoginStartComponent,
    LoginEntComponent,
    LoginForgotPasswordComponent,
    LoginResetPasswordComponent,
    LoginSamlSsoComponent,
    RegisterEntComponent,
    TwoFaSetupComponent,
    TwoFaVerifyComponent,
    RegisterPrivacyPolicyComponent,
    RegisterTrialComponent,
  ],
})
export class LoginModule {}
