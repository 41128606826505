import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Modal2Service } from '@maprix/components'
import { Logger, LoggerService } from '@maprix/core'
import * as moment from 'moment'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { UploadPrototype } from '../../shared/models/prototype/prototype.model'
import { PrototypeData, StoredClientDataType } from '../../shared/models/prototype/stored-client-data'
import { AuthService } from '../../shared/services/auth/auth.service'
import { PrototypeService } from '../../shared/services/prototype/prototype.service'
import { RestoreCachedPrototypeModalComponent } from './components/modals/restore-cached-prototype-modal.component'

@Component({
  selector: 'scs-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit, OnDestroy {
  private onDestroySubject = new Subject<void>()
  private onDestroy$ = this.onDestroySubject.asObservable()
  @HostBinding('class.skel__main') hostClass = true

  prototype: UploadPrototype = null

  private logger: Logger

  constructor(
    loggerService: LoggerService,
    private prototypeService: PrototypeService,
    private modalService: Modal2Service,
    private authService: AuthService,
    private router: Router
  ) {
    this.logger = loggerService.getInstance('UploadComponent')
  }

  ngOnInit() {
    // logged in listener -> redirect to login route.
    this.authService.isLoggedInChanges
      .pipe(takeUntil(this.onDestroy$), filter(isLoggedIn => !isLoggedIn))
      .subscribe(() => this.router.navigateByUrl('/login'))

    if (
      this.prototypeService.storedLocalData &&
      this.prototypeService.storedLocalData.type === StoredClientDataType.PROTOTYPE
    ) {
      this.prototype = (<PrototypeData>this.prototypeService.storedLocalData.data).prototype
      this.prototypeService.storedLocalData = null
    } else {
      const prototype = this.prototypeService.getCachedPrototype()
      if (prototype && Number.isInteger(prototype.type)) {
        this.logger.debug('found cached prototype: ', prototype)
        prototype.createdAtDate = moment()

        // setTimeout as fix for ExpressionChangedAfterItHasBeenCheckedError
        // as stated in https://github.com/angular/angular/issues/15634
        setTimeout(() =>
          this.modalService
            .open<RestoreCachedPrototypeModalComponent, boolean>(RestoreCachedPrototypeModalComponent)
            .afterClosed.pipe(takeUntil(this.onDestroy$))
            .subscribe((restore: boolean) => {
              if (restore) {
                this.logger.debug('Modal rejeced, start fresh')
                this.prototype = prototype
              } else {
                this.prototypeService.removeCachedPrototype()
                this.prototype = PrototypeService.createEmptyPrototype()
              }
            })
        )
      }
    }
  }

  ngOnDestroy() {
    this.onDestroySubject.next()
    this.onDestroySubject.complete()
  }
}
