export class Regex {
  static PATTERN_EMAIL_BASE = '^[a-zA-Z0-9._%+-]+@({{domains}})$'

  static PATTERN_EMAIL = '([a-zA-Z0-9._%+-]+)@([a-z0-9.-]+\\.[a-z]{2,63})'
  static PATTERN_DOMAIN = '^([a-z0-9])(([a-z0-9-]{1,61})?[a-z0-9]{1})?(\\.[a-z0-9](([a-z0-9-]{1,61})?[a-z0-9‌​]{1})?)?(\\.[a-zA-Z]{2,4})+$'
  /* tslint:disable */
  // static DOMAIN:RegExp = /^([a-z0-9])(([a-z0-9-]{1,61})?[a-z0-9]{1})?(\.[a-z0-9](([a-z0-9-]{1,61})?[a-z0-9‌​]{1})?)?(\.[a-zA-Z]{2,4})+$/;
  // static VIMEO_AND_YOUTUBE:RegExp = /(?:http|https)?:\/\/(?:www\.)?(?:(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})|(?:vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|)(\d+)(?:|\/\?)))/;
  // static URL:RegExp = /^(?:(?:https?):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/;
  /* tslint:enable */
  static PATTERN_PASSWORD = '(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!"#$%&\'()+,-.\\/:;<=>?@[\\]^_`{|}~]).*|.{12,}'
  static PATTERN_TWO_FA_TOKEN = '^[0-9]{6}$'
  static PATTERN_PHONE_NUMBER = '^\\+[1-9]{1}[0-9]{6,14}$'

  // static SAP_CUSTOMER_NUMBER = /^[a-z0-9]*$/i;
  // static MOBILE_PHONE_NUMBER = /^\+[1-9]{1}[0-9]{6,14}$/;
  // static TOKEN = /^[0-9]{6}$/;

  static fromPattern(pattern: string, flags?: string): RegExp {
    return new RegExp(pattern, flags)
  }
}
