import { OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Logger } from '@maprix/core'
import { ActionType } from '../../models/action-type.enum'
import { BaseLoginStepComponent } from './base-login-step.component'

export abstract class BaseLoginStepWithFormComponent extends BaseLoginStepComponent implements OnInit {
  formBuilder: FormBuilder
  form: FormGroup

  /**
   *
   * @param logger
   * @param formBuilder If undefined no form is initialized
   */
  constructor(logger: Logger, formBuilder: FormBuilder) {
    super(logger)
    this.formBuilder = formBuilder
  }

  ngOnInit() {
    if (this.formBuilder) {
      this.form = this.buildForm()
    }
  }

  onBack(): void {
    this.back.emit(null)
  }

  onNext(actionType?: ActionType): void {
    if (this.form) {
      this.fillViewDataToModel(actionType)
    }

    super.onNext(actionType)
  }

  abstract buildForm(): FormGroup

  /**
   * Will be called before the next event will be emitted, usually used
   * to get the user inputs and copy them to the data object
   */
  abstract fillViewDataToModel(actionType?: ActionType): void
}
