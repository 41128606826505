import { Directive, Input, TemplateRef } from '@angular/core'

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[scs-accordion-content]',
})
export class AccordionContentDirective {
  // tslint:disable-next-line
  @Input('scs-accordion-content') isActive: boolean

  // only catch the templateRef to query inside TabComponent
  constructor(public templateRef: TemplateRef<any>) {}
}
