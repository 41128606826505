import { NgModule, Optional, SkipSelf } from '@angular/core'
import { DataStore } from './data-store.service'

@NgModule({
  imports: [],
  providers: [DataStore],
})
export class DataStoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: DataStoreModule
  ) {
    if (parentModule) {
      throw new Error('DataStoreModule is already loaded. Import it only once')
    }
  }
}
