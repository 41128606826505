import { NgModule, Optional, SkipSelf } from '@angular/core'
import { PrototypeService } from './prototype.service'

@NgModule({
  imports: [],
  providers: [PrototypeService],
})
export class PrototypeModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: PrototypeModule
  ) {
    if (parentModule) {
      throw new Error('PrototypeModule is already loaded. Import it only once')
    }
  }
}
