import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { Params, Router } from '@angular/router'
import { NotificationCenterService } from '@maprix/components'
import { Logger, LoggerService } from '@maprix/core'
import { JwtHelper } from '../../shared/helpers/jwt-helper'
import { pickQueryParams, pickSamlSsoFragments } from '../../shared/helpers/utils'
import { AuthService } from '../../shared/services/auth/auth.service'
import { LoginInitViewState } from './models/login-init-view-state'
import { SamlSsoFragments } from './models/saml-sso-fragments'

// when we redirect to a path we need to keep some queryParams
const queryParamsForPath = [
  // verify.component.ts
  { path: '/verify', params: ['email', 'expiration', 'signature'] },
]

@Component({
  selector: 'scs-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @HostBinding('class.skel__main') hostClass = true
  @HostBinding('class.facet--base') modalBaseClass = true
  @HostBinding('class.facet--inline') modalInlineClass = true

  @Input() initState: LoginInitViewState

  private logger: Logger
  private preserveFragment = true

  constructor(
    loggerService: LoggerService,
    private router: Router,
    private notificationCenterService: NotificationCenterService,
    private authService: AuthService
  ) {
    this.logger = loggerService.getInstance('LoginComponent')
  }

  ngOnInit(): void {
    const fragment: string = this.router.routerState.snapshot.root.fragment
    this.handleSSO(fragment)
  }

  onLogin(): void {
    const queryParams: Params = this.router.routerState.snapshot.root.queryParams
    const redirectPath = queryParams.redirect

    if (redirectPath) {
      /*
       * by navigating the queryParams will be removed by default, we pick the ones required for any redirect route
       */
      let paramsToKeep: string[]
      queryParamsForPath.forEach(def => {
        if (redirectPath.startsWith(def.path)) {
          paramsToKeep = def.params
        }
      })

      // make sure to preserve fragment
      if (paramsToKeep) {
        this.router.navigate([redirectPath], {
          queryParams: pickQueryParams(queryParams, ...paramsToKeep),
          preserveFragment: this.preserveFragment,
        })
      } else {
        this.router.navigate([redirectPath], { preserveFragment: this.preserveFragment })
      }
    } else {
      // redirect to default page
      this.router.navigateByUrl('/home', { preserveFragment: this.preserveFragment })
    }
  }

  /**
   * Reads the fragment and tries to extract the relevant information (token and more) to login a user via SSO
   * @param fragment
   */
  private handleSSO(fragment): void {
    const samlSsoFragments: SamlSsoFragments = pickSamlSsoFragments(fragment)
    if (samlSsoFragments) {
      // check for error param -> if present something went wrong with the saml sso login
      if (samlSsoFragments.error) {
        this.notificationCenterService.error('LOGIN.COMMONS.SAML_SSO_ERROR_TRY_AGAIN')
      } else if (samlSsoFragments.token && samlSsoFragments.newUser !== null) {
        // do not preserve the token fragment when redirecting later
        this.preserveFragment = false
        this.logger.debug('token and newUser params are present. -> try to log user in next')

        // quick check token validity to be able to handle error
        const parts = samlSsoFragments.token.split('.')
        if (parts.length !== 3) {
          this.logger.error('JWT must have 3 parts')
          this.notificationCenterService.error('LOGIN.COMMONS.SAML_SSO_ERROR_TRY_AGAIN')
        } else {
          const decoded = JwtHelper.urlBase64Decode(parts[1])
          if (!decoded) {
            this.logger.error('Cannot decode the token')
            this.notificationCenterService.error('LOGIN.COMMONS.SAML_SSO_ERROR_TRY_AGAIN')
          } else {
            // store received token and let the storage listener handle the user login
            this.authService.storeTokenFromSamlSsoLogin(samlSsoFragments.token)
          }
        }
      }
    }
  }
}
