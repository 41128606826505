import { Component, HostBinding, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core'
import { Logger, LoggerService, TranslateService } from '@maprix/core'
import { isString } from 'lodash'
import { Observable } from 'rxjs'
import { HttpError } from '../../models/http/http-error'

@Component({
  selector: 'scs-inline-message',
  templateUrl: './inline-message.component.html',
  styleUrls: ['./inline-message.component.scss'],
})
export class InlineMessageComponent implements OnChanges {
  @Input()
  @HostBinding('class.success')
  success: string | undefined

  @Input()
  @HostBinding('class.error')
  error: HttpError | string | undefined
  @Input() details: { [key: string]: any } | undefined

  message: string

  private logger: Logger

  constructor(loggerService: LoggerService, private translateService: TranslateService) {
    this.logger = loggerService.getInstance('Error')
  }

  ngOnChanges(changes: SimpleChanges): any {
    const errorChange: SimpleChange = changes['error']
    const successChange: SimpleChange = changes['success']

    let obs: Observable<string | string[]> | undefined

    if (errorChange && errorChange.currentValue) {
      const error: HttpError | string = errorChange.currentValue
      if (error.hasOwnProperty('code')) {
        obs = this.translateService.get('HTTP_ERROR.' + (<HttpError>this.error).code, (<HttpError>this.error).details)
      } else if (isString(error)) {
        obs = this.translateService.get(<string>error, this.details)
      }
    } else if (successChange && successChange.currentValue) {
      obs = this.translateService.get(<string>successChange.currentValue, this.details)
    }

    if (obs !== undefined) {
      obs.subscribe(message => (this.message = <string>message))
    }
  }
}
