import { Component } from '@angular/core'
import { Modal2 } from '@maprix/components'

@Component({
  selector: 'scs-restore-cached-prototype-modal',
  templateUrl: './restore-cached-prototype-modal.component.html',
})
@Modal2({
  size: 'xs',
})
export class RestoreCachedPrototypeModalComponent {}
