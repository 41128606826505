import { PrototypeType } from '../../models/prototype/prototype-type.enum'
import { Variant } from '../../models/prototype/variant.enum'

export function getPolaroidLabel(idx: number, type: PrototypeType): any {
  let label: any
  const variantView: any = Variant
  if (type === PrototypeType.VARIANT_PROTOTYPE) {
    label = variantView[idx]
  } else {
    label = idx + 1
  }
  return label
}
