import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { FileSelectorComponent } from './file-selector.component'

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [FileSelectorComponent],
  exports: [FileSelectorComponent],
})
export class FileSelectorModule {}
