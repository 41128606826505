import { ContentChildren, Directive, Input, QueryList, TemplateRef } from '@angular/core'
import { SortDirection } from '../../title-bar/title-bar.component'
import { TableColumnGutter } from '../models/table-column-gutter.model'

@Directive({
  // tslint:disable-next-line
  selector: 'scs-table-column',
})
export class TableColumnDirective {
  // a unique id within one table
  @Input() id: string

  // The name which will be displayed in `thead`
  @Input() title: string

  // Property to bind to on the row
  @Input() prop: string

  // Sets the column to 100% in chart-view
  @Input() hasPriority = false

  @Input() gutter: TableColumnGutter = 'none'

  @Input() sortable = true

  @ContentChildren(TemplateRef) templates: QueryList<TemplateRef<any>>

  sortDirection: SortDirection = SortDirection.NONE

  constructor() {}

  get hasHeaderTemplate() {
    return this.templates.length === 2
  }

  get headerTemplate() {
    if (!this.hasHeaderTemplate) {
      return undefined
    } else {
      return this.templates.first
    }
  }

  get cellTemplate() {
    if (!this.hasHeaderTemplate) {
      return this.templates.first
    } else {
      return this.templates.last
    }
  }
}
