import { Component, HostBinding, Inject, Injector, OnInit, Renderer2 } from '@angular/core'
import { Modal2, MODAL2_DATA, Modal2Ref } from '@maprix/components'
import { WindowRef } from '@maprix/core'
import { FeedbackStyle } from '../../models/prototype/feedback-style.enum'
import { Prototype } from '../../models/prototype/prototype.model'
import { CopyService } from '../../services/copy/copy.service'

// used for KEYS
export type InviteFacet = 'WITH_PROTOTYPE' | 'WITHOUT_PROTOTYPE'

@Component({
  selector: 'scs-enterprise-invite-modal',
  templateUrl: './enterprise-invite-modal.component.html',
  styleUrls: ['./enterprise-invite-modal.component.scss'],
})
@Modal2({
  titleKey: 'COMMONS.INVITE',
  size: 'xs',
})
export class EnterpriseInviteModalComponent implements OnInit {
  facet: InviteFacet = 'WITHOUT_PROTOTYPE'

  copied = false
  copyFailed = false

  urlToShare: string
  showCopy = false

  constructor(
    private copyService: CopyService,
    private windowRef: WindowRef,
    private renderer: Renderer2,
    private modalService: Modal2Ref<EnterpriseInviteModalComponent, Prototype>,
    @Inject(MODAL2_DATA) public modalData: { prototype?: Prototype }
  ) {}

  ngOnInit(): void {
    if (this.modalData.prototype) {
      this.facet = 'WITH_PROTOTYPE'
      this.urlToShare = this.windowRef.nativeWindow.location.origin + '/feedback/' + this.modalData.prototype.id
      this.showCopy = this.modalData.prototype.feedbackStyle === FeedbackStyle.COMMUNITY
    }
  }

  close(prototype: Prototype): void {
    this.modalService.close(prototype)
  }

  copyUrl(elem: HTMLAnchorElement) {
    this.copied = this.copyService.copyUrl(elem, this.renderer)

    if (this.copied) {
      setTimeout(() => {
        this.copied = false
      }, 4000)
    } else {
      this.copyFailed = true
      setTimeout(() => {
        this.copyFailed = false
      }, 5000)
    }
  }
}
