export class ImageDimensions {
  width: number
  height: number
  ratio: number

  constructor(width: number, height: number) {
    this.width = width
    this.height = height

    if (width != null && height != null) {
      this.ratio = height / width
    }
  }
}
