import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Modal2Service } from '@maprix/components'
import { filterIfTruthy, Logger, LoggerService } from '@maprix/core'
import { isEmpty, remove } from 'lodash'
import { Subscription } from 'rxjs'
import { AppService } from '../../../../app.service'
import { PrototypeType } from '../../../../shared/models/prototype/prototype-type.enum'
import { UploadPrototype } from '../../../../shared/models/prototype/prototype.model'
import { AuthService } from '../../../../shared/services/auth/auth.service'
import { AppHttp } from '../../../../shared/services/http/app-http.service'
import { PrototypeService } from '../../../../shared/services/prototype/prototype.service'
import { PreviewPrototypeModalComponent } from '../modals/preview-prototype-modal.component'

@Component({
  selector: 'scs-upload-step-questions',
  templateUrl: './upload-step-questions.component.html',
  styleUrls: ['./upload-step-questions.component.scss'],
})
export class UploadStepQuestionsComponent implements OnInit, OnDestroy {
  private static BASE_QUESTION_PREFIX = 'PROTOTYPE.QUESTIONS.'
  private static PROTOTYPE_TYPES: string[] = ['SIMPLE_PROTOTYPE.Q', 'VARIANT_PROTOTYPE.Q', 'FLOW_PROTOTYPE.Q']
  private static PLURAL_QUESTION_POSTFIX = 'P'

  @HostBinding('class') classes = 'wizard-step wizard-step--questions'

  @Input() prototype: UploadPrototype
  @Output() proceed: EventEmitter<void> = new EventEmitter<void>()
  @Output() revalidate: EventEmitter<void> = new EventEmitter<void>()

  form: FormGroup
  defaultQuestions: string[] = []
  displayVerifyMessage: boolean

  private logger: Logger
  private subscriptions: Subscription[] = []

  constructor(
    loggerService: LoggerService,
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private modalService: Modal2Service,
    private prototypeService: PrototypeService,
    private appHttp: AppHttp,
    private appService: AppService
  ) {
    this.logger = loggerService.getInstance('UploadStepQuestionsComponent')
  }

  ngOnInit() {
    this.initDefaultQuestions()
    this.createOrResetForm()

    const s = this.authService.isEmailVerifiedChanges.subscribe(verified => {
      // logged in (not null) and email not verified
      this.displayVerifyMessage = verified !== null && !verified
      if (this.displayVerifyMessage) {
        this.logger.debug('displayVerify inside upload step question')
        this.appService.displayVerifyEmailSticky()
      } else {
        this.appService.removeVerifyEmailSticky()
      }
      this.storePrototype()
    })

    this.subscriptions.push(s)
  }

  ngOnDestroy() {
    this.appService.removeVerifyEmailSticky()
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  isQuestionEmpty(index: number) {
    return !isEmpty(this.prototype.additionalQuestions[index])
  }

  openModal() {
    const questions: string[] = this.defaultQuestions.concat(this.prototype.additionalQuestions)
    this.modalService
      .open<PreviewPrototypeModalComponent, boolean>(PreviewPrototypeModalComponent, {
        data: { prototype: this.prototype, questions },
      })
      .afterClosed.pipe(filterIfTruthy())
      .subscribe(callback => {
        this.logger.debug('callback: ', callback)
        if (this.authService.isEmailVerified()) {
          this.proceed.emit()
        }
      })
  }

  initDefaultQuestions(): void {
    this.defaultQuestions = []
    this.defaultQuestions.push(this.createQuestionKey(0))
    this.defaultQuestions.push(this.createQuestionKey(1))
    if (this.prototype.type === PrototypeType.VARIANT_PROTOTYPE) {
      let key: string = this.createQuestionKey(2)
      key = this.prototype.resources.length === 3 ? key + UploadStepQuestionsComponent.PLURAL_QUESTION_POSTFIX : key
      this.defaultQuestions.push(key)
    }
  }

  private storePrototype() {
    if (this.displayVerifyMessage) {
      this.prototypeService.storePrototypeTemp(this.prototype).subscribe(() => {
        this.logger.debug('successfully written current user prototype to backend (verify email)')
      }, this.appHttp.handle400)
    }
  }

  private createQuestionKey(i: number): string {
    let key: string = UploadStepQuestionsComponent.BASE_QUESTION_PREFIX
    key = key + UploadStepQuestionsComponent.PROTOTYPE_TYPES[this.prototype.type!]
    key = key + i
    return key
  }

  private createOrResetForm(): void {
    this.form = this.formBuilder.group({})

    for (let i = 0; i < 3; i++) {
      this.form.addControl(
        'additionalQuestion' + (i + 1),
        new FormControl(this.prototype.additionalQuestions[i], Validators.maxLength(140))
      )
    }

    this.form.valueChanges.subscribe(data => {
      let removedIndex: number | undefined
      for (let i = 0; i < 3; i++) {
        const newValue: string | null = data['additionalQuestion' + (i + 1)]
        const oldValue: string | undefined = this.prototype.additionalQuestions[i]

        if (oldValue && !newValue) {
          // changed from a value to non value
          removedIndex = i
        }

        // form control model -> prototype model
        this.prototype.additionalQuestions[i] = newValue || ''
      }

      // remove all empty (undefined, null, '') questions
      remove(this.prototype.additionalQuestions!, isEmpty)

      // reinitialize the content of the form controls when a question was removed
      if (removedIndex !== undefined) {
        for (let i = 0; i < 3; i++) {
          this.form
            .get('additionalQuestion' + (i + 1))
            .setValue(this.prototype.additionalQuestions[i], { onlySelf: true })
        }
      }

      this.revalidate.emit()
      this.storePrototype()
    })
  }
}
