import { Injectable } from '@angular/core'
import { NavigationEnd, NavigationExtras, Router, UrlTree } from '@angular/router'
import { LocaleService, Logger, LoggerService, RuntimeConfiguration } from '@maprix/core'
import { escapeRegExp, isEqual } from 'lodash'
import { Observable, Observer, of, ReplaySubject, Subscription, throwError } from 'rxjs'
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators'
import { AvailableCredentialsDTO } from '../../../routes/login/models/available-credentials.dto'
import { CreateOrganizationDTO } from '../../../routes/login/models/create-organization.dto'
import { InitPasswordResetDTO } from '../../../routes/login/models/init-password-reset.dto'
import { InitPasswordDTO } from '../../../routes/login/models/init-password.dto'
import { LoginInfoDTO } from '../../../routes/login/models/login-info.dto'
import { PasswordResetDTO } from '../../../routes/login/models/password-reset.dto'
import { RequestTwoFactorAuthTokenDTO } from '../../../routes/login/models/request-two-factor-auth-token.dto'
import { TwoFactorAuthInfosDTO } from '../../../routes/login/models/two-factor-auth-infos.dto'
import { TwoFactorAuthDTO } from '../../../routes/login/models/two-factor-auth.dto'
import { JwtHelper } from '../../helpers/jwt-helper'
import { Regex } from '../../helpers/regex'
import { pickQueryParamsSignedGuest } from '../../helpers/utils'
import { ApplicationRoles } from '../../models/application-roles'
import { AuthTokenDTO } from '../../models/dto/auth-token.dto'
import { AuthOrganization } from '../../models/organization/auth-organization'
import { AuthUser } from '../../models/user/auth-user'
import { ChangeEmailDTO } from '../../models/user/change-email.dto'
import { ChangePasswordDTO } from '../../models/user/change-password.dto'
import { ChangeUserInfoDTO } from '../../models/user/change-user-info.dto'
import { SignedEmail } from '../../models/user/signed-email'
import { SignedIdGuest } from '../../models/user/signed-id-guest'
import { MicroService } from '../config/runtime-configuration/micro-service.enum'
import { DataStore } from '../data-store/data-store.service'
import { AppHttpResponse } from '../http/app-http-response.model'
import { AppHttp } from '../http/app-http.service'
import { RedirectAfterLogin } from './redirect-after-login.model'

@Injectable()
export class AuthService {
  // login
  private static URI_LOGIN = 'public/login'
  private static URI_2FA_SEND_TOKEN: string = AuthService.URI_LOGIN + '/twofactorauth/sendtoken'
  private static URI_2FA_RESEND_TOKEN: string = AuthService.URI_LOGIN + '/twofactorauth/resendtoken'
  private static URI_2FA_INIT: string = AuthService.URI_LOGIN + '/twofactorauth/init'

  // registration
  private static URI_REGISTRATION = 'public/registration'
  private static URI_REGISTER: string = AuthService.URI_REGISTRATION + '/register'
  private static URI_LOGIN_INFO: string = AuthService.URI_REGISTRATION + '/logininfo'
  private static URI_INIT_PASSWORD_RESET = AuthService.URI_REGISTRATION + '/initpasswordreset'
  private static URI_PASSWORD_RESET = AuthService.URI_REGISTRATION + '/passwordreset'

  // token
  private static URI_TOKEN = 'secure/token'
  private static URI_TOKEN_RENEW: string = AuthService.URI_TOKEN + '/renew'
  private static URI_TOKEN_LOGOUT: string = AuthService.URI_TOKEN + '/logout'

  private static URI_ORGANIZATION_REGISTRATION = 'public/organization'

  private static URI_PUBLIC = 'public/'
  private static URI_SECURE = 'secure/'

  private logger: Logger

  private _authUser: AuthUser | null
  private initFromLSSubscription: Subscription | null | undefined
  private authToken: string | null

  private authEndpoint: string

  private authTokenSubject: ReplaySubject<string | null> = new ReplaySubject<string | null>(1)
  private authUserSubject: ReplaySubject<AuthUser | null> = new ReplaySubject<AuthUser | null>(1)
  private isLoggedInSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1)
  private isGuestModeSubject: ReplaySubject<SignedIdGuest | null> = new ReplaySubject<SignedIdGuest | null>(1)
  private isEmailVerifiedSubject: ReplaySubject<boolean | null> = new ReplaySubject<boolean | null>(1) // emits null if unauthenticated

  // expose observables for reactive implementations
  private _authTokenChanges: Observable<string | null> = this.authTokenSubject
    .asObservable()
    .pipe(distinctUntilChanged())

  private _authUserChanges: Observable<AuthUser | null> = this.authUserSubject
    .asObservable()
    .pipe(distinctUntilChanged(AuthService.authUserComparer))

  private _isLoggedInChanges: Observable<boolean> = this.isLoggedInSubject.asObservable().pipe(distinctUntilChanged())

  private _isGuestModeChanges: Observable<SignedIdGuest | null> = this.isGuestModeSubject
    .asObservable()
    .pipe(distinctUntilChanged())

  private _isEmailVerifiedChanges: Observable<boolean | null> = this.isEmailVerifiedSubject
    .asObservable()
    .pipe(distinctUntilChanged())

  /* ----------------------------------------------------------------------------------------------------------------
   *
   *  static helper methods
   *
   ---------------------------------------------------------------------------------------------------------------- */
  static hasRole(authUser: AuthUser | null, applicationRole: ApplicationRoles): boolean {
    const roles: string[] | null | undefined = !!authUser ? authUser.roles : null
    return !!roles && roles.indexOf(applicationRole) !== -1
  }

  /**
   *  implement comparator for AuthUser.
   *
   *  An authuser is equal if all the relevant fields are equal.
   *  Non Relevant fields are:
   *  - epx
   *  - iat
   *  - iss
   *  - jti
   *  - lastLoginType
   *  - nbf
   * @param a
   * @param b
   * @returns {any}
   */
  static authUserComparer(a: AuthUser | null, b: AuthUser | null): boolean {
    if (a === null || b === null) {
      return a === b
    } else {
      return (
        a.familyName === b.familyName &&
        a.givenName === b.givenName &&
        a.locale === b.locale &&
        isEqual(a.organization, b.organization) &&
        a.receiveAdminNotifications === b.receiveAdminNotifications &&
        isEqual(a.roles, b.roles) &&
        a.sub === b.sub &&
        a.subId === b.subId &&
        a.zoneId === b.zoneId
      )
    }
  }

  constructor(
    loggerService: LoggerService,
    private appHttp: AppHttp,
    private dataStore: DataStore,
    private runtimeConfiguration: RuntimeConfiguration,
    private router: Router,
    private localeService: LocaleService
  ) {
    this.logger = loggerService.getInstance('AuthService', '#2980b9')
    this.authEndpoint = runtimeConfiguration.getUrlForRestService(MicroService.AUTH)
    // TODO ML MED extract all none appHttp depending code to third service (avoid circular deps)
    // cheap fix :-)
    this.appHttp.logoutHandler = this.logoutHelper
    this.initFromLS()
    this.initGuestModeChanges()
    // listen for token changes in local storage to sync browser tabs
    this.dataStore.addJwtListener(this.onJwtTokenChange)
  }

  /*
   * access to the auth properties
   */
  get authUser(): AuthUser | null {
    return this._authUser
  }

  get authTokenChanges(): Observable<string | null> {
    return this._authTokenChanges
  }

  get authUserChanges(): Observable<AuthUser | null> {
    return this._authUserChanges
  }

  get isLoggedInChanges(): Observable<boolean> {
    return this._isLoggedInChanges
  }

  get isGuestModeChanges(): Observable<SignedIdGuest | null> {
    return this._isGuestModeChanges
  }

  get isEmailVerifiedChanges(): Observable<boolean | null> {
    return this._isEmailVerifiedChanges
  }

  isLoggedIn(): boolean {
    return !!this.authUser
  }

  isEmailVerified(): boolean | null {
    return this.authUser ? AuthService.hasRole(this.authUser, 'emailVerified') : null
  }

  getAuthBasedUrl(microService: MicroService, path?: string | undefined): Observable<string> {
    return this._isLoggedInChanges.pipe(
      map(loggedIn => {
        let base = this.runtimeConfiguration.getUrlForRestService(microService)
        if (loggedIn) {
          base += AuthService.URI_SECURE
        } else {
          base += AuthService.URI_PUBLIC
        }

        if (path !== undefined) {
          base += path
        }

        return base
      })
    )
  }

  /**
   * Creates a RegExp to validate that an email is valid for an organization
   * @param domains If no domains are present, we take the one from logged in authUser, if
   * the logged in user is an orgUser. Fallback is an empty array.
   * @returns {string}
   */
  buildEmailPatternForOrgDomains(domains?: string[]): string {
    let domainsForEmails: string[] = []
    if (domains) {
      domainsForEmails = domains
    } else {
      if (this.authUser && this.authUser.organization.domains !== undefined) {
        domainsForEmails = this.authUser.organization.domains
      }
    }

    const replaceRegex = domainsForEmails.reduce(
      (previousValue: string, currentValue: string, idx: number, arr: string[]) => {
        if (idx < arr.length - 1) {
          return previousValue + escapeRegExp(currentValue) + '|'
        } else {
          // last element
          return previousValue + escapeRegExp(currentValue)
        }
      },
      ''
    )

    return Regex.PATTERN_EMAIL_BASE.replace('{{domains}}', replaceRegex)
  }

  /**
   * @param loginData
   * @param isTwoFactorAuthInit
   * @returns {Observable<R>} The response will be returned to check for 20x status code
   */
  login(loginData: any, isTwoFactorAuthInit?: boolean): Observable<AppHttpResponse<AuthUser | null>> {
    const endpoint: string = isTwoFactorAuthInit ? AuthService.URI_2FA_INIT : AuthService.URI_LOGIN
    return this.appHttp.post(this.authEndpoint + endpoint, loginData).pipe(
      map((response: AppHttpResponse<AuthTokenDTO>) => {
        if (response.status === 201) {
          this.logger.debug('2FA required')
          return <AppHttpResponse<null>>{ status: response.status, body: null }
        } else {
          const authUser: AuthUser = this.storeToken(response.body)
          if (authUser !== null) {
            this.localeService.changeLocaleAndTimezone(authUser.locale, authUser.zoneId)
          }
          return <AppHttpResponse<AuthUser>>{ status: response.status, body: authUser }
        }
      })
    )
  }

  /**
   * Will logout the user on backend and on client side, removing the token from local storage. We redirect to
   * the login route after successful logout.
   *
   * @param redirectAfterLogin This object will be appenden as queryParams to the url, so the login component
   * can handle any redirects after successful login
   * @returns {Observable<null>}
   */
  logout(redirectAfterLogin?: RedirectAfterLogin): Observable<null> {
    // cancel any renew calls
    if (this.initFromLSSubscription) {
      this.initFromLSSubscription.unsubscribe()
      this.initFromLSSubscription = null
    }

    let logoutObservable: Observable<null>

    // deactivated for the moment better user exp
    // this.dataStore.clearLastLogin();

    if (this.dataStore.getToken() === null) {
      this.logger.debug('no token found -> doing nothing')
      logoutObservable = of(null)
    } else if (this.dataStore.getToken() !== null && JwtHelper.isTokenExpired(this.dataStore.getToken())) {
      this.logger.debug('token is expired, clear from local storage')
      this.logoutHelper()
      logoutObservable = of(null)
    } else {
      logoutObservable = this.appHttp.post<null>(this.authEndpoint + AuthService.URI_TOKEN_LOGOUT, null).pipe(
        map((response: AppHttpResponse<null>) => {
          this.logger.info('token was deleted on backend')
          this.logoutHelper()
          return null
        })
      )
    }

    logoutObservable = logoutObservable.pipe(
      tap(() => {
        this.logger.debug('logged out')
        // if a redirect was defined, navigate to the login route otherwise the current route should be
        // reactive enough to handle the auth state change
        if (redirectAfterLogin) {
          this.logger.debug('redirect to login')
          const navExtras: NavigationExtras = {
            queryParams: redirectAfterLogin.queryParams,
          }
          if (redirectAfterLogin.fragment) {
            navExtras.fragment = redirectAfterLogin.fragment
          }
          this.router.navigate(['login'], navExtras)
        }
      })
    )

    return logoutObservable
  }

  /**
   * Calls for a new token and persists in storage
   */
  renewToken(): Observable<AuthUser> {
    const obs: Observable<AuthUser> = this.appHttp.get(this.authEndpoint + AuthService.URI_TOKEN_RENEW).pipe(
      map((response: AppHttpResponse<AuthTokenDTO>) => {
        const authUser = this.storeToken(response.body)
        if (authUser !== null) {
          this.localeService.changeLocaleAndTimezone(authUser.locale, authUser.zoneId)
        }
        return authUser
      })
    )

    obs.subscribe((authUser: AuthUser) => {
      this.logger.debug('token was successfully renewed')
    })

    return obs
  }

  getLoginInfo(email: string): Observable<AppHttpResponse<LoginInfoDTO>> {
    return this.appHttp.get<LoginInfoDTO>(this.authEndpoint + AuthService.URI_LOGIN_INFO, { params: { email } })
  }

  register(registrationInfoDTO): Observable<AuthUser> {
    return this.appHttp.post(this.authEndpoint + AuthService.URI_REGISTER, registrationInfoDTO).pipe(
      map((res: AppHttpResponse<AuthTokenDTO>) => {
        const authUser: AuthUser = this.storeToken(res.body)
        this.dataStore.setFirstLogin()
        // resolve with the user
        return authUser
      })
    )
  }

  registerOrganization(createOrganizationDTO: CreateOrganizationDTO): Observable<AuthUser> {
    return this.appHttp.post(this.authEndpoint + AuthService.URI_ORGANIZATION_REGISTRATION, createOrganizationDTO).pipe(
      map((res: AppHttpResponse<AuthTokenDTO>) => {
        const authUser: AuthUser = this.storeToken(res.body)
        this.dataStore.setFirstLogin()
        // resolve with the user
        return authUser
      })
    )
  }

  /**
   * Sends a link to the defined email, which can be used to reset the password
   *
   * @param initPasswordResetDTO
   * @returns {Observable<AppHttpResponse<T>>}
   */
  initPasswordReset(initPasswordResetDTO: InitPasswordResetDTO): Observable<AppHttpResponse<any>> {
    return this.appHttp.put(this.authEndpoint + AuthService.URI_INIT_PASSWORD_RESET, initPasswordResetDTO)
  }

  /**
   * Sets a new password for the linked account
   *
   * @param passwordResetDTO
   * @returns {Observable<AppHttpResponse<AuthUser>>}
   */
  passwordReset(passwordResetDTO: PasswordResetDTO): Observable<AppHttpResponse<void>> {
    return this.appHttp.put<void>(this.authEndpoint + AuthService.URI_PASSWORD_RESET, passwordResetDTO)
  }

  initPassword(initPasswordDTO: InitPasswordDTO): Observable<AppHttpResponse<void>> {
    if (this.authUser !== null) {
      return this.appHttp.post<void>(
        `${this.authEndpoint}secure/authenticateduser/${this.authUser.subId}/initpassword`,
        initPasswordDTO
      )
    } else {
      return throwError('There is no authenticated user')
    }
  }

  changeEmail(changeEmailDTO: ChangeEmailDTO): Observable<AuthUser> {
    if (this.authUser !== null) {
      return this.appHttp
        .put(`${this.authEndpoint}secure/authenticateduser/${this.authUser.subId}/changeemail`, changeEmailDTO)
        .pipe(
          map((res: AppHttpResponse<AuthTokenDTO>) => {
            const authUser: AuthUser = this.storeToken(res.body)
            return authUser
          })
        )
    } else {
      return throwError('There is no authenticated user')
    }
  }

  changePassword(changePasswordDTO: ChangePasswordDTO): Observable<AppHttpResponse<void>> {
    if (this.authUser !== null) {
      return this.appHttp.put<void>(
        `${this.authEndpoint}secure/authenticateduser/${this.authUser.subId}/changepassword`,
        changePasswordDTO
      )
    } else {
      return throwError('There is no authenticated user')
    }
  }

  deleteOwnAccount(): Observable<any> {
    if (this.authUser !== null) {
      return this.appHttp.delete(`${this.authEndpoint}secure/authenticateduser/${this.authUser.subId}`).pipe(
        map(() => {
          this.logoutHelper()
          this.dataStore.clearLastLogin()
        })
      )
    } else {
      return throwError('There is no authenticated user')
    }
  }

  verifyEmail(signedEmail: SignedEmail): Observable<AuthUser> {
    if (this.authUser !== null) {
      return this.appHttp
        .post(`${this.authEndpoint}secure/authenticateduser/${this.authUser.subId}/verifyemail`, signedEmail)
        .pipe(
          map((res: AppHttpResponse<AuthTokenDTO>) => {
            return this.storeToken(res.body)
          })
        )
    } else {
      return throwError('There is no authenticated user')
    }
  }

  resendVerifyEmail(): Observable<any> {
    if (this.authUser !== null) {
      return this.appHttp.post(`${this.authEndpoint}secure/authenticateduser/${this.authUser.subId}/reverifyemail`, {})
    } else {
      return throwError('There is no authenticated user')
    }
  }

  acceptPrivacyPolicy(): Observable<AuthUser> {
    if (this.authUser !== null) {
      return this.appHttp
        .post(`${this.authEndpoint}secure/authenticateduser/${this.authUser.subId}/acceptprivacypolicy`, {})
        .pipe(
          map((res: AppHttpResponse<AuthTokenDTO>) => {
            return this.storeToken(res.body)
          })
        )
    } else {
      return throwError('There is no authenticated user')
    }
  }

  changeUserInfo(changeUserInfoDTO: ChangeUserInfoDTO): Observable<AuthUser> {
    if (this.authUser !== null) {
      return this.appHttp
        .put(`${this.authEndpoint}secure/authenticateduser/${this.authUser.subId}`, changeUserInfoDTO)
        .pipe(
          map((response: AppHttpResponse<AuthTokenDTO>) => {
            const authUser: AuthUser = this.storeToken(response.body)
            if (authUser !== null) {
              this.localeService.changeLocaleAndTimezone(authUser.locale, authUser.zoneId)
            }
            return authUser
          })
        )
    } else {
      return throwError('There is no authenticated user')
    }
  }

  // used in admin panel to update organization name
  changeOrganizationInfo(orgId: string, authOrganization: AuthOrganization): Observable<AuthOrganization> {
    if (this.authUser !== null) {
      return this.appHttp.put(`${this.authEndpoint}secure/organization/${orgId}`, authOrganization).pipe(
        map((response: AppHttpResponse<AuthTokenDTO>) => {
          const authUser: AuthUser = this.storeToken(response.body)
          return authUser.organization
        })
      )
    } else {
      return throwError('There is no authenticated user')
    }
  }

  /* ----------------------------------------------------------------------------------------------------------------
   *
   *  login credentials
   *
   ---------------------------------------------------------------------------------------------------------------- */

  getCredentials(): Observable<AvailableCredentialsDTO> {
    if (this.authUser !== null) {
      return this.appHttp.get(`${this.authEndpoint}secure/authenticateduser/${this.authUser.subId}/credentials`).pipe(
        map((response: AppHttpResponse<AvailableCredentialsDTO>) => {
          return response.body
        })
      )
    } else {
      return throwError('There is no authenticated user')
    }
  }

  /* ----------------------------------------------------------------------------------------------------------------
   *
   *  two factor auth
   *
   ---------------------------------------------------------------------------------------------------------------- */

  requestTwoFactorAuthTokenInit(requestTwoFactorAuthTokenDTO: RequestTwoFactorAuthTokenDTO): Observable<any> {
    return this.appHttp.post(this.authEndpoint + AuthService.URI_2FA_SEND_TOKEN, requestTwoFactorAuthTokenDTO)
  }

  requestTwoFactorAuthToken(email: string): Observable<any> {
    return this.appHttp.post(this.authEndpoint + AuthService.URI_2FA_RESEND_TOKEN, {}, { params: { email } })
  }

  getTwoFactorAuthInfos(): Observable<TwoFactorAuthInfosDTO> {
    if (this.authUser !== null) {
      return this.appHttp
        .get<TwoFactorAuthInfosDTO>(`${this.authEndpoint}secure/authenticateduser/${this.authUser.subId}/twofactorauth`)
        .pipe(
          map((response: AppHttpResponse<TwoFactorAuthInfosDTO>) => {
            return response.body
          })
        )
    } else {
      return throwError('There is no authenticated user')
    }
  }

  activateTwoFactorAuthOnAccount(data: TwoFactorAuthDTO): Observable<AppHttpResponse<TwoFactorAuthInfosDTO>> {
    if (this.authUser !== null) {
      return this.appHttp.post<TwoFactorAuthInfosDTO>(
        `${this.authEndpoint}secure/authenticateduser/${this.authUser.subId}/twofactorauth`,
        data
      )
    } else {
      return throwError('There is no authenticated user')
    }
  }

  changeTwoFactorAuthOnAccount(data: TwoFactorAuthDTO): Observable<AppHttpResponse<TwoFactorAuthInfosDTO>> {
    if (this.authUser !== null) {
      return this.appHttp.put<TwoFactorAuthInfosDTO>(
        `${this.authEndpoint}secure/authenticateduser/${this.authUser.subId}/twofactorauth`,
        data
      )
    } else {
      return throwError('There is no authenticated user')
    }
  }

  deactivateTwoFactorAuthOnAccount(): Observable<AppHttpResponse<any>> {
    if (this.authUser !== null) {
      return this.appHttp.delete(`${this.authEndpoint}secure/authenticateduser/${this.authUser.subId}/twofactorauth`)
    } else {
      return throwError('There is no authenticated user')
    }
  }

  /* ----------------------------------------------------------------------------------------------------------------
   *
   *  saml sso login
   *
   ---------------------------------------------------------------------------------------------------------------- */
  storeTokenFromSamlSsoLogin(token: string) {
    this.storeToken({ authctoken: token })
  }

  /* ----------------------------------------------------------------------------------------------------------------
   *
   *  private api
   *
   ---------------------------------------------------------------------------------------------------------------- */

  /**
   * Reads the jwt token from storage (if available) and checks if it is still valid.
   */
  private initFromLS(): void {
    const observable = Observable.create((observer: Observer<AuthUser | null>) => {
      // store renew call subscription for possible cleanup
      let renewSubscription: Subscription

      // decode jwt at startup from local storage --> test if still valid and store
      this.authToken = this.dataStore.getToken()
      if (this.authToken) {
        if (!JwtHelper.isTokenExpired(this.authToken)) {
          this.logger.info('found valid non expired jwt token in storage -> reusing')

          /*
           * init authUser from local storage token to get the authUser as fast as possible, and inform subscribers.
           * then call the renew endpoint and act accordingly
           */
          this._authUser = this.generateUserFromJWT(this.authToken)
          this.dataStore.setLastLogin(this._authUser.sub)
          observer.next(this.authUser)

          this.notify()

          // renew token to make sure we are in sync with server (have the same user data)
          renewSubscription = this.appHttp.get(this.authEndpoint + AuthService.URI_TOKEN_RENEW).subscribe(
            // SUCCESS -> store updated token
            (response: AppHttpResponse<AuthTokenDTO>) => {
              this.logger.debug('token was successfully renewed')
              const authUser = this.storeToken(response.body)
              if (authUser !== null) {
                this.localeService.changeLocaleAndTimezone(authUser.locale, authUser.zoneId)
              }
              observer.next(authUser)
              observer.complete()
            },
            // ERROR -> the token from local storage is not valid, logout
            error => {
              this.logger.error('token could not be renewed', error)
              observer.error(error)
              this.logoutHelper()
            }
          )
        } else {
          this.logger.info('token is expired')
          this.logoutHelper()
        }
      } else {
        this.logger.info('no token found')
        this._authUser = null
        this.authToken = null
        observer.next(null)
        this.notify()
      }

      // clean up logic, cancels token renew request
      return () => {
        if (renewSubscription) {
          renewSubscription.unsubscribe()
        }
      }
    })

    this.initFromLSSubscription = observable.subscribe(
      authUser => this.logger.debug('initFromLS:: next value %o', authUser),
      error => {
        this.logger.error('initFromLS:: there was an error initializing', error)
        this.initFromLSSubscription = null
      },
      () => {
        this.logger.info('initFromLS:: initialization finished')
        this.initFromLSSubscription = null
      }
    )
  }

  /**
   * If the given token differs from the one from local store, the local store will be updated and any
   * possible subscribers will be notified about the changes
   *
   * @param authTokenDTO
   * @returns {AuthUser}
   */
  private storeToken(authTokenDTO: AuthTokenDTO): AuthUser {
    const currentToken: string = this.dataStore.getToken()
    if (currentToken !== authTokenDTO.authctoken) {
      this.logger.info('storing new token')
      this.dataStore.setToken(authTokenDTO.authctoken)
      const authUser: AuthUser = this.generateUserFromJWT(authTokenDTO.authctoken)

      this._authUser = authUser
      this.authToken = authTokenDTO.authctoken
      this.dataStore.setLastLogin(authUser.sub)

      this.notify()
      if (authUser !== null) {
        this.localeService.changeLocaleAndTimezone(authUser.locale, authUser.zoneId)
      }
      return authUser
    } else {
      return this.generateUserFromJWT(currentToken)
    }
  }

  /**
   * emits next value on all the relevant subjects
   */
  private notify(): void {
    this.logger.info('notify')

    this.authTokenSubject.next(this.authToken)

    this.authUserSubject.next(this.authUser)

    this.isLoggedInSubject.next(this.isLoggedIn())

    this.isEmailVerifiedSubject.next(this.isEmailVerified())
  }

  /**
   * extracts an AuthUser from jwt token
   * @param token
   * @returns {AuthUser}
   */
  private generateUserFromJWT(token: string): AuthUser {
    const authUser: AuthUser = JwtHelper.decodeToken<AuthUser>(token)
    this.logger.debug('generated AuthUser from JWT for user with ID: ', authUser.subId)

    return authUser
  }

  /**
   * removes the token from local storage, deletes authUser and notifies all subscribers
   */
  private logoutHelper = (): void => {
    // logout --> delete token from localStorage and from memory
    this.logger.info('deleting locally stored token if exist')
    this.dataStore.clearToken()
    this.dataStore.clearPrototype()
    this._authUser = null
    this.authToken = null
    this.notify()
  }

  /**
   * Will be triggered whenever the value of the jwt token in local storage changes,
   * we use it to notify subscribers about property changes within the token.
   * The window where the change was triggered will not be notified only other
   * tabs.
   *
   * @param event
   */
  private onJwtTokenChange = (event: StorageEvent): void => {
    const oldValue: string | undefined = event.oldValue
    const newValue: string | undefined = event.newValue
    const currentWindowUUID: string = this.dataStore.getCurrentWindowUUID()
    const storedWindowUUID: string = this.dataStore.getStoredWindowUUID()

    if (currentWindowUUID === storedWindowUUID) {
      this.logger.debug('same window -> ignore storage event. (ie11)')
      return
    }

    if (!oldValue && newValue) {
      // login
      this.logger.debug('onJwtTokenChange:: login')
      this.initFromLS()
    } else if (oldValue && !newValue) {
      // logout
      this.logger.debug('onJwtTokenChange:: logout')
      this._authUser = null
      this.notify()
    } else if (oldValue && newValue) {
      // change of JWT
      this.logger.debug('onJwtTokenChange:: change', JwtHelper.decodeToken(oldValue), JwtHelper.decodeToken(newValue))
      this._authUser = this.generateUserFromJWT(newValue)
      this.notify()
    }
  }

  private initGuestModeChanges() {
    const authUserDefined$ = this.authUserChanges.pipe(filter(authUser => !!authUser))

    this.authUserChanges
      .pipe(
        tap(authUser => (authUser ? this.isGuestModeSubject.next(null) : undefined)),
        filter(authUser => !authUser),
        switchMap(() => this.router.events.pipe(takeUntil(authUserDefined$))),
        filter(event => event instanceof NavigationEnd),
        map(() => pickQueryParamsSignedGuest((<UrlTree>this.router.parseUrl(this.router.url)).queryParams))
      )
      .subscribe(params => this.isGuestModeSubject.next(!!params ? params : null))
  }
}
