export const KEYDOWN = 'keydown'
export const KEYUP = 'keyup'
export const FOCUS = 'focus'
export const DEBOUNCE_DELAY = 250
export const MIN_LENGTH_AUTOCOMPLETE = 2
export const DUPLICATE_KEY = 'TAG_INPUT.DUPLICATE'
export const MAX_ITEMS_KEY = 'TAG_INPUT.MAX_ITEMS'
export const REQUIRED_KEY = 'TAG_INPUT.REQUIRED'

export const ACTIONS_KEYS = {
  DELETE: 'DELETE',
  SWITCH_PREV: 'SWITCH_PREV',
  SWITCH_NEXT: 'SWITCH_NEXT',
  TAB: 'TAB',
}

export const KEY_PRESS_ACTIONS = {
  8: ACTIONS_KEYS.DELETE,
  37: ACTIONS_KEYS.SWITCH_PREV,
  39: ACTIONS_KEYS.SWITCH_NEXT,
  9: ACTIONS_KEYS.TAB,
}
