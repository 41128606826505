import { Injectable } from '@angular/core'
import { LocalStorage, StorageEventListener } from '@maprix/core'

@Injectable()
export class DataStore {
  static LS_KEY_JWT = 'JWT'
  static LS_KEY_LAST_LOGIN = 'LAST_LOGIN'
  static LS_KEY_FIRST_LOGIN = 'FIRST_LOGIN'
  static LS_KEY_PROTOTYPE = 'PROTOTYPE'

  constructor(private localStorage: LocalStorage) {}

  /*
   * JWT Token
   */
  setToken(token: string): void {
    this.localStorage.setItem(DataStore.LS_KEY_JWT, token)
  }

  getToken(): string {
    return this.localStorage.getItem(DataStore.LS_KEY_JWT)
  }

  clearToken() {
    this.localStorage.remove(DataStore.LS_KEY_JWT)
  }

  clearPrototype() {
    this.localStorage.remove(DataStore.LS_KEY_PROTOTYPE)
  }

  addJwtListener(listener: StorageEventListener): void {
    this.localStorage.addListener(DataStore.LS_KEY_JWT, listener)
  }

  removeJwtListener(listener: StorageEventListener): void {
    this.localStorage.removeListener(DataStore.LS_KEY_JWT, listener)
  }

  getStoredWindowUUID(): string {
    return this.localStorage.getItem(LocalStorage.LS_WINDOW_UUID)
  }

  getCurrentWindowUUID(): string {
    return this.localStorage.getCurrentWindowUUID()
  }

  /*
   * LOGIN
   */
  setLastLogin(email: string): void {
    this.localStorage.setItem(DataStore.LS_KEY_LAST_LOGIN, email)
  }

  getLastLogin(): string {
    return this.localStorage.getItem(DataStore.LS_KEY_LAST_LOGIN)
  }

  clearLastLogin(): void {
    this.localStorage.remove(DataStore.LS_KEY_LAST_LOGIN)
  }

  /*
   * FIRST LOGIN
   */
  setFirstLogin(): void {
    this.localStorage.setItem(DataStore.LS_KEY_FIRST_LOGIN, true)
  }

  isFirstLogin(): string {
    return this.localStorage.getItem(DataStore.LS_KEY_FIRST_LOGIN)
  }

  clearFirstLogin(): void {
    this.localStorage.remove(DataStore.LS_KEY_FIRST_LOGIN)
  }
}
