import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { FormControlErrorDisplayStrategy } from '@maprix/components'
import { Logger, LoggerService, RuntimeConfiguration } from '@maprix/core'
import { Observable, of, Subscription } from 'rxjs'
import { catchError, debounceTime, filter, share, switchMap, tap } from 'rxjs/operators'
import { HttpError } from '../../../../shared/models/http/http-error'
import { PrototypeResourceType } from '../../../../shared/models/resource/prototype-resource-type.enum'
import { PrototypeResource } from '../../../../shared/models/resource/prototype-resource.model'
import { MicroService } from '../../../../shared/services/config/runtime-configuration/micro-service.enum'
import { DataObject } from '../../../../shared/services/file-upload/data-object.model'
import { FileUploadService } from '../../../../shared/services/file-upload/file-upload.service'
import { AppHttpResponse } from '../../../../shared/services/http/app-http-response.model'
import { AppHttp } from '../../../../shared/services/http/app-http.service'
import { PrototypeResourceService } from './prototype-resource.service'

@Component({
  selector: 'scs-website-resource-upload',
  templateUrl: './website-resource-upload.component.html',
})
export class WebsiteResourceUploadComponent implements OnInit, OnDestroy {
  private static SCREENSHOT_GENERATOR_URL = 'secure/screenshot?url='
  private static RESOURCE_URL_UPLOAD = 'secure/resources/url?url='

  @Output() prototypeResource: EventEmitter<PrototypeResource> = new EventEmitter<PrototypeResource>()

  form: FormGroup
  imageUrl: string | null
  showLoader = false
  uploadWorker: Observable<any>
  currentImage: DataObject | null
  urlError: any
  formControlErrorDisplayStrategy: typeof FormControlErrorDisplayStrategy = FormControlErrorDisplayStrategy

  private logger: Logger
  private baseDownloadUrl: string
  private uploadSubscription: Subscription | null

  constructor(
    loggerService: LoggerService,
    formBuilder: FormBuilder,
    private resourceService: PrototypeResourceService,
    private fileUploadService: FileUploadService,
    private appHttp: AppHttp,
    runtimeConfiguration: RuntimeConfiguration
  ) {
    this.logger = loggerService.getInstance('WebsiteResourceUploadComponent')
    const pattern = this.resourceService.getValidationRegexForUrl(PrototypeResourceType.URL).source
    this.baseDownloadUrl = `${runtimeConfiguration.getUrlForRestService(MicroService.WEBSCREENSHOT)}${
      WebsiteResourceUploadComponent.SCREENSHOT_GENERATOR_URL
    }`
    this.form = formBuilder.group({
      url: ['', [Validators.pattern(pattern)]],
    })
  }

  ngOnInit() {
    this.logger.debug('Init..')
    this.form
      .get('url')
      .valueChanges.pipe(
        tap(url => {
          this.urlError = null
          this.clearImage()
        }),
        debounceTime(300),
        switchMap<string, DataObject | null>(url => {
          if (this.form.get('url').valid && !!url && !!url.length) {
            const normalizedUrl = PrototypeResourceService.normalizeUrl(url)
            this.showLoader = true
            return of(url).pipe(
              switchMap(urll => this.fileUploadService.downloadDirect(this.baseDownloadUrl + normalizedUrl)),
              catchError((err: HttpError, caught: Observable<DataObject | null>) => {
                this.logger.debug('error during screenshot generation %o', err)
                this.urlError = err
                return of(null)
              })
            )
          } else {
            return of(null)
          }
        })
        // switchMap<string, DataObject | null>(url => {
        //   if (this.form.get('url').valid && !!url && !!url.length) {
        //     const normalizedUrl = PrototypeResourceService.normalizeUrl(url)
        //     this.showLoader = true
        //     return of(url).pipe(
        //       switchMap(urll => this.fileUploadService.downloadDirect(normalizedUrl)),
        //       catchError((err: HttpError, caught: Observable<DataObject | null>) => {
        //         this.logger.debug('error during screenshot generation %o', err)
        //         this.urlError = err
        //         return of(null)
        //       })
        //     )
        //   } else {
        //     return of(null)
        //   }
        // })
      )
      .subscribe((downloadedFile: DataObject | null) => {
        this.showLoader = false
        if (downloadedFile && downloadedFile.data) {
          this.setNewImage(downloadedFile)
        }
      })
  }

  ngOnDestroy(): void {
    this.cancelUploadRequest()
  }

  private clearImage() {
    this.currentImage = null
    this.fileUploadService.revokeObjectUrl(this.imageUrl)
    this.imageUrl = null
  }

  onSubmit() {
    this.logger.debug('upload website image')
    this.uploadWorker = this.fileUploadService
      .uploadSingle(
        this.currentImage!,
        WebsiteResourceUploadComponent.RESOURCE_URL_UPLOAD +
          PrototypeResourceService.normalizeUrl(this.form.get('url').value)
      )
      .pipe(share())

    this.uploadWorker.subscribe(
      (response: AppHttpResponse<PrototypeResource>) => {
        this.prototypeResource.emit(response.body)
      },
      this.appHttp.handle400,
      () => {
        this.logger.debug('upload completed')
      }
    )
  }

  private setNewImage(dataObject: DataObject) {
    this.currentImage = dataObject
    this.fileUploadService.revokeObjectUrl(this.imageUrl)
    this.imageUrl = this.fileUploadService.toObjectUrlSingle(dataObject.data)
  }

  private cancelUploadRequest(): void {
    if (this.uploadSubscription && !this.uploadSubscription.closed) {
      this.uploadSubscription.unsubscribe()
    }
  }
}
