import { Component, EventEmitter, Input, OnInit, Output, SecurityContext } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { LoggerService, ScrollToService, TranslateService } from '@maprix/core'
import { Observable } from 'rxjs'
import { HttpError } from '../../../shared/models/http/http-error'
import { LoginStateData } from '../models/login-state-data'
import { BaseLoginStepComponent } from './base/base-login-step.component'

@Component({
  selector: 'scs-register-trial',
  templateUrl: './register-trial.component.html',
})
export class RegisterTrialComponent extends BaseLoginStepComponent implements OnInit {
  @Input() data: LoginStateData

  @Output() back: EventEmitter<null> = new EventEmitter<null>()
  @Output() next: EventEmitter<null> = new EventEmitter<null>()

  constructor(loggerService: LoggerService, private scrollToService: ScrollToService) {
    super(loggerService.getInstance('RegisterTrialComponent'))
  }

  ngOnInit() {
    this.scrollToService.scrollTo()
  }

  getDomainFromMail(): string {
    if (!this.data.loginData.email) {
      throw new Error('No email present in loginData.')
    }
    const split = this.data.loginData.email.split('@')
    return split[1]
  }
}
