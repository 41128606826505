import { Component } from '@angular/core'
import { Modal2, Modal2Ref } from '@maprix/components'
import { Logger, LoggerService } from '@maprix/core'

@Component({
  selector: 'scs-login-modal',
  templateUrl: './login-modal.component.html',
})
@Modal2({
  size: 'xs',
  closeOnClickBackdrop: false,
  preventFromClosing: true,
})
export class LoginModalComponent {
  private logger: Logger

  constructor(loggerService: LoggerService, private modal: Modal2Ref<LoginModalComponent, boolean>) {
    this.logger = loggerService.getInstance('LoginModalComponent')
  }

  onLogin(): void {
    this.logger.debug('user successfully logged in')
    this.modal.close(true)
  }
}
