/**
 * usually a login component view has one main action, which makes it to just call LoginService.next to decide
 * what to display next. Sometimes we have multiple buttons which lead to different views, that is when we
 * need to use the action type so we can decide accordingly
 */
export enum ActionType {
  // generic
  PRIMARY,
  SECONDARY,
  // action for login
  PASSWORD,
  FORGOT_PASSWORD,
  // when the flow allows for an profile picture upload
  PROFILE_PICTURE,
}
