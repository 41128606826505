import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { Logger, LoggerService } from '@maprix/core'
import { filledArrayWithLength } from '../../helpers/utils'
import { PrototypeResource } from '../../models/resource/prototype-resource.model'
import { PolaroidStackFacet } from './polaroid-stack-facet.model'

@Component({
  selector: 'scs-polaroid-stack',
  templateUrl: './polaroid-stack.component.html',
  styleUrls: ['./polaroid-stack.component.scss'],
})
export class PolaroidStackComponent implements OnInit {
  @Input() facet: PolaroidStackFacet
  @Input() isActive: boolean
  @Input() count: number
  @Input() images: PrototypeResource[] | undefined
  /* tslint:disable:no-input-rename */
  @Input('class') classes: string

  hasImages: boolean
  placeholders: number[]

  private logger: Logger

  @HostBinding('class')
  get mergedClasses() {
    return `${this.classes ? this.classes : ''} facet--${this.facet}`
  }

  constructor(loggerService: LoggerService) {
    this.logger = loggerService.getInstance('PolaroidStackComponent')
  }

  ngOnInit() {
    this.facet = this.facet || 'base'
    this.hasImages = (this.images && this.images.length > 0) || false
    // if there are no images
    if (!this.hasImages) {
      this.placeholders = filledArrayWithLength(this.count, 0)
    }
  }

  get displayImages() {
    return this.images!.slice(0, 3)
  }
}
