import { NgModule, Optional, SkipSelf } from '@angular/core'
import { ProfileUserService } from './profile-user.service'

@NgModule({
  imports: [],
  providers: [ProfileUserService],
})
export class ProfileUserModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ProfileUserModule
  ) {
    if (parentModule) {
      throw new Error('ProfileUserModule is already loaded. Import it only once')
    }
  }
}
