import { PrototypeType } from '../../models/prototype/prototype-type.enum'

export type PolaroidSize =
  | 'full'
  | 'big'
  | 'small'
  | 'xsmall'
  | 'xxsmall'
  | 'change-on-xs'
  | 'change-on-sm'
  | 'change-on-md'

export function getPolaroidSize(type: PrototypeType): PolaroidSize {
  let result: PolaroidSize

  switch (type) {
    case PrototypeType.SIMPLE_PROTOTYPE:
      result = 'full'
      break
    case PrototypeType.VARIANT_PROTOTYPE:
      result = 'change-on-sm'
      break
    case PrototypeType.FLOW_PROTOTYPE:
      result = 'change-on-sm'
      break
    default:
      result = 'change-on-sm'
      break
  }

  return result
}
