import { AfterViewInit, Directive, ElementRef, Input, OnInit } from '@angular/core'
import { DeviceInfoService, Logger, LoggerService, WindowRef } from '@maprix/core'
import { first } from 'rxjs/operators'

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[scs-auto-focus]',
})
export class AutoFocusDirective implements OnInit, AfterViewInit {
  // tslint:disable-next-line
  @Input('scs-auto-focus') scrollToTop: boolean

  private logger: Logger
  private window: Window

  constructor(
    loggerService: LoggerService,
    windowRef: WindowRef,
    private el: ElementRef,
    private deviceInfoService: DeviceInfoService
  ) {
    this.logger = loggerService.getInstance('AutoFocusDirective')
    this.window = windowRef.nativeWindow
  }

  ngOnInit() {
    this.scrollToTop = this.scrollToTop === undefined ? false : this.scrollToTop
  }

  ngAfterViewInit() {
    this.deviceInfoService.featureChanges.pipe(first()).subscribe(features => {
      if (!features.touch) {
        this.logger.debug('device has no touch functionality -> set focus to element')
        // NOTE RENDERER2 invokeElementMethod was removed in Renderer2 -> we use native element method for now
        // this.renderer.invokeElementMethod(this.el.nativeElement, 'focus');
        ;(<HTMLElement>this.el.nativeElement).focus()
        if (this.scrollToTop) {
          this.window.scrollTo(0, 0)
        }
      }
    })
  }
}
