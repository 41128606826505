/**
 * Prototype lifecycle:
 * IN_CREATION : During creation
 * READY_FOR_FEEDBACK : On submit (creation last step)
 * FEEDBACK_IN_PROGRESS : After mails are sent- visible for feedback
 * FEEDBACK_AVAILABLE : end date passed
 * ARCHIVED : User clicked on delete
 */
export enum PrototypeState {
  IN_CREATION,
  READY_FOR_FEEDBACK,
  FEEDBACK_IN_PROGRESS,
  FEEDBACK_AVAILABLE,
  ARCHIVED,
  CENSORED,
}
