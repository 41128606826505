import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlSegment } from '@angular/router'
import { NotificationCenterService } from '@maprix/components'
import { Logger, LoggerService } from '@maprix/core'
import { merge } from 'lodash'
import { Observable, of } from 'rxjs'
import { first, map, mergeMap } from 'rxjs/operators'
import { pickQueryParamsRequestedUser } from '../../helpers/utils'
import { RequestedUser } from '../../models/shared/requested-user.model'
import { AuthUser } from '../../models/user/auth-user'
import { AuthService } from './auth.service'

/**
 * If the url contains some information about the requested user (query params) we check if the requested user
 * matches the one which is logged in.
 * If not the route can not be activated and the user is logged out and redirected to login route with redirect url set.
 */
@Injectable()
export class CanActivateRequestedUserGuard implements CanActivate {
  private logger: Logger

  constructor(
    loggerService: LoggerService,
    private authService: AuthService,
    private notificationCenter: NotificationCenterService
  ) {
    this.logger = loggerService.getInstance('CanActivateRequestedUserGuard')
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const requestedUser: RequestedUser | null = pickQueryParamsRequestedUser(route.queryParams)

    return this.authService.authUserChanges.pipe(
      first(),
      map((authUser: AuthUser | null): { canActivate: boolean; isLoggedIn: boolean } => {
        const res = { canActivate: false, isLoggedIn: false }
        if (authUser === null) {
          // no user logged in
          if (requestedUser === null) {
            // no user logged in and no requested one -> OK
            res.canActivate = true
            return res
          } else {
            // no user logged in but need one -> NOK
            res.canActivate = false
            return res
          }
        } else {
          res.isLoggedIn = true
          // logged in
          if (requestedUser !== null) {
            // logged in user and requested one -> check for match
            if (requestedUser.id !== undefined) {
              // can activate if id matches
              res.canActivate = authUser.subId === requestedUser.id
              return res
            } else if (requestedUser.email) {
              // can activate if email matches
              res.canActivate = authUser.sub === requestedUser.email
              return res
            } else {
              res.canActivate = false
              return res
            }
          } else {
            // logged in and no requested user -> OK
            res.canActivate = true
            return res
          }
        }
      }),
      mergeMap(({ canActivate, isLoggedIn }) => {
        if (!canActivate) {
          // get path for current route including path params - double reduce
          const path = route.pathFromRoot.reduce((prev: string, r: ActivatedRouteSnapshot) => {
            return (
              prev +
              r.url.reduce((prev2: string, u: UrlSegment) => {
                return prev2 + '/' + u.path
              }, '')
            )
          }, '')
          // merge current query params with redirect queryParam
          let queryParams: any = { redirect: path }
          queryParams = merge(queryParams, state.root.queryParams)
          // logout and set redirect (we do this always)
          return this.authService.logout({ queryParams, fragment: route.fragment }).pipe(
            map(() => {
              if (isLoggedIn) {
                // notification only if user was previously logged in
                this.notificationCenter.warn('USER_GUARD.YOU_WERE_LOGGED_OUT')
              }
              return canActivate
            })
          )
        } else {
          return of(true)
        }
      })
    )
  }
}
