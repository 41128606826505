import { Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core'
import { Logger, LoggerService } from '@maprix/core'
import { PolaroidSize } from './polaroid-size.model'

@Component({
  selector: 'scs-polaroid',
  templateUrl: './polaroid.component.html',
  styleUrls: ['./polaroid.component.scss'],
})
export class PolaroidComponent implements OnChanges {
  // TODO TYPINGS PolaroidFacet, relates to https://github.com/webpack/webpack/issues/2977#issuecomment-245887841/https://github.com/angular/angular-cli/issues/2034
  @Input() facet: any = 'base'

  // TODO TYPINGS PolaroidSize, relates to https://github.com/webpack/webpack/issues/2977#issuecomment-245887841/https://github.com/angular/angular-cli/issues/2034
  @Input() size: any = 'change-on-sm' as PolaroidSize

  private logger: Logger

  constructor(loggerService: LoggerService, private elementRef: ElementRef, private renderer: Renderer2) {
    this.logger = loggerService.getInstance('PolaroidComponent')
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['size']) {
      if (!changes['size'].isFirstChange()) {
        this.renderer.removeClass(this.elementRef.nativeElement, `size--${changes['size'].previousValue}`)
      }
      this.renderer.addClass(this.elementRef.nativeElement, `size--${changes['size'].currentValue}`)
    }

    if (changes['facet']) {
      if (!changes['facet'].isFirstChange()) {
        this.renderer.removeClass(this.elementRef.nativeElement, `facet--${changes['facet'].previousValue}`)
      }
      this.renderer.addClass(this.elementRef.nativeElement, `facet--${changes['facet'].currentValue}`)
    }
  }
}
