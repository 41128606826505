import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { LoopingCoreModule } from '../../../looping-core.module'
import { InlineMessageComponent } from './inline-message.component'

@NgModule({
  imports: [CommonModule, LoopingCoreModule],
  declarations: [InlineMessageComponent],
  exports: [InlineMessageComponent],
})
export class InlineMessageModule {}
