import { NgModule, Optional, SkipSelf } from '@angular/core'
import { ScrollToModule } from '@maprix/core'
import { AuthModule } from './auth/auth.module'
import { ConfigModule } from './config/config.module'
import { ContactServiceModule } from './contact/contact-service.module'
import { CopyModule } from './copy/copy.module'
import { DataStoreModule } from './data-store/data-store.module'
import { DocumentClickModule } from './document-click/document-click.module'
import { DragulaModule } from './dragula/dragula.modul'
import { ExpertiseModule } from './expertise/expertise.module'
import { FileUploadModule } from './file-upload/file-upload.module'
import { GroupModule } from './group/group.module'
import { AppHttpModule } from './http/app-http.module'
import { MomentModule } from './moment/moment.module'
import { OEmbedModule } from './oembed/o-embed.module'
import { OrganizationModule } from './organization/organization.module'
import { ProfileUserModule } from './profile-user/profile-user.module'
import { PrototypeModule } from './prototype/prototype.module'
import { ScriptLoaderModule } from './script-loader/script-loader.module'
import { StyleModule } from './style/style.module'
import { WsModule } from './ws/ws.module'

@NgModule({
  imports: [
    ConfigModule,
    AppHttpModule,
    ScriptLoaderModule,
    DocumentClickModule,
    AuthModule,
    FileUploadModule,
    OEmbedModule,
    CopyModule,
    MomentModule,
    WsModule,
    StyleModule,
    DataStoreModule,

    // vendor overwrites
    DragulaModule.forRoot(),

    // domain specific service modules
    PrototypeModule,
    ProfileUserModule,
    OrganizationModule,
    ExpertiseModule,
    ContactServiceModule,
    GroupModule,
  ],
})
export class CoreServiceModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreServiceModule
  ) {
    if (parentModule) {
      throw new Error('CoreServiceModule is already loaded. Import it only once')
    }
  }
}
