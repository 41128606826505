import { NgModule } from '@angular/core'
import { FormControlModule } from '@maprix/components'
import { EnterpriseInviteModule } from './enterprise-invite/enterprise-invite.module'
import { ImageCropperModule } from './image-cropper/image-cropper.module'
import { ResourceViewerModule } from './resource-viewer-modal/resource-viewer.module'

@NgModule({
  imports: [
    ImageCropperModule.forRoot(),
    ResourceViewerModule.forRoot(),
    EnterpriseInviteModule,

    // components always needed
    FormControlModule
  ],
  exports: [FormControlModule],
})
export class CoreComponentsModule {}
