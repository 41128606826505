import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { AutoSizeDirective } from './auto-size.directive'

@NgModule({
  imports: [CommonModule],
  declarations: [AutoSizeDirective],
  exports: [AutoSizeDirective],
})
export class AutoSizeModule {}
