import { NgModule, Optional, SkipSelf } from '@angular/core'
import { CopyService } from './copy.service'

@NgModule({
  imports: [],
  providers: [CopyService],
})
export class CopyModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CopyModule
  ) {
    if (parentModule) {
      throw new Error('CopyModule is already loaded. Import it only once')
    }
  }
}
