/**
 * @license
 * Copyright shiftcode GmbH. All Rights Reserved.
 *
 * For a full list of used third-party software and their licenses see https://app.looping.com/ATTRIBUTION_NOTICES.txt
 */
import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

if (environment.production) {
  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => {
    // tslint:disable-next-line:no-console
    console.error(err)
  })
