import { Component, Inject } from '@angular/core'
import { Modal2, MODAL2_DATA, Modal2Ref } from '@maprix/components'
import { Logger, LoggerService, WindowRef } from '@maprix/core'

@Component({
  selector: 'scs-resource-viewer-url-modal',
  templateUrl: './resource-viewer-url-modal.component.html',
})
@Modal2({
  titleKey: 'RESOURCE_VIEWER.URL_MODAL_TITLE',
  closeOnClickBackdrop: false,
  size: 'xs',
})
export class ResourceViewerUrlModalComponent {
  private logger: Logger
  private window: Window

  constructor(loggerService: LoggerService,
              windowRef: WindowRef,
              private modal: Modal2Ref<ResourceViewerUrlModalComponent, boolean>,
              @Inject(MODAL2_DATA) public modalData: { url: string }) {
    this.logger = loggerService.getInstance('ResourceViewerUrlModalComponent')
    this.window = windowRef.nativeWindow
  }

  open(): void {
    this.window.open(this.modalData.url, '_blank')
    this.modal.close(null)
  }
}
