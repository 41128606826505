import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { Logger, LoggerService, UIEventService } from '@maprix/core'
import { debounce } from 'lodash'
import { Observable, Subscription } from 'rxjs'
import { getOrientation } from '../../helpers/image-utils'
import { makeTouchEventUsable } from '../../helpers/utils'
import { StyleService } from '../../services/style/style.service'
import { ImageCropper } from './image-cropper.service'
import { ImageDimensionsAndOffset } from './image-dimensions-and-offset.model'
import { ImageDimensions } from './image-dimensions.model'
import { Moving } from './moving.model'
import { Offset } from './offset.model'

@Component({
  selector: 'scs-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements OnInit, OnChanges, OnDestroy {
  private static MINIMAL_ZOOM_SIZE = 200

  // the image which will be cropped
  @Input() image: File

  // usually a wrapper component handles the save action, this component can also put the observable representing the save action into this component,
  // so we can indicate a work in progress by showing a loader on the save button
  @Input() worker$: Observable<any> | null

  // indicates if the button bar should be visible or not
  @Input() hideButtonBar: boolean

  // defines if the circle mask should be visible or not (has nothing to do with the actual image data, it's just visually)
  @Input() noMask: boolean

  // indicates if the canvas should grow to 100% or if should take a default width / height
  @Input() fullSize: boolean

  @Output() cropChange: EventEmitter<Blob> = new EventEmitter<Blob>()
  @Output() cancel: EventEmitter<null> = new EventEmitter<null>()
  @Output() save: EventEmitter<Blob> = new EventEmitter<Blob>()

  @ViewChild('canvas') canvas: ElementRef

  // zooming
  minZoom = 0
  maxZoom = 100

  // between 0 and 100
  currentZoom = 0

  // holds the relevant infos when the mouse to change the image position
  moving: Moving = {
    startX: 0,
    startY: 0,
    xChange: 0,
    yChange: 0,
    offsetXpre: 0,
    offsetYpre: 0,
  }

  private logger: Logger

  private img: HTMLImageElement

  private ctx: CanvasRenderingContext2D

  // dimensions of original image
  private originalImage: ImageDimensions

  // dimensions of image scaled to fit into the canvas without zooming
  private currentImage: ImageDimensions

  // the offset calculated when the image is initially resized to fit the canvas size and positioned in the center
  private offset: Offset

  // dimension and position of the image
  private currentImageSelection: { x: number; y: number; width: number; height: number }

  private canvasSize = 600

  // the size available for zooming in (content not visible in canvas, portrait -> height bigger than the canvas,
  // landscape -> width bigger than the canvas)
  private zoomableSize: number

  // zoomableSize / 100 * currentZoom
  private currentScale = 1

  private objectUrl: string
  private subscriptions: Subscription[] = []
  private moveSubscription: Subscription
  private moveEndSubscription: Subscription

  private debouncedEmitBlobChange = debounce(this.emitBlobChange, 150)

  // TODO UNIVERSAL
  constructor(
    loggerService: LoggerService,
    private renderer: Renderer2,
    private uiEventService: UIEventService,
    private styleService: StyleService
  ) {
    this.logger = loggerService.getInstance('ImageCropper')
  }

  ngOnInit() {
    this.registerMouseHandler()

    // convert the inputs into boolean attributes
    this.hideButtonBar = this.hideButtonBar === true || <any>this.hideButtonBar === ''
    this.fullSize = this.fullSize === true || <any>this.fullSize === ''
    this.noMask = this.noMask === true || <any>this.noMask === ''

    // set the dimensions using css which scales the canvas to fit in (canvas width / height attributes are different from css attributes,
    // so the canvas will be scaled up or down depending on the css properties)
    this.renderer.setStyle(this.canvas.nativeElement, 'width', this.fullSize ? '100%' : '300px')
    this.renderer.setStyle(this.canvas.nativeElement, 'height', this.fullSize ? 'auto' : '300px')

    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement
    const ctx = canvasEl.getContext('2d')

    if (ctx !== null) {
      // set canvas size
      ctx.canvas.width = this.canvasSize
      ctx.canvas.height = this.canvasSize

      this.ctx = ctx
    } else {
      throw new Error("Canvas Rendering context was null, can't initialize")
    }
  }

  ngOnDestroy(): any {
    URL.revokeObjectURL(this.objectUrl)
    this.subscriptions.forEach(sub => sub.unsubscribe())
    // this.unregisterMouseHandlers();
  }

  ngOnChanges(changes: SimpleChanges): any {
    this.logger.debug('ngOnChanges')
    const imageChange: SimpleChange = changes['image']

    if (imageChange && imageChange.currentValue && imageChange.currentValue !== imageChange.previousValue) {
      this.applyExifTransformation()
    }
  }

  /**
   * creates the blob with the selected image section
   */
  onSave(): void {
    const blob: Blob = ImageCropper.toBlob(
      this.img,
      this.ctx.canvas.width,
      this.ctx.canvas.height,
      this.currentImageSelection.width,
      this.currentImageSelection.height,
      this.currentImageSelection.x,
      this.currentImageSelection.y,
      this.image.type
    )
    this.save.emit(blob)
  }

  onCancel(): void {
    this.cancel.emit(null)
  }

  changeZoom(dir: number): void {
    this.logger.debug('changeZoom', dir)
    this.currentZoom = Math.min(this.maxZoom, Math.max(this.minZoom, Number(this.currentZoom) + dir))
    this.zoomChanged()
  }

  zoomChanged(): void {
    this.logger.debug('zoom changed', this.currentZoom)

    // calculate zoomable size
    const onePercent = this.zoomableSize / 100
    this.currentScale = onePercent * this.currentZoom
    this.logger.debug('zoom by: %s', this.currentScale)

    this.transformAndDraw()
  }

  private drawImage(): void {
    // let objectUrl: string = URL.createObjectURL(this.image);
    // this.objectUrl = objectUrl;

    const img: HTMLImageElement = new Image()
    this.img = img

    img.onload = (event: any) => {
      const image: HTMLImageElement = event.target
      this.logger.debug('original image width is %s and height is %s', image.width, image.height)

      // store original image dimensions
      const originalImage: ImageDimensions = new ImageDimensions(image.width, image.height)

      /*
       * resize small images to fill canvas
       */
      let currentImageWidth: number
      let currentImageHeight: number

      // height
      if (originalImage.height < this.canvasSize) {
        const resizeFactor = this.canvasSize / originalImage.height
        currentImageHeight = originalImage.height * resizeFactor
        currentImageWidth = originalImage.width * resizeFactor
        this.logger.debug('height too small -> image resized to %s / %s', currentImageWidth, currentImageHeight)
      }

      // width
      if (originalImage.width < this.canvasSize) {
        const resizeFactor = this.canvasSize / originalImage.width
        currentImageWidth = originalImage.width * resizeFactor
        currentImageHeight = originalImage.height * resizeFactor
        this.logger.debug('width too small -> image resized to %s / %s', currentImageWidth, currentImageHeight)
      }

      /*
       * calc zoomableSize, current height/width and imageOffsets
       */
      const offset: Offset = new Offset()
      let zoomableSize: number
      if (originalImage.ratio > 1) {
        // portrait (height > width)
        currentImageHeight = this.canvasSize * originalImage.ratio
        currentImageWidth = this.canvasSize
        zoomableSize = originalImage.width - this.ctx.canvas.width
        // center the image vertically
        offset.y = (-currentImageHeight + this.canvasSize) / 2
        offset.x = 0
      } else if (originalImage.ratio < 1) {
        // landscape (width > height)
        currentImageWidth = this.canvasSize / originalImage.ratio
        currentImageHeight = this.canvasSize
        zoomableSize = originalImage.height - this.ctx.canvas.height
        // center the image horizontally
        offset.x = (-currentImageWidth + this.canvasSize) / 2
        offset.y = 0
      } else {
        // square
        currentImageHeight = this.canvasSize
        currentImageWidth = this.canvasSize
        zoomableSize = originalImage.height - this.canvasSize
        offset.x = 0
        offset.y = 0
      }

      this.offset = offset
      this.originalImage = originalImage

      this.currentImage = new ImageDimensions(currentImageWidth, currentImageHeight)
      this.zoomableSize = zoomableSize

      this.logger.debug(
        'drawImage(): %d / %d (%d / %d) with offset %o',
        originalImage.width,
        originalImage.height,
        currentImageWidth,
        currentImageHeight,
        offset
      )

      // calculate zoom scale
      const onePercent = this.zoomableSize / 100
      this.currentScale = onePercent * this.currentZoom

      // set minimal zoomableSize if necessary
      if (this.zoomableSize <= ImageCropperComponent.MINIMAL_ZOOM_SIZE) {
        this.logger.debug('apply minimal zoomableSize to small image')
        this.zoomableSize = ImageCropperComponent.MINIMAL_ZOOM_SIZE
      }

      this.logger.debug('onload', this.originalImage, this.currentImage, this.offset)
      this.transformAndDraw()
    }
    // start loading
    img.src = this.objectUrl
  }

  private transformAndDraw(): void {
    const imageProperties: ImageDimensionsAndOffset = ImageCropper.transformImage(
      this.currentImage,
      this.originalImage.ratio,
      this.currentScale,
      this.offset,
      this.moving.xChange,
      this.moving.yChange,
      this.canvasSize
    )

    this.currentImageSelection = {
      x: imageProperties.offset.x,
      y: imageProperties.offset.y,
      width: imageProperties.width,
      height: imageProperties.height,
    }

    // sets any pixel to transparent black (important for transparent png, if not clearing we have overlaying images)
    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)

    if (!this.noMask) {
      ImageCropper.drawOverlay(this.ctx, this.canvasSize)
    }

    // draw image under the overlay
    this.ctx.drawImage(
      this.img,
      imageProperties.offset.x,
      imageProperties.offset.y,
      imageProperties.width,
      imageProperties.height
    )
    this.ctx.globalCompositeOperation = 'source-over'

    this.logger.debug('transformAndDraw', this.moving, this.currentImageSelection)

    this.debouncedEmitBlobChange()
  }

  private emitBlobChange(): void {
    this.logger.debug('blob changed')
    const blob: Blob = ImageCropper.toBlob(
      this.img,
      this.ctx.canvas.width,
      this.ctx.canvas.height,
      this.currentImageSelection.width,
      this.currentImageSelection.height,
      this.currentImageSelection.x,
      this.currentImageSelection.y,
      this.image.type
    )
    this.cropChange.emit(blob)
  }

  private applyExifTransformation() {
    const canvas = document.createElement('canvas')
    const img = document.createElement('img')
    getOrientation(this.image, orientation => {
      img.onload = () => {
        canvas.width = orientation > 4 ? img.height : img.width
        canvas.height = orientation > 4 ? img.width : img.height
        const ctx = canvas.getContext('2d')
        this.applyTransform(ctx, orientation, img.width, img.height)
        ctx!.drawImage(img, 0, 0)
        this.objectUrl = canvas.toDataURL(this.image.type)
        URL.revokeObjectURL(img.src)
        this.drawImage()
      }

      img.onerror = () => {
        this.logger.warn('unable to load data url')
      }

      img.src = URL.createObjectURL(this.image)
    })
  }

  private applyTransform(ctx, orientation, width, height) {
    switch (orientation) {
      case 2:
        return ctx.transform(-1, 0, 0, 1, width, 0)
      case 3:
        return ctx.transform(-1, 0, 0, -1, width, height)
      case 4:
        return ctx.transform(1, 0, 0, -1, 0, height)
      case 5:
        return ctx.transform(0, 1, 1, 0, 0, 0)
      case 6:
        return ctx.transform(0, 1, -1, 0, height, 0)
      case 7:
        return ctx.transform(0, -1, -1, 0, height, width)
      case 8:
        return ctx.transform(0, -1, 1, 0, 0, width)
    }
  }

  private registerMouseHandler() {
    const sub: Subscription = this.uiEventService
      .forEvent(['mousedown', 'touchstart'], this.canvas.nativeElement)
      .subscribe(event => {
        // only handle left mouse click
        if (!(event instanceof MouseEvent) || (event instanceof MouseEvent && event.button === 0)) {
          this.logger.warn('start -> add move listener')

          this.moveSubscription = this.uiEventService.forEvent(['mousemove', 'touchmove']).subscribe(moveEvent => {
            // this.logger.warn('move event');
            this.move(moveEvent)
          })

          // setup the listeners for move end event
          this.moveEndSubscription = this.uiEventService
            .forEvent(['mouseup', 'touchend', 'mouseleave', 'touchcancel'])
            .subscribe(endEvent => {
              this.logger.warn('end -> unsubscribe move')

              // unsubscribe events
              if (this.moveSubscription && !this.moveSubscription.closed) {
                this.moveSubscription.unsubscribe()
              }

              if (this.moveEndSubscription && !this.moveEndSubscription.closed) {
                this.moveEndSubscription.unsubscribe()
              }

              // finish the moving
              this.endMove()
            })

          // initialize the moving
          this.startMove(event)
        }
      })

    this.subscriptions.push(sub)
  }

  /*
   * handle mouse / touch events
   */
  private startMove = (event: Event) => {
    this.styleService.setStyle('cursor', 'move')

    const data = makeTouchEventUsable(event, this.ctx.canvas.width / 2, this.ctx.canvas.height / 2)

    // offset relative to the canvas
    this.moving.startX = data.pageX
    this.moving.startY = data.pageY

    this.logger.debug('startMove :: offsetX: %s offsetY: %s', this.moving.startX, this.moving.startY)
    this.logger.debug('startMove :: current image', this.currentImage)
  }

  private move = (event: Event) => {
    event.preventDefault()
    const data = makeTouchEventUsable(event, this.ctx.canvas.width / 2, this.ctx.canvas.height / 2)

    // check for max offset
    const xChange = this.moving.offsetXpre + this.moving.startX - data.pageX
    const yChange = this.moving.offsetYpre + this.moving.startY - data.pageY
    const offset: Offset = ImageCropper.offsetRespectBoundaries(
      this.currentImage,
      this.originalImage.ratio,
      this.currentScale,
      this.offset,
      xChange,
      yChange,
      this.canvasSize
    )

    // this.logger.debug('moving diff %d / %d (%d & %d)', this.moving.startX - data.pageX, this.moving.startY - data.pageY, data.pageX, data.pageY);
    this.logger.debug('move: ', offset)

    let needsRedraw = false
    if (this.moving.xChange === offset.x) {
      this.logger.warn("x edge reached, won't move")
    } else {
      this.moving.xChange = offset.x
      needsRedraw = true
    }

    if (this.moving.yChange === offset.y) {
      this.logger.warn("y edge reached, won't move")
    } else {
      this.moving.yChange = offset.y
      needsRedraw = true
    }

    if (needsRedraw) {
      this.transformAndDraw()
    }
  }

  private endMove = () => {
    this.styleService.deleteStyle('cursor')
    this.moving.offsetXpre = this.moving.xChange
    this.moving.offsetYpre = this.moving.yChange

    this.logger.debug('store offsetXChange: %s offsetYChange: %s', this.moving.offsetXpre, this.moving.offsetYpre)
  }
}
