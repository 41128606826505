import { Pipe, PipeTransform } from '@angular/core'
import { words } from 'lodash'

@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      const names: string[] = words(value)
      let initials = ''

      if (names.length >= 2) {
        initials += names[0].substr(0, 1)
        initials += names[names.length - 1].substr(0, 1)
      } else {
        initials = names[0].substr(0, 1)
      }
      return initials
    } else {
      return ''
    }
  }
}
