import { NgModule, Optional, SkipSelf } from '@angular/core'
import { OEmbedService } from './o-embed.service'

@NgModule({
  imports: [],
  providers: [OEmbedService],
})
export class OEmbedModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: OEmbedModule
  ) {
    if (parentModule) {
      throw new Error('OEmbedModule is already loaded. Import it only once')
    }
  }
}
