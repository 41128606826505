import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms'
import { FileTypeError } from './file-type-error.model'

export const ERR_KEY_FILE_TYPE = 'fileType'

export interface FileTypeValidatorFn extends ValidatorFn {
  (c: AbstractControl): { fileType: FileTypeError } | null
}

export function createFileTypeValidator(accepted: string[]): FileTypeValidatorFn {
  return (c: FormControl): { fileType: FileTypeError } | null => {
    const files: FileList = c.value

    // check for a value
    if (!files) {
      return null
    }

    const valid: File[] = []
    const invalid: File[] = []

    // check for correct file type
    for (let i = 0; i < files.length; i++) {
      const file: File = files.item(i)

      // if no file type (means no suffix is found), we accept it and react to server error codes
      if (file.type === '') {
        valid.push(file)
      } else if (accepted.some(accept => accept.toLowerCase() === file.type.toLowerCase())) {
        valid.push(file)
      } else {
        invalid.push(file)
      }
    }

    if (valid.length === files.length) {
      return null
    } else {
      return {
        fileType: {
          valid,
          invalid,
          accepted,
        },
      }
    }
  }
}
