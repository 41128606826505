import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, Renderer2 } from '@angular/core'
import { Logger, LoggerService } from '@maprix/core'
import { Subject } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'

// Hack for: use MouseEvent instead of DragEvent Safari 10 bug (only appears in none aot builds), https://bugs.webkit.org/show_bug.cgi?id=103423
// tslint:disable-next-line
interface CustomDragEvent extends DragEvent {}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[scs-file-drop]',
})
export class FileDropDirective implements OnDestroy {
  private static FILE_TRANSFER_TYPE = 'Files'

  @Output() fileOver: EventEmitter<boolean> = new EventEmitter<boolean>()
  // tslint:disable-next-line
  @Output('scs-file-drop') fileDrop: EventEmitter<FileList> = new EventEmitter<FileList>()

  @Input() onFileOverCssClass: string

  private logger: Logger

  // Why not use just EventEmitter - see: http://stackoverflow.com/questions/36076700/what-is-the-proper-use-of-an-eventemitter
  private internalFileOverSubject: Subject<boolean> = new Subject<boolean>()

  private static preventAndStop(event: Event): any {
    event.preventDefault()
    event.stopPropagation()
  }

  private static isFilesType(types: DOMStringList | string[]): any {
    if (!types) {
      return false
    }

    if ((<string[]>types).indexOf) {
      return (<string[]>types).indexOf(FileDropDirective.FILE_TRANSFER_TYPE) !== -1
    } else if ((<DOMStringList>types).contains) {
      return (<DOMStringList>types).contains(FileDropDirective.FILE_TRANSFER_TYPE)
    } else {
      return false
    }
  }

  constructor(private element: ElementRef, private renderer: Renderer2, loggerService: LoggerService) {
    this.logger = loggerService.getInstance('FileDropDirective')
    this.subscribeInternal()
  }

  @HostListener('drop', ['$event'])
  onDrop(event: CustomDragEvent): void {
    this.logger.debug(event)
    if (!event.dataTransfer) {
      this.logger.debug('no data dropped')
      return
    }

    FileDropDirective.preventAndStop(event)

    this.internalFileOverSubject.next(false)
    this.fileDrop.emit(event.dataTransfer.files)
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: CustomDragEvent): void {
    const transfer = event.dataTransfer
    if (!FileDropDirective.isFilesType(transfer.types)) {
      return
    }

    transfer.dropEffect = 'copy'
    FileDropDirective.preventAndStop(event)
    this.internalFileOverSubject.next(true)
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: CustomDragEvent): any {
    this.logger.debug('drag leave')
    FileDropDirective.preventAndStop(event)
    this.internalFileOverSubject.next(false)
  }

  ngOnDestroy() {
    this.logger.debug('completing subject')
    this.internalFileOverSubject.complete()
  }

  private subscribeInternal(): void {
    // we only want events if the value (true/false) changes (dragover fires on every mouse move).
    this.internalFileOverSubject.pipe(distinctUntilChanged()).subscribe((state: boolean) => {
      this.toggleClass(state)
      this.fileOver.emit(state)
    })
  }

  private toggleClass(addOrRemove: boolean) {
    addOrRemove
      ? this.renderer.addClass(this.element.nativeElement, this.onFileOverCssClass)
      : this.renderer.removeClass(this.element.nativeElement, this.onFileOverCssClass)
  }
}
