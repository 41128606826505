import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SecurityContext } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { Logger, LoggerService, TranslateService } from '@maprix/core'
import { clone, merge } from 'lodash'
import { from, Observable, Subscription } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { pickQueryParams } from '../../shared/helpers/utils'
import { HttpError } from '../../shared/models/http/http-error'
import { HttpErrorCodes } from '../../shared/models/http/http-error-codes'
import { AuthService } from '../../shared/services/auth/auth.service'
import { LoginService } from './login.service'
import { ActionType } from './models/action-type.enum'
import { LoginError } from './models/login-error'
import { LoginInitViewState } from './models/login-init-view-state'
import { LoginNextState } from './models/login-next-view-state'
import { LoginQueryParams } from './models/login-query-params.model'
import { LoginStateData } from './models/login-state-data'
import { LoginStateView } from './models/login-view-state.enum'

@Component({
  selector: 'scs-login-flow',
  templateUrl: './login-flow.component.html',
})
export class LoginFlowComponent implements OnInit, OnDestroy {
  private static LINK_TERMS_ENTERPRISE = 'terms/b2e'
  private static KEY_LINK_TERMS_ENTERPRISE = 'LOGIN.COMMONS.TERMS_ENTERPRISE'

  @Input() initState: LoginInitViewState | undefined
  @Output() login: EventEmitter<void> = new EventEmitter<void>()

  error: HttpError | string | null

  state: LoginStateView
  data: LoginStateData | null = {
    loginData: {},
  }

  loginStateView: any = LoginStateView

  history: LoginNextState[] = []
  // TODO TYPINGS SafeHtml, relates to this issue https://github.com/webpack/webpack/issues/2977#issuecomment-245887841
  // (on cli repo https://github.com/angular/angular-cli/issues/2034)
  trustedTermsHtml: any
  initializing: boolean
  workers$: { [key: number]: Observable<any> | null } = {} // key is of type LoginStateView

  private logger: Logger
  private subscriptions: Subscription[]

  constructor(
    loggerService: LoggerService,
    private loginService: LoginService,
    private authService: AuthService,
    private router: Router,
    private translateService: TranslateService,
    private domSanitizer: DomSanitizer,
    route: ActivatedRoute
  ) {
    this.logger = loggerService.getInstance('LoginFlow')
  }

  ngOnInit() {
    this.initializing = true
    this.subscriptions = []
    const termsKey = LoginFlowComponent.KEY_LINK_TERMS_ENTERPRISE
    const linkO = { link: LoginFlowComponent.LINK_TERMS_ENTERPRISE }

    // the terms are used on several steps so we translate it here and give it to the steps
    let s = this.translateService.get(termsKey, linkO).subscribe((terms: string) => {
      this.trustedTermsHtml = this.domSanitizer.sanitize(SecurityContext.HTML, terms)
    })

    this.subscriptions.push(s)

    /*
     * emit login event when a user logged in when login route was active
     */
    s = this.authService.isLoggedInChanges.subscribe((isLoggedIn: boolean) => {
      if (isLoggedIn) {
        // on login
        this.login.emit()
      } else {
        // on logout -> start over
        this.initFlow(false)
      }
    })

    this.subscriptions.push(s)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  next(actionType?: ActionType): void {
    this.error = null

    const nextData: LoginStateData | null = clone(this.data)

    if (nextData === null) {
      throw new Error()
    }

    const promise: Promise<any> = this.loginService
      .next(this.state, nextData, actionType)
      .then(this.handleNextState)
      .catch(this.handleError)

    this.workers$[this.state] = from(promise)
  }

  back(): void {
    const stateObj: LoginNextState | undefined = this.history.pop()

    // reset login type for first state, is manipulated before it gets stored in history
    if (stateObj !== undefined) {
      if (stateObj.state === LoginStateView.START) {
        merge(stateObj, { data: { loginData: {} } })
      }

      this.state = stateObj.state
      this.data = stateObj.data
      this.error = null
    } else {
      this.logger.error("state from history was undefined, can't restore previous state")
    }
  }

  private initFlow(loggedIn: boolean): void {
    // if no current user
    if (!loggedIn) {
      if (this.initState) {
        // we need to jump to a predefined state
        this.loginService
          .init({ jumpTo: this.initState })
          .then((nextState: LoginNextState) => {
            this.initializing = false
            this.handleNextState(nextState)
          })
          .catch((error: any) => {
            this.initializing = false
            this.handleError(error)
            this.handleNoOrganizationOrBlackListError()
          })
      } else {
        this.router.routerState.root.queryParams
          .pipe(
            first(),
            map((queryParams: LoginQueryParams) => {
              return pickQueryParams(
                queryParams,
                'email',
                'expiration',
                'signature',
                'requestedUserEmail',
                'requestedUserId'
              )
            })
          )
          .subscribe((queryParams: LoginQueryParams) => {
            this.loginService
              .init({ queryParams })
              .then((next: LoginNextState) => {
                this.initializing = false
                this.state = next.state
                this.data = next.data

                if (next.state !== LoginStateView.START) {
                  this.history.push({ state: LoginStateView.START, data: null })
                }
              })
              .catch((error: any) => {
                this.initializing = false
                this.handleError(error)
                this.handleNoOrganizationOrBlackListError()
              })
          })
      }
    }
  }

  private handleNextState = (nextState: LoginNextState): LoginNextState => {
    // remove worker$ for current state
    this.workers$[this.state] = null

    switch (nextState.state) {
      case LoginStateView.END:
        // no need to emit login, will be done be the subscription on loggedIn state in ngInit
        this.state = LoginStateView.END
        this.data = null
        break
      case LoginStateView.GO_BACK:
        this.back()
        break
      default:
      // do nothing
    }

    // update history items and store current state if state is not END or GO_BACK
    if (
      nextState.state !== LoginStateView.END &&
      nextState.state !== LoginStateView.GO_BACK &&
      nextState.state !== LoginStateView.NO_CHANGE
    ) {
      if (!nextState.data) {
        // no change in state data
        nextState.data = clone(this.data)
      }

      // do not populate history for image cropper state, because this is a side step outside the normal flow
      if (this.state !== LoginStateView.IMAGE_CROPPER) {
        this.storeCurrentState()
      }

      this.state = nextState.state
      this.data = nextState.data
    }

    // return the next state for chaining purposes
    return nextState
  }

  private handleError = (error: LoginError | HttpError): Promise<LoginError | HttpError> => {
    this.logger.warn('could not resolve the next login view state', error)

    // remove worker$ for current state
    this.workers$[this.state] = null

    // check against undefined not working if there is a cast
    // maybe a typescript bug
    if (this.isLoginError(error)) {
      this.error =
        error.httpError ||
        // message key provided by LoginService not from http service
        error.plainErrorKey
    } else {
      this.error = error
    }

    // we do not want to restore the promise chain, we are just intercepting it to update the error field, return the rejection to let custom error handlers on
    // the worker$ execute too
    return Promise.reject(error)
  }

  private storeCurrentState(): void {
    this.history.push({ state: this.state, data: this.data })
  }

  // we need to set the state to start when one of these errors occure (from old links for ex.)
  private handleNoOrganizationOrBlackListError() {
    const errorKey = (<HttpError>this.error).code ? (<HttpError>this.error).code : this.error
    if (
      errorKey === HttpErrorCodes.DOMAIN_BLACK_LISTED ||
      errorKey === HttpErrorCodes.NO_MATCHING_ORGANIZATION_FOR_DOMAIN
    ) {
      this.state = LoginStateView.START
    }
  }

  private isLoginError(error: LoginError | HttpError): error is LoginError {
    if ((<LoginError>error).httpError !== undefined || (<LoginError>error).plainErrorKey !== undefined) {
      return true
    } else {
      return false
    }
  }
}
