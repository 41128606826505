import { Component, HostBinding, Inject, OnInit, SecurityContext } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { Modal2, MODAL2_DATA, Modal2Ref } from '@maprix/components'
import { Logger, LoggerService, TranslateService } from '@maprix/core'
import { Observable } from 'rxjs'
import { share } from 'rxjs/operators'
import { HttpError } from '../../models/http/http-error'
import { AuthService } from '../../services/auth/auth.service'

export interface PrivacyPolicyModalData {
  useCase: 'login' | 'register'
  customerName: string
}

@Component({
  selector: 'scs-privacy-policy-modal',
  templateUrl: './privacy-policy-modal.component.html',
  styleUrls: ['./privacy-policy-modal.component.scss'],
})
@Modal2({
  titleKey: 'PRIVACY_POLICY.TITLE',
  preventFromClosing: true,
  size: 'xs',
})
export class PrivacyPolicyModalComponent implements OnInit {
  worker: Observable<any>
  error: HttpError
  trustedContent: SafeHtml

  private logger: Logger

  constructor(
    loggerService: LoggerService,
    private modal: Modal2Ref<PrivacyPolicyModalComponent, boolean>,
    private authService: AuthService,
    private translateService: TranslateService,
    private domSanitizer: DomSanitizer,
    @Inject(MODAL2_DATA) public modalData: PrivacyPolicyModalData
  ) {
    this.logger = loggerService.getInstance('PrivacyPolicyModalComponent')
  }

  ngOnInit() {
    this.translateService
      .get(`PRIVACY_POLICY.POLICY`, { customerName: this.modalData.customerName })
      .subscribe((terms: string) => {
        this.trustedContent = this.domSanitizer.sanitize(SecurityContext.HTML, terms)
      })
  }

  accept(): void {
    this.worker = this.authService.acceptPrivacyPolicy().pipe(share())

    this.worker.subscribe(
      // SUCCESS
      () => {
        this.modal.close(true)
      },
      // ERROR
      (httpError: HttpError) => {
        this.error = httpError
      }
    )
  }
}
