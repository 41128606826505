import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ScValidators } from '@maprix/components'
import { Logger, LoggerService } from '@maprix/core'
import { UploadPrototype } from '../../../../shared/models/prototype/prototype.model'

@Component({
  selector: 'scs-upload-step-describe',
  templateUrl: './upload-step-describe.component.html',
  styleUrls: ['./upload-step-describe.component.scss'],
})
export class UploadStepDescribeComponent implements OnInit {
  @HostBinding('class') classes = 'wizard-step wizard-step--describe'

  @Input() prototype: UploadPrototype
  @Output() revalidate: EventEmitter<void> = new EventEmitter<void>()

  form: FormGroup

  private logger: Logger

  constructor(loggerService: LoggerService, private formBuilder: FormBuilder) {
    this.logger = loggerService.getInstance('UploadStepDescribeComponent')
  }

  ngOnInit() {
    this.createOrResetForm()
  }

  createOrResetForm(): void {
    const title = this.prototype.title
    const description = this.prototype.description
    this.form = this.formBuilder.group({
      title: [title, Validators.compose([ScValidators.notEmpty, Validators.maxLength(25)])],
      description: [description, Validators.compose([ScValidators.notEmpty, Validators.maxLength(1000)])],
    })

    this.form.valueChanges.subscribe(data => {
      this.prototype.title = data.title
      this.prototype.description = data.description
      this.logger.debug('revalidate emit')
      this.revalidate.emit()
    })
  }
}
