import { isEmpty } from 'lodash'
import { Observable } from 'rxjs'
import { SortDirection } from '../../title-bar/title-bar.component'
import { TableColumnDirective } from '../directives/table-column.directive'
import { TableColumnGutter } from './table-column-gutter.model'

export class TableColumnModel {
  id: string // used to resolve the sortEnum for a column using the options.sorting field
  prop: string
  title: string
  hasPriority: boolean
  gutter: TableColumnGutter

  sortDirection: SortDirection
  sortable: boolean

  // indicates if there is a header template defined or note
  hasHeaderTemplate: boolean

  // ng2 header template
  headerTemplate: any

  // ng2 cell template
  cellTemplate: any

  // TODO MED (REVIEW TABLE CONCEPT) check usage of Object.assign
  constructor(props?: TableColumnDirective, titleObs?: Observable<string>) {
    if (props && !isEmpty(props)) {
      this.id = props.id
      this.hasHeaderTemplate = props.hasHeaderTemplate

      if (titleObs) {
        titleObs.subscribe(title => (this.title = title))
      }

      this.prop = props.prop
      this.hasPriority = props.hasPriority
      this.gutter = props.gutter
      this.sortDirection = props.sortDirection
      this.sortable = props.sortable

      if (props && props.templates) {
        this.headerTemplate = props.headerTemplate
        this.cellTemplate = props.cellTemplate
      }
    }
  }
}
