import { Component, HostBinding, Inject, OnInit } from '@angular/core'
import { Modal2, MODAL2_DATA, Modal2Ref } from '@maprix/components'
import { Logger, LoggerService } from '@maprix/core'
import { filledArrayWithLength } from '../../../../shared/helpers/utils'
import { OEmbedProvider } from '../../../../shared/models/resource/o-embed-provider.enum'
import { OEmbedResource } from '../../../../shared/models/resource/o-embed-resource.model'
import { O_EMBED_TYPE_LOGOS } from '../../../../shared/models/resource/o-embed-type-logos.const'
import { PrototypeResourceType } from '../../../../shared/models/resource/prototype-resource-type.enum'
import { PrototypeResource } from '../../../../shared/models/resource/prototype-resource.model'
import { O_EMBED_RESOURCE_META_DATA } from '../../../../shared/models/resource/resource-meta-data.const'
import { OEmbedResourceMetaData } from '../../../../shared/services/oembed/o-embed-resource-meta-data.model'
import { OEmbedType } from '../../../../shared/services/oembed/o-embed-type.enum'

export interface ResourceUploadData {
  firstResource: PrototypeResource | undefined
}

@Component({
  selector: 'scs-resource-upload-modal',
  templateUrl: './resource-upload-modal.component.html',
  styleUrls: ['./resource-upload-modal.component.scss'],
})
@Modal2({
  size: 'xs',
  facet: 'upload',
})
export class ResourceUploadModalComponent implements OnInit {
  @HostBinding('class.grid') hasGrid = true
  activeTab: boolean[] = filledArrayWithLength(4, false)
  disabledTab: boolean[] = filledArrayWithLength(4, true)
  supportedVideoUploadPlatforms: OEmbedResourceMetaData[]
  platformLogoVideo: string = O_EMBED_TYPE_LOGOS.filter(logo => logo.type === OEmbedType.VIDEO)[0].logoPath
  supportedAudioUploadPlatforms: OEmbedResourceMetaData[]
  platformLogoAudio: string = O_EMBED_TYPE_LOGOS.filter(logo => logo.type === OEmbedType.AUDIO)[0].logoPath

  // needed to keep import
  oEmbedType = OEmbedType

  private logger: Logger

  constructor(loggerService: LoggerService,
              private modal: Modal2Ref<ResourceUploadModalComponent, PrototypeResource>,
              @Inject(MODAL2_DATA) public modalData: ResourceUploadData) {
    this.logger = loggerService.getInstance('ResourceUploadModalComponent')
  }

  ngOnInit() {
    this.determinateTabSate(this.modalData.firstResource)

    this.supportedVideoUploadPlatforms = O_EMBED_RESOURCE_META_DATA.filter(
      platform => platform.oEmbedType === OEmbedType.VIDEO,
    )

    this.supportedAudioUploadPlatforms = O_EMBED_RESOURCE_META_DATA.filter(
      platform => platform.oEmbedType === OEmbedType.AUDIO,
    )
  }

  onPrototypeResource(resource: PrototypeResource) {
    this.logger.debug('got resource %O', resource)
    this.modal.close(resource)
  }

  private determinateTabSate(firstResource: PrototypeResource | undefined) {
    if (firstResource) {
      switch (firstResource.type) {
        case PrototypeResourceType.IMAGE:
          this.activeTab[0] = true
          this.disabledTab[0] = false
          break
        case PrototypeResourceType.URL:
          this.activeTab[3] = true
          this.disabledTab[3] = false
          break
        case PrototypeResourceType.O_EMBED:
          switch ((<OEmbedResource>this.modalData.firstResource).oEmbed.oEmbedProvider) {
            case OEmbedProvider.MIXCLOUD:
            case OEmbedProvider.SOUNDCLOUD:
              this.activeTab[2] = true
              this.disabledTab[2] = false
              break
            case OEmbedProvider.VIMEO:
            case OEmbedProvider.YOUTUBE:
              this.activeTab[1] = true
              this.disabledTab[1] = false
              break
          }
      }
    } else {
      this.activeTab[0] = true
      this.disabledTab = filledArrayWithLength(4, false)
    }
  }
}
