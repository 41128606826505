export enum LoginStateView {
  START,
  LOGIN_ENT,
  TRIAL,
  TWO_FA_SETUP,
  TWO_FA_VERIFY,
  REGISTER_ENT,
  IMAGE_CROPPER,
  FORGOT_PASSWORD,
  LOGIN_SAML_SSO,
  // PRIVACY_POLICY,
  END, // marker states

  // navigate one step back
  GO_BACK,

  // states which are triggered from a link (jumpTo state)
  RESET_PASSWORD_FROM_LINK,
  REGISTER_FROM_LINK,

  // no change, no step will be stored in history
  NO_CHANGE,
}
