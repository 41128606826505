import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot } from '@angular/router'
import { Logger, LoggerService } from '@maprix/core'
import { AuthService } from './auth.service'

/**
 * We enforce enterprise customers (check for LS_KEY_LAST_LOGIN_ENTERPRISE_USER key in LS) to login for accessing
 * certain routes (to prevent them from wasting time with public content.)
 */
@Injectable()
export class CanActivateEnterpriseLoggedInGuard implements CanActivate {
  private logger: Logger

  constructor(loggerService: LoggerService, private authService: AuthService, private router: Router) {
    this.logger = loggerService.getInstance('CanActivateEnterpriseLoggedInGuard')
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // OK if logged in or previously not logged in as enterprise user
    if (this.authService.isLoggedIn()) {
      return true
    } else {
      // special handling if url has a fragment (otherwise the fragment is wrongly de and encoded and not recognised as a fragment anymore..)
      const navigationExtras: NavigationExtras = {
        queryParamsHandling: 'merge',
        fragment: route.fragment,
        queryParams: { redirect: state.url.replace(`#${route.fragment}`, '') },
      }
      // not logged in and enterprise user -> redirect to login with current state url as redirect query param
      this.router.navigate(['login'], navigationExtras)
      return false
    }
  }
}
