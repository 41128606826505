import { Injectable } from '@angular/core'
import { Logger, LoggerService, RuntimeConfiguration } from '@maprix/core'
import { Observable, of, ReplaySubject } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { QueryStats } from '../../models/shared/query-stats.model'
import { AuthUser } from '../../models/user/auth-user'
import { DeleteFeedbackDTO } from '../../models/user/delete-feedback.dto'
import { ProfileUserMessage } from '../../models/user/profile-user-messages.model'
import { ProfileUser } from '../../models/user/profile-user.model'
import { AuthService } from '../auth/auth.service'
import { MicroService } from '../config/runtime-configuration/micro-service.enum'
import { FileUploadService } from '../file-upload/file-upload.service'
import { AppHttpResponse } from '../http/app-http-response.model'
import { AppHttp } from '../http/app-http.service'

@Injectable()
export class ProfileUserService {
  private static URI_BASE = 'secure/profileuser'
  private static URI_PUBLIC_BASE = 'public/profileuser'
  private static URI_CURRENT = ProfileUserService.URI_BASE + '/current'
  private static URI_PROFILE_PICTURE = ProfileUserService.URI_BASE + '/current/profilepicture'
  private static URI_DELETE_FEEDBACK = ProfileUserService.URI_BASE + '/current/deletefeedback'
  private static URI_MESSAGES = ProfileUserService.URI_BASE + '/current/messages'
  private static URI_SEARCH = ProfileUserService.URI_BASE + '/search'
  private static URI_ORGANIZATION_ALL_EMAILS = ProfileUserService.URI_BASE + '/organization/allemails'
  private static URI_ORGANIZATION_NONADMIN = ProfileUserService.URI_BASE + '/organization/nonadmin'
  private static URI_EXPERTISES = ProfileUserService.URI_BASE + '/current/expertises'
  private static URI_POSTPONE_EXPERTISE_PROMPT = ProfileUserService.URI_BASE + '/current/expertises/postponeprompt'

  private logger: Logger
  private mainUrl: string

  private profileUserSubject: ReplaySubject<ProfileUser | null> = new ReplaySubject<ProfileUser | null>(1)
  private profileUserMessageSubject: ReplaySubject<ProfileUserMessage[]> = new ReplaySubject<ProfileUserMessage[]>(1)

  private _profileUserChanges: Observable<ProfileUser | null> = this.profileUserSubject.asObservable()
  private _profileUserMessageChanges: Observable<ProfileUserMessage[]> = this.profileUserMessageSubject.asObservable()

  constructor(
    loggerService: LoggerService,
    runtimeConfiguration: RuntimeConfiguration,
    private authService: AuthService,
    private appHttp: AppHttp,
    private fileUploadService: FileUploadService
  ) {
    this.logger = loggerService.getInstance('ProfileUser')
    this.mainUrl = runtimeConfiguration.getUrlForRestService(MicroService.MAIN)

    this.authService.authUserChanges.subscribe((authUser: AuthUser) => {
      if (authUser) {
        // fetch profile user
        this.getCurrent().subscribe(profileUser => this.logger.debug('got profile user', profileUser))

        this.getMessages().subscribe(messages => this.logger.debug('got profile messages', messages))
      } else {
        this.profileUserSubject.next(null)
      }
    })
  }

  get profileUserChanges(): Observable<ProfileUser | null> {
    return this._profileUserChanges
  }

  get profileUserMessageChanges(): Observable<ProfileUserMessage[]> {
    return this._profileUserMessageChanges
  }

  getCurrent(): Observable<ProfileUser> {
    return this.appHttp
      .get<ProfileUser>(this.mainUrl + ProfileUserService.URI_CURRENT)
      .pipe(map(this.getProfileUserAndNotify))
  }

  get(id: string): Observable<ProfileUser> {
    return this.getUrlBasedOnLoginState().pipe(
      switchMap(baseUrl => {
        return this.appHttp.get<ProfileUser>(this.mainUrl + baseUrl + '/' + id).pipe(
          map((response: AppHttpResponse<ProfileUser>) => {
            return response.body
          })
        )
      })
    )
  }

  getCommunityCount(): Observable<QueryStats> {
    return this.getUrlBasedOnLoginState().pipe(
      switchMap(baseUrl => {
        return this.appHttp.get<QueryStats>(`${this.mainUrl}${baseUrl}/community/count`).pipe(
          map((response: AppHttpResponse<QueryStats>) => {
            return response.body
          })
        )
      })
    )
  }

  setProfilePicture(picture: Blob): Observable<ProfileUser> {
    return this.fileUploadService
      .uploadSingle({ data: picture }, ProfileUserService.URI_PROFILE_PICTURE)
      .pipe(map(this.getProfileUserAndNotify))
  }

  search(searchString: string): Observable<ProfileUser[]> {
    return this.appHttp.get(this.mainUrl + ProfileUserService.URI_SEARCH, { params: { search: searchString } }).pipe(
      map((response: AppHttpResponse<ProfileUser[]>) => {
        return response.body
      })
    )
  }

  getNonAdmins(searchString: string): Observable<ProfileUser[]> {
    return this.appHttp
      .get(this.mainUrl + ProfileUserService.URI_ORGANIZATION_NONADMIN, { params: { search: searchString } })
      .pipe(
        map((response: AppHttpResponse<ProfileUser[]>) => {
          return response.body
        })
      )
  }

  allEmails(): Observable<string[]> {
    return this.appHttp.get(this.mainUrl + ProfileUserService.URI_ORGANIZATION_ALL_EMAILS).pipe(
      map((response: AppHttpResponse<string[]>) => {
        return response.body
      })
    )
  }

  updateCurrent(profileUser: ProfileUser): Observable<ProfileUser> {
    return this.appHttp
      .put<ProfileUser>(this.mainUrl + ProfileUserService.URI_CURRENT, profileUser)
      .pipe(map(this.getProfileUserAndNotify))
  }

  deleteFeedback(deleteFeedbackDTO: DeleteFeedbackDTO): Observable<ProfileUser> {
    return this.appHttp
      .post<ProfileUser>(this.mainUrl + ProfileUserService.URI_DELETE_FEEDBACK, deleteFeedbackDTO)
      .pipe(map(this.getProfileUserAndNotify))
  }

  updateExpertises(profileUser: ProfileUser): Observable<ProfileUser> {
    return this.appHttp
      .put<ProfileUser>(this.mainUrl + ProfileUserService.URI_EXPERTISES, profileUser)
      .pipe(map(this.getProfileUserAndNotify))
  }

  postponeExpertisePrompt(): Observable<ProfileUser> {
    return this.appHttp
      .put<ProfileUser>(this.mainUrl + ProfileUserService.URI_POSTPONE_EXPERTISE_PROMPT, {})
      .pipe(map(this.getProfileUserAndNotify))
  }

  private getProfileUserAndNotify = (response: AppHttpResponse<ProfileUser>): ProfileUser => {
    const profileUser: ProfileUser = response.body
    this.profileUserSubject.next(profileUser)
    return profileUser
  }

  private getMessages(): Observable<ProfileUserMessage[]> {
    return this.appHttp.get<ProfileUserMessage[]>(this.mainUrl + ProfileUserService.URI_MESSAGES).pipe(
      map((response: AppHttpResponse<ProfileUserMessage[]>) => {
        const messages: ProfileUserMessage[] = response.body
        this.profileUserMessageSubject.next(messages)
        return messages
      })
    )
  }

  private getUrlBasedOnLoginState(): Observable<string> {
    return of(this.authService.isLoggedIn() ? ProfileUserService.URI_BASE : ProfileUserService.URI_PUBLIC_BASE)
  }
}
