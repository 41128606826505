import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser'
import { Logger, LoggerService, WindowRef } from '@maprix/core'
import { createAmountValidator, ERR_KEY_WRONG_AMOUNT } from '../file-selector/validators/amount.validator'
import { FileTypeError } from '../file-selector/validators/file-type-error.model'
import { createFileTypeValidator, ERR_KEY_FILE_TYPE } from '../file-selector/validators/file-type.validator'
import { MaxFileSizeError } from '../file-selector/validators/max-file-size-error.model'
import { createFileSizeValidator, ERR_KEY_MAX_FILE_SIZE } from '../file-selector/validators/max-file-size.validator'

@Component({
  selector: 'scs-login-avatar',
  templateUrl: './login-avatar.component.html',
  styleUrls: ['./login-avatar.component.scss'],
})
export class LoginAvatarComponent implements OnInit, OnChanges, OnDestroy {
  private static MAX_FILE_SIZE: number = 8 * 1024 * 1024 // 1MB
  private static ACCEPTED_FILE_TYPES: string[] = ['image/jpg', 'image/jpeg', 'image/png']
  private static MAX_AMOUNT = 1

  @Input() displayName: string
  // TODO TYPINGS SafeUrl, relates to this issue https://github.com/webpack/webpack/issues/2977#issuecomment-245887841/https://github.com/angular/angular-cli/issues/2034
  @Input() imageUrl: any
  @Input() imageBlob: Blob
  @Input() avatarColor: string

  @Input() email: string

  @Input() editable: boolean // Indicates if the edit icon should be visible or not
  @Input() anonymous: boolean
  @Input() hideDisplayName: boolean
  @Input() organization: boolean

  @Output() edit: EventEmitter<File> = new EventEmitter<File>()

  profilePictureControl: FormControl

  // error definition
  error: string | null
  details: { [key: string]: any } | null

  private logger: Logger
  private createObjectURL: (blob: Blob) => string
  private objectUrl: string

  // TODO UNIVERSAL
  constructor(loggerService: LoggerService, private windowRef: WindowRef, private sanitizer: DomSanitizer) {
    this.logger = loggerService.getInstance('LoginAvatar')
    this.createObjectURL = windowRef.nativeWindow.URL.createObjectURL
  }

  ngOnInit() {
    if (this.imageBlob) {
      if (this.imageUrl) {
        this.logger.error('make sure to only specify either imageBlob or imageUrl found both')
      } else {
        // create object url to display image
        this.objectUrl = this.createObjectURL(this.imageBlob)
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(this.objectUrl)
      }
    }

    this.setupProfilePictureControl()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.normalizeBoolean(changes, 'editable', 'anonymous', 'hideDisplayName', 'organization')
  }

  ngOnDestroy() {
    if (this.objectUrl) {
      this.windowRef.nativeWindow.URL.revokeObjectURL(this.objectUrl)
    }
  }

  onFileDrop(files: FileList): void {
    this.logger.debug('onFileDrop', files.length)
    this.profilePictureControl.setValue(files)
  }

  private normalizeBoolean(changes: SimpleChanges, ...fields: string[]): void {
    fields.forEach((field: string) => {
      if (changes[field] && changes[field].currentValue === '') {
        this[field] = true
      }
    })
  }

  private setupProfilePictureControl(): void {
    this.profilePictureControl = new FormControl(
      null,
      Validators.compose([
        createAmountValidator(LoginAvatarComponent.MAX_AMOUNT),
        createFileTypeValidator(LoginAvatarComponent.ACCEPTED_FILE_TYPES),
        createFileSizeValidator(LoginAvatarComponent.MAX_FILE_SIZE),
      ])
    )

    this.profilePictureControl.valueChanges.subscribe((files: FileList) => {
      if (this.profilePictureControl.valid) {
        this.edit.emit(files.item(0))
      } else {
        this.logger.warn('image is invalid')
      }
    })

    this.profilePictureControl.statusChanges.subscribe((status: any) => {
      if (status === 'INVALID') {
        // get the first error - we only display one at a time
        const errorKey = Object.keys(this.profilePictureControl.errors)[0]

        const error: any = this.profilePictureControl.getError(errorKey)
        switch (errorKey) {
          case ERR_KEY_MAX_FILE_SIZE:
            this.error = 'LOGIN_AVATAR.ERR_MAX_FILE_SIZE'
            this.details = { maxSize: (<MaxFileSizeError>error).maxSize / 1024 / 1024 }
            break
          case ERR_KEY_FILE_TYPE:
            this.error = 'LOGIN_AVATAR.ERR_FILE_TYPE'
            const accepted: string = (<FileTypeError>error).accepted
              .map((acceptedType: string) => {
                if (acceptedType.indexOf('/')) {
                  return acceptedType.split('/')[1]
                }

                return acceptedType
              })
              .join(', ')

            this.details = { accepted }
            break
          case ERR_KEY_WRONG_AMOUNT:
            this.error = 'LOGIN_AVATAR.ERR_WRONG_AMOUNT'
            this.details = null
            break
          default:
          // do nothing
        }
      } else if (status === 'VALID') {
        // reset error
        this.error = null
        this.details = null
      }
    })
  }
}
