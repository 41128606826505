import { NgModule, Optional, SkipSelf } from '@angular/core'
import { ContactService } from './contact.service'
import { PublicContactService } from './public-contact.service'

@NgModule({
  imports: [],
  providers: [PublicContactService, ContactService],
})
export class ContactServiceModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ContactServiceModule
  ) {
    if (parentModule) {
      throw new Error('ContactServiceModule is already loaded. Import it only once')
    }
  }
}
