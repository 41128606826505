export class Enums {
  static getNames(e: any): string[] {
    const a: string[] = []
    for (const val in e) {
      if (isNaN(parseInt(val, 10))) {
        a.push(val)
      }
    }
    return a
  }

  static getValues(e: any): number[] {
    const a: number[] = []
    for (const val in e) {
      if (!isNaN(parseInt(val, 10))) {
        a.push(parseInt(val, 10))
      }
    }
    return a
  }

  static fromString(enumObject: any, name: string): number {
    return enumObject[name.toUpperCase()]
  }

  static fromNumber(enumObject: any, id: number): string {
    return enumObject[id]
  }

  static isEnum(enumObject: any, name: string): boolean {
    const names: string[] = Enums.getNames(enumObject)

    if (names && Array.isArray(names) && names.length) {
      return names.includes(name)
    } else {
      return false
    }
  }
}
