import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Logger, LoggerService } from '@maprix/core'
import { StepState } from '../../models/wizard/step-state.model'

@Component({
  selector: 'scs-step-header',
  templateUrl: './step-header.component.html',
})
export class StepHeaderComponent implements OnInit {
  @Input() step: StepState
  @Input() isActive: boolean
  @Input() proceedVisible: boolean | undefined
  @Output() proceed: EventEmitter<void> = new EventEmitter<void>()
  @Output() jumpToStep: EventEmitter<number> = new EventEmitter<number>()

  private logger: Logger

  constructor(loggerService: LoggerService) {
    this.logger = loggerService.getInstance('StepHeaderComponent')
  }

  ngOnInit() {
    this.proceedVisible = this.proceedVisible === undefined ? true : this.proceedVisible
  }

  onProceed(event: Event) {
    // accordion has its own click handler - we stop the propagation of the event.
    // because we handle everything inside wizard.
    event.stopPropagation()
    this.proceed.emit()
  }

  onJumpToStep(event: Event): void {
    // accordion has its own click handler - we stop the propagation of the event.
    // because we handle everything inside wizard.
    event.stopPropagation()
    this.jumpToStep.emit(this.step.id)
  }
}
