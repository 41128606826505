import { Injectable } from '@angular/core'
import { Logger, LoggerService } from '@maprix/core'
import { Observable, Subject } from 'rxjs'

export interface StyleAttr {
  action: 'add' | 'delete'
  name: string
}

export interface AddStyleAttr extends StyleAttr {
  value: string
}

@Injectable()
export class StyleService {
  private logger: Logger
  private styleSubject: Subject<StyleAttr> = new Subject<StyleAttr>()
  styleChanges: Observable<StyleAttr>

  constructor(loggerService: LoggerService) {
    this.logger = loggerService.getInstance('StyleService')
    this.styleChanges = this.styleSubject.asObservable()
  }

  setStyle(name: string, value: string) {
    this.styleSubject.next(<AddStyleAttr>{ action: 'add', name, value })
  }

  deleteStyle(name: string): void {
    this.styleSubject.next({ action: 'delete', name })
  }
}
