import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { Params, Router } from '@angular/router'
import { NotificationCenterService } from '@maprix/components'
import {
  CountryService,
  LocaleService,
  Logger,
  LoggerService,
  TRANSLATE_CONFIG,
  TranslateConfig,
} from '@maprix/core'
import { Observable } from 'rxjs'
import { Enums } from '../../shared/helpers/enums'
import { HttpError } from '../../shared/models/http/http-error'
import { HttpErrorCodes } from '../../shared/models/http/http-error-codes'
import { SimpleImage } from '../../shared/models/shared/simple-image.model'
import { AuthUser } from '../../shared/models/user/auth-user'
import { ProfileUser } from '../../shared/models/user/profile-user.model'
import { SignedEmail } from '../../shared/models/user/signed-email'
import { AuthService } from '../../shared/services/auth/auth.service'
import { DataStore } from '../../shared/services/data-store/data-store.service'
import { AppHttpResponse } from '../../shared/services/http/app-http-response.model'
import { PublicOrganizationService } from '../../shared/services/organization/public-organization.service'
import { ProfileUserService } from '../../shared/services/profile-user/profile-user.service'
import { ActionType } from './models/action-type.enum'
import { InitPasswordResetDTO } from './models/init-password-reset.dto'
import { LoginAndInitTwoFactorAuthDTO } from './models/login-and-init-two-factor-auth.dto'
import { LoginData } from './models/login-data'
import { LoginError } from './models/login-error'
import { LoginInfoDTO } from './models/login-info.dto'
import { LoginInitViewState } from './models/login-init-view-state'
import { LoginNextState } from './models/login-next-view-state'
import { LoginQueryParams } from './models/login-query-params.model'
import { LoginStateData } from './models/login-state-data'
import { LoginType } from './models/login-type.enum'
import { LoginStateView } from './models/login-view-state.enum'
import { LoginDTO } from './models/login.dto'
import { PasswordResetDTO } from './models/password-reset.dto'
import { RegistrationInfoDTO } from './models/registration-info.dto'
import { RequestTwoFactorAuthTokenDTO } from './models/request-two-factor-auth-token.dto'

@Injectable()
export class LoginService {
  private logger: Logger

  constructor(
    @Inject(DOCUMENT) private document: Document,
    loggerService: LoggerService,
    private dataStore: DataStore,
    private authService: AuthService,
    private countryService: CountryService,
    private profileUserService: ProfileUserService,
    private notificationCenter: NotificationCenterService,
    private router: Router,
    private localeService: LocaleService,
    private publicOrganizationService: PublicOrganizationService,
    @Inject(TRANSLATE_CONFIG) private translateConfig: TranslateConfig
  ) {
    this.logger = loggerService.getInstance('LoginService')
  }

  /**
   * if we got an email property on the routeParams we will fetch the login info for this address and proceed
   * depending on login info.
   *
   * otherwise we check if there is a preferred login in local storage. If we find one we proceed depending on
   * login info.
   *
   * if there is no user found in login info we start with registration flow and loginType password.
   *
   * @param jumpTo The login flow can be initialized with a jumpTo step (usually through a specific browser url)
   * @param queryParams Available query params on the login route
   */
  init(initOptions: { jumpTo?: LoginInitViewState; queryParams?: LoginQueryParams }): Promise<LoginNextState> {
    return new Promise((resolve, reject) => {
      this.logger.debug('init with initOptions %o', initOptions)

      if (initOptions.jumpTo) {
        this.prepareJumpToState(initOptions.jumpTo, resolve, reject)
      } else {
        const stateData: LoginStateData = {
          loginData: {
            loginType: LoginType.PASSWORD,
          },
        }

        let predefinedEmail: string | undefined

        // handle query params
        if (initOptions.queryParams) {
          if (initOptions.queryParams.email) {
            this.logger.debug('found query param «email»')
            predefinedEmail = initOptions.queryParams.email
          } else if (initOptions.queryParams.requestedUserEmail) {
            this.logger.debug('found query param «requestedUserEmail»')
            predefinedEmail = initOptions.queryParams.requestedUserEmail
          }
        } else {
          predefinedEmail = this.dataStore.getLastLogin()
        }

        // do the initialization
        if (predefinedEmail) {
          // start login or register flow with predefined email
          this.checkLoginInfo(predefinedEmail)
            .then((loginInfo: LoginInfoDTO) => {
              let initLoginStateView: LoginStateView

              stateData.loginInfo = loginInfo
              stateData.loginData.loginType = LoginType.PASSWORD
              stateData.loginData.email = predefinedEmail

              if (loginInfo.samlLoginUrl) {
                initLoginStateView = LoginStateView.LOGIN_SAML_SSO
                stateData.loginData._samlLoginUrl = stateData.loginInfo.samlLoginUrl

                this.logger.debug('ready to login with saml on endpoint %s', stateData.loginData._samlLoginUrl)

                if (loginInfo.existingAccount) {
                  this.fetchProfilePicture(loginInfo, stateData, initLoginStateView, resolve, reject)
                } else {
                  resolve({ state: initLoginStateView, data: stateData })
                }
              } else if (loginInfo.existingAccount) {
                initLoginStateView = LoginStateView.LOGIN_ENT

                // call for profile picture
                this.fetchProfilePicture(loginInfo, stateData, initLoginStateView, resolve, reject)
              } else {
                // make sure no non existing email is stored
                this.dataStore.clearLastLogin()

                // start register flow with type PASSWORD
                // set signed email if there is email param present
                if (initOptions.queryParams && initOptions.queryParams.email) {
                  stateData.loginData._signedEmail = {
                    email: initOptions.queryParams.email,
                    expiration: initOptions.queryParams.expiration,
                    signature: initOptions.queryParams.signature,
                  }
                }
                stateData.loginData.loginType = LoginType.PASSWORD
                initLoginStateView = LoginStateView.REGISTER_ENT

                // call for ent profile picture
                this.fetchProfilePicture(loginInfo, stateData, initLoginStateView, resolve, reject)
              }
            })
            .catch((error: HttpError) => {
              this.logger.error('there was a problem loading the login info', error.message)
              reject(error)
            })
        } else {
          resolve({ state: LoginStateView.START, data: stateData })
        }
      }
    })
  }

  next(currentState: LoginStateView, data: LoginStateData, actionType?: ActionType): Promise<LoginNextState> {
    return new Promise((resolve, reject) => {
      this.logger.debug('currentState %o / data %o / actionType %o', currentState, data, actionType)

      switch (currentState) {
        case LoginStateView.START:
          if (actionType !== undefined) {
            this.start(resolve, reject, data, actionType)
          } else {
            throw Error('actionType must be defined')
          }
          break
        case LoginStateView.LOGIN_ENT:
          if (actionType === ActionType.FORGOT_PASSWORD) {
            resolve({ state: LoginStateView.FORGOT_PASSWORD, data: null })
          } else {
            this.loginEnt(resolve, reject, data)
          }
          break
        case LoginStateView.TRIAL:
          this.router.navigate(['/trial'])
          break
        case LoginStateView.FORGOT_PASSWORD:
          this.forgotPassword(resolve, reject, data)
          break
        case LoginStateView.RESET_PASSWORD_FROM_LINK:
          this.resetPassword(resolve, reject, data)
          break
        case LoginStateView.TWO_FA_SETUP:
          this.registerTwoFa(resolve, reject, data)
          break
        case LoginStateView.TWO_FA_VERIFY:
          this.verifyTwoFa(resolve, reject, data)
          break
        case LoginStateView.REGISTER_ENT:
          if (actionType === ActionType.PROFILE_PICTURE) {
            data.loginData.previousLoginStateView = LoginStateView.REGISTER_ENT
            resolve({ state: LoginStateView.IMAGE_CROPPER, data: null })
          } else {
            this.registerEnt(resolve, reject, data)
          }
          break
        case LoginStateView.IMAGE_CROPPER:
          resolve({ state: data.loginData.previousLoginStateView, data })
          data.loginData.previousLoginStateView = null
          break
        case LoginStateView.LOGIN_SAML_SSO:
          this.loginSamlSso(data)
          break
        default:
          this.logger.error('unknown view state', currentState)
      }
    })
  }

  private prepareJumpToState(initState: LoginInitViewState, resolve, reject): void {
    const signedEmail: SignedEmail = initState.data.signedEmail
    // let requestedUser: RequestedUser = initState.data.requestedUser;

    switch (initState.state) {
      case LoginStateView.REGISTER_FROM_LINK:
        // redirect to login if email param contains existing user
        this.checkLoginInfo(signedEmail.email).then((loginInfo: LoginInfoDTO) => {
          if (loginInfo.samlLoginUrl) {
            this.logger.info('no register from link for saml sso login')
            this.router.navigate(['/login'], { queryParamsHandling: 'preserve' })
          } else if (loginInfo.existingAccount) {
            this.logger.info('redirect to login, the requested user already has an account')
            this.router.navigate(['/login'], { queryParamsHandling: 'preserve' })
          } else {
            const data: LoginStateData = {
              loginData: {
                loginType: LoginType.PASSWORD,
                email: signedEmail.email,
                _signedEmail: signedEmail,
              },
            }

            this.start(resolve, reject, data, ActionType.PASSWORD)
          }
        })
        break
      case LoginStateView.RESET_PASSWORD_FROM_LINK:
        // call for login info and resolve with
        this.checkLoginInfo(signedEmail.email)
          .then((loginInfo: LoginInfoDTO) => {
            if (loginInfo.samlLoginUrl) {
              this.logger.info('no pw reset for saml sso login')
              this.router.navigateByUrl('/login')
            } else if (loginInfo.existingAccount) {
              const data: LoginStateData = {
                loginData: {
                  email: signedEmail.email,
                  _signedEmail: signedEmail,
                },
                loginInfo,
              }

              resolve({ state: LoginStateView.RESET_PASSWORD_FROM_LINK, data })
            } else {
              // redirect to login route
              this.notificationCenter.warn('LOGIN.RESET_PASSWORD.MSG_NO_USER', { email: signedEmail.email })
              this.logger.info('no existing user found for email parameter -> redirect to login')
              this.router.navigateByUrl('/login')
            }
          })
          .catch(reject)
        break
      default:
        this.logger.error(
          'prepare init state is not implemented for state ',
          Enums.fromNumber(LoginStateView, initState.state)
        )
    }
  }

  /* ------------------------------------------------------------------------------------------------
   *
   * methods to handle the state transitions
   *
   ------------------------------------------------------------------------------------------------ */

  /* ------------------------------------------------------------------------------------------------
   *
   *
   * models.LoginStateView.START
   *
   *
   ------------------------------------------------------------------------------------------------ */
  private start(resolve, reject, data: LoginStateData, actionType: ActionType): void {
    this.actionTypeToLoginType(actionType, data)
    this.prepareLoginWithPassword(resolve, reject, data)
  }

  /**
   * Fetch the login info to display the correct view login vs. register vs. trial
   *
   * @param resolve
   * @param reject
   * @param data
   */
  private prepareLoginWithPassword(resolve, reject, data: LoginStateData): void {
    if (data.loginData && data.loginData.email) {
      this.checkLoginInfo(data.loginData.email).then(
        (loginInfoDTO: LoginInfoDTO) => {
          let nextStateView: LoginStateView

          data.loginInfo = loginInfoDTO

          if (loginInfoDTO.samlLoginUrl) {
            data.loginData._samlLoginUrl = loginInfoDTO.samlLoginUrl
            nextStateView = LoginStateView.LOGIN_SAML_SSO
            if (loginInfoDTO.existingAccount) {
              this.fetchProfilePicture(loginInfoDTO, data, nextStateView, resolve, reject)
            } else {
              resolve({ state: nextStateView, data })
            }
          } else if (loginInfoDTO.existingAccount) {
            // existing user
            nextStateView = LoginStateView.LOGIN_ENT

            // call for profile image
            this.fetchProfilePicture(loginInfoDTO, data, nextStateView, resolve, reject)
          } else {
            // new user
            nextStateView = LoginStateView.REGISTER_ENT
            // call for ent profile picture
            this.fetchProfilePicture(loginInfoDTO, data, nextStateView, resolve, reject)
          }
        },
        (error: AppHttpResponse<HttpError>) => {
          const httpError = error.body ? error.body : error
          // check for non existing org domains to show trial step
          if ((<HttpError>httpError).code === HttpErrorCodes.NO_MATCHING_ORGANIZATION_FOR_DOMAIN) {
            resolve({ state: LoginStateView.TRIAL, data })
          } else {
            reject({ httpError })
          }
        }
      )
    } else {
      this.logger.error('login data and email on login data object must be defined')
    }
  }

  private fetchProfilePicture(
    loginInfoDTO: LoginInfoDTO,
    data: LoginStateData,
    nextStateView: LoginStateView,
    resolve,
    reject
  ) {
    const profilePictureObs: Observable<
      AppHttpResponse<SimpleImage>
    > | null = this.publicOrganizationService.getProfilePicture(loginInfoDTO.organization.id)
    if (profilePictureObs) {
      profilePictureObs.subscribe(
        // SUCCESS
        (response: AppHttpResponse<SimpleImage>) => {
          if (response.status !== 204) {
            data.profilePicture = response.body
          }
          resolve({ state: nextStateView, data })
        },
        // ERROR
        (error: HttpError) => {
          reject({ httpError: error })
        }
      )
    } else {
      // type ENTERPRISE but no organization with id -> should never happen
      resolve({ state: nextStateView, data })
    }
  }

  private loginSuccess(resolve, data: LoginStateData): void {
    resolve({ state: LoginStateView.END })
  }

  private registerTwoFa(resolve, reject, data: LoginStateData): void {
    if (data.loginData.phoneNumber !== undefined) {
      let requestTwoFaTokenDTO: RequestTwoFactorAuthTokenDTO

      requestTwoFaTokenDTO = {
        locale: this.localeService.currentLocale,
        phoneNumber: data.loginData.phoneNumber,
      }

      this.authService.requestTwoFactorAuthTokenInit(requestTwoFaTokenDTO).subscribe(
        () => {
          this.logger.debug('phone number was successfully initialized for 2fa')
          data.loginData.twoFaInitializing = true

          resolve({ state: LoginStateView.TWO_FA_VERIFY, data })
        },
        (err: HttpError) => {
          this.logger.warn('there was a problem registering the phone number for 2fa')
          reject(err)
        }
      )
    } else {
      this.logger.error('phone number must be provided on login data')
    }
  }

  private verifyTwoFa(resolve, reject, data: LoginStateData): void {
    // login again with token
    if (data.loginInfo) {
      this.loginEnt(resolve, reject, data)
    } else {
      this.logger.error('loginInfo must not be null')
    }
  }

  /* ------------------------------------------------------------------------------------------------
   *
   * LoginStateView.LOGIN_ENT
   *
   ------------------------------------------------------------------------------------------------ */

  private loginEnt(resolve, reject, data: LoginStateData): void {
    this.login(data.loginData)
      .then((response: AppHttpResponse<AuthUser>) => {
        if (response.status === 201) {
          resolve({ state: LoginStateView.TWO_FA_VERIFY, data })
        } else {
          this.loginSuccess(resolve, data)
        }
      })
      // login rejects with LoginError
      .catch((error: LoginError) => {
        if (error.httpError !== undefined) {
          switch (error.httpError.code) {
            case HttpErrorCodes.UNAUTHORIZED_MANDATORY_TWO_FACTOR_AUTH:
              this.logger.debug('user was forced to setup 2FA')
              data.loginData.twoFaInitializing = true
              resolve({ state: LoginStateView.TWO_FA_SETUP, data })
              break
            case HttpErrorCodes.UNAUTHORIZED_INVALID_TWO_FACTOR_TOKEN:
              reject(error)
              break
            default:
              reject(error)
          }
        } else {
          this.logger.error('httpError property must not be null on login error')
        }
      })
  }

  /* ------------------------------------------------------------------------------------------------
   *
   * LoginStateView.FORGOT_PASSWORD
   *
   ------------------------------------------------------------------------------------------------ */

  private forgotPassword(resolve, reject, data: LoginStateData): void {
    if (data.loginData.email) {
      const initPasswordResetDTO: InitPasswordResetDTO = {
        email: data.loginData.email,
      }

      this.authService.initPasswordReset(initPasswordResetDTO).subscribe(
        (response: AppHttpResponse<void>) => {
          resolve({ state: LoginStateView.NO_CHANGE })
        },
        (error: HttpError) => {
          this.logger.error('could not send reset password link', error)
          reject(error)
        }
      )
    } else {
      this.logger.error('loginData.email must be defined')
    }
  }

  /* ------------------------------------------------------------------------------------------------
   *
   * LoginStateView.RESET_PASSWORD
   *
   ------------------------------------------------------------------------------------------------ */

  private resetPassword(resolve, reject, data: LoginStateData): void {
    if (data.loginData._signedEmail !== undefined) {
      const passwordResetDTO: PasswordResetDTO = {
        newPassword: data.loginData._newPassword,
        signedEmail: data.loginData._signedEmail,
      }

      this.authService.passwordReset(passwordResetDTO).subscribe(
        (response: AppHttpResponse<void>) => {
          data.loginData.tokenOrPassword = data.loginData._newPassword
          data.loginData.loginType = LoginType.PASSWORD

          if (data.loginInfo != null) {
            this.loginEnt(resolve, reject, data)
          } else {
            this.logger.error('data.loginData._signedEmail must be defined')
          }
        },
        (error: HttpError) => {
          this.logger.error('could not reset password', error)
          reject(error)
        }
      )
    } else {
      this.logger.error('loginInfo must be defined')
    }
  }

  /* ------------------------------------------------------------------------------------------------
   *
   * LoginStateView.REGISTER_ENT
   *
   ------------------------------------------------------------------------------------------------ */

  private registerEnt(resolve, reject, data: LoginStateData): void {
    this.register(data)
      .then((authUser: AuthUser) => {
        data.authUser = authUser

        this.notificationCenter.success('LOGIN.COMMONS.MSG_REGISTER_SUCCESS')
        resolve({ state: LoginStateView.END })
      })
      .catch((response: HttpError) => {
        reject(response)
      })
  }

  /* ------------------------------------------------------------------------------------------------
   *
   * LoginStateView.LOGIN_SAML_SSO
   *
   ------------------------------------------------------------------------------------------------ */

  /*
   * Due to browser redirection during the SSO login, we will loose the information where the user was before starting the SAML flow. So we store the path as
   * RelayState value, which is used to transport context information during the SAML login.
   * The redirect path is one of:
   * - redirect query param if existing (first prio)
   * - current path (second prio)
   */
  private loginSamlSso(data: LoginStateData): void {
    const queryParams: Params = this.router.routerState.snapshot.root.queryParams

    let redirectPath: string
    if (queryParams && queryParams['redirect']) {
      redirectPath = queryParams['redirect']
    } else {
      // full path including query params
      redirectPath = this.router.url
    }

    // make sure the path starts with a / because the backend validates against it to build the redirect path
    redirectPath = redirectPath.startsWith('/') ? redirectPath : `/${redirectPath}`
    this.logger.debug('redirecting to saml url %s', `${data.loginData._samlLoginUrl}&RelayState=${redirectPath}`)
    this.document.location.href = `${data.loginData._samlLoginUrl}&RelayState=${redirectPath}`
  }

  /* ------------------------------------------------------------------------------------------------
   *
   * methods used to process some data for state transitions
   *
   ------------------------------------------------------------------------------------------------ */

  /**
   * Checks if the given email points to an existing account or not.
   * @param email
   * @return Returns a promise which resolves with the loginInfoDTO
   */
  private checkLoginInfo(email: string): Promise<LoginInfoDTO> {
    return this.authService
      .getLoginInfo(email)
      .toPromise()
      .then((response: AppHttpResponse<LoginInfoDTO>) => {
        return response.body
      })
  }

  /**
   * @param loginData
   * @returns {IPromise<TResult>|IPromise<T>} The promise will be resolved with auth user if the login succeeds for
   * given login type. If not the promise will be rejected.
   */
  private login(loginData: LoginData): Promise<AppHttpResponse<AuthUser>> {
    return new Promise((resolve, reject) => {
      switch (loginData.loginType) {
        case LoginType.PASSWORD:
          let loginDTO: LoginDTO | LoginAndInitTwoFactorAuthDTO

          if (loginData.email && loginData.tokenOrPassword) {
            loginDTO = {
              email: loginData.email,
              password: loginData.tokenOrPassword,
            }

            if (loginData.twoFaToken) {
              loginDTO.token = loginData.twoFaToken
            }

            if (loginData.phoneNumber) {
              if (loginData.twoFaInitializing) {
                ;(<LoginAndInitTwoFactorAuthDTO>loginDTO).phoneNumber = loginData.phoneNumber
              }
            } else {
              this.logger.warn('phone number should be defined on login data')
            }

            this.authService.login(loginDTO, loginData.twoFaInitializing).subscribe(
              // SUCCESS
              (response: AppHttpResponse<AuthUser>) => {
                resolve(response)
              }, // ERROR
              (error: HttpError) => {
                reject({ httpError: error })
              }
            )
          } else {
            this.logger.error(
              'one of the following properties was not defined on login data (email, tokenOrPassword, twoFaToken)'
            )
          }
          break
        default:
          this.logger.error('there is no loginType defined, need one to work properly')
      }
    })
  }

  private register(data: LoginStateData): Promise<AuthUser> {
    return new Promise((resolve, reject) => {
      let registrationDTO: RegistrationInfoDTO

      const loginData: LoginData = data.loginData

      let signedEmail: SignedEmail
      if (data.loginData._signedEmail) {
        signedEmail = data.loginData._signedEmail
      } else if (data.loginData.email) {
        signedEmail = { email: data.loginData.email }
      } else {
        throw new Error('signed email is not defined')
      }

      if (loginData.givenName && loginData.familyName && loginData.tokenOrPassword) {
        registrationDTO = new RegistrationInfoDTO(
          loginData.givenName,
          loginData.familyName,
          LoginType.PASSWORD,
          loginData.tokenOrPassword,
          this.localeService.currentLocale,
          true,
          signedEmail,
          this.countryService.getGuessedCurrentTimeZone()
        )
      } else {
        throw new Error('need the following properties to be defined: givenName, familyName, tokenOrPassword')
      }

      this.authService
        .register(registrationDTO)
        .toPromise()
        .then((authUser: AuthUser) => {
          if (data.loginData._selectedImageBlob) {
            /*
             * store the selected profile picture
             */
            this.profileUserService
              .setProfilePicture(data.loginData._selectedImageBlob)
              .subscribe((user: ProfileUser) => {
                resolve(authUser)
              }, reject)
          } else {
            resolve(authUser)
          }
        })
        .catch(reject)
    })
  }

  /**
   * Sets the logintype depending on given action type
   * @param actionType {ActionType}
   * @param data {LoginStateData}
   */
  private actionTypeToLoginType(actionType: ActionType, data: LoginStateData): void {
    switch (actionType) {
      case ActionType.PASSWORD:
        data.loginData.loginType = LoginType.PASSWORD
        break
      default:
        this.logger.error('no implementation for action type ' + Enums.fromNumber(ActionType, actionType))
    }
  }
}
