import { Injectable } from '@angular/core'
import { LocalStorageOptionArgs, LocalStorageOptions } from '@maprix/core'

/**
 * configuration for localStorage service
 */

@Injectable()
export class AppLocalStorageOptions extends LocalStorageOptions {
  static PREFIX = 'scs-flux'

  constructor() {
    super(<LocalStorageOptionArgs>{ prefix: AppLocalStorageOptions.PREFIX })
  }
}
