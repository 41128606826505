import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { LoopingCoreModule } from '../../../looping-core.module'
import { LanguageSwitcherComponent } from './language-switcher.component'

@NgModule({
  imports: [CommonModule, LoopingCoreModule],
  declarations: [LanguageSwitcherComponent],
  exports: [LanguageSwitcherComponent],
})
export class LanguageSwitcherModule {}
