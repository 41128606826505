export class HttpErrorCodes {
  static KEY_PREFIX = 'HTTP_ERROR.'

  /* ------------------------------------------------------------------
   *
   *      COMMONS
   *
   ------------------------------------------------------------------ */

  /* ------------------------------------------------------------------
   *      400
   ------------------------------------------------------------------ */
  static DEFAULT_CLIENT_EXCEPTION = '4XX:000:DEFAULT_CLIENT_EXCEPTION'
  static GENERAL_BAD_REQUEST = '400:001:GENERAL_BAD_REQUEST'
  static BEAN_VALIDATION_FAILED = '400:002:BEAN_VALIDATION_FAILED'

  static NO_SUCH_USER = '400:003:NO_SUCH_USER'
  static NO_SUCH_ORGANIZATION = '400:004:NO_SUCH_ORGANIZATION'
  static USER_ALREADY_EXISTS = '400:005:USER_ALREADY_EXISTS'
  static ORGANIZATION_ALREADY_EXISTS = '400:006:ORGANIZATION_ALREADY_EXISTS'
  static EMAIL_ORG_DOMAIN_MISMATCH = '400:007:EMAIL_ORG_DOMAIN_MISMATCH'
  static INVALID_TWO_FACTOR_AUTH_TOKEN = '400:112:INVALID_TWO_FACTOR_AUTH'
  static ACTION_NOT_ALLOWED_SSO_USER = '400:009:ACTION_NOT_ALLOWED_SSO_USER'
  static NO_MATCHING_ORGANIZATION_FOR_DOMAIN = '400:010:NO_MATCHING_ORGANIZATION_FOR_DOMAIN'

  /* ------------------------------------------------------------------
   *      401
   ------------------------------------------------------------------ */
  static UNAUTHORIZED = '401:001:UNAUTHORIZED'
  static UNAUTHORIZED_INVALID_CREDENTIALS = '401:101:UNAUTHORIZED_INVALID_PASSWORD'
  static UNAUTHORIZED_ACCOUNT_LOCKED = '401:104:UNAUTHORIZED_ACCOUNT_LOCKED'
  static UNAUTHORIZED_MANDATORY_TWO_FACTOR_AUTH = '401:103:UNAUTHORIZED_MANDATORY_TWO_FACTOR_AUTH'
  static UNAUTHORIZED_INVALID_TWO_FACTOR_TOKEN = '401:102:UNAUTHORIZED_INVALID_TWO_FACTOR_TOKEN'

  /* ------------------------------------------------------------------
   *      403
   ------------------------------------------------------------------ */
  static ACCESS_DENIED = '403:001:ACCESS_DENIED'
  static ACCESS_DENIED_EMAIL_NOT_VERIFIED = '403:002:ACCESS_DENIED_EMAIL_NOT_VERIFIED'

  /* ------------------------------------------------------------------
   *      404
   ------------------------------------------------------------------ */
  static USER_NOT_FOUND = '404:001:USER_NOT_FOUND'

  /* ------------------------------------------------------------------
   *      500
   ------------------------------------------------------------------ */
  static GENERAL_APPLICATION_ERROR = '500:001:DEFAULT_APPLICATION_ERROR'
  static BEAN_VALIDATION_ERROR = '500:002:BEAN_VALIDATION_ERROR'

  /* ------------------------------------------------------------------
   *
   *      AUTH
   *
   ------------------------------------------------------------------ */

  /* ------------------------------------------------------------------
   *      20x
   ------------------------------------------------------------------ */
  static TWO_FACTOR_AUTH_REQUIRED = '201:101:TWO_FACTOR_AUTH_REQUIRED'

  /* ------------------------------------------------------------------
   *      400
   ------------------------------------------------------------------ */
  static PASSWORD_INVALID = '400:101:PASSWORD_INVALID'
  static EMAIL_ALREADY_IN_USE = '400:102:EMAIL_ALREADY_IN_USE'

  static USER_ALREADY_IN_ORG = '400:104:USER_ALREADY_IN_ORG'
  static USER_NOT_IN_ORGANIZATION = '400:105:USER_NOT_IN_ORGANIZATION'
  static LAST_ORG_ADMIN_REMAINING = '400:106:LAST_ORG_ADMIN_REMAINING'
  static ORG_SHORTCUT_TAKEN = '400:107:ORG_SHORTCUT_TAKEN'
  static LAST_DOMAIN_REMAINING = '400:108:LAST_DOMAIN_REMAINING'
  static PASSWORD_POLICY_VIOLATION = '400:109:PASSWORD_POLICY_VIOLATION'
  static LAST_ORG_ADMIN_NOTIFICATION_RECEIVER_REMAINING = '400:110:LAST_ORG_ADMIN_NOTIFICATION_RECEIVER_REMAINING'
  static LAST_ORG_ADMIN_NOTIFICATION_RECEIVER_REMAINING_DELETE = '400:111:LAST_ORG_ADMIN_NOTIFICATION_RECEIVER_REMAINING_DELETE'

  static SOCIAL_LOGIN_ALREADY_LINKED = '400:130:SOCIAL_LOGIN_ALREADY_LINKED'
  static SOCIAL_LOGIN_ALREADY_IN_USE = '400:131:SOCIAL_LOGIN_ALREADY_IN_USE'
  static SOCIAL_LOGIN_ENTERPRISE_DOMAIN = '400:132:SOCIAL_LOGIN_ENTERPRISE_DOMAIN'
  static SOCIAL_LOGIN_ENTERPRISE_USER = '400:133:SOCIAL_LOGIN_ENTERPRISE_USER'
  static NO_SOCIAL_LOGIN = '400:134:NO_SOCIAL_LOGIN'
  static DIFFERENT_SOCIAL_LOGIN_FACEBOOK = '400:135:DIFFERENT_SOCIAL_LOGIN_FACEBOOK'
  static DIFFERENT_SOCIAL_LOGIN_GOOGLE = '400:136:DIFFERENT_SOCIAL_LOGIN_GOOGLE'
  static ENTERPRISE_DOMAIN = '400:137:ENTERPRISE_DOMAIN'
  static NO_PASSWORD = '400:138:NO_PASSWORD'
  static PASSWORD_ALREADY_INITIALIZED = '400:139:PASSWORD_ALREADY_INITIALIZED'
  static DOMAIN_BLACK_LISTED = '400:140:DOMAIN_BLACK_LISTED'

  /* ------------------------------------------------------------------
   *
   *      MAIN
   *
   ------------------------------------------------------------------ */

  /* ------------------------------------------------------------------
   *      400
   ------------------------------------------------------------------ */
  static IMAGE_SIZE_EXCEEDED = '400:201:IMAGE_SIZE_EXCEEDED'
  static IMAGE_WRONG_TYPE = '400:202:IMAGE_WRONG_TYPE'
  static URL_INVALID = '400:203:URL_INVALID'

  static DISPLAY_NAME_TAKEN = '400:204:DISPLAY_NAME_TAKEN'

  static INVALID_RESOURCE_SIGNATURE = '400:205:INVALID_RESOURCE_SIGNATURE'

  static NO_SUCH_EXPERTISE = '400:207:NO_SUCH_EXPERTISE'
  static NO_SUCH_FEEDBACK = '400:208:NO_SUCH_FEEDBACK'

  static CANNOT_UPVOTE_OWN_FEEDBACK = '400:209:CANNOT_UPVOTE_OWN_FEEDBACK'
  static FEEDBACK_ALREADY_UPVOTED = '400:210:FEEDBACK_ALREADY_UPVOTED'
  static FEEDBACK_ALREADY_GIVEN = '400:211:FEEDBACK_ALREADY_GIVEN'
  static NO_FEEDBACK_PROVIDED = '400:212:NO_FEEDBACK_PROVIDED'
  static PROTOTYPE_EXPIRED = '400:213:PROTOTYPE_EXPIRED'
  static NO_USERS_MATCH_CRITERIA = '400:214:NO_USERS_MATCH_CRITERIA'

  static NO_SUCH_VARIANT = '400:215:NO_SUCH_VARIANT'

  static PRODUCT_ALREADY_EXISTS = '400:216:PRODUCT_ALREADY_EXISTS'
  static NO_SUCH_PRODUCT = '400:217:NO_SUCH_PRODUCT'
  static ILLEGAL_PRODUCT = '400:218:ILLEGAL_PRODUCT'
  static ILLEGAL_PAYMENT_INFO = '400:219:ILLEGAL_PAYMENT_INFO'
  static TOO_MANY_USER_FOR_PRODUCT = '400:220:TOO_MANY_USER_FOR_PRODUCT'

  static NO_PREFERRED_VARIANT_PROVIDED = '400:221:NO_PREFERRED_VARIANT_PROVIDED'
  static FEEDBACK_NOT_YET_GIVEN = '400:222:FEEDBACK_NOT_YET_GIVEN'

  static INVALID_BILLING_ITEM = '400:223:INVALID_BILLING_ITEM'
  static NO_SUCH_BILLING_ITEM = '400:224:NO_SUCH_BILLING_ITEM'

  static CUSTOMER_NUMBER_ALREADY_TAKEN = '400:225:CUSTOMER_NUMBER_ALREADY_TAKEN'

  static NO_SUCH_GROUP = '400:230:NO_SUCH_GROUP'
  static NO_GROUP_INVITATION = '400:231:NO_GROUP_INVITATION'
  static NO_SUCH_GROUP_USER = '400:232:NO_SUCH_GROUP_USER'
  static GROUP_INVITATION_REMOVED = '400:233:GROUP_INVITATION_REMOVED'

  static ORGANIZATION_USERS_NOT_VISIBLE = '400:244:ORGANIZATION_USERS_NOT_VISIBLE'

  /* ------------------------------------------------------------------
   *      401
   ------------------------------------------------------------------ */
  static INVALID_INVOICE_SIGNATURE = '401:202:INVALID_INVOICE_SIGNATURE'
  static LOGIN_REQUIRED_FOR_PROTOTYPE = '401:201:LOGIN_REQUIRED_FOR_PROTOTYPE'

  /* ------------------------------------------------------------------
   *      403
   ------------------------------------------------------------------ */
  static ACCESS_DENIED_GROUP_PROTO = '403:201:ACCESS_DENIED_GROUP_PROTO'
  static ACCESS_DENIED_CRITERIA_PROTO = '403:202:ACCESS_DENIED_CRITERIA_PROTO'
  static ACCESS_DENIED_COMMUNITY_PROTO = '403:203:ACCESS_DENIED_COMMUNITY_PROTO'
  static ACCESS_DENIED_TO_OWNER = '403:204:ACCESS_DENIED_TO_OWNER'
  static ACCESS_DENIED_NOT_OWNER = '403:205:ACCESS_DENIED_NOT_OWNER'
  static ACCESS_DENIED_USER_NOT_VISIBLE = '403:206:ACCESS_DENIED_USER_NOT_VISIBLE'
  static ACCESS_DENIED_TRIAL_EXPIRED = '403:207:ACCESS_DENIED_TRIAL_EXPIRED'
  static ACCESS_DENIED_GROUP_USER_MISMATCH = '403:208:ACCESS_DENIED_GROUP_USER_MISMATCH'
  static ACCESS_DENIED_EXTERNAL_GROUP_PROTO = '403:209:ACCESS_DENIED_EXTERNAL_GROUP_PROTO'
  static ACCESS_DENIED_ENTERPRISE_PUBLIC_COMMUNITY_PROTO = '403:210:ACCESS_DENIED_ENTERPRISE_PUBLIC_COMMUNITY_PROTO'
  static ACCESS_DENIED_PROTOTYPE_CENSORED = '403:211:ACCESS_DENIED_PROTOTYPE_CENSORED'

  /* ------------------------------------------------------------------
   *      404
   ------------------------------------------------------------------ */
  static PROTOTYPE_NOT_FOUND = '404:201:PROTOTYPE_NOT_FOUND'
  static PRODUCT_NOT_FOUND = '404:202:PRODUCT_NOT_FOUND'
  static INVOICE_NOT_FOUND = '404:203:INVOICE_NOT_FOUND'
  static VIDEO_NOT_FOUND = '404:204:VIDEO_NOT_FOUND'

  // helpers
  static getKeyForCode(code: string): string {
    return HttpErrorCodes.KEY_PREFIX + code
  }
}
