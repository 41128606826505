import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router'
import { NotificationCenterService } from '@maprix/components'
import { Logger, LoggerService } from '@maprix/core'
import { Observable, of } from 'rxjs'
import { first, map, mergeMap } from 'rxjs/operators'
import { pickQueryParamsRequestedUser } from '../../helpers/utils'
import { RequestedUser } from '../../models/shared/requested-user.model'
import { AuthUser } from '../../models/user/auth-user'
import { AuthService } from './auth.service'

/**
 * If the url contains some information about the requested user (query params) we check if the requested user
 * matches the one which is logged in.
 * If not the route can not be activated and the user is logged out but not redirected to login route.
 * Use this guard for routes that do not need a logged in user but need to check for a requested user
 */
@Injectable()
export class CanActivateRequestedUserNoLoginRedirectGuard implements CanActivate {
  private logger: Logger

  constructor(
    loggerService: LoggerService,
    private authService: AuthService,
    private notificationCenter: NotificationCenterService
  ) {
    this.logger = loggerService.getInstance('CanActivateRequestedUserNoLoginRedirectGuard')
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const requestedUser: RequestedUser | null = pickQueryParamsRequestedUser(route.queryParams)

    return this.authService.authUserChanges.pipe(
      first(),
      map((authUser: AuthUser | null): { canActivate: boolean; isLoggedIn: boolean } => {
        const res = { canActivate: false, isLoggedIn: false }

        if (authUser === null) {
          // no user logged in
          // a) no user logged in and no requested one -> OK
          // b) no user logged in but need one -> NOK
          res.canActivate = requestedUser === null
        } else {
          // logged in
          res.isLoggedIn = true
          if (requestedUser !== null) {
            // logged in user and requested one -> check for match
            if (requestedUser.id !== undefined) {
              // can activate if id matches
              res.canActivate = authUser.subId === requestedUser.id
            } else if (requestedUser.email) {
              // can activate if email matches
              res.canActivate = authUser.sub === requestedUser.email
            } else {
              res.canActivate = false
            }
          } else {
            // logged in and no requested user -> OK
            res.canActivate = true
          }
        }
        return res
      }),
      mergeMap(({ canActivate, isLoggedIn }) => {
        if (!canActivate) {
          // logout
          return this.authService.logout().pipe(
            map(() => {
              if (isLoggedIn) {
                // notification only if user was previously logged in
                this.notificationCenter.warn('USER_GUARD.YOU_WERE_LOGGED_OUT')
              }
              return true
            })
          )
        } else {
          return of(true)
        }
      })
    )
  }
}
