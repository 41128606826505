import { Params } from '@angular/router'
import { isEmpty, pick } from 'lodash'
import { SamlSsoFragments } from '../../routes/login/models/saml-sso-fragments'
import { RequestedUser } from '../models/shared/requested-user.model'
import { SignedEmail } from '../models/user/signed-email'
import { SignedIdGuest } from '../models/user/signed-id-guest'
import { SignedIdGuestOwner } from '../models/user/signed-id-guest-owner'

interface ETouch extends Touch {
  offsetX?: number
  offsetY?: number
}

/**
 * Picks the given params from queryParams.
 *
 * @param queryParams The Params object (router.routerState.snapshot.root.queryParams)
 * @param picks A list of strings which define the keys of the query params to be returned
 * @return Returns The picked Params (all strings) or null if no matches were found
 */
export function pickQueryParams(queryParams: Params, ...picks: string[]): Params | null {
  if (picks && picks.length === 0) {
    return queryParams
  } else {
    const picked: { [key: string]: any } = pick(queryParams, picks)

    if (isEmpty(picked)) {
      return null
    } else {
      return picked
    }
  }
}

export function pickQueryParamsSignedEmail(queryParams: Params): SignedEmail {
  return <SignedEmail>pickQueryParams(queryParams, 'email', 'expiration', 'signature')
}

export function pickQueryParamsRequestedUser(queryParams: Params): RequestedUser | null {
  const picked: { [key: string]: any } | null = pickQueryParams(queryParams, 'requestedUserId', 'requestedUserEmail')

  if (picked === null || isEmpty(picked)) {
    return null
  } else {
    return <RequestedUser>{
      id: picked['requestedUserId'],
      email: picked['requestedUserEmail'],
    }
  }
}

export function pickQueryParamsSignedGuest(queryParams: Params): SignedIdGuest {
  return <SignedIdGuest>pickQueryParams(queryParams, 'id', 'guestEmail', 'expiration', 'signature')
}

export function pickQueryParamsSignedGuestOwner(queryParams: Params): SignedIdGuestOwner {
  return <SignedIdGuestOwner>pickQueryParams(
    queryParams,
    'id',
    'guestEmail',
    'expiration',
    'signature',
    'groupOwnerName'
  )
}

export function updateQueryString(key: string, value: string, urlOrPath: string, setIfMissing = false): string {
  const re = new RegExp('([?&])' + key + '=.*?(&|#|$)(.*)', 'gi')
  let hash: string[]
  // update existing
  if (re.test(urlOrPath)) {
    if (typeof value !== 'undefined' && value !== null) {
      return urlOrPath.replace(re, '$1' + key + '=' + value + '$2$3')
    } else {
      hash = urlOrPath.split('#')
      urlOrPath = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '')
      if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
        urlOrPath += '#' + hash[1]
      }
      return urlOrPath
    }
  }
  // set missing
  if (setIfMissing && typeof value !== 'undefined' && value !== null) {
    const separator = urlOrPath.indexOf('?') !== -1 ? '&' : '?'
    hash = urlOrPath.split('#')
    urlOrPath = hash[0] + separator + key + '=' + value
    if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
      urlOrPath += '#' + hash[1]
    }
    return urlOrPath
  }
  return urlOrPath
}

/*
 * From Modernizr:
 * See this article: [You Can't Detect A Touchscreen](http://www.stucox.com/blog/you-cant-detect-a-touchscreen/).
 */
export function hasTouch(): boolean {
  return 'ontouchstart' in window
}

export function makeTouchEventUsable(event: Event | TouchEvent, centerX: number, centerY: number): any {
  if (hasTouch() && event instanceof TouchEvent) {
    event.preventDefault()
    const touch: ETouch = event.touches[0]
    // decorate with some new fields
    touch.offsetX =
      touch.clientX -
      ((<HTMLElement>touch.target).offsetLeft + (<HTMLElement>(<HTMLElement>touch.target).offsetParent).offsetLeft) +
      centerX
    // header height include
    touch.offsetY =
      touch.clientY -
      ((<HTMLElement>touch.target).offsetTop + (<HTMLElement>(<HTMLElement>touch.target).offsetParent).offsetTop) +
      centerY
    return touch
  } else {
    return event
  }
}

export function createUUID(): string {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }

  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

export function filledArrayWithLength<T>(length: number, fillData: T): T[] {
  return new Array(length).fill(fillData)
}

export function randomFloat(min: number, max: number) {
  return min + Math.random() * (max - min)
}

export function pickSamlSsoFragments(fragments: string): SamlSsoFragments {
  if (fragments) {
    const samlSsoFragments: SamlSsoFragments = {
      token: null,
      newUser: null,
      error: null,
    }

    const fragmentsSplitted: string[] = fragments.split('&')
    fragmentsSplitted.forEach(fragment => {
      const token: string | null = findFragmentValue(fragment, 'token=')
      if (token) {
        samlSsoFragments.token = token
      }

      const newUser: string | null = findFragmentValue(fragment, 'newUser=')
      if (newUser) {
        samlSsoFragments.newUser = newUser === 'true'
      }

      const error: string | null = findFragmentValue(fragment, 'error=')
      if (error) {
        samlSsoFragments.error = error === 'true'
      }
    })

    return samlSsoFragments
  }
  return null
}

function findFragmentValue(fragment: string, name: string): string | null {
  if (fragment.split(name).length === 2) {
    return fragment.split(name)[1]
  } else {
    return null
  }
}
