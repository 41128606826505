import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { LoopingCoreModule } from '../../../looping-core.module'
import { LanguageSwitcherModule } from '../language-switcher/language-switcher.module'
import { FooterComponent } from './footer.component'

@NgModule({
  imports: [CommonModule, LoopingCoreModule, LanguageSwitcherModule, RouterModule],
  declarations: [FooterComponent],
  exports: [FooterComponent],
})
export class FooterModule {}
