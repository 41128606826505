import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { NotificationCenterService } from '@maprix/components'
import { Logger, LoggerService } from '@maprix/core'
import { remove } from 'lodash'
import { Observable, of, throwError } from 'rxjs'
import { first, map, share } from 'rxjs/operators'
import { Regex } from '../../helpers/regex'
import { HttpError } from '../../models/http/http-error'
import { FeedbackStyle } from '../../models/prototype/feedback-style.enum'
import { SimpleGroup } from '../../models/prototype/group.model'
import { Prototype } from '../../models/prototype/prototype.model'
import { AuthUser } from '../../models/user/auth-user'
import { ProfileUser } from '../../models/user/profile-user.model'
import { SearchableUser } from '../../models/user/searchable-user.model'
import { AuthService } from '../../services/auth/auth.service'
import { OrganizationService } from '../../services/organization/organization.service'
import { ProfileUserService } from '../../services/profile-user/profile-user.service'
import { PrototypeService } from '../../services/prototype/prototype.service'
import { TagInputError } from '../tag-input/tag-input-error.model'
import { TagInputHttpError } from '../tag-input/tag-input-http-error.model'

@Component({
  selector: 'scs-enterprise-invite',
  templateUrl: './enterprise-invite.component.html',
  styleUrls: ['./enterprise-invite.component.scss'],
})
export class EnterpriseInviteComponent implements OnInit {
  @Input() prototype: Prototype
  @Input() isModal: false

  @Output() closeModal: EventEmitter<null | Prototype> = new EventEmitter<null | Prototype>()

  hasPrototype: boolean

  usersToInvite: SearchableUser[] = []
  usersToInviteEmails: string[] = []

  tags: string[] = []
  tagInputReady = true

  groups: SimpleGroup[] = []

  error: HttpError | string | null
  success: HttpError | string | null
  errorDetails: { [key: string]: any } | null
  successDetails: { [key: string]: any } | null

  worker: Observable<any>
  isGroupPrototype = false

  showGuestWarning = false

  private emailRegex: RegExp
  private loggedInUserEmail: string
  private logger: Logger
  private isGuestFeatureActivated: boolean

  constructor(
    loggerService: LoggerService,
    private organizationService: OrganizationService,
    private authService: AuthService,
    private profileUserService: ProfileUserService,
    private prototypeService: PrototypeService,
    private notificationCenterService: NotificationCenterService
  ) {
    this.logger = loggerService.getInstance('EnterpriseInviteComponent')
  }

  ngOnInit(): void {
    this.hasPrototype = !!this.prototype
    this.isGroupPrototype = this.hasPrototype && this.prototype.feedbackStyle === FeedbackStyle.GROUP
    this.authService.authUserChanges.pipe(first()).subscribe(
      (authUser: AuthUser) => {
        if (authUser && authUser.sub) {
          this.organizationService.guestFeature$.pipe(first()).subscribe(activated => {
            // set regex for validation. default email regex only when group prototype and guest feature is activated
            this.isGuestFeatureActivated = this.isGroupPrototype && activated
            this.emailRegex = this.isGuestFeatureActivated
              ? Regex.fromPattern(Regex.PATTERN_EMAIL)
              : Regex.fromPattern(this.authService.buildEmailPatternForOrgDomains())
            this.loggedInUserEmail = authUser.sub
          })
        }
      },
      (error: any) => {
        throw new Error('Could not get currently logged in user, needs one to work.')
      }
    )
  }

  //
  // TAG-INPUT INVITE USERS
  //

  invite(): void {
    this.tagInputReady = false
    const emails: any[] = this.usersToInvite.map(user => user.email)
    const emailsToInvite: string[] = emails.concat(this.usersToInviteEmails)

    if (this.isGroupPrototype) {
      let groupIds: string[] = []
      if (this.groups.length) {
        groupIds = this.groups.map(g => g.id)
      }
      this.worker = this.prototypeService.inviteGroupMembers(this.prototype.id, emailsToInvite, groupIds).pipe(share())
      this.worker.subscribe(
        (prototype: Prototype) => {
          if (this.isModal) {
            this.notificationCenterService.success('TAG_INPUT.INVITE_SUCCESS', { count: emailsToInvite.length })
            this.closeModal.emit(prototype)
          } else {
            this.success = 'TAG_INPUT.INVITE_SUCCESS'
            this.successDetails = { count: emailsToInvite.length }
            this.reset()
          }
        },
        (error: HttpError) => {
          this.error = error
          this.tagInputReady = true
        }
      )
    } else {
      this.worker = this.organizationService
        .invite(emailsToInvite, this.prototype && this.prototype.id ? this.prototype.id : undefined)
        .pipe(share())
      this.worker.subscribe(
        (invited: string[]) => {
          if (invited && invited.length) {
            if (this.isModal) {
              this.notificationCenterService.success('TAG_INPUT.INVITE_SUCCESS', { count: emailsToInvite.length })
              this.closeModal.emit()
            } else {
              this.success = 'TAG_INPUT.INVITE_SUCCESS'
              this.successDetails = { count: emailsToInvite.length }
            }
          } else {
            this.errorDetails = { emails: emailsToInvite.join(', ') }
            this.error = 'TAG_INPUT.INVITE_ERROR_NOT_INVITED'
          }
          this.reset()
        },
        (error: HttpError) => {
          this.error = error
          this.tagInputReady = true
        }
      )
    }
  }

  itemAdded = item => {
    if (typeof item === 'string') {
      this.usersToInviteEmails.push(item)
      this.updateGuestWarning()
    } else {
      this.usersToInvite.push(<SearchableUser>item)
    }
  }

  itemRemoved = item => {
    if (typeof item === 'string') {
      this.usersToInviteEmails = this.usersToInviteEmails.filter(currentItem => currentItem !== item)
      this.updateGuestWarning()
    } else {
      this.usersToInvite = this.usersToInvite.filter(currentUser => currentUser.email !== (<SearchableUser>item).email)
    }
  }

  maxItemsReached = (res: any) => {
    this.logger.warn('MAX ITEMS REACHED: ', res)
  }

  loadData = (query: string): Observable<SearchableUser[] | ProfileUser[]> => {
    this.resetMessages()
    if (this.authService.isLoggedIn()) {
      // simply return the search results of our secure endpoint
      return this.profileUserService.search(query)
    } else {
      // return an empty array
      return of([])
    }
  }

  validate = (item: any): Observable<any> => {
    this.resetMessages()
    if (typeof item === 'string' && Regex.fromPattern(Regex.PATTERN_EMAIL).test(item)) {
      if (!this.emailRegex.test(item)) {
        const errorObject: TagInputError = {
          key: 'TAG_INPUT.ERROR_ORG_DOMAIN',
          values: { item },
        }
        return throwError(errorObject)
      } else if (this.loggedInUserEmail !== undefined && item.toLocaleLowerCase() === this.loggedInUserEmail) {
        const errorObject: TagInputError = {
          key: 'TAG_INPUT.ERROR_INVITE_SELF',
          values: { item },
        }
        return throwError(errorObject)
      } else if (this.isGroupPrototype && this.isEmailAlreadyInvited(item)) {
        const errorObject: TagInputError = {
          key: 'TAG_INPUT.PROTOTYPE_ALREADY_INVITED_EMAIL',
          values: { item },
        }
        return throwError(errorObject)
      } else {
        if (this.hasPrototype) {
          return this.loadData(<string>item).pipe(
            map((responseArray: SearchableUser[]) => {
              if (responseArray && responseArray.length > 0) {
                responseArray.forEach((user: SearchableUser | ProfileUser) => {
                  if (user.email.toLocaleLowerCase() === item.toLocaleLowerCase()) {
                    if (this.isGroupPrototype && this.isUserAlreadyInvited(<ProfileUser>user)) {
                      const errorObject: TagInputError = {
                        key: 'TAG_INPUT.PROTOTYPE_ALREADY_INVITED_USER',
                        values: { item },
                      }
                      const error = new TagInputHttpError()
                      error.tagInputError = errorObject
                      throw error
                    } else {
                      item = user
                    }
                  }
                })
              }
              return item
            })
          )
        } else {
          return of(item)
        }
      }
    } else if (item.fullName) {
      if (
        this.loggedInUserEmail !== undefined &&
        item.email &&
        item.email.toLocaleLowerCase() === this.loggedInUserEmail
      ) {
        const errorObject: TagInputError = {
          key: 'TAG_INPUT.ERROR_INVITE_SELF',
          values: { item: item.email },
        }
        return throwError(errorObject)
      } else if (this.isGroupPrototype && item.id && this.isUserAlreadyInvited(item)) {
        const errorObject: TagInputError = {
          key: 'TAG_INPUT.PROTOTYPE_ALREADY_INVITED_USER',
          values: { item: item.fullName },
        }
        return throwError(errorObject)
      } else {
        return of(item)
      }
    } else {
      const errorObject: TagInputError = {
        key: 'TAG_INPUT.INVALID_ITEM',
        values: { item },
      }
      return throwError(errorObject)
    }
  }

  onToggleGroupSelection(g: SimpleGroup, checked: boolean) {
    if (checked) {
      this.groups.push(g)
    } else {
      remove(this.groups, ge => ge.id === g.id)
    }
  }

  isGroupChecked(g: SimpleGroup): boolean {
    const index: number = this.groups.findIndex(ge => ge.id === g.id)
    return index > -1
  }

  private resetMessages(): void {
    this.error = null
    this.errorDetails = null
    this.success = null
    this.successDetails = null
  }

  private isEmailAlreadyInvited(email: string): boolean {
    return (
      this.prototype.invitedExternalUsers.some(
        invitedMail => invitedMail.toLocaleLowerCase() === email.toLocaleLowerCase()
      ) ||
      this.prototype.invitedGuests.some(invitedMail => invitedMail.toLocaleLowerCase() === email.toLocaleLowerCase())
    )
  }

  private isUserAlreadyInvited(user: ProfileUser): boolean {
    return this.prototype.invitedUsers.some(invitedUser => invitedUser.id === user.id)
  }

  private reset(): void {
    this.tags.length = 0
    this.usersToInvite.length = 0
    this.usersToInviteEmails.length = 0
    this.updateGuestWarning()
    this.tagInputReady = true
  }

  private updateGuestWarning() {
    this.showGuestWarning =
      this.isGuestFeatureActivated &&
      !this.usersToInviteEmails.every(mail =>
        Regex.fromPattern(this.authService.buildEmailPatternForOrgDomains()).test(mail)
      )
  }
}
