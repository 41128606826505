import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { HeaderProvider, MenuItem } from '@maprix/components'
import { Logger, LoggerService } from '@maprix/core'
import { combineLatest } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { ProfileUserService } from '../../../shared/services/profile-user/profile-user.service'
import {
  ADMIN_MENU_ITEM,
  DEFAULT_MENU_ITEMS,
  LOGGED_IN_MENU_ITEMS,
  LOGIN_MENU_ITEM,
  LOGOUT_MENU_ITEM,
  PROFILE_MENU_ITEM,
  SALES_MENU_ITEMS,
} from '../../models/menu/menu-items'
import { AuthUser } from '../../models/user/auth-user'
import { SignedIdGuest } from '../../models/user/signed-id-guest'
import { AuthService } from '../../services/auth/auth.service'

@Injectable()
export class AppHeaderProvider extends HeaderProvider {
  private logger: Logger

  defaultMenuItem: MenuItem = { key: 'MENU.DEFAULT', path: null }
  previewMenuItem: MenuItem | null = null

  constructor(
    loggerService: LoggerService,
    private profileUserService: ProfileUserService,
    private authService: AuthService,
    private router: Router
  ) {
    super()

    this.logger = loggerService.getInstance('AppHeaderProvider')
    this.init()
  }

  private init() {
    this.profileUserService.profileUserChanges
      .pipe(filter(v => !!v))
      // only if logged in
      .subscribe(this.userSubject)

    this.authService.authUserChanges
      .pipe(filter(authUser => !!authUser))
      // only if auth user available / logged in
      .subscribe((authUser: AuthUser) => {
        let userMenuItems: MenuItem[] = [PROFILE_MENU_ITEM]

        if (AuthService.hasRole(authUser, 'orgAdmin')) {
          userMenuItems.push(ADMIN_MENU_ITEM)
        }
        if (AuthService.hasRole(authUser, 'salesAdmin')) {
          userMenuItems = userMenuItems.concat(SALES_MENU_ITEMS)
        }
        userMenuItems.push(LOGOUT_MENU_ITEM)

        this.mainMenuSubject.next(LOGGED_IN_MENU_ITEMS)
        this.userMenuSubject.next(userMenuItems)
      })

    combineLatest(this.authService.isLoggedInChanges, this.authService.isGuestModeChanges)
      .pipe(map(([loggedIn, signedIdGuest]) => ({ loggedIn, signedIdGuest })), filter(val => !val.loggedIn))
      // only if no auth user / logged out
      .subscribe((val: { signedIdGuest: SignedIdGuest }) => {
        if (val.signedIdGuest) {
          this.userSubject.next({ isAnonymous: true, anonymousName: val.signedIdGuest.guestEmail })
          this.mainMenuSubject.next([])
          this.userMenuSubject.next([])
        } else {
          this.userSubject.next(null)
          this.mainMenuSubject.next(DEFAULT_MENU_ITEMS)
          this.userMenuSubject.next([LOGIN_MENU_ITEM])
        }
      })
  }
}
