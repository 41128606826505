import { animate, state, style, transition, trigger } from '@angular/animations'
import { DEFAULT_ANIMATION_DURATION } from './config.animations'

export const fadeInAnimation = trigger('fadeInAnimation', [
  state(
    'fadeIn',
    style({
      opacity: 1,
    })
  ),
  transition('void => *', [style({ opacity: 0 }), animate(DEFAULT_ANIMATION_DURATION + 'ms ease-in-out')]),
])
