import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { BgImageModule } from '../../directives/bg-image/bg-image.module'
import { PolaroidStackComponent } from './polaroid-stack.component'
import { PolaroidComponent } from './polaroid.component'

@NgModule({
  imports: [CommonModule, BgImageModule],
  declarations: [PolaroidComponent, PolaroidStackComponent],
  exports: [PolaroidComponent, PolaroidStackComponent, BgImageModule],
})
export class PolaroidModule {}
