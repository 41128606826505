import { SignedEmail } from '../../../shared/models/user/signed-email'
import { LoginType } from './login-type.enum'

export class RegistrationInfoDTO {
  givenName: string
  familyName: string
  tokenOrPassword: string
  locale: string
  loginType: LoginType
  acceptedTermsAndConditions: boolean
  signedEmail: SignedEmail | null
  zoneId: string
  profilePictureUrl: string

  constructor(
    givenName: string,
    familyName: string,
    loginType: LoginType,
    tokenOrPassword: string,
    locale: string,
    acceptedTermsAndConditions: boolean,
    signedEmail: SignedEmail,
    zoneId: string
  ) {
    this.givenName = givenName
    this.familyName = familyName
    this.loginType = loginType
    this.tokenOrPassword = tokenOrPassword
    this.locale = locale
    this.acceptedTermsAndConditions = acceptedTermsAndConditions
    this.signedEmail = signedEmail
    this.zoneId = zoneId
  }
}
