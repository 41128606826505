import { Component, HostBinding, Inject } from '@angular/core'
import { Modal2, MODAL2_DATA } from '@maprix/components'

@Component({
  selector: 'scs-confirm-guests-submit-modal',
  templateUrl: './confirm-guests-submit-modal.component.html',
})
@Modal2({
  size: 'xs',
  titleKey: 'PROTOTYPE.CONFIRM_GUESTS_MODAL.TITLE'
})
export class ConfirmGuestsSubmitModalComponent {
  constructor(@Inject(MODAL2_DATA) public modalData: string[]) {}

  joinGroupNames(): { name: string } {
    return { name: this.modalData.join(', ') }
  }
}
