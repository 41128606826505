import { Component, ElementRef, NgZone, OnInit, Renderer2 } from '@angular/core'
import {
  LocaleService,
  LocalStorage,
  Logger,
  LoggerService,
  MetaService,
  ProjectStage,
  RouterScrollService,
  RuntimeConfiguration,
  WindowRef,
} from '@maprix/core'
import { Angulartics2 } from 'angulartics2'
import { map } from 'rxjs/operators'
import { AppService } from './app.service'
import { Angulartics2GoogleTagManager } from './shared/angularitics/angulartics2-gtm'
import { AuthService } from './shared/services/auth/auth.service'
import { ScriptLoader } from './shared/services/script-loader/script-loader.service'
import { AddStyleAttr, StyleAttr, StyleService } from './shared/services/style/style.service'
import { WsService } from './shared/services/ws/ws.service'

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'scs-app',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.scss'],
})
export class AppComponent implements OnInit {
  private logger: Logger
  private bodyEl: HTMLElement
  private htmlEl: HTMLElement
  private window: Window

  isGuestMode$ = this.authService.isGuestModeChanges
  orgShortCut$ = this.authService.authUserChanges
    .pipe(map(au => au ? au.organization.shortcut : null))

  // don't remove unused services, they are here so the services get instantiated
  constructor(
    loggerService: LoggerService,
    windowRef: WindowRef,
    routerScrollToService: RouterScrollService,
    runtimeConfiguration: RuntimeConfiguration,
    scriptLoader: ScriptLoader,
    angulartics2: Angulartics2,
    appService: AppService,
    // do not remove
    gtm: Angulartics2GoogleTagManager,
    metaService: MetaService,
    el: ElementRef,
    private renderer: Renderer2,
    private localeService: LocaleService,
    private styleService: StyleService,
    private ngZone: NgZone,
    private authService: AuthService,
    wsService: WsService,
    private localStorage: LocalStorage,
  ) {
    this.logger = loggerService.getInstance('App')
    this.window = windowRef.nativeWindow
    this.bodyEl = this.window.document.body
    this.htmlEl = this.window.document.documentElement

    // side effects service
    appService.init()

    // TODO workaround for https://github.com/angular/protractor/issues/4004
    // safari 10+ will otherwise never find angular -> sync fails because window.angular is undefined...
    if (navigator.webdriver) {
      this.window.name = 'NG_DEFER_BOOTSTRAP!' + this.window.name
    }

    routerScrollToService.init()

    // set initial language & initial guessed timezone
    this.localeService.init(true)

    // if (runtimeConfiguration.projectStage > ProjectStage.DEVELOPMENT) {
      // Google Tag Manager
      this.window['dataLayer'] = this.window['dataLayer'] || []
      this.window['dataLayer'].push(
        { 'gtm.start': new Date().getTime(), event: 'gtm.js' },
        { 'gtm.blacklist': ['customScripts'] },
      )
      const analyticsId = runtimeConfiguration.getAnalyticsId() as String;
      scriptLoader.addScriptToHead(
        '//www.googletagmanager.com/gtm.js?id=' + analyticsId,
        true,
      )
    // }
  }

  ngOnInit() {
    this.styleService.styleChanges.subscribe(this.handleStyleChange)

    // NOTE: This fixes iOS not applying `:active` states at all.
    document.addEventListener('touchstart', () => {}, false)

    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.ngZone.run(() => {
          this.renderer.addClass(this.window.document.body, 'initial-loader--loaded')
        })
      }, 200)
    })
  }

  private handleStyleChange = (styleAttr: StyleAttr): void => {
    switch (styleAttr.action) {
      case 'add':
        this.renderer.setStyle(this.bodyEl, styleAttr.name, (<AddStyleAttr>styleAttr).value)
        break
      case 'delete':
        this.renderer.setStyle(this.bodyEl, styleAttr.name, '')
        break
    }
  }
}
