import { Routes } from '@angular/router'

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: './routes/home/home.module#HomeModule',
  },
  {
    path: 'contact',
    loadChildren: './routes/contact/contact.module#ContactModule',
  },
  {
    path: 'terms',
    loadChildren: './routes/terms/terms.module#TermsModule',
  },
  {
    path: 'trial',
    loadChildren: './routes/trial/trial.module#TrialModule',
  },
  {
    path: 'styleguide',
    loadChildren: './routes/styleguide/styleguide.module#StyleguideModule',
  },
  {
    path: 'admin',
    loadChildren: './routes/admin/admin.module#AdminModule',
  },
  {
    path: 'verify',
    loadChildren: './routes/verify-email/verify-email.module#VerifyEmailModule',
  },
  {
    path: 'profile',
    loadChildren: './routes/profile/profile.module#ProfileModule',
  },
  {
    path: 'register',
    loadChildren: './routes/register/register.module#RegisterModule',
  },
  {
    path: 'passwordreset',
    loadChildren: './routes/password-reset/password-reset.module#PasswordResetModule',
  },
  {
    path: 'community',
    loadChildren: './routes/community/community.module#CommunityModule',
  },
  {
    path: 'feedback',
    loadChildren: './routes/feedback/feedback.module#FeedbackModule',
  },
  {
    path: 'results',
    loadChildren: './routes/results/results.module#ResultsModule',
  },
  {
    path: 'group/unsubscribe',
    loadChildren: './routes/unsubscribe-from-group/unsubscribe-from-group.module#UnsubscribeFromGroupModule',
  },
  {
    path: 'privacy-policy',
    loadChildren: './routes/privacy-policy/privacy-policy.module#PrivacyPolicyModule',
  },
  {
    path: 'organizations',
    loadChildren: './routes/organizations/organizations.module#OrganizationsModule',
  },
  {
    path: '**',
    redirectTo: 'home',
  },
]
