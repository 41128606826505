import { Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core'
import { Logger, LoggerService } from '@maprix/core'
import { AvatarFacet } from './avatar-facet.model'

@Component({
  selector: 'scs-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input() displayName: string
  @Input() imageUrl: string // If null, the initials will be displayed
  @Input() avatarColor: string
  @Input() facet: AvatarFacet = 'base'

  @Input() organization: boolean
  @Input() anonymous: boolean
  @Input() checked: boolean
  @Input() link: boolean

  private logger: Logger

  constructor(loggerService: LoggerService, private renderer: Renderer2, private elementRef: ElementRef) {
    this.logger = loggerService.getInstance('Avatar')
  }

  ngOnInit() {
    if (this.imageUrl == 'https://scs-flux-resources.s3.eu-central-1.amazonaws.com/fa79e285-0bd8-4ab3-8dfb-6d6b85b6e335') {
      this.imageUrl = '../assets/img/logo/rready_logo_short.svg';
    }
    if (this.displayName == 'Team Looping') {
      this.displayName = 'Team Feedback';
    }
    if (this.avatarColor) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'background-color', this.avatarColor)
      this.renderer.addClass(this.elementRef.nativeElement, 'avatar--colored')
    }

    this.renderer.addClass(this.elementRef.nativeElement, `avatar--${this.facet}`)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.normalizeBoolean(changes, 'organization', 'anonymous', 'checked', 'link')
  }

  private normalizeBoolean(changes: SimpleChanges, ...fields: string[]): void {
    fields.forEach((field: string) => {
      if (changes[field] && changes[field].currentValue === '') {
        this[field] = true
      }
    })
  }
}
