import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { FormControlErrorDisplayStrategy } from '@maprix/components'
import { LoggerService, ScrollToService } from '@maprix/core'
import { Observable } from 'rxjs'
import { Regex } from '../../../shared/helpers/regex'
import { HttpError } from '../../../shared/models/http/http-error'
import { AuthService } from '../../../shared/services/auth/auth.service'
import { LoginStateData } from '../models/login-state-data'
import { ResetableLoginStepWithFormComponent } from './base/resetable-login-step-with-form.component'

@Component({
  selector: 'scs-two-fa-verify',
  templateUrl: 'two-fa-verify.component.html',
})
export class TwoFaVerifyComponent extends ResetableLoginStepWithFormComponent implements OnInit {
  @Input() data: LoginStateData
  @Input() worker$: Observable<void>

  // two way binding for error
  @Input() error: HttpError | string
  @Output() errorChange: EventEmitter<HttpError | string> = new EventEmitter<HttpError | string>()

  @Output() back: EventEmitter<null> = new EventEmitter<null>()
  @Output() next: EventEmitter<null> = new EventEmitter<null>()

  resendSuccess: string | null
  errorDisplayStrategy: any = FormControlErrorDisplayStrategy

  constructor(
    loggerService: LoggerService,
    formBuilder: FormBuilder,
    private authService: AuthService,
    private scrollToService: ScrollToService
  ) {
    super(loggerService.getInstance('TwoFaSetup'), formBuilder)
  }

  ngOnInit(): void {
    super.ngOnInit()

    this.form.get('token').valueChanges.subscribe(() => {
      this.resetError()
    })

    this.scrollToService.scrollTo()
  }

  resendToken(): void {
    if (!this.resendSuccess) {
      this.authService.requestTwoFactorAuthToken(this.data.loginData.email!).subscribe(() => {
        this.logger.info('token was sent')
        this.resendSuccess = 'LOGIN.TWO_FA_VERIFY.RESEND_SUCCESS'
        setTimeout(() => {
          this.resendSuccess = null
        }, 5000)
      })
    } else {
      this.logger.warn('wait 5s after the last token request')
    }
  }

  buildForm(): FormGroup {
    return this.formBuilder.group({
      token: ['', Validators.compose([Validators.required, Validators.pattern(Regex.PATTERN_TWO_FA_TOKEN)])],
    })
  }

  fillViewDataToModel(): void {
    this.data.loginData.twoFaToken = this.form.get('token').value
  }
}
