import { Injectable } from '@angular/core'
import { Logger, LoggerService, RuntimeConfiguration } from '@maprix/core'
import { Observable } from 'rxjs'
import { SimpleImage } from '../../models/shared/simple-image.model'
import { MicroService } from '../config/runtime-configuration/micro-service.enum'
import { AppHttpResponse } from '../http/app-http-response.model'
import { AppHttp } from '../http/app-http.service'

@Injectable()
export class PublicOrganizationService {
  private static URI_BASE = 'public/organization'
  private static URI_PROFILE_PICTURE = '/profilepicture'

  private logger: Logger
  private mainUrl: string

  constructor(loggerService: LoggerService, runtimeConfiguration: RuntimeConfiguration, private appHttp: AppHttp) {
    this.logger = loggerService.getInstance('PublicOrganizationService')
    this.mainUrl = runtimeConfiguration.getUrlForRestService(MicroService.MAIN)
  }

  getProfilePicture(orgId: string): Observable<AppHttpResponse<SimpleImage>> {
    return this.appHttp.get<SimpleImage>(
      `${this.mainUrl}${PublicOrganizationService.URI_BASE}/${orgId}${PublicOrganizationService.URI_PROFILE_PICTURE}`
    )
  }
}
