import { URLSearchParams } from '@angular/http'
import { Logger, LoggerService } from '@maprix/core'
import { combineLatest, Observable, Subject, Subscription } from 'rxjs'
import { debounceTime, map, mergeMap, share, startWith, switchMap, tap } from 'rxjs/operators'
import { QueryStats } from '../../models/shared/query-stats.model'
import { AppHttp } from './app-http.service'

interface FetchDataDTO {
  params: URLSearchParams
  url: string
}

export class PagedLoader<T, K, S> {
  private static QUERY_PARAM_FILTER = 'filter'
  private static QUERY_PARAM_SEARCH = 'search'
  private static QUERY_PARAM_SORTING = 'sorting'
  private static QUERY_PARAM_LIMIT = 'limit'
  private static QUERY_PARAM_OFFSET = 'offset'

  private static COUNT_URI = '/count'

  max: number

  private limit: number
  private offset: number

  private _loading: boolean
  private _modelData: T[] = []
  private _countObservable: Observable<number>
  private searchObservable: Observable<URLSearchParams>
  private sortObservable: Observable<URLSearchParams>
  private filterObservable: Observable<URLSearchParams>
  private additionalParamsObservables: Array<Observable<URLSearchParams>> = []

  private nextSubject: Subject<void>

  private subscriptions: Subscription[] = []

  private logger: Logger

  private static toParams(key: string, value: string | null): URLSearchParams {
    const urlParams = new URLSearchParams()
    if (value && value.length) {
      urlParams.set(key, value)
    }
    return urlParams
  }

  constructor(
    loggerService: LoggerService,
    private http: AppHttp,
    private dataUrl: Observable<string>,
    filterObservable: Observable<K> | null,
    searchObservable: Observable<string | null> | null,
    sortObservable: Observable<S> | null,
    additionalParamsObservables: Array<Observable<{ key: string; value: string | null }>> | null,
    limit: number
  ) {
    this.logger = loggerService.getInstance('PagedLoader')

    this.limit = limit
    this.offset = 0
    this._loading = false

    if (searchObservable) {
      this.searchObservable = searchObservable.pipe(
        startWith(''),
        debounceTime(150),
        map(search => PagedLoader.toParams(PagedLoader.QUERY_PARAM_SEARCH, search)),
        tap(search => this.logger.debug('search changed', search.toString()))
      )
    }

    if (filterObservable) {
      this.filterObservable = filterObservable.pipe(
        map(filter => PagedLoader.toParams(PagedLoader.QUERY_PARAM_FILTER, filter.toString())),
        tap(filter => this.logger.debug('filter changed', filter.toString()))
      )
    }

    if (additionalParamsObservables) {
      // map optional params to URLSearchParams
      additionalParamsObservables.forEach(obs => {
        this.additionalParamsObservables.push(
          obs.pipe(
            map(e => PagedLoader.toParams(e.key, e.value)),
            tap(additionalParams => this.logger.debug('additional params changed', additionalParams.toString()))
          )
        )
      })
    }

    if (sortObservable) {
      this.sortObservable = sortObservable.pipe(
        map(sort => PagedLoader.toParams(PagedLoader.QUERY_PARAM_SORTING, sort.toString())),
        tap(sort => this.logger.debug('sort changed', sort.toString()))
      )
    }

    // next subject for next call
    this.nextSubject = new Subject<void>()

    // url params which trigger reset
    const observables: Array<Observable<URLSearchParams>> = []

    if (searchObservable !== null && searchObservable !== undefined) {
      this.logger.debug('listen for search changes')
      observables.push(this.searchObservable)
    }

    if (filterObservable) {
      this.logger.debug('listen for filter changes')
      observables.push(this.filterObservable)
    }

    if (sortObservable) {
      this.logger.debug('listen for sort changes')
      observables.push(this.sortObservable)
    }

    if (additionalParamsObservables) {
      this.logger.debug('listen for additional param changes')
      observables.push(...this.additionalParamsObservables)
    }

    // observables which trigger reset
    const urlParamsObservable: Observable<FetchDataDTO> = combineLatest<URLSearchParams>(observables).pipe(
      mergeMap((paramsInput: URLSearchParams[]) => {
        // merge with endpoint url
        return this.dataUrl.pipe(
          map(url => {
            const params = new URLSearchParams()
            paramsInput.forEach(param => {
              params.setAll(param)
            })
            // return FetchDataDTO
            return { params, url }
          })
        )
      }),
      tap((fetchDataDTO: FetchDataDTO) => {
        this.logger.debug('fetch data DTO changed <%s / %s>', fetchDataDTO.params.toString(), fetchDataDTO.url)
      }),
      // when ever the url or criteria changes -> reset
      tap(this.reset),
      share()
    )

    const nextObservable: Observable<void> = this.nextSubject
      .asObservable()
      .pipe(tap(() => this.logger.debug('next changed')))

    // fetch data
    const dataSub = combineLatest<FetchDataDTO>(urlParamsObservable, nextObservable, fetchDataDTO => fetchDataDTO)
      .pipe(map(this.addPagedParams), switchMap(this.fetchData))
      .subscribe(
        data => {
          if (data) {
            this._modelData.push(...data)
          }

          this._loading = false
        },
        e => {
          // possible errors are handled inside appHttp
          this.logger.warn('unable to fetch data error : %o', e)
          this._loading = false
        },
        () => {
          this._loading = false
        }
      )

    // fetch count
    this._countObservable = urlParamsObservable.pipe(switchMap(this.getCount), map(stat => stat.count), share())

    const countSub = this._countObservable.subscribe(count => (this.max = count))

    // next on subject
    this.nextSubject.next()

    // push subscription to unsubscribe on dispose
    this.subscriptions.push(dataSub)
    this.subscriptions.push(countSub)
  }

  destroy() {
    this.logger.debug('Complete subject and unsubscribe')
    this.nextSubject.complete()
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  hasMore(): boolean {
    return this.max > this.offset + this.limit
  }

  next() {
    if (this.hasMore()) {
      this.offset += this.limit
      this.nextSubject.next()
    }
  }

  // quick fix to reset/refetch table data
  pseudoReset() {
    if (this._modelData && this.nextSubject) {
      this._modelData.length = 0
      this.nextSubject.next()
    }
  }

  get loading(): boolean {
    return this._loading
  }

  get modelData(): T[] {
    return this._modelData
  }

  get countObservable(): Observable<number> {
    return this._countObservable
  }

  private getCount = (fetchData: FetchDataDTO): Observable<QueryStats> => {
    this.logger.debug('get count...')
    return this.http.get<QueryStats>(fetchData.url + PagedLoader.COUNT_URI, { params: fetchData.params }).pipe(
      map(resp => {
        return resp.body
      })
    )
  }

  private reset = () => {
    this.logger.debug('reset all params')
    this.offset = 0
    this._modelData = []
  }

  private fetchData = (fetchData: FetchDataDTO): Observable<T[]> => {
    this.logger.debug('fetching data...')
    this._loading = true
    return this.http.get<T[]>(fetchData.url, { params: fetchData.params }).pipe(map(resp => resp.body))
  }

  private addPagedParams = (fetchData: FetchDataDTO): FetchDataDTO => {
    const clone: FetchDataDTO = {
      url: fetchData.url,
      params: fetchData.params.clone(),
    }

    clone.params.set(PagedLoader.QUERY_PARAM_LIMIT, this.limit.toString())
    clone.params.set(PagedLoader.QUERY_PARAM_OFFSET, this.offset.toString())

    return clone
  }
}
