import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IconModule } from '@maprix/components'
import { LoopingCoreModule } from '../../../looping-core.module'
import { StepContentComponent } from './step-content.component'
import { StepContinueBtnComponent } from './step-continue-btn.component'
import { StepHeaderComponent } from './step-header.component'

@NgModule({
  imports: [CommonModule, LoopingCoreModule, IconModule],
  declarations: [StepContentComponent, StepHeaderComponent, StepContinueBtnComponent],
  exports: [StepContentComponent, StepHeaderComponent, StepContinueBtnComponent],
})
export class StepModule {}
