import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { FormControlErrorDisplayStrategy } from '@maprix/components'
import { LoggerService, ScrollToService } from '@maprix/core'
import { Observable } from 'rxjs'
import { HttpError } from '../../../shared/models/http/http-error'
import { ActionType } from '../models/action-type.enum'
import { LoginStateData } from '../models/login-state-data'
import { ResetableLoginStepWithFormComponent } from './base/resetable-login-step-with-form.component'

@Component({
  selector: 'scs-login-ent',
  templateUrl: 'login-ent.component.html',
})
export class LoginEntComponent extends ResetableLoginStepWithFormComponent implements OnInit {
  @Input() data: LoginStateData
  @Input() worker$: Observable<any>

  // two way binding for error
  @Input() error: HttpError | string
  @Output() errorChange: EventEmitter<HttpError | string> = new EventEmitter<HttpError | string>()

  @Output() next: EventEmitter<null | ActionType> = new EventEmitter<null | ActionType>()
  @Output() back: EventEmitter<null> = new EventEmitter<null>()

  errorDisplayStrategy: any = FormControlErrorDisplayStrategy

  constructor(loggerService: LoggerService, formBuilder: FormBuilder, private scrollToService: ScrollToService) {
    super(loggerService.getInstance('LoginEnt'), formBuilder)
  }

  /*
   * define triggers to reset error
   */
  ngOnInit(): void {
    super.ngOnInit()
    this.form.get('password').valueChanges.subscribe(() => {
      // reset errors on value changes
      this.resetError()
    })

    this.scrollToService.scrollTo()
  }

  forgotPassword(): void {
    this.next.emit(ActionType.FORGOT_PASSWORD)
  }

  buildForm(): FormGroup {
    return this.formBuilder.group({
      password: ['', Validators.required],
    })
  }

  fillViewDataToModel(): void {
    this.data.loginData.tokenOrPassword = this.form.get('password').value
  }
}
