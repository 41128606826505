import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core'
import { Logger, LoggerService } from '@maprix/core'
import { AudienceChoice } from './wizard-steps/audience-choice.model'

@Component({
  selector: 'scs-audience-choice',
  templateUrl: './audience-choice.component.html',
  styleUrls: ['./audience-choice.component.scss'],
})
export class AudienceChoiceComponent {
  @Input() choice: AudienceChoice
  @Input()
  @HostBinding('class.is-selected')
  selected: boolean
  @Output() selectChoice: EventEmitter<AudienceChoice> = new EventEmitter<AudienceChoice>()

  private logger: Logger

  @HostListener('click')
  onClick() {
    this.selectChoice.emit(this.choice)
  }

  constructor(loggerService: LoggerService) {
    this.logger = loggerService.getInstance('AudienceChoiceComponent')
  }
}
