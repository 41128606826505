import { HttpClient } from '@angular/common/http'
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core'
import {
  LocalStorageOptions,
  LOGGLY_CONFIG,
  LogglyConfig,
  LogglyErrorHandler,
  MessageFormatParser,
  Parser,
  PRODUCT_NAME,
  RuntimeConfiguration,
  TRANSLATE_CONFIG,
  TranslateConfig,
  TranslateLoader,
  translateLoaderFactoryIcu,
} from '@maprix/core'
import { LocaleHelper } from '../locale/locale-helper'
import { AppLocalStorageOptions } from './app-local-storage-options'
import { RuntimeConfigurationService } from './runtime-configuration/runtime-configuration.service'

const LOOPING_LOCALE_MATCHER = (locale: string) => LocaleHelper.matchWithAvailableLanguageKeys(locale)

@NgModule({
  imports: [],
  providers: [
    { provide: ErrorHandler, useClass: LogglyErrorHandler },
    { provide: LocalStorageOptions, useClass: AppLocalStorageOptions },
    { provide: PRODUCT_NAME, useValue: 'scs-flux-client' },
    {
      provide: LOGGLY_CONFIG,
      useValue: <LogglyConfig>{ collectorDomain: 'https://logs-01.loggly.com/inputs/', tag: '/tag/scs-flux-client' },
    },
    { provide: RuntimeConfiguration, useClass: RuntimeConfigurationService },
    /*
     * translation
     */
    {
      provide: TRANSLATE_CONFIG,
      useValue: <TranslateConfig>{
        fallbackLanguage: 'en-US',
        localStorageKey: 'NG_TRANSLATE_LANG_KEY',
        localeMatcher: LOOPING_LOCALE_MATCHER,
      },
    },
    {
      provide: TranslateLoader,
      useFactory: translateLoaderFactoryIcu,
      deps: [HttpClient],
    },
    {
      provide: Parser,
      useClass: MessageFormatParser,
    },
  ],
})
export class ConfigModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ConfigModule
  ) {
    if (parentModule) {
      throw new Error('ConfigModule is already loaded. Import it only once')
    }
  }
}
