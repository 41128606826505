import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import {
  HeaderService,
  MenuItem,
  Modal2Service,
  NotificationCallbackType,
  NotificationCenterService,
  NotificationMessage,
  NotificationSeverity,
} from '@maprix/components'
import { Enums, Logger, LoggerService } from '@maprix/core'
import { combineLatest, Observable } from 'rxjs'
import { filter, first, flatMap, map, mapTo, tap } from 'rxjs/operators'
import { EditExpertisesModalComponent } from './shared/components/edit-expertise-modal-module/edit-expertises-modal.component'
import { PrivacyPolicyService } from './shared/components/privacy-policy-modal/privacy-policy.service'
import { MenuAction } from './shared/models/menu/menu-actions'
import {
  FEEDBACK_MENU_ITEM,
  LOGIN_MENU_ITEM,
  TRIAL_MENU_ITEM,
  UPLOAD_MENU_ITEM,
  VERIFY_MENU_ITEM,
} from './shared/models/menu/menu-items'
import { Expertise } from './shared/models/shared/expertise.model'
import { AuthUser } from './shared/models/user/auth-user'
import { ProfileUserMessage } from './shared/models/user/profile-user-messages.model'
import { ProfileUser } from './shared/models/user/profile-user.model'
import { AuthService } from './shared/services/auth/auth.service'
import { AppHttp } from './shared/services/http/app-http.service'
import { ProfileUserService } from './shared/services/profile-user/profile-user.service'

const ROUTES_WITHOUT_VERIFY_MESSAGE: MenuItem[] = [
  VERIFY_MENU_ITEM,
  TRIAL_MENU_ITEM,
  UPLOAD_MENU_ITEM,
  FEEDBACK_MENU_ITEM,
]

const KEY_TO_ROUTE = {
  SUBSCRIBE_NOW: '/admin/settings',
  INVITE_EMPLOYEES: '/admin/members',
  ADJUST_LIMIT_NOW: '/admin/settings',
}
const KEY_TO_FRAGMENT = {
  SUBSCRIBE_NOW: 'subscription',
  ADJUST_LIMIT_NOW: 'subscription',
}

const PUBLIC_CLOSING_NOTIFICATION_KEY = 'PUBLIC_CLOSING_INFO'

@Injectable()
export class AppService {
  static NOTIFICATION_ID_VERIFY_EMAIL = 'verifyYourEmailMessage'
  static NOTIFICATION_ID_VERIFY_EMAIL_STICKY = 'verifyYourEmailMessageSticky'

  private logger: Logger
  private user: ProfileUser | null

  constructor(
    loggerService: LoggerService,
    private router: Router,
    private authService: AuthService,
    private appHttp: AppHttp,
    private profileUserService: ProfileUserService,
    private headerService: HeaderService,
    private modalService: Modal2Service,
    private notificationCenter: NotificationCenterService,
    private privacyPolicyService: PrivacyPolicyService
  ) {
    this.logger = loggerService.getInstance('AppService')
  }

  init() {
    this.logger.debug('INIT')

    this.headerService.actions$
      .pipe(
        filter(action => action === MenuAction.LOGOUT),
        tap(() => this.logger.debug('user initiated logout')),
        flatMap(() => this.authService.logout())
      )
      .subscribe(
        () => this.router.navigateByUrl(LOGIN_MENU_ITEM.path),
        error => this.logger.debug('ignore error: ', error)
      )

    /*
     * remove all notification messages if user logs out
     */
    this.authService.isLoggedInChanges.subscribe((isLoggedIn: boolean) => {
      // remove all messages in notificationCenter when logged out
      if (!isLoggedIn) {
        this.logger.debug('remove all notifications')
        this.notificationCenter.remove()
      } else {
        // show phase out message once logged in
        // this.notificationCenter
        //   .builder()
        //   .setSeverity(NotificationSeverity.WARNING)
        //   .setClickToClose(true)
        //   .setSticky(true)
        //   .setKey('NOTIFICATIONS.PHASE_OUT')
        //   .show()
      }
    })

    /*
     * update profile user whenever auth user changes
     */
    this.profileUserService.profileUserChanges
      .pipe(
        flatMap(user =>
          // TODO: prevent dialog form closing on first route change -
          // hack align with pulse once rewrite of dialog service logic is done
          this.router.events.pipe(filter(event => event instanceof NavigationEnd), first(), mapTo(user))
        )
      )
      .subscribe(user => {
        this.user = user
        const authUser = this.authService.authUser
        if (this.user && authUser && !authUser.acceptedPrivacyPolicy) {
          this.logger.debug('acceptedPrivacyPolicy: ', authUser.acceptedPrivacyPolicy)
          this.handleAcceptPrivacyPolicyPrompt(authUser)
        } else if (this.user && this.user.showExpertisePrompt && this.authService.isEmailVerified()) {
          this.handleExpertisePrompt()
        }
      })

    /*
     * display all the profile user messages
     */
    this.profileUserService.profileUserMessageChanges.subscribe((messages: ProfileUserMessage[]) => {
      if (messages) {
        messages.forEach((message: ProfileUserMessage) => {
          const messageBuilder: NotificationMessage = this.notificationCenter
            .builder()
            .setSeverity(Enums.fromString(NotificationSeverity, message.severity))
            .setKey('PROFILE_USER_NOTIFICATIONS.' + message.key, message.params)

          const route = KEY_TO_ROUTE[message.linkKey] || null
          if (route) {
            messageBuilder.setRouteKey('PROFILE_USER_NOTIFICATIONS.LINKS.' + message.linkKey).setRoute(route)
          }

          const fragment = KEY_TO_FRAGMENT[message.linkKey] || null
          if (fragment) {
            messageBuilder.setRouteFragment(fragment)
          }

          // special messages additional config
          if (message.key === PUBLIC_CLOSING_NOTIFICATION_KEY) {
            messageBuilder.setClickToClose(true).setSticky(true)
          }

          messageBuilder.show()
        })
      }
    })

    /*
     *  display «verify your email» message
     */
    const firstNavigationEnd$ = this.router.events.pipe(filter(event => event instanceof NavigationEnd), first())
    // combine latest with firstNavigationEnd$ to not check too early (=>route)
    combineLatest(this.authService.isEmailVerifiedChanges, firstNavigationEnd$)
      .pipe(map(combined => combined[0]))
      .subscribe(verified => {
        if (verified === true) {
          this.removeVerifyEmail()
        } else if (
          verified === false &&
          !ROUTES_WITHOUT_VERIFY_MESSAGE.some(menuItem => this.router.isActive(menuItem.path, false))
        ) {
          // only display if not on certain route upon navigation
          this.logger.debug('displayVerify inside header called')
          this.displayVerifyEmail()
        }
      })
  }

  displayVerifyEmail() {
    const obs: Observable<NotificationCallbackType> = this.notificationCenter
      .builder()
      .setId(AppService.NOTIFICATION_ID_VERIFY_EMAIL)
      .setSeverity(NotificationSeverity.WARNING)
      .setDuration(10000)
      .setKey('HEADER.NOT_VERIFIED_NOTIFICATION.TEXT', { email: this.authService.authUser!.sub })
      .setPrimaryKey('HEADER.NOT_VERIFIED_NOTIFICATION.BTN_PRIMARY')
      .setSecondaryKey('HEADER.NOT_VERIFIED_NOTIFICATION.BTN_SECONDARY')
      .show()

    this.handleVerificationNotificationCallback(obs)
  }

  displayVerifyEmailSticky() {
    this.notificationCenter.remove(AppService.NOTIFICATION_ID_VERIFY_EMAIL)
    const obs: Observable<NotificationCallbackType> = this.notificationCenter
      .builder()
      .setId(AppService.NOTIFICATION_ID_VERIFY_EMAIL_STICKY)
      .setSeverity(NotificationSeverity.WARNING)
      .setSticky(true)
      .setKey('HEADER.NOT_VERIFIED_NOTIFICATION.TEXT_STICKY', { email: this.authService.authUser!.sub })
      .setSecondaryKey('HEADER.NOT_VERIFIED_NOTIFICATION.BTN_SECONDARY')
      .show()

    this.handleVerificationNotificationCallback(obs)
  }

  removeVerifyEmailSticky() {
    this.notificationCenter.remove(AppService.NOTIFICATION_ID_VERIFY_EMAIL_STICKY)
  }

  removeVerifyEmail() {
    this.notificationCenter.remove(AppService.NOTIFICATION_ID_VERIFY_EMAIL)
  }

  private handleVerificationNotificationCallback(obs: Observable<NotificationCallbackType>): void {
    obs.subscribe((callbackType: NotificationCallbackType) => {
      switch (callbackType) {
        case NotificationCallbackType.PRIMARY:
          // change email
          this.logger.debug('change email')
          this.router.navigate(['/profile/settings'], { fragment: 'changeemail' })
          break
        case NotificationCallbackType.SECONDARY:
          // resend link
          this.authService.resendVerifyEmail().subscribe(response => this.logger.debug('verification email was sent'))
          break
        default:
          this.logger.debug('handleVerificationNotificationCallback() default -> do nothing')
      }
    })
  }

  private handleExpertisePrompt(): void {
    this.modalService
      .open<EditExpertisesModalComponent, Expertise[]>(EditExpertisesModalComponent, {
        data: { isPrompt: true, expertises: this.user.expertises },
      })
      .afterClosed.subscribe((data: Expertise[]) => {
        if (Array.isArray(data) && data.length) {
          this.user.expertises = data
          this.profileUserService.updateExpertises(this.user).subscribe(profileUser => {
            this.user = profileUser
            this.notificationCenter
              .builder()
              .setDuration(2000)
              .setFacet('compact')
              .setKey('PROFILE_SETTINGS.GENERAL.SAVED')
              .setClickToClose(false)
              .setShowProgressBar(false)
              .success()
              .show()
          }, this.appHttp.handle400)
        } else {
          this.profileUserService.postponeExpertisePrompt().subscribe(profileUser => {
            this.user = profileUser
          }, this.appHttp.handle400)
        }
      })
  }

  private handleAcceptPrivacyPolicyPrompt(authUser: AuthUser): void {
    this.privacyPolicyService
      .open({ useCase: 'login', customerName: authUser.organization.name })
      .subscribe((accepted: boolean) => {
        if (accepted) {
          if (this.user && this.user.showExpertisePrompt && this.authService.isEmailVerified()) {
            this.profileUserService.postponeExpertisePrompt().subscribe(profileUser => {
              this.user = profileUser
            }, this.appHttp.handle400)
          }

          this.notificationCenter
            .builder()
            .setDuration(2000)
            .setFacet('compact')
            .setKey('PROFILE_SETTINGS.GENERAL.SAVED')
            .setClickToClose(false)
            .setShowProgressBar(false)
            .success()
            .show()
        } else {
          this.authService.logout().subscribe(() => {
            this.notificationCenter.warn('PRIVACY_POLICY.NOTIFICATION')
          }, this.appHttp.handle400)
        }
      })
  }
}
