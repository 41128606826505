import { Component, Inject, OnInit } from '@angular/core'
import { Modal2, MODAL2_DATA, Modal2Ref } from '@maprix/components'
import { Logger, LoggerService } from '@maprix/core'
import { remove } from 'lodash'
import { Expertise } from '../../models/shared/expertise.model'
import { ExpertiseService } from '../../services/expertise/expertise.service'
import { AppHttp } from '../../services/http/app-http.service'

export interface EditExpertisesData {
  isPrompt: boolean
  expertises: Expertise[]
}

@Component({
  selector: 'scs-edit-expertises-modal',
  templateUrl: './edit-expertises-modal.component.html',
  styleUrls: ['./edit-expertises-modal.component.scss'],
})
@Modal2({
  titleKey: 'PROFILE_SETTINGS.EXPERTISE.TITLE',
  size: 'xs'
})
export class EditExpertisesModalComponent implements OnInit {
  expertises: Expertise[] = []
  selectedExpertises: Expertise[] = []

  private logger: Logger

  constructor(
    loggerService: LoggerService,
    private expertiseService: ExpertiseService,
    private appHttp: AppHttp,
    private modal: Modal2Ref<EditExpertisesModalComponent, Expertise[]>,
    @Inject(MODAL2_DATA) public modalData: EditExpertisesData,
  ) {
    this.logger = loggerService.getInstance('EditExpertisesModalComponent')
  }

  ngOnInit() {
    this.logger.debug('init: the passed data: ', this.modalData)
    if (this.modalData && this.modalData.expertises && this.modalData.expertises.length) {
      this.modalData.expertises.forEach((e: Expertise) => {
        this.selectedExpertises.push(e)
      })
    }
    this.expertiseService.getAll().subscribe((response: Expertise[]) => {
      this.expertises = response
    }, this.appHttp.handle400)
  }

  onToggleExpertiseSelection(expertise: Expertise, checked: boolean) {
    if (checked) {
      if (this.selectedExpertises === undefined || this.selectedExpertises === null) {
        this.selectedExpertises = []
      }
      this.selectedExpertises.push(expertise)
    } else {
      remove(this.selectedExpertises, e => e.id === expertise.id)
    }
  }

  isExpertiseChecked(expertise: Expertise): boolean {
    const index: number = this.selectedExpertises.findIndex(e => e.id === expertise.id)
    return index > -1
  }

  onSave(): void {
    this.modal.close(this.selectedExpertises)
  }
}
