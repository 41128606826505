import { EventEmitter } from '@angular/core'
import { Logger } from '@maprix/core'
import { Observable } from 'rxjs'
import { ActionType } from '../../models/action-type.enum'
import { LoginStateData } from '../../models/login-state-data'

export abstract class BaseLoginStepComponent {
  logger: Logger

  abstract data: LoginStateData
  worker$: Observable<any>

  /**
   * next must be overridden in subclass with @Output Annotation, back can be optionally defined
   * @type {EventEmitter<{}>}
   */
  back: EventEmitter<null>
  abstract next: EventEmitter<ActionType | null>

  /**
   *
   * @param logger
   */
  constructor(logger: Logger) {
    this.logger = logger
  }

  onBack(): void {
    this.back.emit(null)
  }

  onNext(actionType?: ActionType): void {
    if (actionType != null) {
      this.next.emit(actionType)
    } else {
      this.next.emit(null)
    }
  }
}
