import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { fromEvent, Observable } from 'rxjs'
import { hasTouch } from '../../helpers/utils'

@Injectable()
export class DocumentClickService {
  observable: Observable<Event>
  touchObservable: Observable<Event>

  constructor(@Inject(DOCUMENT) document: Document) {
    this.observable = <Observable<Event>>fromEvent(document, 'click')
    if (hasTouch()) {
      this.touchObservable = <Observable<Event>>fromEvent(document, 'touchstart')
    }
  }
}
