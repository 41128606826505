import { ModuleWithProviders, NgModule } from '@angular/core'
import { DragulaDirective } from './dragula.directive'
import { DragulaService } from './dragula.service'

@NgModule({
  declarations: [DragulaDirective],
  exports: [DragulaDirective],
})
export class DragulaModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: DragulaModule,
      providers: [DragulaService],
    }
  }
}
