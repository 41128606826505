import { ModuleWithProviders } from '@angular/core'
import { RouterModule } from '@angular/router'
import { LoginComponent } from './login.component'

export const routing: ModuleWithProviders = RouterModule.forChild([
  {
    path: 'login',
    component: LoginComponent,
  },
  // used for SSO
  {
    path: 'login/token',
    component: LoginComponent,
  },
  /*
     * This is route is just for communication purposes, so for example the url looping.com/login/swisscom could be published on the intranet,
     * what it does? nothing than just opening the normal login screen
     */
  {
    path: 'login/:enterprise',
    component: LoginComponent,
  },
])
