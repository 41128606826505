import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import {
  CheckboxModule,
  FormControlModule,
  IconModule,
  LoadingIndicatorModule, Modal2Module,
  StateButtonModule,
} from '@maprix/components'
import { LoopingCoreModule } from '../../looping-core.module'
import { AccordionModule } from '../../shared/components/accordion/accordion.module'
import { EmbedIframeModule } from '../../shared/components/embed-iframe/embed-iframe.module'
import { FileSelectorModule } from '../../shared/components/file-selector/file-selector.module'
import { ImageCropperModule } from '../../shared/components/image-cropper/image-cropper.module'
import { InlineMessageModule } from '../../shared/components/inline-message/inline-message.module'
import { PolaroidModule } from '../../shared/components/polaroid/polaroid.module'
import { ResourceViewerModule } from '../../shared/components/resource-viewer-modal/resource-viewer.module'
import { StepModule } from '../../shared/components/step/step.module'
import { TabModule } from '../../shared/components/tab/tab.module'
import { TagInputModule } from '../../shared/components/tag-input/tag-input-module'
import { UserGroupsModule } from '../../shared/components/user-groups/user-groups.module'
import { AutoFocusModule } from '../../shared/directives/auto-focus/auto-focus.module'
import { AutoSizeModule } from '../../shared/directives/auto-size/auto-size.module'
import { BgImageModule } from '../../shared/directives/bg-image/bg-image.module'
import { BlurOnSubmitModule } from '../../shared/directives/blur-on-submit/blur-on-submit.module'
import { FileDropModule } from '../../shared/directives/file-drop/file-drop.module'
import { ImageOrientationModule } from '../../shared/directives/image-orientation/image-orientation.module'
import { DragulaModule } from '../../shared/services/dragula/dragula.modul'
import { AudienceChoiceComponent } from './components/audience-choice.component'
import { ConfirmGuestsSubmitModalComponent } from './components/modals/confirm-guests-submit-modal.component'
import { PreviewPrototypeModalComponent } from './components/modals/preview-prototype-modal.component'
import { PublishedPrototypeModalComponent } from './components/modals/published-prototype-modal.component'
import { RestoreCachedPrototypeModalComponent } from './components/modals/restore-cached-prototype-modal.component'
import { ImageResourceUploadComponent } from './components/resource/image-resource-upload.component'
import { OEmbedResourceUploadComponent } from './components/resource/o-embed-resource-upload.component'
import { PrototypeResourceService } from './components/resource/prototype-resource.service'
import { ResourceUploadModalComponent } from './components/resource/resource-upload-modal.component'
import { WebsiteResourceUploadComponent } from './components/resource/website-resource-upload.component'
import { UploadStepAudienceComponent } from './components/wizard-steps/upload-step-audience.component'
import { UploadStepDescribeComponent } from './components/wizard-steps/upload-step-describe.component'
import { UploadStepQuestionsComponent } from './components/wizard-steps/upload-step-questions.component'
import { UploadStepResourcesComponent } from './components/wizard-steps/upload-step-resources.component'
import { UploadStepTypeComponent } from './components/wizard-steps/upload-step-type.component'
import { UploadWizardComponent } from './components/wizard-steps/upload-wizard.component'
import { UploadComponent } from './upload.component'
import { routing } from './upload.routing'

@NgModule({
  imports: [
    routing,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DragulaModule,
    IconModule,
    LoopingCoreModule,
    AccordionModule,
    FormControlModule,
    TabModule,
    InlineMessageModule,
    FileSelectorModule,
    StateButtonModule,
    EmbedIframeModule,
    PolaroidModule,
    StepModule,
    TagInputModule,
    ResourceViewerModule,
    BgImageModule,
    FileDropModule,
    CheckboxModule,
    BlurOnSubmitModule,
    ImageOrientationModule,
    AutoFocusModule,
    AutoSizeModule,
    ImageCropperModule,
    UserGroupsModule,
    LoadingIndicatorModule,
    Modal2Module,
  ],
  declarations: [
    // steps
    UploadComponent,
    UploadWizardComponent,
    UploadStepTypeComponent,
    UploadStepAudienceComponent,
    UploadStepDescribeComponent,
    UploadStepQuestionsComponent,
    UploadStepResourcesComponent,

    // general
    AudienceChoiceComponent,
    ImageResourceUploadComponent,
    OEmbedResourceUploadComponent,
    WebsiteResourceUploadComponent,

    // modal
    RestoreCachedPrototypeModalComponent,
    ResourceUploadModalComponent,
    PreviewPrototypeModalComponent,
    PublishedPrototypeModalComponent,
    ConfirmGuestsSubmitModalComponent,
  ],

  providers: [PrototypeResourceService],

  entryComponents: [
    RestoreCachedPrototypeModalComponent,
    ResourceUploadModalComponent,
    PreviewPrototypeModalComponent,
    PublishedPrototypeModalComponent,
    ConfirmGuestsSubmitModalComponent,
  ],
  // exports are for styleguide only
  exports: [],
})
export class UploadModule {}
