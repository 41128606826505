import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { LocalStorage } from '@maprix/core'
import { map, takeUntil } from 'rxjs/operators'
import { UploadPrototype } from '../../models/prototype/prototype.model'
import { StepState } from '../../models/wizard/step-state.model'
import { AuthService } from '../../services/auth/auth.service'

@Component({
  selector: 'scs-step-content',
  templateUrl: './step-content.component.html',
})
export class StepContentComponent implements OnInit {
  private static PROTOTYPE_TYPES: string[] = [
    'SIMPLE_PROTOTYPE.TITLE',
    'VARIANT_PROTOTYPE.TITLE',
    'FLOW_PROTOTYPE.TITLE',
  ]

  orgShortCut$ = this.authService.authUser != null ? this.authService.authUser.organization.name : '';

  @Input() step: StepState
  @Input() prototype: UploadPrototype
  @Input() proceedVisible: boolean | undefined
  @Output() proceed: EventEmitter<void> = new EventEmitter<void>()

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.proceedVisible = this.proceedVisible === undefined ? true : this.proceedVisible
  }

  onProceed() {
    this.proceed.emit()
  }

  getTitle(): string {
    let title: string = this.step.title
    title =
      this.prototype !== undefined && this.step.id === 1
        ? title + StepContentComponent.PROTOTYPE_TYPES[this.prototype.type!]
        : title
    return title
  }
}
