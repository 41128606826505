import { Observable, Subject } from 'rxjs'
import { Enums } from '../../../helpers/enums'
import { PagedLoader } from '../../../services/http/paged-loader'
import { Sort, SortDirection } from '../../title-bar/title-bar.component'
import { Sorting } from './sorting.model'
import { TableColumnModel } from './table-column.model'
import { TableMobileViewModel } from './table-mobile-view.model'
import { ViewToFilterConfigList } from './view-to-filter-config-list.type'

// TODO MED (REVIEW TABLE CONCEPT) fix dependency on outside model
export class TableOptions<T, K, S> {
  hasSorting = true
  sortChanges: Observable<S>
  sorting: Sorting<S>
  columnToFilterMap: ViewToFilterConfigList<K>

  private _columnsAll: TableColumnModel[] = []
  private _columns: TableColumnModel[] = []

  private _mobileViewsAll: TableMobileViewModel[] = []
  private _mobileView: TableMobileViewModel

  private _pagedLoader: PagedLoader<T, K, S> // when used with pagedLoader
  private _modelData: T[] // when used with static data

  // TODO MED (REVIEW TABLE CONCEPT) improve coupling
  // only available if the user implements the bridge between title-bar to this field by himself
  private _activeFilter: K

  private sortSubject: Subject<S> = new Subject<S>()

  constructor() {
    this.sortChanges = this.sortSubject.asObservable()
  }

  /**
   * Returns all the defined columns for the table
   */
  get columnsAll() {
    return this._columnsAll
  }

  get columns() {
    return this._columns
  }

  get mobileViewsAll() {
    return this._mobileViewsAll
  }

  get mobileView() {
    return this._mobileView
  }

  addColumn(column: TableColumnModel): void {
    this._columnsAll.push(column)
    this._columns.push(column)
  }

  addMobileView(mobileView: TableMobileViewModel) {
    this._mobileViewsAll.push(mobileView)

    // take the first mobile view by default
    if (this._mobileViewsAll.length === 1) {
      this._mobileView = mobileView
    }
  }

  onNextSort(clickedColumn: TableColumnModel): void {
    // reset all other columns to default selection
    this.columnsAll.forEach(column => {
      if (column.title !== clickedColumn.title) {
        column.sortDirection = SortDirection.NONE
      } else {
        // state transition for clicked column
        if (column.sortDirection === SortDirection.NONE) {
          column.sortDirection = SortDirection.ASC
        } else if (column.sortDirection === SortDirection.ASC) {
          column.sortDirection = SortDirection.DESC
        } else if (column.sortDirection === SortDirection.DESC) {
          column.sortDirection = SortDirection.ASC
        }

        // get the actual sorting
        const sort: Sort<S> | undefined = this.sorting.options.find(
          option => option.direction === column.sortDirection && option.columnId === column.id
        )
        if (!sort) {
          throw new Error(
            'There is not sort definition for columnId <' +
              column.id +
              '> and direction <' +
              Enums.fromNumber(SortDirection, column.sortDirection) +
              '>'
          )
        } else {
          this.sortSubject.next(sort.value)
        }
      }
    })
  }

  get activeFilter() {
    return this._activeFilter
  }

  set activeFilter(filter: K) {
    if (!this.columnToFilterMap) {
      throw new Error(
        'there was a filter change observable provided but no columnToFilterMap was defined, needs one to work'
      )
    } else {
      const activeColumns = this.columnToFilterMap.filter(f => f.filter === filter)
      if (activeColumns) {
        if (activeColumns.length > 1) {
          // TODO MED (REVIEW TABLE CONCEPT) use setter for columnToFilterMap and check already for duplicates when setting the property
          throw new Error('there is more than one column active, check if you added to columns with the same filter')
        } else {
          // filter active columns and active mobile view for current filter
          const filteredColumns = this.columnsAll.filter(column => activeColumns[0].columnIds.indexOf(column.id) !== -1)

          if (filteredColumns.length === 0) {
            throw new Error('there is no column left to display for selected filter')
          }

          this._columns = filteredColumns

          const filteredMobileView = this.mobileViewsAll.filter(
            mobileView => activeColumns[0].mobileViewId === mobileView.id
          )

          if (filteredMobileView.length === 0) {
            throw new Error('there is no mobile view defined for selected filter')
          }

          this._mobileView = filteredMobileView[0]

          this._activeFilter = filter
        }
      } else {
        throw new Error('there are no columns defined for selected filter')
      }
    }
  }

  getData(): T[] {
    if (this._pagedLoader) {
      return this._pagedLoader.modelData
    } else if (this._modelData) {
      return this._modelData
    } else {
      return []
    }
  }

  get pagedLoader() {
    return this._pagedLoader
  }

  set pagedLoader(pagedLoader) {
    if (this._modelData) {
      throw new Error("you can't use modelData and pagedLoader at the same time")
    }
    this._pagedLoader = pagedLoader
  }

  get modelData() {
    return this._modelData
  }

  set modelData(modelData) {
    if (this._pagedLoader) {
      throw new Error("you can't use modelData and pagedLoader at the same time")
    }
    this._modelData = modelData
  }
}
