import { Component, OnInit } from '@angular/core'
import { LocaleService, Logger, LoggerService } from '@maprix/core'
import { first } from 'rxjs/operators'
import { AuthUser } from '../../models/user/auth-user'
import { ChangeUserInfoDTO } from '../../models/user/change-user-info.dto'
import { AuthService } from '../../services/auth/auth.service'
import { LocaleHelper } from '../../services/locale/locale-helper'
import { LanguageSwitcherItem } from './language-switcher-item.model'

@Component({
  selector: 'scs-language-switcher',
  templateUrl: './language-switcher.component.html',
})
export class LanguageSwitcherComponent implements OnInit {
  languageSwitcherItems: LanguageSwitcherItem[]

  private logger: Logger

  constructor(loggerService: LoggerService, private localeService: LocaleService, private authService: AuthService) {
    this.logger = loggerService.getInstance('LanguageSwitcher')
  }

  ngOnInit() {
    this.languageSwitcherItems = LocaleHelper.getLanguageSwitcherItems()
  }

  isCurrentLanguage(locale: string): boolean {
    return this.localeService.currentLocale === locale
  }

  changeLanguage(locale: string): void {
    this.authService.authUserChanges.pipe(first()).subscribe((authUser: AuthUser | null) => {
      if (authUser) {
        const changeUserInfo: ChangeUserInfoDTO = {
          familyName: authUser.familyName,
          givenName: authUser.givenName,
          locale,
          zoneId: authUser.zoneId,
        }
        this.authService.changeUserInfo(changeUserInfo).subscribe((authUserUpdated: AuthUser) => {
          this.localeService.changeLocale(authUserUpdated.locale)
        })
      } else {
        // should never happen because only logged in users can change language
        this.localeService.changeLocale(locale)
      }
    })
  }
}
