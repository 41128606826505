import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { CanActivateRoleService } from './can-activate-role.service'

/**
 * checks if a user is logged in and has the orgAdmin role
 */
@Injectable()
export class CanActivateOrgAdminGuard implements CanActivate {
  constructor(private canActivateRole: CanActivateRoleService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const role = 'orgAdmin'
    const redirectTo = '/home'
    const notificationKey = 'NOTIFICATIONS.NOT_ORG_ADMIN'
    return this.canActivateRole.canActivate(route, state, { role, redirectTo, warningKey: notificationKey })
  }
}
