import { Injectable } from '@angular/core'
import { Logger, LoggerService, RuntimeConfiguration } from '@maprix/core'
import { Observable, throwError } from 'rxjs'
import { Enums } from '../../../../shared/helpers/enums'
import { UploadOEmbedResource } from '../../../../shared/models/resource/o-embed-resource.model'
import { PrototypeResourceType } from '../../../../shared/models/resource/prototype-resource-type.enum'
import { PrototypeResource } from '../../../../shared/models/resource/prototype-resource.model'
import { RESOURCE_META_DATA } from '../../../../shared/models/resource/resource-meta-data.const'
import { ResourceMetaData } from '../../../../shared/models/resource/resource-meta-data.model'
import { MicroService } from '../../../../shared/services/config/runtime-configuration/micro-service.enum'
import { AppHttpResponse } from '../../../../shared/services/http/app-http-response.model'
import { AppHttp } from '../../../../shared/services/http/app-http.service'
import { OEmbedResourceMetaData } from '../../../../shared/services/oembed/o-embed-resource-meta-data.model'
import { OEmbedType } from '../../../../shared/services/oembed/o-embed-type.enum'

@Injectable()
export class PrototypeResourceService {
  private static RESOURCE_URL_UPLOAD = 'secure/resources/'

  private logger: Logger
  private baseUrl: string

  private static findResourceMetaDataByPrototypeType(
    type: PrototypeResourceType
  ): ResourceMetaData[] | OEmbedResourceMetaData[] {
    return RESOURCE_META_DATA.filter(value => value.prototypeResourceType === type)
  }

  private static isBinary(resourceType: PrototypeResourceType): boolean {
    return resourceType === PrototypeResourceType.IMAGE || resourceType === PrototypeResourceType.URL
  }

  static normalizeUrl(url: string): string {
    const matchingUrl = url.toLowerCase()
    if (matchingUrl.startsWith('http://') || matchingUrl.startsWith('https://')) {
      return url
    } else {
      return `https://${url}`
    }
  }

  constructor(loggerService: LoggerService, runtimeConfiguration: RuntimeConfiguration, private appHttp: AppHttp) {
    this.logger = loggerService.getInstance('ResourceUploadService')
    this.baseUrl = runtimeConfiguration.getUrlForRestService(MicroService.MAIN)
  }

  getValidationRegexForUrl(type: PrototypeResourceType, oEmbedType?: OEmbedType): RegExp {
    let metaData: ResourceMetaData[] = PrototypeResourceService.findResourceMetaDataByPrototypeType(type)

    // filter based on oembed type
    if (oEmbedType !== undefined) {
      metaData = (<OEmbedResourceMetaData[]>metaData).filter(value => value.oEmbedType === oEmbedType)
    }

    const combinedRegex = metaData.reduce((previous: string, current: ResourceMetaData, currentIndex: number) => {
      if (currentIndex === 0) {
        // first element
        return `${current.urlRegexp.source}`
      }
      return `${previous}|${current.urlRegexp.source}`
    }, '')

    this.logger.debug('Regex for Url validation: %s', combinedRegex)

    return new RegExp(combinedRegex)
  }

  uploadNonBinaryResource(resource: UploadOEmbedResource): Observable<AppHttpResponse<PrototypeResource>> {
    if (!PrototypeResourceService.isBinary(resource.type)) {
      const resourceTypePart = Enums.fromNumber(PrototypeResourceType, resource.type).toLowerCase()
      const url = `${this.baseUrl}${PrototypeResourceService.RESOURCE_URL_UPLOAD}${resourceTypePart}`
      return this.appHttp.post<PrototypeResource>(url, resource)
    }

    return throwError(new Error('Cannot upload binary resource'))
  }
}
