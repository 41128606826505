import { animate, query, stagger, style, transition, trigger } from '@angular/animations'
import { DEFAULT_ANIMATION_DURATION } from './config.animations'

export const tableRowStaggerAnimation = trigger('tableRowStaggerAnimation', [
  transition('* => *', [
    // each time the binding value changes
    query(
      ':leave',
      [stagger(100, [animate(DEFAULT_ANIMATION_DURATION + 'ms ease-in-out', style({ opacity: 0, height: 0 }))])],
      { optional: true }
    ),
    query(
      ':enter',
      [
        style({ opacity: 0, height: 0 }),
        stagger(100, [animate(DEFAULT_ANIMATION_DURATION + 'ms ease-in-out', style({ opacity: 1, height: '*' }))]),
      ],
      { optional: true }
    ),
  ]),
])
