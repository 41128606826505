import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { LoggerService, ScrollToService } from '@maprix/core'
import { Observable } from 'rxjs'
import { Regex } from '../../../shared/helpers/regex'
import { HttpError } from '../../../shared/models/http/http-error'
import { ActionType } from '../models/action-type.enum'
import { LoginStateData } from '../models/login-state-data'
import { ResetableLoginStepWithFormComponent } from './base/resetable-login-step-with-form.component'

@Component({
  selector: 'scs-register-ent',
  templateUrl: 'register-ent.component.html',
})
export class RegisterEntComponent extends ResetableLoginStepWithFormComponent implements OnInit {
  @Input() data: LoginStateData
  @Input() worker$: Observable<any>

  @Input() trustedTermsHtml: string

  // two way binding for error
  @Input() error: HttpError | string
  @Output() errorChange: EventEmitter<HttpError | string> = new EventEmitter<HttpError | string>()

  @Output() back: EventEmitter<null> = new EventEmitter<null>()
  @Output() next: EventEmitter<null | ActionType> = new EventEmitter<null | ActionType>()

  constructor(loggerService: LoggerService, formBuilder: FormBuilder, private scrollToService: ScrollToService) {
    super(loggerService.getInstance('RegisterEnt'), formBuilder)
  }

  ngOnInit() {
    super.ngOnInit()
    this.form.valueChanges.subscribe(() => {
      this.resetError()
    })

    this.scrollToService.scrollTo()
  }

  buildForm(): FormGroup {
    return this.formBuilder.group({
      firstName: [this.data.loginData.givenName, Validators.required],
      lastName: [this.data.loginData.familyName, Validators.required],
      password: [
        this.data.loginData.tokenOrPassword,
        Validators.compose([Validators.required, Validators.pattern(Regex.PATTERN_PASSWORD)]),
      ],
    })
  }

  fillViewDataToModel(): void {
    this.data.loginData.givenName = this.form.get('firstName').value
    this.data.loginData.familyName = this.form.get('lastName').value
    this.data.loginData.tokenOrPassword = this.form.get('password').value
  }

  startEdit(file: File): void {
    this.logger.debug('start profile image edit', file)
    this.fillViewDataToModel()
    this.data.loginData._selectedImage = file
    this.next.emit(ActionType.PROFILE_PICTURE)
  }
}
