import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TabContentDirective } from './tab-content.directive'
import { TabHeaderDirective } from './tab-header.directive'
import { TabSetComponent } from './tab-set.component'
import { TabDirective } from './tab.directive'

@NgModule({
  imports: [CommonModule],
  declarations: [TabHeaderDirective, TabContentDirective, TabDirective, TabSetComponent],
  exports: [TabHeaderDirective, TabContentDirective, TabDirective, TabSetComponent],
})
export class TabModule {}
