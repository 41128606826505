import { Injectable } from '@angular/core'
import { Logger, LoggerService, TranslateService } from '@maprix/core'
import * as moment from 'moment'
import { Observable } from 'rxjs'
import { map, share } from 'rxjs/operators'
import { SimplePrototype } from '../../models/prototype/prototype.model'

interface PrototypeDates {
  now: moment.Moment
  createTime: moment.Moment
  endTime: moment.Moment
  fullDuration: moment.Duration
  passedDuration: moment.Duration
  remainingDuration: moment.Duration
}

interface MomentTranslations {
  today: string
  yesterday: string
  now: string
  days: string
  hours: string
  hour: string
  short: string
}

@Injectable()
export class MomentService {
  private logger: Logger

  private static mapToCalendarConfig(translations: MomentTranslations): moment.CalendarSpec {
    return {
      sameDay: '[' + translations.today + ']',
      lastDay: '[' + translations.yesterday + ']',
      lastWeek: 'L',
      nextDay: 'L',
      nextWeek: 'L',
      sameElse: 'L',
    }
  }

  constructor(loggerService: LoggerService, private translateService: TranslateService) {
    this.logger = loggerService.getInstance('MomentService')
  }

  getHumanizedTime(date: moment.Moment): Observable<string> {
    return this.getCalendarConfig().pipe(map(config => date.calendar(moment(), config)))
  }

  getHumanizedCreateTime(prototype: SimplePrototype): Observable<string> {
    const dates: PrototypeDates = this.analyzeDates(prototype)
    if (Math.abs(dates.passedDuration.asMinutes()) < 30) {
      return this.getTranslations().pipe(map(translations => translations.now))
    } else {
      return this.getCalendarConfig().pipe(map(config => dates.createTime.calendar(moment(), config)))
    }
  }

  getProgressLabel(prototype: SimplePrototype): Observable<string> {
    const dates: PrototypeDates = this.analyzeDates(prototype)
    const remainingDays: number = Math.floor(dates.remainingDuration.asDays())
    const remainingHours: number = Math.floor(dates.remainingDuration.asHours())

    if (remainingHours > 48) {
      return this.getTranslations().pipe(map(translations => `${remainingDays} ${translations.days}`))
    } else {
      return this.getTranslations().pipe(
        map(
          translations => (remainingHours === 1 ? `1 ${translations.hour}` : `${remainingHours} ${translations.hours}`)
        )
      )
    }
  }

  getSimpleProgressLabel(prototype: SimplePrototype): Observable<string> {
    const dates: PrototypeDates = this.analyzeDates(prototype)
    const remainingHours: number = Math.floor(dates.remainingDuration.asHours())
    return this.getTranslations().pipe(
      map(translations => (remainingHours === 1 ? `1${translations.short}` : `${remainingHours}${translations.short}`))
    )
  }

  getProgressPercentage(simplePrototype: SimplePrototype): number {
    const dates: PrototypeDates = this.analyzeDates(simplePrototype)

    const fullDuration: number = dates.fullDuration.asMilliseconds()
    const passedDuration: number = dates.passedDuration.asMilliseconds()

    return Math.min(1, Math.round(passedDuration / fullDuration * 1000) / 1000) * 100
  }

  private analyzeDates(prototype: SimplePrototype): PrototypeDates {
    const analyzedDates: PrototypeDates = <PrototypeDates>{}

    analyzedDates.now = moment()
    analyzedDates.createTime = prototype.createdAtDate
    analyzedDates.endTime = prototype.endDate
    analyzedDates.fullDuration = moment.duration(analyzedDates.endTime.diff(analyzedDates.createTime))
    analyzedDates.passedDuration = moment.duration(analyzedDates.now.diff(analyzedDates.createTime))
    analyzedDates.remainingDuration = moment.duration(analyzedDates.endTime.diff(analyzedDates.now))

    return analyzedDates
  }

  private getTranslations(): Observable<MomentTranslations> {
    return this.translateService
      .get([
        'COMMONS.TODAY',
        'COMMONS.YESTERDAY',
        'COMMONS.NOW',
        'COMMONS.DAYS',
        'COMMONS.DAY',
        'COMMONS.HOURS',
        'COMMONS.HOUR',
        'COMMONS.HOUR_SHORT',
      ])
      .pipe(
        map(translations => {
          return {
            today: translations[0],
            yesterday: translations[1],
            now: translations[2],
            days: translations[3],
            day: translations[4],
            hours: translations[5],
            hour: translations[6],
            short: translations[7],
          }
        }),
        share()
      )
  }

  private getCalendarConfig(): Observable<moment.CalendarSpec> {
    return this.getTranslations().pipe(map(MomentService.mapToCalendarConfig), share())
  }
}
