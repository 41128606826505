import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { BlurOnSubmitDirective } from './blur-on-submit.directive'

@NgModule({
  imports: [CommonModule],
  declarations: [BlurOnSubmitDirective],
  exports: [BlurOnSubmitDirective],
})
export class BlurOnSubmitModule {}
