import { NgModule, Optional, SkipSelf } from '@angular/core'
import { DocumentClickService } from './document-click.service'

@NgModule({
  imports: [],
  providers: [DocumentClickService],
})
export class DocumentClickModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: DocumentClickModule
  ) {
    if (parentModule) {
      throw new Error('DocumentClickModule is already loaded. Import it only once')
    }
  }
}
