import { NgModule, Optional, SkipSelf } from '@angular/core'
import { MomentService } from './moment.service'

@NgModule({
  imports: [],
  providers: [MomentService],
})
export class MomentModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: MomentModule
  ) {
    if (parentModule) {
      throw new Error('MomentModule is already loaded. Import it only once')
    }
  }
}
