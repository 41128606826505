import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Logger, LoggerService } from '@maprix/core'
import { StepState } from '../../models/wizard/step-state.model'

@Component({
  selector: 'scs-step-continue-btn',
  templateUrl: './step-continue-btn.component.html',
  styleUrls: ['./step-continue-btn.component.scss'],
})
export class StepContinueBtnComponent {
  @Input() step: StepState
  @Output() proceed: EventEmitter<void> = new EventEmitter<void>()

  private logger: Logger

  constructor(loggerService: LoggerService) {
    this.logger = loggerService.getInstance('StepContinueBtnComponent')
  }

  onProceed(): void {
    this.proceed.emit()
  }
}
