/**
 *
 */
export enum MicroService {
  MAIN,
  AUTH,
  WEBSCREENSHOT,
  MONITORING,
  CORSPROXY,
}
