import { animate, state, style, transition, trigger } from '@angular/animations'
import { DOCUMENT } from '@angular/common'
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core'
import { FormControl, FormGroup, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms'
import { DeviceInfoService, Features, Logger, LoggerService, ScreenProperties, WindowRef } from '@maprix/core'
import { debounce, isEmpty } from 'lodash'
import { Observable, of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { Regex } from '../../helpers/regex'
import { HttpErrorCodes } from '../../models/http/http-error-codes'
import { TagInputAccessor } from './accessor'
import { AutoCompleteComponent } from './auto-complete/auto-complete.component'
import {
  ACTIONS_KEYS,
  DEBOUNCE_DELAY,
  DUPLICATE_KEY,
  KEY_PRESS_ACTIONS,
  KEYDOWN,
  KEYUP,
  MAX_ITEMS_KEY,
  MIN_LENGTH_AUTOCOMPLETE,
  REQUIRED_KEY,
} from './constants'
import { InputManager } from './input-manager.model'
import { TagInputError } from './tag-input-error.model'
import { TagInputHttpError } from './tag-input-http-error.model'

const ACCORDION_ANIMATION_DURATION = 200

/**
 * A component for entering a list of terms to be used with ngModel.
 * You need to provide a service extending abstract TagsInputService to make it work!!
 */
@Component({
  selector: 'scs-tag-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagInputComponent),
      multi: true,
    },
  ],
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss'],
  animations: [
    trigger('scsFormControlState', [
      state(
        'active',
        style({
          height: '*',
          opacity: '1',
        })
      ),
      transition('void => *', [
        style({
          height: '0',
          opacity: '0',
        }),
        animate(ACCORDION_ANIMATION_DURATION + 'ms ease'),
      ]),
      transition('* => void', [
        animate(
          ACCORDION_ANIMATION_DURATION + 'ms ease',
          style({
            height: '0',
            opacity: '0',
          })
        ),
      ]),
    ]),
  ],
})
export class TagInputComponent extends TagInputAccessor implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @Input() separatorKeys: number[] = [188, 186, 32] // , ; space
  @Input() placeholder = 'define a placeholder'
  @Input() secondaryPlaceholder = 'define a secondary placeholder'
  @Input() maxItems: number | undefined | null
  @Input() readonly: boolean | undefined
  @Input() validators: ValidatorFn[] = []
  @Input() pasteSplitPattern: RegExp = /,|;|\s/
  @Input() labelTranslate: string | undefined
  @Input() maxItemsTranslate: string = MAX_ITEMS_KEY
  @Input() duplicateTranslate: string = DUPLICATE_KEY
  @Input() requiredTranslate: string = REQUIRED_KEY
  @Input() validate: (item: any) => Observable<any>

  // autoComplete related inputs
  @Input() hasAutoComplete: boolean
  @Input() onlyFromAutoComplete: boolean
  @Input() displayProperty: string
  @Input() loadData: (item: string) => Observable<any[]>
  // debounce delay for loadData of autoComplete. default is 250 ms
  @Input() debounceDelay: number = DEBOUNCE_DELAY
  @Input() minLength: number = MIN_LENGTH_AUTOCOMPLETE

  @Output() add = new EventEmitter<any>()
  @Output() remove = new EventEmitter<any>()
  @Output() select = new EventEmitter<string | null>()
  @Output() maximumItemsReached = new EventEmitter<any>()

  @ViewChild(AutoCompleteComponent) autoCompleteCmp: AutoCompleteComponent
  @ViewChild('tagInputElement') tagInputElement: ElementRef

  @HostBinding('class.is-mobile')
  get isMobileClass() {
    return this.isActive
  }

  autoCompleteItems: any[]
  selectedTag: string | null
  isActive = false
  loading = false
  loadingFromPaste = false
  errorMessages: TagInputError[] = []
  input: InputManager
  form: FormGroup
  screenXS = false

  /* tslint:disable */
  private listeners = {
    [KEYDOWN]: <{ (fun): any }[]>[],
    [KEYUP]: <{ (fun): any }[]>[],
    change: <{ (fun): any }[]>[],
  }
  /* tslint:enable */

  private logger: Logger
  private originalItemsFromAutoComplete: any[] = []
  private tagElements: HTMLDivElement[]
  private hasTouch = false
  private bodyEl: HTMLElement
  private bodyScrollTop = 0
  private debouncedScroll: () => void
  private listenGlobalFn: () => void
  private window: Window

  constructor(
    loggerService: LoggerService,
    deviceInfo: DeviceInfoService,
    @Inject(DOCUMENT) document: Document,
    windowRef: WindowRef,
    private element: ElementRef,
    private renderer: Renderer2
  ) {
    super()
    this.logger = loggerService.getInstance('TagInputComponent')
    this.bodyEl = document.body
    this.bodyScrollTop = this.bodyEl.scrollTop
    this.debouncedScroll = debounce(this.onScroll, 150)
    this.window = windowRef.nativeWindow

    deviceInfo.featureChanges.subscribe((features: Features) => {
      this.hasTouch = features.touch
    })

    deviceInfo.screenChanges.subscribe((screenProperties: ScreenProperties) => {
      this.screenXS = screenProperties.width === 'xs'
    })

    this.listenGlobalFn = this.renderer.listen('window', 'scroll', this.debouncedScroll)

    // init the inputManager -> is public and could be accessed from outside to handle focus, blur etc.
    this.input = {
      element: null,
      isFocused: false,
      // methods
      focus: this.focusInput,
      blur: this.blurInput,
    }
  }

  ngOnInit() {
    // default values for inputs which could evaluate to undefined and overwrite default when using @Input() varname: boolean = false;
    this.hasAutoComplete = this.hasAutoComplete ? this.hasAutoComplete : false
    this.onlyFromAutoComplete = this.onlyFromAutoComplete ? this.onlyFromAutoComplete : false
    this.readonly = this.readonly ? this.readonly : false

    // setting up the keypress listeners for the actual input element
    this.addListener.call(this, KEYDOWN, this.backSpaceListener)
    this.addListener.call(this, KEYDOWN, this.customSeparatorKeys, this.separatorKeys.length)

    // if the number of items specified in the model is > of the value of maxItems
    if (this.maxItemsReached) {
      this.maximumItemsReached.emit('Max items reached')
    }

    // creating form
    this.form = new FormGroup({
      item: new FormControl('', Validators.compose(this.validators)),
    })
  }

  ngAfterViewChecked() {
    this.input.element = this.input.element || this.element.nativeElement.querySelector('input')
    this.tagElements = this.element.nativeElement.querySelectorAll('.tag')
  }

  ngAfterViewInit() {
    if (this.hasAutoComplete) {
      this.autoCompleteCmp.addItem.subscribe(this.onAutocompleteItemClicked.bind(this))
      this.autoCompleteCmp.releaseFocus.subscribe(this.focus.bind(this))
      this.addListener.call(this, KEYDOWN, this.showAndSelectAutoComplete, this.autoCompleteCmp)
      this.addListener.call(this, KEYDOWN, debounce(this.triggerLoadData, this.debounceDelay), this.autoCompleteCmp)
    }
  }

  ngOnDestroy() {
    this.listenGlobalFn()
  }

  reset() {
    this.errorMessages.length = 0
    this.originalItemsFromAutoComplete.length = 0
  }

  /**
   * @name addItem
   * @desc adds the current text model to the items array
   */
  addItem(isFromAutocomplete = false, originalItem?: any): void {
    if (this.hasAutoComplete && !isFromAutocomplete && this.onlyFromAutoComplete) {
      this.logger.debug('tags can only be added from auto complete..')
      this.scrollToBottom()
      return
    }

    // update form value with the transformed item
    let item: string
    if (!isFromAutocomplete) {
      this.setInputValue(this.form.value.item).subscribe((response: any) => {
        const validatedItem: any = response
        if (this.displayProperty && validatedItem[this.displayProperty]) {
          originalItem = validatedItem
          item = validatedItem[this.displayProperty]
          this.addItemHelper(item, originalItem, true)
        } else {
          item = validatedItem
          this.addItemHelper(item, originalItem, isFromAutocomplete)
        }
      })
    } else {
      item = this.form.value.item
      this.addItemHelper(item, originalItem, isFromAutocomplete)
    }
    this.scrollToBottom()
  }

  /**
   * @name removeItem
   * @desc removes an item from the array of the model
   * @param item {string}
   */
  removeItem(item: string): void {
    this.items = this.items.filter(i => i !== item).slice(0)
    let originalItem: any

    // remove original item if set
    if (this.hasAutoComplete && this.originalItemsFromAutoComplete.length) {
      this.originalItemsFromAutoComplete = this.originalItemsFromAutoComplete
        .filter(origItem => {
          if (origItem[this.displayProperty] === item) {
            originalItem = origItem
          }
          return origItem[this.displayProperty] !== item
        })
        .slice(0)
    }

    // if the removed tag was selected, set it as undefined
    if (this.selectedTag === item) {
      this.selectedTag = null
    }

    // focus input right after removing an item
    this.focus()
    this.scrollToBottom()

    // emit remove event
    if (!originalItem) {
      this.remove.emit(item)
    } else {
      this.remove.emit(originalItem)
    }
  }

  /**
   * @name selectItem
   * @desc selects item passed as parameter as the selected tag
   * @param item
   */
  selectItem(item: string | null, $event: Event | null): void {
    if ($event) {
      $event.stopPropagation()
    }
    if (this.readonly) {
      const el: HTMLDivElement = this.element.nativeElement
      // NOTE RENDERER2 invokeElementMethod was removed in Renderer2 -> we use native element method for now
      // this.renderer.invokeElementMethod(el, FOCUS, []);
      el.focus()
      return
    } else if (this.hasTouch) {
      // NOTE RENDERER2 invokeElementMethod was removed in Renderer2 -> we use native element method for now
      // this.renderer.invokeElementMethod(this.input.element, 'focus', []);
      this.input.element.focus()
      this.input.isFocused = true
    }

    this.selectedTag = item

    // emit event
    this.select.emit(item)
  }

  /**
   * @name fireEvents
   * @desc goes through the list of the events for a given eventName, and fires each of them
   * @param eventName
   * @param $event
   */
  fireEvents(eventName: string, $event?): void {
    this.listeners[eventName].forEach(listener => listener.call(this, $event))
  }

  /**
   * @name handleKeydown
   * @desc handles action when the user hits a keyboard key
   * @param $event
   * @param item
   */
  handleKeydown($event, item: string): void {
    const action = this.getAction($event.keyCode || $event.which)
    const itemIndex = this.items.indexOf(item)

    // call action
    action.call(this, itemIndex)
    // prevent default behaviour
    $event.preventDefault()
  }

  focus(): void {
    if (this.readonly) {
      return
    }
    if (!this.isActive) {
      if (this.screenXS && this.hasTouch) {
        this.bodyScrollTop = this.bodyEl.scrollTop
      }
      this.isActive = true
    }
    if (this.autoCompleteCmp && this.autoCompleteCmp.isOpen) {
      this.autoCompleteCmp.removeFocus()
    }
    this.input.focus.call(this)
  }

  blur(): void {
    this.input.blur.call(this)
    if (
      !this.readonly &&
      !this.form.valid &&
      !this.items.length &&
      (this.autoCompleteCmp && !this.autoCompleteCmp.isOpen)
    ) {
      const errorObject: TagInputError = {
        key: this.requiredTranslate,
      }
      this.errorMessages.length = 0
      this.errorMessages.push(errorObject)
    }
  }

  onInputPaste(event: ClipboardEvent): void {
    if (!this.onlyFromAutoComplete) {
      // ie11 does not have paste event (ClipboardEvent)
      const data: string = event.clipboardData
        ? event.clipboardData.getData('text/plain')
        : this.window['clipboardData'].getData('Text')
      const tags: string[] = data.split(this.pasteSplitPattern)
      if (tags.length) {
        this.loadingFromPaste = true
        let count = 0
        tags.forEach((tag, index: number) => {
          if (tag !== undefined && !isEmpty(tag)) {
            this.setInputValue(tag).subscribe((response: any) => {
              const item: any = response
              if (this.displayProperty && item[this.displayProperty]) {
                this.addItem(true, item)
              } else {
                this.addItem(true, typeof item === 'string' ? item : null)
              }
              // finish loading when all tags have been processed
              if (count !== tags.length - 1) {
                count++
              } else {
                this.loadingFromPaste = false
              }
            })
          } else {
            // finish loading when all tags have been processed
            if (count !== tags.length - 1) {
              count++
            } else {
              this.loadingFromPaste = false
            }
          }
        })
        event.preventDefault()
      }
    }
  }

  private addItemHelper(item: string, originalItem: any, isFromAutocomplete: boolean): void {
    if (item && item !== '') {
      // check if the transformed item is already existing in the list
      const itemsLowerCased: string[] = []
      this.items.forEach((i: string) => {
        itemsLowerCased.push(i.toLocaleLowerCase())
      })
      const isDupe: boolean = itemsLowerCased.indexOf(item.toLocaleLowerCase()) !== -1
      if (isDupe) {
        const errorObject: TagInputError = {
          key: this.duplicateTranslate,
          values: { item },
        }
        this.errorMessages.push(errorObject)
      }

      const maxItemsReached: boolean = this.maxItemsReached
      if (maxItemsReached) {
        this.maximumItemsReached.emit('Max items reached')
      }

      // check validity:
      // 1. form must be valid
      // 2. there must be no dupe
      // 3. check max items has not been reached
      // 4. check item comes from autocomplete
      // 5. or onlyFromAutoComplete is false
      const isValid: boolean =
        this.form.valid &&
        isDupe === false &&
        !maxItemsReached &&
        ((isFromAutocomplete && this.onlyFromAutoComplete === true) || this.onlyFromAutoComplete === false)

      // if valid:
      if (isValid) {
        // append item to the ngModel list
        this.items = this.items.concat([item])
        if (this.maxItemsReached) {
          this.maximumItemsReached.emit('Max items reached')
        }

        //  and emit event
        if (isFromAutocomplete && originalItem) {
          this.originalItemsFromAutoComplete.push(originalItem)
          this.add.emit(originalItem)
        } else {
          this.add.emit(item)
        }
      }
    }

    // reset control & autoComplete
    this.getControl().setValue('')
    if (this.autoCompleteCmp && this.autoCompleteCmp.isOpen) {
      this.autoCompleteItems = []
    }
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      const elem: HTMLElement = this.tagInputElement.nativeElement
      elem.scrollTop = elem.scrollHeight
    }, 0)
  }

  private setInputValue(value: string, originalItem?: any): Observable<any> {
    let item: any
    let itemToValidate: any

    if (originalItem) {
      itemToValidate = originalItem
    } else if (value !== undefined && !isEmpty(value)) {
      itemToValidate = value
    } else {
      item = ''
    }

    if (itemToValidate !== undefined) {
      if (this.validate !== undefined) {
        this.loading = true
        return this.validate(itemToValidate).pipe(
          map((response: any) => {
            item = response
            this.setFormControlValue(item)
            this.loading = false
            return item === false ? '' : item
          }),
          tap(
            () => {},
            (error: any) => {
              let translateValue: string = itemToValidate
              if (this.displayProperty && itemToValidate[this.displayProperty]) {
                translateValue = itemToValidate[this.displayProperty]
              }
              if (error instanceof TagInputHttpError) {
                this.errorMessages.push(error.tagInputError)
              } else if (error && error.key !== undefined) {
                // TagInputError
                this.errorMessages.push(error)
              } else if (error && error.code !== undefined) {
                // HttpError from backend
                this.errorMessages.push({
                  key: HttpErrorCodes.getKeyForCode(error.code),
                  values: { email: translateValue },
                })
              }
              this.loading = false
              this.setFormControlValue('')
            }
          ),
          catchError((err: any, caught: Observable<any>): Observable<any> => {
            return of('')
          })
        )
      } else if (this.onlyFromAutoComplete && originalItem) {
        this.setFormControlValue(itemToValidate)
        return of(itemToValidate)
      } else {
        this.logger.error('You have not passed in an validate function returning an observable.')
        return of('')
      }
    } else {
      this.setFormControlValue(item)
      return of(item)
    }
  }

  private setFormControlValue(item: any) {
    let stringToAdd: string | undefined
    if (this.displayProperty && item[this.displayProperty]) {
      stringToAdd = item[this.displayProperty]
    } else if (typeof item === 'string') {
      stringToAdd = item
    }

    const control = this.getControl()

    // update form value with the transformed item
    control.setValue(stringToAdd)
  }

  private getControl(): FormControl {
    return <FormControl>this.form.get('item')
  }

  get maxItemsReached(): boolean {
    return (
      this.maxItems !== undefined &&
      this.maxItems !== null &&
      this.items !== undefined &&
      this.items.length >= this.maxItems
    )
  }

  private onScroll = (): void => {
    this.handleBodyScrollTop()
  }

  private handleBodyScrollTop(): void {
    if (this.hasTouch && this.screenXS && this.isActive) {
      this.renderer.setProperty(this.bodyEl, 'scrollTop', 0)
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // ----------------------------- EVENT ACTIONS FOR TAG INPUT -------------------------------------------
  // -----------------------------------------------------------------------------------------------------

  private customSeparatorKeys = ($event): void => {
    if (this.separatorKeys.indexOf($event.keyCode) >= 0) {
      // separator keys will only be considered if entered text is a valid email
      // (if it has a autocomplete to be able to  enter spaces when searching for a name)
      // or if there is no autocomplete they will trigger immediately
      // any further (external) validation will happen afterwards when trying to add the item
      if (Regex.fromPattern(Regex.PATTERN_EMAIL).test(this.form.value.item) || !this.hasAutoComplete) {
        this.logger.debug('valid email with separator added: ', this.form.value.item)
        $event.preventDefault()
        this.addItem()
      }
    }
  }

  private backSpaceListener = ($event): void => {
    const itemsLength: number = this.items.length
    const inputValue: string = this.form.get('item').value
    const isCorrectKey = $event.keyCode === 37 || $event.keyCode === 8

    if (isCorrectKey && !inputValue && itemsLength) {
      if (this.selectedTag && $event.keyCode !== 37) {
        this.removeItem(this.selectedTag)
      } else {
        this.selectItem(this.items[itemsLength - 1], null)
        if (!this.hasTouch) {
          // NOTE RENDERER2 invokeElementMethod was removed in Renderer2 -> we use native element method for now
          // this.renderer.invokeElementMethod(this.tagElements[itemsLength - 1], 'focus', []);
          this.tagElements[itemsLength - 1].focus()
        }
      }
    } else if (
      inputValue &&
      inputValue.length < this.minLength &&
      this.autoCompleteItems &&
      this.autoCompleteItems.length
    ) {
      this.autoCompleteItems = []
    }

    // clean errorMessages
    const isKeyToClean: boolean = $event.keyCode === 37 || $event.keyCode === 8 || $event.keyCode === 13
    if (inputValue.length >= 0 && !isKeyToClean) {
      // when entering an empty value (hitting enter) or navigating the tags -> clean error messages
      this.errorMessages.length = 0
    } else if (inputValue.length === 0 && isKeyToClean) {
      // default entering chars -> clean error messages
      this.errorMessages.length = 0
    }
  }

  private showAndSelectAutoComplete = ($event): void => {
    if ($event.keyCode === 40 && this.autoCompleteItems && this.autoCompleteItems[0]) {
      this.autoCompleteCmp.open()
      $event.preventDefault()
    }
  }

  private triggerLoadData = ($event): void => {
    // without timeout the formControl input value is not yet set and is still an empty string
    setTimeout(() => {
      const chars: string = this.form.get('item').value
      const isCorrectKey =
        $event.keyCode !== 37 &&
        $event.keyCode !== 39 &&
        $event.keyCode !== 38 &&
        $event.keyCode !== 40 &&
        $event.keyCode !== 13

      if (isCorrectKey && chars) {
        if (chars.length >= this.minLength) {
          this.loading = true
          if (this.loadData !== undefined) {
            this.loadData(chars).subscribe(
              (responseArray: any[]) => {
                if (responseArray && responseArray.length) {
                  this.autoCompleteItems = []
                  responseArray.forEach(i => {
                    const objectExists: boolean =
                      this.displayProperty &&
                      i[this.displayProperty] &&
                      this.items.indexOf(i[this.displayProperty]) !== -1
                    const stringExists: boolean = typeof i === 'string' && this.items.indexOf(i) !== -1
                    if (!objectExists && !stringExists) {
                      this.autoCompleteItems.push(i)
                    }
                  })
                } else {
                  // no matching items returned from loadData -> empty autoComplete items
                  this.autoCompleteItems = []
                }
                this.loading = false
              },
              (error: TagInputError) => {
                // we ignore our HttpErrors here -> will be handled when actually adding the item
                // (otherwise duplicate errors will be displayed)
                if (error && error.key !== undefined) {
                  this.errorMessages.push(error)
                }
                this.loading = false
              }
            )
          } else {
            throw new Error('You have not passed in an loadData function returning an observable.')
          }
        } else if (this.autoCompleteItems && this.autoCompleteItems.length) {
          this.autoCompleteItems = []
        }
      }
    }, 0)
  }

  private addListener = (listenerType: string, action: () => any, condition = true): void => {
    // if the event provided does not exist, throw an error
    if (!this.listeners.hasOwnProperty(listenerType)) {
      throw new Error('The event entered may be wrong')
    }

    // if a condition is present and is false, exit early
    if (!condition) {
      return
    }

    // fire listener
    this.listeners[listenerType].push(action)
  }

  private onAutocompleteItemClicked = (item: any): void => {
    if (!item) {
      return
    }

    if (this.displayProperty && item[this.displayProperty]) {
      this.setInputValue(item[this.displayProperty], item).subscribe((response: any) => {
        item = response
        this.addItem(true, item)
        this.focus()
        this.autoCompleteItems = []
      })
    } else if (typeof item === 'string') {
      this.setInputValue(item).subscribe((response: any) => {
        item = response
        this.addItem(true, item)
        this.focus()
        this.autoCompleteItems = []
      })
    } else {
      throw new Error(
        'if you do not specify the displayProperty attribute you have to use an array of strings for the autocomplete'
      )
    }
  }

  /**
   * @name focus
   * @desc focuses input element
   */
  private focusInput = (): void => {
    // exit early if the input is not visible
    if (this.input.isFocused) {
      return
    }

    this.handleBodyScrollTop()
    // set input as focused and unselect tag
    // NOTE RENDERER2 invokeElementMethod was removed in Renderer2 -> we use native element method for now
    // this.renderer.invokeElementMethod(this.input.element, 'focus', []);
    this.input.element.focus()
    this.input.isFocused = true
    this.selectItem(null, null)
  }

  /**
   * @name blur
   */
  private blurInput = (): void => {
    this.input.isFocused = false

    setTimeout(() => {
      // handle the isActive flag used for the special mobile view
      this.isActive = !!(this.selectedTag || this.input.isFocused)
      this.handleBodyScrollTop()
      if (this.screenXS && this.hasTouch) {
        this.renderer.setProperty(this.bodyEl, 'scrollTop', this.bodyScrollTop)
      }
    }, 0)
    if (this.hasTouch && this.autoCompleteCmp && this.autoCompleteCmp.isOpen) {
      this.autoCompleteCmp.close()
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // ----------------------------- KEYPRESS ACTIONS FOR TAG SELECTION ------------------------------------
  // -----------------------------------------------------------------------------------------------------

  private getAction = (KEY: number): (() => any) => {
    const ACTION_TYPE = KEY_PRESS_ACTIONS[KEY]

    let action

    switch (ACTION_TYPE) {
      case ACTIONS_KEYS.DELETE:
        action = this.deleteSelectedTag
        break
      case ACTIONS_KEYS.SWITCH_PREV:
        action = this.switchPrev
        break
      case ACTIONS_KEYS.SWITCH_NEXT:
        action = this.switchNext
        break
      case ACTIONS_KEYS.TAB:
        action = this.switchNext
        break
      default:
        return () => {}
    }

    return action
  }

  private deleteSelectedTag = (): void => {
    if (this.selectedTag) {
      this.removeItem(this.selectedTag)
    }
  }

  private switchPrev = (itemIndex): void => {
    if (itemIndex > 0) {
      const el: HTMLDivElement = this.tagElements[itemIndex - 1]
      this.selectItem(this.items[itemIndex - 1], null)
      // NOTE RENDERER2 invokeElementMethod was removed in Renderer2 -> we use native element method for now
      // this.renderer.invokeElementMethod(el, 'focus', []);
      el.focus()
    } else {
      this.focus()
    }
  }

  private switchNext = (itemIndex): void => {
    if (itemIndex < this.items.length - 1) {
      const el: HTMLDivElement = this.tagElements[itemIndex + 1]
      this.selectItem(this.items[itemIndex + 1], null)
      // NOTE RENDERER2 invokeElementMethod was removed in Renderer2 -> we use native element method for now
      // this.renderer.invokeElementMethod(el, 'focus', []);
      el.focus()
    } else {
      this.focus()
    }
  }
}
