import { NgModule, Optional, SkipSelf } from '@angular/core'
import { Modal2Module } from '@maprix/components'
import { LoginModule } from '../../../routes/login/login.module'
import { AuthService } from './auth.service'
import { CanActivateEnterpriseLoggedInGuard } from './can-activate-enterprise-logged-in.guard'
import { CanActivateLoggedInUserGuard } from './can-activate-logged-in-user.guard'
import { CanActivateLoggedOutGuard } from './can-activate-logged-out.guard'
import { CanActivateOrgAdminGuard } from './can-activate-org-admin.guard'
import { CanActivateRequestedUserNoLoginRedirectGuard } from './can-activate-requested-user-no-login-redirect.guard'
import { CanActivateRequestedUserGuard } from './can-activate-requested-user.guard'
import { CanActivateRoleService } from './can-activate-role.service'
import { CanActivateSalesAdminGuard } from './can-activate-sales-admin.guard'

@NgModule({
  imports: [LoginModule, Modal2Module],
  providers: [
    AuthService,
    CanActivateRequestedUserGuard,
    CanActivateLoggedInUserGuard,
    CanActivateOrgAdminGuard,
    CanActivateEnterpriseLoggedInGuard,
    CanActivateLoggedOutGuard,
    CanActivateRequestedUserNoLoginRedirectGuard,
    CanActivateSalesAdminGuard,
    CanActivateRoleService,
  ],
})
export class AuthModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: AuthModule
  ) {
    if (parentModule) {
      throw new Error('AuthModule is already loaded. Import it only once')
    }
  }
}
