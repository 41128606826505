import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import {
  CheckboxModule,
  ConfirmDeleteModalModule,
  FormControlModule,
  IconModule,
  Modal2Module,
  StateButtonModule,
} from '@maprix/components'
import { LoopingCoreModule } from '../../../looping-core.module'
import { BlurOnSubmitModule } from '../../directives/blur-on-submit/blur-on-submit.module'
import { AvatarModule } from '../avatar/avatar.module'
import { InlineMessageModule } from '../inline-message/inline-message.module'
import { TableModule } from '../table/table.module'
import { TagInputModule } from '../tag-input/tag-input-module'
import { CreateUserGroupModalComponent } from './modals/create-user-group-modal.component'
import { UserGroupsComponent } from './user-groups.component'

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    CheckboxModule,
    LoopingCoreModule,
    ReactiveFormsModule,
    FormsModule,
    FormControlModule,
    TagInputModule,
    InlineMessageModule,
    AvatarModule,
    TableModule,
    BlurOnSubmitModule,
    StateButtonModule,
    Modal2Module,
    ConfirmDeleteModalModule,
  ],
  declarations: [UserGroupsComponent, CreateUserGroupModalComponent],
  exports: [UserGroupsComponent, CreateUserGroupModalComponent],
  entryComponents: [CreateUserGroupModalComponent],
})
export class UserGroupsModule {}
