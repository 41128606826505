import { ContentChild, Directive, Input, OnInit } from '@angular/core'
import { TabContentDirective } from './tab-content.directive'
import { TabHeaderDirective } from './tab-header.directive'

@Directive({
  // tslint:disable-next-line
  selector: 'scs-tab',
})
export class TabDirective implements OnInit {
  @ContentChild(TabHeaderDirective) header: TabHeaderDirective
  @ContentChild(TabContentDirective) content: TabContentDirective

  @Input() title: string
  @Input() active = false
  @Input() disabled = false

  constructor() {}

  ngOnInit() {
    // tab can't be active and disabled at the same time
    if (this.active && this.disabled) {
      throw new Error('scs-tab can not be active and disabled at the same time')
    }
  }
}
