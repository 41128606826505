import { ContentChild, Directive, Input, TemplateRef } from '@angular/core'

@Directive({
  // tslint:disable-next-line
  selector: 'scs-mobile-view',
})
export class MobileViewDirective {
  @Input() id: string

  @ContentChild(TemplateRef) template: TemplateRef<any>

  constructor() {}
}
