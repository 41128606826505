import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core'
import { Logger, LoggerService } from '@maprix/core'
import { TabSetFacet } from './tab-set-facet.model'
import { TabDirective } from './tab.directive'

@Component({
  selector: 'scs-tab-set',
  templateUrl: './tab-set.component.html',
})
export class TabSetComponent implements AfterContentInit {
  @Input() facet: TabSetFacet = 'base'
  @Input() showMobileAsChips = true

  @ContentChildren(TabDirective) tabs: QueryList<TabDirective>

  activeTab: TabDirective

  private logger: Logger

  constructor(loggerService: LoggerService) {
    this.logger = loggerService.getInstance('TabSetComponent')
  }

  changeActiveTab(activeTab: TabDirective) {
    if (activeTab.disabled) {
      return
    }

    this.deactivateAll()
    this.activeTab = activeTab
    this.activeTab.active = true
  }

  ngAfterContentInit() {
    this.logger.debug('Found %i Tabs', this.tabs.length)
    this.activeTab = this.findInitialActiveTab()
    // ensure only one tab can be active anytime
    this.deactivateAll()
    this.activeTab.active = true
  }

  private findInitialActiveTab() {
    const notDisabled = this.tabs.filter(val => !val.disabled)
    const activeNotDisabled = this.tabs.filter(val => val.active)
    // should return only one tab
    if (activeNotDisabled.length === 1) {
      return activeNotDisabled[0]
    } else if (activeNotDisabled.length === 0 && notDisabled.length !== 0) {
      this.logger.debug('Active tab not explicit specified using first not disabled one')
      return notDisabled[0]
    } else {
      throw new Error(
        'Specify only one scs-tab as ACTIVE which is not DISABLED or make sure not all tabs are disabled - currently there are ' +
          activeNotDisabled.length +
          ' scs-tabs active and not disabled'
      )
    }
  }

  private deactivateAll() {
    this.tabs.forEach(tab => (tab.active = false))
  }
}
