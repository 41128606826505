import { Injectable } from '@angular/core'
import { Logger, LoggerService, RuntimeConfiguration } from '@maprix/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { AvailableExpertise } from '../../../routes/community/components/available-expertise.model'
import { Expertise } from '../../models/shared/expertise.model'
import { MicroService } from '../config/runtime-configuration/micro-service.enum'
import { AppHttpResponse } from '../http/app-http-response.model'
import { AppHttp } from '../http/app-http.service'

@Injectable()
export class ExpertiseService {
  private static URI_BASE = 'secure/expertise'
  private static URI_AVAILABLE = '/available'

  private logger: Logger
  private mainUrl: string

  constructor(loggerService: LoggerService, runtimeConfiguration: RuntimeConfiguration, private appHttp: AppHttp) {
    this.logger = loggerService.getInstance('ExpertiseService')
    this.mainUrl = runtimeConfiguration.getUrlForRestService(MicroService.MAIN)
  }

  getAvailable(): Observable<AvailableExpertise[]> {
    return this.appHttp
      .get<AvailableExpertise[]>(this.mainUrl + ExpertiseService.URI_BASE + ExpertiseService.URI_AVAILABLE)
      .pipe(
        map((response: AppHttpResponse<AvailableExpertise[]>): AvailableExpertise[] => {
          return response.body
        })
      )
  }

  getAll(): Observable<Expertise[]> {
    return this.appHttp.get<Expertise[]>(this.mainUrl + ExpertiseService.URI_BASE).pipe(
      map((response: AppHttpResponse<Expertise[]>): Expertise[] => {
        return response.body
      })
    )
  }
}
