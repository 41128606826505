import { Component, HostBinding, Inject, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Modal2, MODAL2_DATA, Modal2Ref, ScValidators } from '@maprix/components'
import { Logger, LoggerService } from '@maprix/core'
import { cloneDeep, without } from 'lodash'
import { Observable, of, throwError } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { fadeInOutAnimation } from '../../../animations/fade-in-out.animation'
import { tableRowStaggerAnimation } from '../../../animations/table-row-stagger.animation'
import { Regex } from '../../../helpers/regex'
import { HttpError } from '../../../models/http/http-error'
import { Group } from '../../../models/prototype/group.model'
import { AuthUser } from '../../../models/user/auth-user'
import { ProfileUser } from '../../../models/user/profile-user.model'
import { SearchableUser } from '../../../models/user/searchable-user.model'
import { AuthService } from '../../../services/auth/auth.service'
import { GroupService } from '../../../services/group/group.service'
import { OrganizationService } from '../../../services/organization/organization.service'
import { ProfileUserService } from '../../../services/profile-user/profile-user.service'
import { TagInputError } from '../../tag-input/tag-input-error.model'
import { TagInputComponent } from '../../tag-input/tag-input.component'

@Component({
  selector: 'scs-create-user-group-modal',
  templateUrl: './create-user-group-modal.component.html',
  styleUrls: ['./create-user-group-modal.component.scss'],
  animations: [tableRowStaggerAnimation, fadeInOutAnimation],
})
@Modal2({
  size: 'xs',
})
export class CreateUserGroupModalComponent implements OnInit {
  @ViewChild(TagInputComponent) tagInputComponent: TagInputComponent

  overallChanges = false

  group: Group
  form: FormGroup

  tags: string[] = []
  existingUsers: ProfileUser[] = []
  externalUsers: string[] = []
  guests: string[] = []
  error: HttpError | string | null
  membersCount: { count: number } | { internalCount: number; guestCount: number }
  membersCountKey: string
  changed = false

  members: Array<string | ProfileUser> = []

  private logger: Logger
  private loggedInUserEmail: string
  private orgEmailRegex: RegExp
  private isGuestFeatureActivated: boolean

  constructor(
    loggerService: LoggerService,
    private formBuilder: FormBuilder,
    private modal: Modal2Ref<CreateUserGroupModalComponent, Group>,
    private authService: AuthService,
    private profileUserService: ProfileUserService,
    private groupService: GroupService,
    private organizationService: OrganizationService,
    @Inject(MODAL2_DATA) public modalData: { group?: Group }
  ) {
    this.logger = loggerService.getInstance('EditEmailModalComponent')
  }

  ngOnInit() {
    this.authService.authUserChanges.pipe(first()).subscribe(
      (authUser: AuthUser) => {
        if (authUser && authUser.sub) {
          this.organizationService.guestFeature$.pipe(first()).subscribe(activated => {
            // set regex for validation. default email regex only when group prototype and guest feature is activated
            this.isGuestFeatureActivated = activated
            this.orgEmailRegex = Regex.fromPattern(this.authService.buildEmailPatternForOrgDomains())
            this.loggedInUserEmail = authUser.sub.toLocaleLowerCase()
            if (this.modalData.group && this.modalData.group.id) {
              this.group = cloneDeep(this.modalData.group)
              this.group.existingUsers.sort((a, b) => (a.fullName > b.fullName ? 1 : -1))
              this.group.externalUsers.sort((a, b) => (a > b ? 1 : -1))
              this.group.guests.sort((a, b) => (a > b ? 1 : -1))
              this.members = this.members
                .concat(this.group.existingUsers)
                .concat(this.group.externalUsers)
                .concat(this.group.guests)
            } else {
              this.group = { title: '', existingUsers: [], externalUsers: [], guests: [], owner: null, id: null }
            }
            this.updateMembersCountKey()
            this.createOrResetForm()
          })
        }
      },
      (error: any) => {
        throw new Error('Could not get currently logged in user, needs one to work.')
      }
    )
  }

  onSubmit(close?: boolean): void {
    this.overallChanges = true
    if (this.form.get('title').valid) {
      this.group.title = this.form.get('title').value
    }

    // create new group
    if (!this.group.id) {
      this.group.externalUsers = this.externalUsers
      this.group.existingUsers = this.existingUsers
      this.group.guests = this.guests
      this.groupService.createGroup(this.group).subscribe(
        (group: Group) => {
          if (close) {
            this.modal.close(group)
          }
        },
        (error: any) => {
          this.error = error
        }
      )
    } else {
      this.groupService.updateGroup(this.group).subscribe(
        (group: Group) => {
          if (close) {
            this.modal.close(group)
          }
        },
        (error: any) => {
          this.error = error
        }
      )
    }
  }

  getDisplayNameFromEmail(email: string): string {
    return email.charAt(0) + ' ' + email.charAt(email.indexOf('@') + 1)
  }

  // ADD GROUP USERS

  itemAdded = item => {
    this.overallChanges = true
    if (typeof item === 'string' && !this.isGuestEmail(item)) {
      this.externalUsers.push(item)
    } else if (typeof item === 'string') {
      this.guests.push(item)
    } else if (item.id) {
      this.existingUsers.push(<ProfileUser>item)
    }
  }

  itemRemoved = item => {
    this.overallChanges = true
    if (typeof item === 'string' && !this.isGuestEmail(item)) {
      this.externalUsers = without(this.externalUsers, item)
    } else if (typeof item === 'string') {
      this.guests = without(this.guests, item)
    } else if (item.id) {
      this.existingUsers = without(this.existingUsers, <ProfileUser>item)
    }
  }

  loadData = (query: string): Observable<SearchableUser[]> => {
    return this.profileUserService.search(query)
  }

  validate = (item: any): Observable<any> => {
    if (typeof item === 'string' && Regex.fromPattern(Regex.PATTERN_EMAIL).test(item)) {
      if (!this.isGuestFeatureActivated && !this.orgEmailRegex.test(item)) {
        const errorObject: TagInputError = {
          key: 'TAG_INPUT.ERROR_ORG_DOMAIN',
          values: { item },
        }
        return throwError(errorObject)
      } else if (this.loggedInUserEmail && item.toLocaleLowerCase() === this.loggedInUserEmail) {
        const errorObject: TagInputError = {
          key: 'TAG_INPUT.ERROR_INVITE_SELF',
          values: { item },
        }
        return throwError(errorObject)
      } else {
        return this.loadData(<string>item).pipe(
          map((responseArray: SearchableUser[]) => {
            if (responseArray && responseArray.length > 0) {
              responseArray.forEach((user: SearchableUser) => {
                if (user.email.toLocaleLowerCase() === item.toLocaleLowerCase()) {
                  item = user
                }
              })
            }
            return item
          })
        )
      }
    } else if (item.fullName) {
      if (
        this.loggedInUserEmail !== undefined &&
        item.email &&
        item.email.toLocaleLowerCase() === this.loggedInUserEmail
      ) {
        const errorObject: TagInputError = {
          key: 'TAG_INPUT.ERROR_INVITE_SELF',
          values: { item },
        }
        return throwError(errorObject)
      } else {
        return of(item)
      }
    } else {
      const errorObject: TagInputError = {
        key: 'TAG_INPUT.INVALID_ITEM',
        values: { item },
      }
      return throwError(errorObject)
    }
  }

  addUsersToGroup(): void {
    this.logger.debug('addUsersToGroup')
    const errors: TagInputError[] = []
    this.existingUsers.forEach(ua => {
      if (!this.group.existingUsers.find(eu => eu.id === ua.id)) {
        this.group.existingUsers.unshift(ua)
        this.members.unshift(ua)
      } else {
        errors.push({
          key: 'TAG_INPUT.DUPLICATE',
          values: { item: ua.fullName },
        })
      }
    })
    this.externalUsers.forEach(ue => {
      if (this.group.externalUsers.indexOf(ue) === -1) {
        this.group.externalUsers.unshift(ue)
        this.members.unshift(ue)
      } else {
        errors.push({
          key: 'TAG_INPUT.DUPLICATE',
          values: { item: ue },
        })
      }
    })
    this.guests.forEach(ue => {
      if (this.group.guests.indexOf(ue) === -1) {
        this.group.guests.unshift(ue)
        this.members.unshift(ue)
      } else {
        errors.push({
          key: 'TAG_INPUT.DUPLICATE',
          values: { item: ue },
        })
      }
    })
    this.updateMembersCountKey()
    this.resetTags(errors)
    if (this.group.id) {
      this.onSubmit(false)
    }
  }

  hasChanged(): boolean {
    return (this.changed && this.members.length > 0) || (this.hasNameChanged() && this.members.length > 0)
  }

  hasNameChanged(): boolean {
    return this.group && this.group.title !== this.form.get('title').value && this.form.get('title').valid
  }

  removeMember(user: string | ProfileUser): void {
    if (typeof user === 'string' && !this.isGuestEmail(user)) {
      this.group.externalUsers = without(this.group.externalUsers, user)
    } else if (typeof user === 'string') {
      this.group.guests = without(this.group.guests, user)
    } else {
      this.group.existingUsers = without(this.group.existingUsers, <ProfileUser>user)
    }
    this.members = without(this.members, user)
    this.updateMembersCountKey()
    if (this.group.id) {
      this.onSubmit(false)
    }
  }

  isGuestEmail(email: string): boolean {
    return !this.orgEmailRegex.test(email)
  }

  private resetTags(errors?: TagInputError[]): void {
    this.tags.length = 0
    this.existingUsers.length = 0
    this.externalUsers.length = 0
    this.guests.length = 0
    if (this.tagInputComponent) {
      this.tagInputComponent.reset()
      this.tagInputComponent.errorMessages = errors ? errors : []
    }
  }

  private createOrResetForm(): void {
    this.form = this.formBuilder.group({
      title: [this.group.title, Validators.compose([Validators.required, ScValidators.notEmpty])],
    })
  }

  private updateMembersCountKey() {
    if (this.group.guests.length > 0) {
      //  key for guests
      if (this.group.externalUsers.length === 0 && this.group.existingUsers.length === 0) {
        //  only guest members
        this.membersCount = { count: this.members.length }
        this.membersCountKey = 'USER_GROUP.MODAL.MEMBERS_GUESTS_COUNT'
      } else {
        const guestCount: number = this.group.guests.length
        this.membersCount = { internalCount: this.members.length - guestCount, guestCount }
        this.membersCountKey = 'USER_GROUP.MODAL.MEMBERS_INTERNAL_AND_GUESTS_COUNT'
      }
    } else {
      this.membersCount = { count: this.members.length }
      this.membersCountKey = 'USER_GROUP.MODAL.MEMBERS_COUNT'
    }
  }
}
