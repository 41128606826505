import { ControlValueAccessor } from '@angular/forms'

export class TagInputAccessor implements ControlValueAccessor {
  private _items: string[] = []

  private onTouchedCallback: (items: string[]) => void
  private onChangeCallback: (items: string[]) => void

  get items(): string[] {
    return this._items
  }

  set items(items: string[]) {
    this._items = items
    this.onChangeCallback(items)
  }

  onTouched(items) {
    this.onTouchedCallback(items)
  }

  writeValue(items: string[]) {
    this._items = items || []
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn
  }
}
