import { Injectable } from '@angular/core'
import { ClientIdService, Logger, LoggerService, RuntimeConfiguration } from '@maprix/core'
import * as moment from 'moment'
import { Observable, Subscription } from 'rxjs'
import { delay, filter, map, retryWhen, share } from 'rxjs/operators'
import { WebSocketSubject } from 'rxjs/webSocket'
import { MomentDateConverter } from '../../helpers/moment-date-converter'
import { MessageType } from '../../models/ws/message-type.enum'
import { WsMessage } from '../../models/ws/ws-message'
import { AuthService } from '../auth/auth.service'
import { MicroService } from '../config/runtime-configuration/micro-service.enum'

@Injectable()
export class WsService {
  private logger: Logger
  private wsUrl: string

  private wsSubject: WebSocketSubject<string>
  private sharedObservable: Observable<any>

  private tokenSubscription: Subscription
  private subscriptions: Subscription[] = []

  private static createMessage<T>(type: MessageType, data: T): WsMessage<T> {
    return {
      type,
      date: moment(),
      data,
    }
  }

  private static transformIncomingMessage<T>(message: string): WsMessage<T> {
    const obj = <WsMessage<T>>JSON.parse(message)
    MomentDateConverter.transformResponseBody(obj)
    return obj
  }

  private static transformOutgoingMessage(message: WsMessage<any>): string {
    MomentDateConverter.transformRequestBody(message)
    return JSON.stringify(message)
  }

  constructor(
    loggerService: LoggerService,
    private runtimeConfiguration: RuntimeConfiguration,
    private clientIdService: ClientIdService,
    private authService: AuthService
  ) {
    this.logger = loggerService.getInstance('WsService')
    this.wsUrl = this.runtimeConfiguration.getUrlForWebSocketService(MicroService.MAIN)

    this.init()
  }

  getObservableFor<T>(messageType: MessageType): Observable<WsMessage<T>> {
    return this.sharedObservable.pipe(
      map(message => WsService.transformIncomingMessage<T>(message)),
      filter(wsMessage => wsMessage.type === messageType)
    )
  }

  destroy() {
    this.tokenSubscription.unsubscribe()
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  private init() {
    // websocket subject
    this.wsSubject = WebSocketSubject.create({
      url: this.wsUrl,
      resultSelector: message => message.data,
      openObserver: {
        // login on socket open
        next: openEvent => {
          this.logger.debug(openEvent)
          if (this.tokenSubscription) {
            this.tokenSubscription.unsubscribe()
          }
          this.tokenSubscription = this.authService.authTokenChanges.subscribe(token => {
            if (token) {
              this.authenticate(token)
            } else {
              // if token is null logout
              this.logout()
            }
          })
        },
      },
    })

    // create shard observable with retry logic
    this.sharedObservable = this.wsSubject.asObservable().pipe(
      retryWhen<string>(errors => {
        this.logger.debug('retry connecting to ws')
        // retry all 3s
        return errors.pipe(delay(3000))
      }),
      share()
    )

    // subscribe to user changed to renew token (update) only sent to clients not responsible for the change
    const userSub = this.getObservableFor<string>(MessageType.USER_CHANGED).subscribe(
      message => {
        this.logger.debug('authuser changed WS')
        this.authService.renewToken()
      },
      err => {
        this.logger.warn('ignoring ws error: ', err)
      },
      () => {
        this.logger.debug('completed websocket subscription')
      }
    )

    // add to unsubscribe on destroy
    this.subscriptions.push(userSub)
  }

  private authenticate(token: string): void {
    this.logger.debug('authenticating to WS endpoint')
    const data = WsService.createMessage(MessageType.AUTHENTICATION, {
      clientId: this.clientIdService.getClientId(),
      token,
    })
    this.publishMessage(data)
  }

  private logout(): void {
    this.logger.debug('logout to WS endpoint')
    const data = WsService.createMessage(MessageType.LOGOUT, 'sutti for president')
    this.publishMessage(data)
  }

  private publishMessage(message: WsMessage<any>) {
    const publishMessage = WsService.transformOutgoingMessage(message)
    this.wsSubject.next(publishMessage)
  }
}
