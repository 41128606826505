import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { BgImageDirective } from './bg-image.directive'

@NgModule({
  imports: [CommonModule],
  declarations: [BgImageDirective],
  exports: [BgImageDirective],
})
export class BgImageModule {}
