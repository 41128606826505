import { OEmbedType } from '../../services/oembed/o-embed-type.enum'
import { OEmbedLogo } from './o-embed-logo.model'

export const O_EMBED_TYPE_LOGOS: OEmbedLogo[] = [
  {
    type: OEmbedType.VIDEO,
    logoPath: '/assets/img/resource-upload-modal/video.svg',
  },
  {
    type: OEmbedType.AUDIO,
    logoPath: '/assets/img/resource-upload-modal/audio.svg',
  },
]
