import { Injectable } from '@angular/core'
import { Logger, LoggerService, RuntimeConfiguration } from '@maprix/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { BooleanDTO } from '../../models/dto/boolean.dto'
import { AuthOrganization } from '../../models/organization/auth-organization'
import { SalesAdminRedirectDTO } from '../../models/organization/sales-admin-redirect.dto'
import { TwoFactorAuthModeDTO } from '../../models/organization/two-factor-auth-mode.dto'
import { MicroService } from '../config/runtime-configuration/micro-service.enum'
import { AppHttpResponse } from '../http/app-http-response.model'
import { AppHttp } from '../http/app-http.service'

@Injectable()
export class AuthOrganizationService {
  private static URI_BASE = 'secure/organization'

  private static URI_USER = `${AuthOrganizationService.URI_BASE}/user/`
  private static URI_DOMAIN = `${AuthOrganizationService.URI_BASE}/domain`
  private static URI_ADMIN = `${AuthOrganizationService.URI_BASE}/admin/`
  private static URI_NOTIFICATIONS = '/notifications'
  private static URI_TWO_FACTOR_AUTH = `${AuthOrganizationService.URI_BASE}/twofactorauth`
  private static URI_LOOPING_ADMIN = `${AuthOrganizationService.URI_BASE}/loopingadmin`

  private logger: Logger
  private authUrl: string

  private static URI_ORG_LOGIN_URL = orgId => `${AuthOrganizationService.URI_BASE}/${orgId}/loginurl`

  private static getBody<T>(response: AppHttpResponse<T>): T {
    return response.body
  }

  constructor(loggerService: LoggerService, runtimeConfiguration: RuntimeConfiguration, private appHttp: AppHttp) {
    this.logger = loggerService.getInstance('AuthOrganizationService')
    this.authUrl = runtimeConfiguration.getUrlForRestService(MicroService.AUTH)
  }

  getOrganizationInfo(orgId: string): Observable<AuthOrganization> {
    return this.appHttp
      .get<AuthOrganization>(`${this.authUrl}${AuthOrganizationService.URI_BASE}/${orgId}`)
      .pipe(map<AppHttpResponse<AuthOrganization>, AuthOrganization>(AuthOrganizationService.getBody))
  }

  deleteUser(userId: string): Observable<AppHttpResponse<void>> {
    return this.appHttp.delete(`${this.authUrl}${AuthOrganizationService.URI_USER}${userId}`)
  }

  addDomain(domain: string): Observable<AppHttpResponse<void>> {
    return this.appHttp.post<void>(`${this.authUrl}${AuthOrganizationService.URI_DOMAIN}`, {}, { params: { domain } })
  }

  deleteDomain(domain: string): Observable<AppHttpResponse<void>> {
    return this.appHttp.delete(`${this.authUrl}${AuthOrganizationService.URI_DOMAIN}`, { params: { domain } })
  }

  addAdmin(userId: string): Observable<AppHttpResponse<void>> {
    return this.appHttp.post<void>(`${this.authUrl}${AuthOrganizationService.URI_ADMIN}${userId}`, {})
  }

  deleteAdmin(userId: string): Observable<AppHttpResponse<void>> {
    return this.appHttp.delete(`${this.authUrl}${AuthOrganizationService.URI_ADMIN}${userId}`)
  }

  updateAdminNotifications(userId: string, receive: boolean): Observable<AppHttpResponse<void>> {
    return this.appHttp.post<void>(
      `${this.authUrl}${AuthOrganizationService.URI_ADMIN}${userId}${AuthOrganizationService.URI_NOTIFICATIONS}`,
      {},
      { params: { receive: receive.toString() } }
    )
  }

  getTwoFactorAuthMode(): Observable<TwoFactorAuthModeDTO> {
    return this.appHttp
      .get<TwoFactorAuthModeDTO>(`${this.authUrl}${AuthOrganizationService.URI_TWO_FACTOR_AUTH}`)
      .pipe(map<AppHttpResponse<TwoFactorAuthModeDTO>, TwoFactorAuthModeDTO>(AuthOrganizationService.getBody))
  }

  updateTwoFactorAuthMode(twoFactorAuthMode: TwoFactorAuthModeDTO): Observable<TwoFactorAuthModeDTO> {
    return this.appHttp
      .put<TwoFactorAuthModeDTO>(`${this.authUrl}${AuthOrganizationService.URI_TWO_FACTOR_AUTH}`, twoFactorAuthMode)
      .pipe(map<AppHttpResponse<TwoFactorAuthModeDTO>, TwoFactorAuthModeDTO>(AuthOrganizationService.getBody))
  }

  getLoopingAdminFeature(): Observable<BooleanDTO> {
    return this.appHttp
      .get<BooleanDTO>(`${this.authUrl}${AuthOrganizationService.URI_LOOPING_ADMIN}`)
      .pipe(map<AppHttpResponse<BooleanDTO>, BooleanDTO>(AuthOrganizationService.getBody))
  }

  updateLoopingAdminFeature(value: boolean): Observable<boolean> {
    return this.appHttp
      .put<boolean>(`${this.authUrl}${AuthOrganizationService.URI_LOOPING_ADMIN}`, { value })
      .pipe(map<AppHttpResponse<boolean>, boolean>(AuthOrganizationService.getBody))
  }

  getOrgLoginUrl(orgId: string): Observable<SalesAdminRedirectDTO> {
    return this.appHttp
      .get<SalesAdminRedirectDTO>(`${this.authUrl}${AuthOrganizationService.URI_ORG_LOGIN_URL(orgId)}`)
      .pipe(map<AppHttpResponse<SalesAdminRedirectDTO>, SalesAdminRedirectDTO>(AuthOrganizationService.getBody))
  }
}
