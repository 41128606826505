import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Modal2Service, NotificationCenterService } from '@maprix/components'
import { Logger, LoggerService } from '@maprix/core'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { LoginModalComponent } from '../../../routes/login/login-modal.component'
import { AuthService } from './auth.service'

/**
 * Checks if a user is logged in, if not it opens the login modal and redirects
 * to the desired route with preserved query params and fragments
 */
@Injectable()
export class CanActivateLoggedInUserGuard implements CanActivate {
  private logger: Logger

  constructor(
    loggerService: LoggerService,
    private authService: AuthService,
    private router: Router,
    private modalService: Modal2Service,
    private notificationCenter: NotificationCenterService,
  ) {
    this.logger = loggerService.getInstance('CanActivateLoggedInUserGuard')
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.authService.isLoggedIn()) {
      return of(true)
    } else {
      return this.modalService
        .open<LoginModalComponent, boolean>(LoginModalComponent)
        .afterClosed
        .pipe(
          map((loggedIn: boolean) => {
            if (loggedIn) {
              this.router.navigateByUrl(state.url, { queryParamsHandling: 'preserve', preserveFragment: true })
              return true
            } else {
              this.notificationCenter.warn('NOTIFICATIONS.NOT_LOGGED_IN')
              this.router.navigateByUrl('/login')
              return false
            }
          }),
        )
    }
  }
}
