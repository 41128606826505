import { NgModule, Optional, SkipSelf } from '@angular/core'
import { AppHttp } from './app-http.service'
import { PagedLoaderFactory } from './paged-loader-factory.service'

@NgModule({
  imports: [],
  providers: [AppHttp, PagedLoaderFactory], // additional providers
})
export class AppHttpModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: AppHttpModule
  ) {
    if (parentModule) {
      throw new Error('AppHttpModule is already loaded. Import it only once')
    }
  }
}
