import { Injectable } from '@angular/core'
import { Logger, LoggerService, RuntimeConfiguration } from '@maprix/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Enums } from '../../helpers/enums'
import { OEmbedProvider } from '../../models/resource/o-embed-provider.enum'
import { O_EMBED_RESOURCE_META_DATA } from '../../models/resource/resource-meta-data.const'
import { MicroService } from '../config/runtime-configuration/micro-service.enum'
import { AppHttpResponse } from '../http/app-http-response.model'
import { AppHttp } from '../http/app-http.service'
import { OEmbedResourceMetaData } from './o-embed-resource-meta-data.model'
import { OEmbedRichResponse } from './o-embed-rich-response.model'
import { OEmbedVideoResponse } from './o-embed-video-response.model'
import { OEMBED_PROVIDERS } from './o_embed_providers'

@Injectable()
export class OEmbedService {
  private static OEMBED_FORMAT = 'json'
  private static OEMBED_FORMAT_TAG = '{format}'

  private logger: Logger
  private corsProxyUrl: string

  private static replaceFormat(providerUrl: string): string {
    return providerUrl.replace(OEmbedService.OEMBED_FORMAT_TAG, OEmbedService.OEMBED_FORMAT)
  }

  constructor(private appHttp: AppHttp, loggerService: LoggerService, runtimeConfiguration: RuntimeConfiguration) {
    this.logger = loggerService.getInstance('OEmbedService')
    this.corsProxyUrl = `${runtimeConfiguration.getUrlForRestService(MicroService.CORSPROXY)}secure/`
  }

  getOEmbedResponse(
    resourceUrl: string,
    resourceMetaData: OEmbedResourceMetaData
  ): Observable<AppHttpResponse<OEmbedVideoResponse | OEmbedRichResponse>> {
    const providerUrl = this.getProviderUrl(Enums.fromNumber(OEmbedProvider, resourceMetaData.oEmbedProvider))
    return this.makeOEmbedCall(providerUrl, resourceUrl)
  }

  getOEmbedResourceMetaDataByUrl(url: string): OEmbedResourceMetaData | undefined {
    return O_EMBED_RESOURCE_META_DATA.find(res => res.urlRegexp.test(url))
  }

  private makeOEmbedCall(
    providerUrl: string,
    resourceUrl: string
  ): Observable<AppHttpResponse<OEmbedVideoResponse | OEmbedRichResponse>> {
    const url = `${this.corsProxyUrl}${providerUrl}?url=${resourceUrl}&format=${OEmbedService.OEMBED_FORMAT}`
    // const url = `${providerUrl}?url=${resourceUrl}&format=${OEmbedService.OEMBED_FORMAT}`
    // type argument inference not working explicit setting type to OEmbedResponse
    return this.appHttp.get<OEmbedVideoResponse | OEmbedRichResponse>(url).pipe(map(this.normalize))
  }

  private getProviderUrl(name: string): string {
    // return first matching provider
    const provider = OEMBED_PROVIDERS.filter(
      providerName => providerName.provider_name.toLowerCase() === name.toLowerCase()
    )
    if (provider.length === 1) {
      return OEmbedService.replaceFormat(provider[0].endpoints[0].url)
    } else {
      throw new Error(`Not able to find a single provider for: ${name} --> found ${provider.length} instead of 1`)
    }
  }

  private normalize(
    response: AppHttpResponse<OEmbedVideoResponse | OEmbedRichResponse>
  ): AppHttpResponse<OEmbedVideoResponse | OEmbedRichResponse> {
    // needed for none standard compliant response form mixcloud
    if (response.body.provider_name && response.body.provider_name.toLowerCase().indexOf('mixcloud') !== -1) {
      response.body.thumbnail_url = response.body.thumbnail_url || response.body['image']
    }
    return response
  }
}
