import { Injectable } from '@angular/core'
import { Logger, LoggerService } from '@maprix/core'
import { Observable } from 'rxjs'
import { AppHttp } from './app-http.service'
import { PagedLoader } from './paged-loader'

/*
 * SOME NOTES FOR IMPROVEMENTS
 * refactor to use an limit-observable (screen width dependent onWindowLoaded) in the future
 */
@Injectable()
export class PagedLoaderFactory {
  private logger: Logger

  constructor(private loggerService: LoggerService, private http: AppHttp) {
    this.logger = loggerService.getInstance('PagedLoaderFactory')
  }

  /*
   * SOME NOTES FOR IMPROVEMENTS
   * - make more flexible to get rid of all the null types and null arguments for simpler usage (see member-grid.ts for example)
   * - remove sorting queryParam from count call
   */
  createPagedLoader<T, K, S>(
    dataUrl: Observable<string>,
    searchObservable: Observable<string> | null,
    filterObservable: Observable<K> | null,
    sortObservable: Observable<S> | null,
    additionalParamsObservables: Array<Observable<{ key: string; value: string | null }>> | null,
    limit: number = 16
  ): PagedLoader<T, K, S> {
    return new PagedLoader<T, K, S>(
      this.loggerService,
      this.http,
      dataUrl,
      filterObservable,
      searchObservable,
      sortObservable,
      additionalParamsObservables,
      limit
    )
  }
}
