import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ConfirmDeleteService, Modal2Service } from '@maprix/components'
import { filterIfTruthy, Logger, LoggerService } from '@maprix/core'
import { remove, without } from 'lodash'
import { filter, first } from 'rxjs/operators'
import { fadeInOutAnimation } from '../../animations/fade-in-out.animation'
import { HttpError } from '../../models/http/http-error'
import { Group } from '../../models/prototype/group.model'
import { UploadPrototype } from '../../models/prototype/prototype.model'
import { GroupService } from '../../services/group/group.service'
import { OrganizationService } from '../../services/organization/organization.service'
import { CreateUserGroupModalComponent } from './modals/create-user-group-modal.component'

@Component({
  selector: 'scs-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.scss'],
  animations: [fadeInOutAnimation],
})
export class UserGroupsComponent implements OnInit {
  // for upload route only
  @Input() prototype: UploadPrototype
  @Input() titleTranslate: string
  @Output() revalidate: EventEmitter<void> = new EventEmitter<void>()
  @Output() saved: EventEmitter<void> = new EventEmitter<void>()

  availableGroups: Group[] = []
  isGuestFeatureActivated: boolean
  private logger: Logger

  constructor(
    loggerService: LoggerService,
    private groupService: GroupService,
    private modalService: Modal2Service,
    private deleteService: ConfirmDeleteService,
    private organizationService: OrganizationService,
  ) {
    this.logger = loggerService.getInstance('UserGroupsComponent')
  }

  ngOnInit() {
    this.organizationService.guestFeature$.pipe(first()).subscribe(activated => {
      this.isGuestFeatureActivated = activated
      this.initGroups()
    })
  }

  getGroupMemberCount(group: Group): number {
    return (
      (group.externalUsers ? group.externalUsers.length : 0) +
      (group.existingUsers ? group.existingUsers.length : 0) +
      (group.guests ? group.guests.length : 0)
    )
  }

  onToggleGroupSelection(g: Group, checked: boolean) {
    if (checked) {
      if (this.prototype.groups === undefined || this.prototype.groups === null) {
        this.prototype.groups = []
      }
      this.prototype.groups.push(g)
    } else {
      remove(this.prototype.groups, ge => ge.id === g.id)
    }
    // validate prototype
    this.revalidate.emit()
  }

  isGroupChecked(g: Group): boolean {
    const index: number = this.prototype.groups.findIndex(ge => ge.id === g.id)
    return index > -1
  }

  editGroup(g: Group): void {
    this.logger.debug('edit group: ', g)
    this.createGroup(g)
  }

  deleteGroupFn = (group: Group) => this.groupService.deleteGroup(group)

  deleteGroup(g: Group) {
    // todo: new message with messageValues (group name)
    this.deleteService.open('USER_GROUP.MODAL.DELETE_GROUP_MODAL_HINT', {}, this.deleteGroupFn, [g])
      .pipe(filterIfTruthy())
      .subscribe(() => {
        if (g && g.id) {
          // group delete -> remove from displayed
          this.availableGroups = without(this.availableGroups, g)
          if (this.prototype) {
            // remove from proto if added
            this.prototype.groups = without(this.prototype.groups, g)
            this.revalidate.emit()
          }
        }
        // emit saved to show saved indicator or message
        this.saved.emit()
      })
  }

  createGroup(g?: Group): void {
    this.modalService
      .open<CreateUserGroupModalComponent, Group>(CreateUserGroupModalComponent, {
        titleKey: g ? 'USER_GROUP.MODAL.TITLE_EDIT' : 'USER_GROUP.MODAL.TITLE_CREATE',
        data: { group: g },
      })
      .afterClosed
      .pipe(filter((data: Group) => !!data))
      .subscribe((data: Group) => {
        this.logger.debug('the group: ', data)
        if (g && g.id) {
          // edit mode
          if (data) {
            // replace from available
            let foundIndex = this.availableGroups.findIndex(ge => ge.id === g.id)
            this.availableGroups[foundIndex] = data

            if (this.prototype) {
              // replace from selected group
              foundIndex = this.prototype.groups.findIndex(ge => ge.id === g.id)
              if (foundIndex !== -1) {
                this.prototype.groups[foundIndex] = data
                this.revalidate.emit()
              }
            }
          }
        } else {
          // create mode
          this.availableGroups.push(data)
          if (this.prototype) {
            this.prototype.groups.push(data)
            this.revalidate.emit()
          }
        }
        // emit saved to show saved indicator or message
        this.saved.emit()
      })
  }

  private initGroups(): void {
    this.groupService.getAll().subscribe(
      (res: Group[]) => {
        if (res !== null) {
          this.availableGroups = res
          if (this.availableGroups.length === 0 && this.prototype) {
            this.prototype.groups = []
          } else if (this.prototype && this.prototype.groups && this.prototype.groups.length) {
            // check if the group is still available, if not remove it from the prototype
            this.prototype.groups.forEach(g => {
              if (!this.availableGroups.find(ag => ag.id === g.id)) {
                remove(this.prototype.groups, gr => gr.id === g.id)
              }
            })
          }
        } else if (this.prototype) {
          this.prototype.groups = []
        }
      },
      (error: HttpError) => {
        this.logger.debug('could not fetch available groups: ', error)
      },
    )
  }
}
