import { isObject } from 'lodash'
import * as moment from 'moment/moment'

export class MomentDateConverter {
  private static DATE_REGEX: RegExp = /^(?:date|[\w]+Date(?:[A-Z]{1}[\w]+)?)$/

  static transformRequestBody(body: any): void {
    MomentDateConverter.unwrapIfArray(body, MomentDateConverter.toUnixTimestamp)
  }

  static transformResponseBody(body: any): void {
    MomentDateConverter.unwrapIfArray(body, MomentDateConverter.parse)
  }

  private static unwrapIfArray(
    object: any,
    func: (date: number | moment.Moment) => moment.Moment | number | null
  ): void {
    if (Array.isArray(object)) {
      object.forEach((o: any) => {
        MomentDateConverter.traverse(o, func)
      })
    } else if (object != null) {
      this.traverse(object, func)
    }
  }

  private static traverse(obj: any, func: (date: number | moment.Moment) => moment.Moment | number | null) {
    for (const key in obj) {
      if (MomentDateConverter.DATE_REGEX.test(key)) {
        obj[key] = func(obj[key])
      } else if (Array.isArray(obj[key])) {
        obj[key].forEach(el => {
          if (isObject(el)) {
            this.traverse(el, func)
          }
        })
      } else if (isObject(obj[key]) && key.indexOf('$') === -1) {
        // exclude inferred angular properties like "$promise" etc.
        this.traverse(obj[key], func)
      }
    }
  }

  private static toUnixTimestamp(date: number | moment.Moment | number): number | null {
    if (Number.isInteger(<number>date)) {
      return <number>date
    } else if (date && moment.isMoment(date) && (<moment.Moment>date).isValid()) {
      return (<moment.Moment>date).valueOf()
    } else {
      return null
    }
  }

  private static parse(date: number): moment.Moment | null {
    const d: moment.Moment = moment(date)
    if (d.isValid()) {
      return d
    }

    return null
  }
}
