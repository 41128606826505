import { AfterContentInit, Component, HostBinding, Input, OnChanges, SimpleChange } from '@angular/core'
import { Logger, LoggerService } from '@maprix/core'
import { AccordionGroupComponent } from './accordion-group.component'

@Component({
  selector: 'scs-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements AfterContentInit, OnChanges {
  @Input() facet: 'base' | 'upload' | 'admin' = 'base'
  @Input() closeOthers = true
  @Input() expandAll = false

  private accordionGroups: AccordionGroupComponent[] = []

  private logger: Logger

  @HostBinding('attr.role') role = 'tablist'

  @HostBinding('class')
  get facetClass(): string {
    return this.facet
  }

  constructor(loggerService: LoggerService) {
    this.logger = loggerService.getInstance('AccordionComponent')
  }

  ngAfterContentInit() {
    if (this.expandAll) {
      this.openAll()
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (changes['expandAll'] && changes['expandAll'].currentValue === true) {
      this.openAll()
    }
  }

  addGroup(group: AccordionGroupComponent) {
    this.logger.debug('Added AccordionGroupComponent...')
    this.accordionGroups.push(group)
  }

  removeGroup(group: AccordionGroupComponent) {
    this.logger.debug('Removed AccordionGroupComponent...')
    const i = this.accordionGroups.indexOf(group)
    this.accordionGroups.splice(i, 1)
  }

  openAll() {
    this.logger.debug('Opening all accordionGroups : %O', this.accordionGroups)
    this.toggleAll(true)
  }

  closeAll() {
    this.logger.debug('Closing all accordionGroups : %O', this.accordionGroups)
    this.toggleAll(false)
  }

  private toggleAll(toggle: boolean) {
    this.accordionGroups.forEach(group => {
      group.isOpened = toggle
    })
  }
}
