import { Injectable } from '@angular/core'
import { URLSearchParams } from '@angular/http'
import { LocalStorage, Logger, LoggerService, RuntimeConfiguration } from '@maprix/core'
import * as moment from 'moment'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Enums } from '../../helpers/enums'
import { Feedback } from '../../models/prototype/feedback.model'
import { OrgPrototypeFilter } from '../../models/prototype/org-prototype-filter.enum'
import { ProfilePrototypeFilter } from '../../models/prototype/profile-prototype-filter.enum'
import { PrototypeState } from '../../models/prototype/prototype-state.enum'
import { BasePrototype, Prototype, SimplePrototype, UploadPrototype } from '../../models/prototype/prototype.model'
import { SignedPrototypeFeedbackDTO } from '../../models/prototype/signed-prototype-feedback.dto'
import { FeedbackData, PrototypeData, StoredClientData } from '../../models/prototype/stored-client-data'
import { ThanksMessageDTO } from '../../models/prototype/thanks-message.dto'
import { UpVoteDTO } from '../../models/prototype/up-vote.dto'
import { QueryStats } from '../../models/shared/query-stats.model'
import { AuthUser } from '../../models/user/auth-user'
import { SignedIdGuest } from '../../models/user/signed-id-guest'
import { MicroService } from '../config/runtime-configuration/micro-service.enum'
import { AppHttpResponse } from '../http/app-http-response.model'
import { AppHttp } from '../http/app-http.service'

@Injectable()
export class PrototypeService {
  private static PROTOTYPE_KEY = 'PROTOTYPE'
  private static URI_BASE = 'secure/prototype/'
  private static URI_FOR_FEEDBACK = '/forfeedback'
  private static URI_FOR_RESULTS = '/forresults'

  private static URI_ORGANIZATION_COUNT = 'organization/count'
  private static URI_NEXT = 'next'
  private static URI_FEATURED = 'featured'
  private static URI_FEEDBACK = '/feedback'
  private static URI_GUEST = 'public/prototype/guest'
  private static URI_UPVOTE = '/feedback/upvote'
  private static URI_THANKS = '/thanksmessage'
  private static URI_REMOVE_UPVOTE = '/feedback/upvote/remove'
  private static URI_STORE_PROTOTYPE = 'store'
  private static URI_STORE_FEEDBACK = '/feedback/store'
  private static URI_STORED_DATA = 'storeddata'
  private static URI_INVITE_GROUP_MEMBERS = '/groupmembers'

  storedLocalData: StoredClientData<FeedbackData | PrototypeData> | null = null

  private logger: Logger
  private mainUrl: string

  static addWorkdays(start: moment.Moment, days: number): moment.Moment {
    const increment = days / Math.abs(days)
    const date = moment(start).add(Math.floor(Math.abs(days) / 5) * 7 * increment, 'days')
    let remaining = days % 5
    while (remaining !== 0) {
      date.add(increment, 'days')
      if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
        remaining -= increment
      }
    }
    return date
  }

  static isOpen(prototype: BasePrototype): boolean {
    return prototype.endDate > moment()
  }

  static isOwn(prototype: SimplePrototype, authUser: AuthUser | null): boolean {
    return (authUser && authUser.subId) === (prototype.owner && prototype.owner.id)
  }

  static createEmptyPrototype(): UploadPrototype {
    return {
      title: null,
      description: null,
      type: null,
      feedbackStyle: null,
      createdAtDate: moment(),
      startDate: null,
      endDate: null,
      state: PrototypeState.IN_CREATION,
      resources: [],
      additionalQuestions: [],
      criterias: [],
      invitedUsers: [],
      invitedExternalUsers: [],
      invitedGuests: [],
      groups: [],
      choice: null,
    }
  }

  private static getBodyAndNotify<T>(response: AppHttpResponse<T>): T {
    return response.body
  }

  constructor(
    loggerService: LoggerService,
    runtimeConfiguration: RuntimeConfiguration,
    private localStorage: LocalStorage,
    private http: AppHttp
  ) {
    this.logger = loggerService.getInstance('PrototypeService')
    this.mainUrl = runtimeConfiguration.getUrlForRestService(MicroService.MAIN)
  }

  setCachedPrototype(prototype: UploadPrototype) {
    this.localStorage.setItem(PrototypeService.PROTOTYPE_KEY, prototype)
  }

  getPrototypeForFeedback(id: string): Observable<Prototype> {
    return this.http
      .get<Prototype>(this.mainUrl + PrototypeService.URI_BASE + id + PrototypeService.URI_FOR_FEEDBACK)
      .pipe(map(PrototypeService.getBodyAndNotify))
  }

  getGuestPrototypeForFeedback(signedIdGuest: SignedIdGuest): Observable<Prototype> {
    return this.http
      .get<Prototype>(this.mainUrl + PrototypeService.URI_GUEST, { params: signedIdGuest })
      .pipe(map(PrototypeService.getBodyAndNotify))
  }

  getPrototypeForResults(id: string): Observable<Prototype> {
    return this.http
      .get<Prototype>(this.mainUrl + PrototypeService.URI_BASE + id + PrototypeService.URI_FOR_RESULTS)
      .pipe(
        map(PrototypeService.getBodyAndNotify),
        map(proto => {
          proto.feedbacks = proto.feedbacks || []
          return proto
        })
      )
  }

  getNextPrototype(skip?: string): Observable<Prototype> {
    const params: URLSearchParams = new URLSearchParams()
    if (skip !== undefined) {
      params.set('skip', skip)
    }
    return this.http
      .get<Prototype>(this.mainUrl + PrototypeService.URI_BASE + PrototypeService.URI_NEXT, { params })
      .pipe(map(PrototypeService.getBodyAndNotify))
  }

  getFeaturedPrototype(): Observable<SimplePrototype> {
    return this.http
      .get<SimplePrototype>(this.mainUrl + PrototypeService.URI_BASE + PrototypeService.URI_FEATURED)
      .pipe(map(PrototypeService.getBodyAndNotify))
  }

  getOrganizationCount(filter: OrgPrototypeFilter) {
    return this.http
      .get<QueryStats>(this.mainUrl + PrototypeService.URI_BASE + PrototypeService.URI_ORGANIZATION_COUNT, {
        params: { filter: filter.toString() },
      })
      .pipe(map(resp => resp.body))
  }

  getUserPrototypesCount(userId: string, filter: ProfilePrototypeFilter): Observable<QueryStats> {
    return this.http
      .get<QueryStats>(this.mainUrl + PrototypeService.URI_BASE + 'user/' + userId + '/count', {
        params: { filter: Enums.getNames(ProfilePrototypeFilter)[filter] },
      })
      .pipe(
        map(resp => {
          return resp.body
        })
      )
  }

  createPrototype(prototype: UploadPrototype): Observable<Prototype> {
    return this.http
      .post<Prototype>(this.mainUrl + 'secure/prototype', prototype)
      .pipe(map(PrototypeService.getBodyAndNotify))
  }

  deletePrototype(id: string): Observable<AppHttpResponse<void>> {
    return this.http.delete(this.mainUrl + PrototypeService.URI_BASE + id)
  }

  createFeedback(id: string, feedback: Feedback): Observable<Prototype> {
    return this.http
      .post<Prototype>(this.mainUrl + PrototypeService.URI_BASE + id + PrototypeService.URI_FEEDBACK, feedback)
      .pipe(map(PrototypeService.getBodyAndNotify))
  }

  createGuestFeedback(data: SignedPrototypeFeedbackDTO): Observable<Prototype> {
    return this.http
      .post<Prototype>(this.mainUrl + PrototypeService.URI_GUEST, data)
      .pipe(map(PrototypeService.getBodyAndNotify))
  }

  upVoteFeedback(upVoteDTO: UpVoteDTO): Observable<Prototype> {
    return this.http
      .post<Prototype>(this.mainUrl + PrototypeService.URI_BASE + upVoteDTO.id + PrototypeService.URI_UPVOTE, upVoteDTO)
      .pipe(map(PrototypeService.getBodyAndNotify))
  }

  removeUpVote(upVoteDTO: UpVoteDTO): Observable<Prototype> {
    return this.http
      .post<Prototype>(
        this.mainUrl + PrototypeService.URI_BASE + upVoteDTO.id + PrototypeService.URI_REMOVE_UPVOTE,
        upVoteDTO
      )
      .pipe(map(PrototypeService.getBodyAndNotify))
  }

  sayThanks(id: string, thanksMessageDTO: ThanksMessageDTO): Observable<Prototype> {
    return this.http
      .post<Prototype>(this.mainUrl + PrototypeService.URI_BASE + id + PrototypeService.URI_THANKS, thanksMessageDTO)
      .pipe(map(PrototypeService.getBodyAndNotify))
  }

  inviteGroupMembers(id: string, emails: string[], groups?: string[]): Observable<Prototype> {
    return this.http
      .post<Prototype>(
        this.mainUrl + PrototypeService.URI_BASE + id + PrototypeService.URI_INVITE_GROUP_MEMBERS,
        emails,
        { params: { groups } }
      )
      .pipe(map(PrototypeService.getBodyAndNotify))
  }

  storePrototypeTemp(uploadPrototype: UploadPrototype): Observable<AppHttpResponse<void>> {
    return this.http.post<void>(
      this.mainUrl + PrototypeService.URI_BASE + PrototypeService.URI_STORE_PROTOTYPE,
      uploadPrototype
    )
  }

  storeFeedbackTemp(feedback: Feedback, prototypeId: string): Observable<AppHttpResponse<void>> {
    return this.http.post<void>(
      this.mainUrl + PrototypeService.URI_BASE + prototypeId + PrototypeService.URI_STORE_FEEDBACK,
      feedback
    )
  }

  getSoredData(): Observable<StoredClientData<FeedbackData | PrototypeData>> {
    return this.http
      .get<StoredClientData<FeedbackData | PrototypeData>>(
        this.mainUrl + PrototypeService.URI_BASE + PrototypeService.URI_STORED_DATA
      )
      .pipe(map(PrototypeService.getBodyAndNotify))
  }

  getCachedPrototype(): any {
    return this.localStorage.getItem(PrototypeService.PROTOTYPE_KEY)
  }

  removeCachedPrototype(): void {
    this.localStorage.remove(PrototypeService.PROTOTYPE_KEY)
    this.logger.debug('cached prototype removed')
  }
}
