import { EventEmitter } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { Logger } from '@maprix/core'
import { HttpError } from '../../../../shared/models/http/http-error'
import { BaseLoginStepWithFormComponent } from './base-login-step-with-form.component'

export abstract class ResetableLoginStepWithFormComponent extends BaseLoginStepWithFormComponent {
  abstract error: HttpError | string | null
  abstract errorChange: EventEmitter<HttpError | string | null>

  constructor(logger: Logger, formBuilder: FormBuilder) {
    super(logger, formBuilder)
  }

  resetError(): void {
    this.error = null
    this.errorChange.emit(null)
  }
}
