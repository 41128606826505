import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { LoggerService, ScrollToService } from '@maprix/core'
import { LoginStateData } from '../models/login-state-data'
import { BaseLoginStepComponent } from './base/base-login-step.component'

@Component({
  selector: 'scs-login-image-cropper',
  templateUrl: './login-image-cropper.component.html',
})
export class LoginImageCropperComponent extends BaseLoginStepComponent implements OnInit {
  @Input() data: LoginStateData

  @Output() back: EventEmitter<null> = new EventEmitter<null>()
  @Output() next: EventEmitter<null> = new EventEmitter<null>()

  constructor(loggerService: LoggerService, private scrollToService: ScrollToService) {
    super(loggerService.getInstance('LoginImageCropper'))
  }

  ngOnInit() {
    this.scrollToService.scrollTo()
  }

  onSave(image: Blob): void {
    this.logger.debug('store blob temporary')
    this.data.loginData._selectedImage = null
    this.data.loginData._selectedImageBlob = image
    this.next.emit(null)
  }
}
