import { Injectable } from '@angular/core'
import { RequestOptionsArgs, URLSearchParams } from '@angular/http'
import { Logger, LoggerService, RuntimeConfiguration } from '@maprix/core'
import { Observable, ReplaySubject } from 'rxjs'
import { distinctUntilChanged, map, tap } from 'rxjs/operators'
import { BooleanDTO } from '../../models/dto/boolean.dto'
import { Contact } from '../../models/organization/contact.model'
import { MainOrganization } from '../../models/organization/main-organization.model'
import { OrganizationStatistics } from '../../models/organization/organizations-statistics.model'
import { PaymentInfo } from '../../models/organization/payment-info.model'
import { BillingItem } from '../../models/payment/billing-item.model'
import { Product } from '../../models/product/product.model'
import { TimeSettings } from '../../models/shared/time-settings.model'
import { AuthService } from '../auth/auth.service'
import { MicroService } from '../config/runtime-configuration/micro-service.enum'
import { FileUploadService } from '../file-upload/file-upload.service'
import { AppHttpResponse } from '../http/app-http-response.model'
import { AppHttp } from '../http/app-http.service'

@Injectable()
export class OrganizationService {
  private static URI_BASE = 'secure/organization'
  private static URI_CURRENT = `${OrganizationService.URI_BASE}/current`
  private static URI_TRIAL = `${OrganizationService.URI_BASE}/trial`
  private static URI_STATISTIC = `${OrganizationService.URI_BASE}/statistics`
  private static URI_INVITE = `${OrganizationService.URI_BASE}/invite`
  private static URI_PRODUCT = `${OrganizationService.URI_CURRENT}/product`
  private static URI_CONTACT = `${OrganizationService.URI_CURRENT}/contact`
  private static URI_PAYMENT_INFO = `${OrganizationService.URI_CURRENT}/paymentinfo`
  private static URI_TIME_SETTINGS = `${OrganizationService.URI_CURRENT}/timesettings`
  private static URI_PROFILE_PICTURE = `${OrganizationService.URI_BASE}/current/profilepicture`
  private static URI_BILLING_ITEM = `${OrganizationService.URI_CURRENT}/billingitem`
  private static QUERY_PARAM_PROTOTYPE_ID = 'prototype'
  private static URI_GUEST_FEATURE = `${OrganizationService.URI_CURRENT}/guestfeature`

  private logger: Logger
  private mainUrl: string

  private guestFeatureSubject: ReplaySubject<boolean> = new ReplaySubject(1)

  readonly guestFeature$ = this.guestFeatureSubject.asObservable().pipe(distinctUntilChanged())

  private static URI_GUEST_FEATURE_SALES = (orgId: string) => `${OrganizationService.URI_BASE}/${orgId}/guestfeature`

  private static getBody<T>(response: AppHttpResponse<T>): T {
    return response.body
  }

  constructor(
    loggerService: LoggerService,
    runtimeConfiguration: RuntimeConfiguration,
    authService: AuthService,
    private fileUploadService: FileUploadService,
    private appHttp: AppHttp
  ) {
    this.logger = loggerService.getInstance('OrganizationService')
    this.mainUrl = runtimeConfiguration.getUrlForRestService(MicroService.MAIN)

    this.isGuestFeatureActivated().subscribe(
      activated => {
        this.guestFeatureSubject.next(activated)
      },
      error => {
        this.guestFeatureSubject.next(false)
      }
    )
  }

  startTrial(organization: MainOrganization): Observable<MainOrganization> {
    return this.appHttp
      .post<MainOrganization>(`${this.mainUrl}${OrganizationService.URI_TRIAL}`, organization)
      .pipe(map<AppHttpResponse<MainOrganization>, MainOrganization>(OrganizationService.getBody))
  }

  statistics(): Observable<OrganizationStatistics> {
    return this.appHttp
      .get<OrganizationStatistics>(`${this.mainUrl}${OrganizationService.URI_STATISTIC}`)
      .pipe(map<AppHttpResponse<OrganizationStatistics>, OrganizationStatistics>(OrganizationService.getBody))
  }

  invite(emails: string[], prototypeId?: string): Observable<string[]> {
    const options: RequestOptionsArgs = {}
    if (prototypeId) {
      const search = new URLSearchParams()
      search.set(OrganizationService.QUERY_PARAM_PROTOTYPE_ID, prototypeId)
      options.params = search
    }
    return this.appHttp
      .post<string[]>(`${this.mainUrl}${OrganizationService.URI_INVITE}`, emails, options)
      .pipe(map<AppHttpResponse<string[]>, string[]>(OrganizationService.getBody))
  }

  updateProduct(product: Product): Observable<MainOrganization> {
    return this.appHttp
      .put<MainOrganization>(`${this.mainUrl}${OrganizationService.URI_PRODUCT}`, product)
      .pipe(map<AppHttpResponse<MainOrganization>, MainOrganization>(OrganizationService.getBody))
  }

  current(): Observable<MainOrganization> {
    return this.appHttp
      .get<MainOrganization>(`${this.mainUrl}${OrganizationService.URI_CURRENT}`)
      .pipe(map<AppHttpResponse<MainOrganization>, MainOrganization>(OrganizationService.getBody))
  }

  updateContact(contact: Contact): Observable<MainOrganization> {
    return this.appHttp
      .put<MainOrganization>(`${this.mainUrl}${OrganizationService.URI_CONTACT}`, contact)
      .pipe(map<AppHttpResponse<MainOrganization>, MainOrganization>(OrganizationService.getBody))
  }

  updatePaymentinfo(paymentInfo: PaymentInfo): Observable<MainOrganization> {
    return this.appHttp
      .put<MainOrganization>(`${this.mainUrl}${OrganizationService.URI_PAYMENT_INFO}`, paymentInfo)
      .pipe(map<AppHttpResponse<MainOrganization>, MainOrganization>(OrganizationService.getBody))
  }

  updateTimeSettings(timeSettings: TimeSettings): Observable<MainOrganization> {
    return this.appHttp
      .put<MainOrganization>(`${this.mainUrl}${OrganizationService.URI_TIME_SETTINGS}`, timeSettings)
      .pipe(map<AppHttpResponse<MainOrganization>, MainOrganization>(OrganizationService.getBody))
  }

  setProfilePicture(picture: Blob): Observable<MainOrganization> {
    return this.fileUploadService
      .uploadSingle<MainOrganization>({ data: picture }, OrganizationService.URI_PROFILE_PICTURE)
      .pipe(map<AppHttpResponse<MainOrganization>, MainOrganization>(OrganizationService.getBody))
  }

  currentBillingItem(): Observable<BillingItem> {
    return this.appHttp
      .get<BillingItem>(`${this.mainUrl}${OrganizationService.URI_BILLING_ITEM}`)
      .pipe(map<AppHttpResponse<BillingItem>, BillingItem>(OrganizationService.getBody))
  }

  updateGuestFeature(orgId: string, value: boolean): Observable<boolean> {
    return this.appHttp
      .put<BooleanDTO>(`${this.mainUrl}${OrganizationService.URI_GUEST_FEATURE_SALES(orgId)}`, { value })
      .pipe(
        map<AppHttpResponse<BooleanDTO>, boolean>(response => OrganizationService.getBody(response).value),
        tap<boolean>(isGuestFeatureActivated => this.guestFeatureSubject.next(isGuestFeatureActivated))
      )
  }

  private isGuestFeatureActivated(): Observable<boolean> {
    return this.appHttp
      .get<BooleanDTO>(`${this.mainUrl}${OrganizationService.URI_GUEST_FEATURE}`)
      .pipe(map<AppHttpResponse<BooleanDTO>, boolean>(response => OrganizationService.getBody(response).value))
  }
}
