import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { Modal2Service, NotificationCenterService } from '@maprix/components'
import { Logger, LoggerService } from '@maprix/core'
import { Subscription } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { getPolaroidFacet } from '../../../../shared/components/polaroid/polaroid-facet.model'
import { getPolaroidLabel } from '../../../../shared/components/polaroid/polaroid-label.model'
import { getPolaroidSize } from '../../../../shared/components/polaroid/polaroid-size.model'
import { UploadPrototype } from '../../../../shared/models/prototype/prototype.model'
import { PrototypeResource } from '../../../../shared/models/resource/prototype-resource.model'
import { DragulaService } from '../../../../shared/services/dragula/dragula.service'
import {
  FileUploadService,
  MIN_MAX_RESOURCES,
  MinMaxInit,
} from '../../../../shared/services/file-upload/file-upload.service'
import { AppHttp } from '../../../../shared/services/http/app-http.service'
import { ResourceUploadData, ResourceUploadModalComponent } from '../resource/resource-upload-modal.component'

interface DisplayResource {
  filled: boolean
  prototypeResource?: PrototypeResource
}

@Component({
  selector: 'scs-upload-step-resources',
  templateUrl: './upload-step-resources.component.html',
  styleUrls: ['./upload-step-resources.component.scss'],
})
export class UploadStepResourcesComponent implements OnInit, OnDestroy {
  static BAG_NAME = 'resources-bag'
  private static DATA_INDEX = 'data-index'
  private static RESOURCE_URL_UPLOAD = 'secure/resources/image'

  @ViewChild('dragulaRoot') dragulaRoot: ElementRef

  @Input() prototype: UploadPrototype

  @Output() revalidate: EventEmitter<void> = new EventEmitter<void>()

  displayResources: DisplayResource[] = []
  minMax: MinMaxInit

  getPolaroidSize = getPolaroidSize
  getPolaroidFacet = getPolaroidFacet
  getPolaroidLabel = getPolaroidLabel

  private logger: Logger
  private dropSubscription: Subscription

  @HostBinding('class')
  get classes(): string {
    return `wizard-step wizard-step--resources polaroid-grid polaroid-grid--resources polaroid-grid--type-${
      this.prototype.type
      }`
  }

  constructor(
    loggerService: LoggerService,
    private modalService: Modal2Service,
    private dragulaService: DragulaService,
    private fileUploadService: FileUploadService,
    private appHttp: AppHttp,
    private notificationService: NotificationCenterService,
  ) {
    this.logger = loggerService.getInstance('UploadStepResourcesComponent')
  }

  ngOnInit() {
    this.minMax = MIN_MAX_RESOURCES[this.prototype.type!]
    if (this.prototype.resources) {
      if (this.prototype.resources.length > this.minMax.max) {
        this.logger.debug('removing resources max: %i current: %i', this.minMax.max, this.prototype.resources.length)
        this.prototype.resources.length = this.minMax.max
      }
      // init display resources from prototype resource
      this.prototype.resources.forEach(proto => {
        this.displayResources.push({ prototypeResource: proto, filled: true })
      })
    }

    // add empty polaroids depending on type
    while (this.displayResources.length < this.minMax.init) {
      this.logger.debug('adding empty resource')
      this.displayResources.push({ filled: false })
    }

    // dragula options
    this.dragulaService.setOptions(UploadStepResourcesComponent.BAG_NAME, {
      mirrorContainer: this.dragulaRoot.nativeElement,
      // TODO ML LOW check if we have to change the direction depending on device type (mobile tablet and maybe prototype-typ).
      direction: 'horizontal',
      // drop enabled
      accepts: this.dragulaAccepts,
      // drag enabled
      invalid: this.dragulaInvalid,
      ignoreInputTextSelection: false,
    })

    // subscription emits on order change
    this.dropSubscription = this.dragulaService.dropModel.subscribe(data => {
      // we have to resync positions with the underlying prototype.resource array
      this.logger.debug('dropped --> model changes')
      this.displayResources.filter(dr => dr.filled).forEach((dr, idx) => {
        this.logger.debug('syncing model changes')
        this.prototype.resources[idx] = dr.prototypeResource!
      })
    })
  }

  ngOnDestroy() {
    this.dropSubscription.unsubscribe()
    this.dragulaService.destroy(UploadStepResourcesComponent.BAG_NAME)
  }

  openModal(idx: number): void {
    this.modalService
      .open<ResourceUploadModalComponent, PrototypeResource>(
        ResourceUploadModalComponent,
        { data: <ResourceUploadData>{ firstResource: this.prototype.resources[0] } },
      )
      .afterClosed
      .pipe(filter(data => !!data))
      .subscribe((data: PrototypeResource) => {
        this.logger.debug('got resource %O', data)
        this.addResource(data)
      })
  }

  onFileDrop(fileList: FileList) {
    this.logger.debug('got files : %o', fileList)
    const validationResult = FileUploadService.validateFileList(fileList)

    // we ignore additional files
    if (validationResult.validFiles.length + this.prototype.resources.length > this.minMax.max) {
      this.logger.warn('to many valid files dropped')
      validationResult.validFiles.length = this.minMax.max - this.prototype.resources.length
    }
    this.fileUploadService
      .upload(validationResult, UploadStepResourcesComponent.RESOURCE_URL_UPLOAD)
      .pipe(map(resp => resp.body))
      .subscribe(imageResource => {
        this.addResource(imageResource)
      }, this.appHttp.handle400)

    // errors
    validationResult.invalidFiles.forEach(file => {
      this.logger.warn('File validation failed display notification error')
      this.notificationService.warn('PROTOTYPE.STEP_1.ERRORS.' + file.errorKey, file)
    })
  }

  deleteResource(idx: number) {
    this.logger.debug('delete resource with index %i', idx)
    // FIXME MW PS MED remove from backend too
    this.prototype.resources.splice(idx, 1)
    this.displayResources.splice(idx, 1)
    this.addPolaroid()
    this.revalidate.emit()
  }

  isOptional(index: number): boolean {
    // between min and max are optional
    return this.minMax.min <= index && index < this.minMax.max
  }

  dragulaAccepts = (el: HTMLElement, target, source, sibling: HTMLElement | null) => {
    // if all potential polaroids are filled every position is valid
    if (this.prototype.resources.length === this.minMax.max) {
      return true
    }

    // last position should not be possible
    if (sibling && sibling.hasAttribute(UploadStepResourcesComponent.DATA_INDEX)) {
      const targetNextIndex = parseInt(sibling.getAttribute(UploadStepResourcesComponent.DATA_INDEX)!, 10)
      return targetNextIndex <= this.prototype.resources.length
    }

    // not allowed
    return false
  }

  dragulaInvalid = (el: HTMLElement, handle: HTMLElement) => {
    if (el.hasAttribute(UploadStepResourcesComponent.DATA_INDEX)) {
      const attr = parseInt(el.getAttribute(UploadStepResourcesComponent.DATA_INDEX)!, 10)
      return !this.displayResources[attr].filled && this.displayResources.length >= 2
    }
    return false
  }

  private addResource(resource: PrototypeResource) {
    const next = this.prototype.resources.length
    this.displayResources[next].prototypeResource = resource
    this.displayResources[next].filled = true
    this.prototype.resources.push(resource)
    this.addPolaroid()
    this.revalidate.emit()
  }

  private addPolaroid() {
    // only add till max and only if there is not one empty left or the value is below the initial value
    if (
      this.displayResources.length < this.minMax.max &&
      (this.prototype.resources.length === this.displayResources.length ||
        this.displayResources.length < this.minMax.init)
    ) {
      this.logger.debug('Adding empty displayResource')
      this.displayResources.push({
        filled: false,
      })
    }
  }
}
