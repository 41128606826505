import { Injectable } from '@angular/core'
import { RuntimeConfiguration } from '@maprix/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ContactRequest } from '../../models/contact/contact-request.model'
import { MicroService } from '../config/runtime-configuration/micro-service.enum'
import { AppHttp } from '../http/app-http.service'

@Injectable()
export class PublicContactService {
  private static URI_BASE = 'public/contact'
  private static URI_GENERAL = `${PublicContactService.URI_BASE}/general`

  private mainUrl: string

  constructor(private appHttp: AppHttp, runtimeConfiguration: RuntimeConfiguration) {
    this.mainUrl = runtimeConfiguration.getUrlForRestService(MicroService.MAIN)
  }

  generalContact(contactRequest: ContactRequest): Observable<void> {
    return this.appHttp
      .post<void>(`${this.mainUrl}${PublicContactService.URI_GENERAL}`, contactRequest)
      .pipe(map(response => response.body))
  }
}
