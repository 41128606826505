import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChange, ViewChild } from '@angular/core'
import { DeviceInfoService, Features, Logger, LoggerService } from '@maprix/core'

@Component({
  selector: 'scs-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
})
export class AutoCompleteComponent implements OnChanges {
  @ViewChild('list') listElRef: any
  @Input() source: any[]

  @Output() addItem: EventEmitter<any> = new EventEmitter<any>()
  @Output() releaseFocus: EventEmitter<any> = new EventEmitter<any>()

  selectedItem: any = null
  isOpen = false

  private logger: Logger
  private hasTouch = false

  constructor(loggerService: LoggerService, deviceInfo: DeviceInfoService) {
    this.logger = loggerService.getInstance('AutoCompleteComponent')

    deviceInfo.featureChanges.subscribe((features: Features) => {
      this.hasTouch = features.touch
    })
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (changes['source']) {
      this.selectedItem = null
      this.isOpen = !!(this.source && this.source.length > 0)
      if (this.isOpen) {
        this.onReleaseFocus()
      }
    }
  }

  /*
   * host event listener for keydown events
   */
  @HostListener('keydown', ['$event'])
  onKeydown(e: KeyboardEvent) {
    if (e.keyCode !== 13) {
      e.preventDefault()
    }
    if (e.keyCode === 40) {
      e.preventDefault()
      e.stopPropagation()
      // down key, select next in autoComplete
      if (this.selectedItem) {
        if (this.source.indexOf(this.selectedItem) !== -1) {
          const currentIdx: number = this.source.indexOf(this.selectedItem)
          const max: number = this.source.length
          if (currentIdx + 1 < max) {
            this.selectedItem = this.source[currentIdx + 1]
            this.handleFocus()
          }
        }
      } else if (this.source[0]) {
        this.selectedItem = this.source[0]
        this.handleFocus()
      }
    } else if (e.keyCode === 38) {
      e.preventDefault()
      e.stopPropagation()
      // up key, select previous in autoComplete
      if (this.selectedItem) {
        if (this.source.indexOf(this.selectedItem) !== -1) {
          const currentIdx: number = this.source.indexOf(this.selectedItem)
          if (currentIdx > 0) {
            this.selectedItem = this.source[currentIdx - 1]
            this.handleFocus()
          } else {
            this.selectedItem = null
            this.handleFocus()
          }
        }
      }
    } else if (e.keyCode !== 13) {
      this.selectedItem = null
      e.preventDefault()
      this.handleFocus()
    }
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.selectedItem === null) {
      this.selectedItem = this.source[0]
      this.handleFocus()
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.selectedItem = null
    this.onReleaseFocus()
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(e: MouseEvent) {
    if (
      this.isOpen &&
      e.srcElement !== null &&
      !e.srcElement.classList.contains('tag-input__text-input') &&
      !e.srcElement.classList.contains('tag-input--focused')
    ) {
      this.close()
      this.onReleaseFocus()
    }
  }

  onAddItem(item: any): void {
    if (!this.hasTouch) {
      this.addItem.emit(item)
    }
  }

  onReleaseFocus(): void {
    this.releaseFocus.emit(null)
  }

  onMouseOverItem(item: any): void {
    if (this.hasTouch) {
      this.addItem.emit(item)
    } else {
      this.selectedItem = item
      this.handleFocus()
    }
  }

  open(): void {
    this.selectedItem = this.source[0]
    this.isOpen = true
    this.handleFocus()
  }

  close(): void {
    this.selectedItem = null
    this.isOpen = false
  }

  removeFocus(): void {
    this.selectedItem = null
  }

  resolveProfilePictureUrl(item: any): string | null {
    let url: string | null = null
    if (item.profilePictureThumbnail && item.profilePictureThumbnail !== null) {
      url = item.profilePictureThumbnail.retrievalUrl
    }
    return url
  }

  // used to set the focus on a selected item (possible to add selected entry by enter key)
  // or release the focus and focus the tag-input input
  private handleFocus() {
    setTimeout(() => {
      if (this.selectedItem) {
        const anchorEl: HTMLAnchorElement | null = this.listElRef.nativeElement.querySelector(
          '.auto-complete__nav-link--selected'
        )
        if (anchorEl) {
          anchorEl.focus()
        }
      } else {
        this.onReleaseFocus()
      }
    }, 0)
  }
}
