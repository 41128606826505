import { Injectable } from '@angular/core'
import { RuntimeConfiguration } from '@maprix/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Group } from '../../models/prototype/group.model'
import { SignedIdGuest } from '../../models/user/signed-id-guest'
import { MicroService } from '../config/runtime-configuration/micro-service.enum'
import { AppHttpResponse } from '../http/app-http-response.model'
import { AppHttp } from '../http/app-http.service'

@Injectable()
export class GroupService {
  private static URI_BASE = 'secure/group/'
  private static URI_UNSUBSCRIBE = 'public/group/unsubscribe'

  private mainUrl: string

  constructor(private appHttp: AppHttp, runtimeConfiguration: RuntimeConfiguration) {
    this.mainUrl = runtimeConfiguration.getUrlForRestService(MicroService.MAIN)
  }

  getAll(): Observable<Group[]> {
    return this.appHttp.get<Group[]>(`${this.mainUrl}${GroupService.URI_BASE}`).pipe(
      map((response: AppHttpResponse<Group[]>) => {
        return response.body
      })
    )
  }

  getById(id: string): Observable<Group> {
    return this.appHttp.get<Group>(`${this.mainUrl}${GroupService.URI_BASE}${id}`).pipe(
      map((response: AppHttpResponse<Group>) => {
        return response.body
      })
    )
  }

  createGroup(group: Group): Observable<Group> {
    return this.appHttp.post<Group>(`${this.mainUrl}${GroupService.URI_BASE}`, group).pipe(
      map((response: AppHttpResponse<Group>) => {
        return response.body
      })
    )
  }

  updateGroup(group: Group): Observable<Group> {
    return this.appHttp.put<Group>(`${this.mainUrl}${GroupService.URI_BASE}${group.id}`, group).pipe(
      map((response: AppHttpResponse<Group>) => {
        return response.body
      })
    )
  }

  deleteGroup(group: Group): Observable<AppHttpResponse<void>> {
    return this.appHttp.delete(`${this.mainUrl}${GroupService.URI_BASE}${group.id}`)
  }

  unsubscribe(signedIdGuest: SignedIdGuest): Observable<void> {
    return this.appHttp
      .post<void>(`${this.mainUrl}${GroupService.URI_UNSUBSCRIBE}`, {}, { params: signedIdGuest })
      .pipe(
        map((response: AppHttpResponse<void>) => {
          return response.body
        })
      )
  }
}
