import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpModule, JsonpModule } from '@angular/http'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import {
  HeaderModule,
  HeaderProvider,
  IconModule,
  NotificationCenterModule,
  PlatformInformationModule,
} from '@maprix/components'
import {
  ClientIdModule,
  DeviceInfoModule,
  LocaleModule,
  LocalStorageModule,
  LoggerModule,
  MetaModule,
  ScrollToModule,
  UiEventModule,
  WindowModule,
} from '@maprix/core'
import { Angulartics2Module } from 'angulartics2'
import { AppComponent } from './app.component'
import { ROUTES } from './app.routes'
import { AppService } from './app.service'
import { LoginModule } from './routes/login/login.module'
import { UploadModule } from './routes/upload/upload.module'
import { Angulartics2GoogleTagManager } from './shared/angularitics/angulartics2-gtm'
import { EditExpertisesModalModuleModule } from './shared/components/edit-expertise-modal-module/edit-expertises-modal-module.module'
import { PrivacyPolicyModalModule } from './shared/components/privacy-policy-modal/privacy-policy-modal.module'
import { CoreModule } from './shared/core.module'
import { AppHeaderProvider } from './shared/services/app-header-provider/app-header-provider'

@NgModule({
  imports: [
    CoreModule,
    BrowserModule,
    HttpModule,
    JsonpModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forRoot(ROUTES),
    BrowserAnimationsModule,

    // third party
    Angulartics2Module.forRoot([Angulartics2GoogleTagManager], {
      pageTracking: {
        clearHash: true,
        clearQueryParams: true,
      },
    }),

    // sc core
    LocaleModule.forRoot(),
    LoggerModule.forRoot(),
    WindowModule.forRoot(),
    ClientIdModule.forRoot(),
    LocalStorageModule.forRoot(),
    DeviceInfoModule.forRoot(),
    MetaModule.forRoot(),
    UiEventModule.forRoot(),
    ScrollToModule.forRoot(),

    // sc components
    PlatformInformationModule,
    IconModule.forRoot(),
    HeaderModule.forRoot(),
    NotificationCenterModule.forRoot(),

    // component modules without route
    PrivacyPolicyModalModule,
    EditExpertisesModalModuleModule,

    // not lazy loaded routes
    LoginModule,
    UploadModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    AppService,
    {
      provide: HeaderProvider,
      useClass: AppHeaderProvider,
    },
  ],
})
export class AppModule {}
