import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { IconModule, StateButtonModule } from '@maprix/components'
import { LoopingCoreModule } from '../../../looping-core.module'
import { ImageCropperComponent } from './image-cropper.component'
import { ImageCropper } from './image-cropper.service'

@NgModule({
  imports: [CommonModule, FormsModule, IconModule, LoopingCoreModule, StateButtonModule],
  declarations: [ImageCropperComponent],
  exports: [ImageCropperComponent],
})
export class ImageCropperModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ImageCropperModule,
      providers: [ImageCropper],
    }
  }
}
