import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
} from '@angular/core'
import { DeviceInfoService, Logger, LoggerService, ScreenProperties, TranslateService } from '@maprix/core'
import { Observable, Subscription } from 'rxjs'
import { Sort, SortDirection } from '../title-bar/title-bar.component'
import { MobileViewDirective } from './directives/mobile-view.directive'
import { TableColumnDirective } from './directives/table-column.directive'
import { TableColumnModel } from './models/table-column.model'
import { TableMobileViewModel } from './models/table-mobile-view.model'
import { TableOptions } from './models/table-options.model'

/*
 * TODO MED (REVIEW TABLE CONCEPT) somehow the content gets duplicated when changing from desktop to mobile and back
 */
@Component({
  selector: 'scs-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnDestroy, OnChanges, AfterContentInit {
  @Input() options: TableOptions<any, any, any>

  @ContentChildren(TableColumnDirective) columns: QueryList<TableColumnDirective>

  @ContentChildren(MobileViewDirective) mobileViews: QueryList<MobileViewDirective>

  isXsScreen: boolean
  sortDirection: any = SortDirection

  // used to expose on the template so we provide the possibilty for any custom template too
  sortFn: (column: TableColumnModel) => void = this.sortBy.bind(this)

  private logger: Logger
  private deviceInfoSubscription: Subscription

  constructor(loggerService: LoggerService, deviceInfo: DeviceInfoService, private translateService: TranslateService) {
    this.logger = loggerService.getInstance('TableComponent')

    this.deviceInfoSubscription = deviceInfo.screenChanges.subscribe((props: ScreenProperties) => {
      this.isXsScreen = props.width === 'xs'
    })
  }

  ngOnInit() {
    this.logger.debug('onInit')
  }

  ngOnDestroy() {
    this.deviceInfoSubscription.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.logger.debug('onChanges')
  }

  ngAfterContentInit(): void {
    if (this.options === undefined) {
      this.options = new TableOptions<any, any, any>()
    }

    if (this.columns && this.columns.length) {
      this.columns.forEach(column => {
        let obs: Observable<string> | undefined
        if (column.title) {
          obs = <Observable<string>>this.translateService.get(column.title)
        }

        this.options.addColumn(new TableColumnModel(column, obs))
      })

      // TODO MED (REVIEW TABLE CONCEPT) implement check that id exists for sorting definitions
      if (this.options.sorting && this.options.sorting.default !== undefined) {
        const sort: Sort<any> | undefined = this.options.sorting.options.find(
          option => option.value === this.options.sorting.default
        )
        if (!sort) {
          this.logger.error('there was no sort definition found matching the default sorting')
        } else {
          const column: TableColumnModel | undefined = this.options.columnsAll.find(c => c.id === sort.columnId)
          if (column) {
            column.sortDirection = sort.direction
          } else {
            this.logger.error('could not find column defined for default sorting')
          }
        }
      }
    }

    if (this.mobileViews && this.mobileViews.length) {
      this.logger.debug('found mobile view')
      this.mobileViews.forEach(mobileView => {
        this.options.addMobileView(new TableMobileViewModel(mobileView))
      })
    }
  }

  sortBy(clickedColumn: TableColumnModel): void {
    if (clickedColumn.sortable && this.options.hasSorting) {
      this.logger.debug('sorty by', clickedColumn)

      this.options.onNextSort(clickedColumn)
    }
  }
}
