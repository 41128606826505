import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChange } from '@angular/core'
import { Router } from '@angular/router'
import {
  Modal2Service,
  NotificationCenterService,
  NotificationMessage,
  NotificationSeverity,
} from '@maprix/components'
import { filterIfTruthy, Logger, LoggerService } from '@maprix/core'
import { isEmpty } from 'lodash'
import * as moment from 'moment'
import { Observable, Subscription } from 'rxjs'
import { first, share } from 'rxjs/operators'
import { KeyboardKeyCode } from '../../../../shared/helpers/keyboard-key-code'
import { HttpError } from '../../../../shared/models/http/http-error'
import { HttpErrorCodes } from '../../../../shared/models/http/http-error-codes'
import { Criteria } from '../../../../shared/models/prototype/criteria.model'
import { FeedbackStyle } from '../../../../shared/models/prototype/feedback-style.enum'
import { Group, SimpleGroup } from '../../../../shared/models/prototype/group.model'
import { PrototypeState } from '../../../../shared/models/prototype/prototype-state.enum'
import { Prototype, UploadPrototype } from '../../../../shared/models/prototype/prototype.model'
import { StepState } from '../../../../shared/models/wizard/step-state.model'
import { AuthService } from '../../../../shared/services/auth/auth.service'
import { AppHttp } from '../../../../shared/services/http/app-http.service'
import { PrototypeService } from '../../../../shared/services/prototype/prototype.service'
import { ConfirmGuestsSubmitModalComponent } from '../modals/confirm-guests-submit-modal.component'
import { PublishedPrototypeData, PublishedPrototypeModalComponent } from '../modals/published-prototype-modal.component'
import { AudienceChoice } from './audience-choice.model'

const VALIDATION_RULES = {
  resources: [{ max: 1, min: 1 }, { max: 3, min: 2 }, { max: 9, min: 3 }],
  steps: [
    (prototype: UploadPrototype, emailVerified: boolean): boolean => {
      return Number.isInteger(prototype.type)
    },
    (prototype: UploadPrototype, emailVerified: boolean): boolean => {
      const resourceLength = VALIDATION_RULES.resources[prototype.type!]
      return resourceLength.min <= prototype.resources.length && prototype.resources.length <= resourceLength.max
    },
    (prototype: UploadPrototype, emailVerified: boolean): boolean => {
      let valid = false
      if (prototype.title !== null && !isEmpty(prototype.title.trim()) && prototype.title.length <= 25) {
        if (
          prototype.description !== null &&
          !isEmpty(prototype.description.trim()) &&
          prototype.description.length <= 1000
        ) {
          valid = true
        }
      }
      return valid
    },
    (prototype: UploadPrototype, emailVerified: boolean): boolean => {
      // questions are optional but have max length 140 but login & verified email is required
      let valid = true
      if (!emailVerified) {
        return false
      } else if (prototype.additionalQuestions.length) {
        prototype.additionalQuestions.forEach((q: string) => {
          if (q.length > 140) {
            valid = false
          }
        })
      }
      return valid
    },
    (prototype: UploadPrototype, emailVerified: boolean): boolean => {
      switch (prototype.feedbackStyle) {
        case FeedbackStyle.COMMUNITY:
          // nothing more needed
          return true
        case FeedbackStyle.GROUP:
          // not empty at least one user
          return !isEmpty(prototype.groups)
        case FeedbackStyle.CRITERIA:
          // at least one criteria
          return !isEmpty(prototype.criterias)
        default:
          return false
      }
    },
  ],
}

@Component({
  selector: 'scs-upload-wizard',
  templateUrl: './upload-wizard.component.html',
})
export class UploadWizardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() prototype: UploadPrototype

  valid = false
  currentStep = 0

  steps: StepState[] = [
    {
      id: 0,
      title: 'PROTOTYPE.STEP_0.TITLE',
      titleBasic: 'PROTOTYPE.STEP_0.TITLE_BASIC',
      titleSideNav: 'PROTOTYPE.STEP_0.TITLE_SIDE_NAV',
      proceed: 'COMMONS.CONTINUE',
      complete: false,
    },
    {
      id: 1,
      title: 'PROTOTYPE.STEP_1.',
      titleBasic: 'PROTOTYPE.STEP_1.TITLE_BASIC',
      titleSideNav: 'PROTOTYPE.STEP_1.TITLE_SIDE_NAV',
      proceed: 'COMMONS.CONTINUE',
      complete: false,
    },
    {
      id: 2,
      title: 'PROTOTYPE.STEP_2.TITLE',
      titleBasic: 'PROTOTYPE.STEP_2.TITLE_BASIC',
      titleSideNav: 'PROTOTYPE.STEP_2.TITLE_SIDE_NAV',
      proceed: 'COMMONS.CONTINUE',
      complete: false,
    },
    {
      id: 3,
      title: 'PROTOTYPE.STEP_3.TITLE',
      titleBasic: 'PROTOTYPE.STEP_3.TITLE_BASIC',
      titleSideNav: 'PROTOTYPE.STEP_3.TITLE_SIDE_NAV',
      proceed: 'COMMONS.CONTINUE',
      complete: false,
    },
    {
      id: 4,
      title: 'PROTOTYPE.STEP_4.TITLE',
      titleBasic: 'PROTOTYPE.STEP_4.TITLE_BASIC',
      titleSideNav: 'PROTOTYPE.STEP_4.TITLE_SIDE_NAV',
      proceed: 'COMMONS.CONTINUE',
      complete: false,
    },
  ]

  submitWorker: Observable<any>

  get isFirstStepActive(): boolean {
    return this.currentStep === 0
  }

  get isLastStepActive(): boolean {
    return this.currentStep === this.steps.length - 1
  }

  private logger: Logger
  private emailVerifiedSubscription: Subscription
  private emailVerified: boolean

  private static ignoreKeyDownEvent(event: KeyboardEvent): boolean {
    const nodeName: string = event.srcElement ? event.srcElement.nodeName.toLowerCase() : ''
    return nodeName === 'input' || nodeName === 'textarea'
  }

  constructor(
    loggerService: LoggerService,
    private prototypeService: PrototypeService,
    private modalService: Modal2Service,
    private router: Router,
    private appHttp: AppHttp,
    private authService: AuthService,
    private notificationCenter: NotificationCenterService,
  ) {
    this.logger = loggerService.getInstance('UploadWizardComponent')
  }

  ngOnInit() {
    this.emailVerifiedSubscription = this.authService.isEmailVerifiedChanges.subscribe(verified => {
      this.emailVerified = !!verified
      this.validate()
    })

    this.authService.isEmailVerifiedChanges.pipe(first()).subscribe(() => {
      if (this.prototype !== null && this.prototype.type !== undefined) {
        this.determineStep()
        this.validate()
      }
    })
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    this.logger.debug('something changed: ', changes)
    if (changes['prototype']) {
      if (changes['prototype'].currentValue === null) {
        this.logger.debug('proto was resetted to null, cancelling upload')
        this.cancel()
      }
      if (changes['prototype'].previousValue === null) {
        this.determineStep()
      }
    }
  }

  ngOnDestroy() {
    this.emailVerifiedSubscription.unsubscribe()
  }

  revalidate() {
    this.logger.debug('revalidate triggerd by child...: ', this.prototype)
    if (this.prototype !== null && this.prototype.type !== undefined) {
      this.validate()
    }
  }

  /*
   * host event listener for keydown events
   */
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case KeyboardKeyCode.ARROW_DOWN:
        if (!UploadWizardComponent.ignoreKeyDownEvent(event)) {
          // ignore down arrow when inviting colleagues (autocomplete selection by same key)
          if (this.currentStep !== this.steps.length - 1 && this.prototype.feedbackStyle !== FeedbackStyle.GROUP) {
            event.preventDefault()
            this.proceed()
          }
        }
        break
      case KeyboardKeyCode.ARROW_UP:
        if (!UploadWizardComponent.ignoreKeyDownEvent(event)) {
          event.preventDefault()
          if (this.currentStep > 0) {
            this.jumpToStep(this.currentStep - 1)
          }
        }
        break
      default:
      //    do nothing
    }
  }

  proceed(step?: number) {
    if (this.currentStep === this.steps.length - 1 && this.valid) {
      this.handleLastStep()
    } else {
      // it is needed to validate before further checks (when going back to previous step for example)
      this.validate()
      if (this.valid && this.isJumpToStepAllowed(step)) {
        this.currentStep = step || Math.min(this.steps.length - 1, this.currentStep + 1)
        this.validate()
      } else {
        this.logger.debug('current step is not valid, no proceed possible')
      }
    }
  }

  isBackwardButtonDisabled(): boolean {
    return this.currentStep === 0
  }

  goBackward(): void {
    if (this.currentStep > 0) {
      this.jumpToStep(this.currentStep - 1)
    }
  }

  jumpToStep(step: number) {
    if (step < this.currentStep) {
      this.currentStep = step
    } else if (step > this.currentStep) {
      this.proceed(step)
    }
  }

  isForwardButtonDisabled(): boolean {
    return !this.steps[this.currentStep].complete
  }

  isForwardButtonVisible(): boolean {
    return !(this.currentStep === this.steps.length - 1)
  }

  private cancel(): void {
    this.valid = false
    this.currentStep = 0
    this.steps.forEach(step => {
      step.complete = false
    })

    this.prototype = PrototypeService.createEmptyPrototype()
  }

  // used to check the validity of the steps between currentStep and step to jump to. prevent jumping when a invalid step was found.
  private isJumpToStepAllowed(step: number | undefined): boolean {
    const allowed = true
    if (step !== undefined) {
      for (let i = this.currentStep; i < step; i++) {
        if (!VALIDATION_RULES.steps[i](this.prototype, this.emailVerified)) {
          this.logger.debug('can not proceed because the following step is not valid: ', i)
          return false
        }
      }
    }

    return allowed
  }

  private validate() {
    this.valid = VALIDATION_RULES.steps[this.currentStep](this.prototype, this.emailVerified)
    if (this.valid) {
      this.prototypeService.setCachedPrototype(this.prototype)
      this.steps[this.currentStep].complete = true
    } else {
      this.steps[this.currentStep].complete = false
    }
  }

  // used to determine the validity of all steps when restoring cached prototype
  private determineStep(): void {
    // restore until audience step
    for (let i = 0; i < 4; i++) {
      if (VALIDATION_RULES.steps[i](this.prototype, this.emailVerified)) {
        this.steps[i].complete = true
        // sets current step to the one coming after the last valid step (see for clause)
        this.currentStep = i + 1
      } else {
        break
      }
    }
  }

  private handleLastStep(): void {
    if (this.prototype.feedbackStyle === FeedbackStyle.GROUP && this.prototype.groups) {
      const groupsWithGuests: string[] = []
      this.prototype.groups.forEach(group => {
        if ((<Group>group).guests && (<Group>group).guests.length) {
          groupsWithGuests.push(group.title)
        }
      })
      if (groupsWithGuests.length) {
        this.modalService
          .open<ConfirmGuestsSubmitModalComponent, boolean>(ConfirmGuestsSubmitModalComponent, { data: groupsWithGuests })
          .afterClosed
          .pipe(filterIfTruthy())
          .subscribe(() => {
            this.submitPrototype()
          })
      } else {
        this.submitPrototype()
      }
    } else {
      this.submitPrototype()
    }
  }

  private submitPrototype(): void {
    this.prototype.startDate = moment()
    this.prototype.endDate = PrototypeService.addWorkdays(this.prototype.startDate, 2)
    this.prototype.state = PrototypeState.READY_FOR_FEEDBACK

    const choice: AudienceChoice | null = this.prototype.choice
    this.prototype.choice = null

    // do not send unused stuff to backend..
    const groups: SimpleGroup[] = this.prototype.groups
    const criterias: Criteria[] = this.prototype.criterias
    switch (this.prototype.feedbackStyle) {
      case FeedbackStyle.COMMUNITY:
        this.prototype.groups = []
        this.prototype.criterias = []
        break
      case FeedbackStyle.GROUP:
        this.prototype.criterias = []
        // add all the users from the groups to proto invited users.
        this.prototype.invitedUsers = []
        this.prototype.invitedExternalUsers = []
        this.prototype.groups.forEach(g => {
          this.prototype.invitedUsers = this.prototype.invitedUsers.concat((<Group>g).existingUsers)
          this.prototype.invitedExternalUsers = this.prototype.invitedExternalUsers.concat((<Group>g).externalUsers)
          this.prototype.invitedGuests = this.prototype.invitedGuests.concat((<Group>g).guests)
        })
        break
      case FeedbackStyle.CRITERIA:
        this.prototype.groups = []
        break
      default:
      // do nothing
    }

    this.submitWorker = this.prototypeService.createPrototype(this.prototype).pipe(share())
    this.submitWorker.subscribe(
      (prototype: Prototype) => {
        this.logger.debug('the submitted prototype: ', prototype)
        this.modalService
          .open<PublishedPrototypeModalComponent, void>(PublishedPrototypeModalComponent, { data: <PublishedPrototypeData>{ prototype } })
          .afterClosed
          .subscribe(() => {
            this.prototypeService.removeCachedPrototype()
            this.router.navigateByUrl('/home')
          })
      },
      (error: HttpError) => {
        this.prototype.choice = choice
        this.prototype.groups = groups
        this.prototype.criterias = criterias
        if (error.code === HttpErrorCodes.ACCESS_DENIED_TRIAL_EXPIRED) {
          this.authService.authUserChanges.pipe(first()).subscribe(authUser => {
            if (authUser) {
              const messageBuilder: NotificationMessage = this.notificationCenter
                .builder()
                .setSeverity(NotificationSeverity.WARNING)
              if (AuthService.hasRole(authUser, 'orgAdmin')) {
                messageBuilder
                  .setKey('PROFILE_USER_NOTIFICATIONS.ORGANIZATION_TRIAL_EXPIRED_ADMIN')
                  .setRoute('/admin/settings')
                  .setRouteFragment('subscription')
                  .setRouteKey('PROFILE_USER_NOTIFICATIONS.LINKS.SUBSCRIBE_NOW')
              } else {
                messageBuilder
                  .setKey('PROFILE_USER_NOTIFICATIONS.ORGANIZATION_TRIAL_EXPIRED_USER')
                  .setRoute('/contact')
                  .setRouteKey('PROFILE_USER_NOTIFICATIONS.LINKS.CONTACT_ADMIN')
              }
              messageBuilder.show()
            }
          })
        } else {
          this.appHttp.handle400(error)
        }
      },
    )
  }
}
