import { DOCUMENT } from '@angular/common'
import { Inject, Injectable, Renderer2 } from '@angular/core'
import { Logger, LoggerService, WindowRef } from '@maprix/core'

@Injectable()
export class CopyService {
  private logger: Logger
  private window: Window

  constructor(loggerService: LoggerService, @Inject(DOCUMENT) private document: Document, windowRef: WindowRef) {
    this.logger = loggerService.getInstance('CopyService')
    this.window = windowRef.nativeWindow
  }

  copyUrl(elem: HTMLAnchorElement, renderer: Renderer2): boolean {
    this.logger.debug('elem: ', elem)
    const textToCopy: string = elem.text
    return this.copyText(textToCopy, renderer)
  }

  copyText(textToCopy: string, renderer: Renderer2): boolean {
    this.logger.debug('the text to copy: ', textToCopy)

    const isCopySupported: boolean = this.document.queryCommandSupported('copy')
    this.logger.debug('check if copy command is supported: ', isCopySupported)

    // some browser my show queryCommandSupported is true but not always. so we check if it really worked.
    let copyWorked = false

    // style textarea to be invisible if copy command is supported -> same as clipboard.js does
    // https://github.com/zenorocha/clipboard.js/blob/master/src/clipboard-action.js
    if (isCopySupported) {
      const isRTL = this.document.documentElement.getAttribute('dir') === 'rtl'
      const fakeElem: HTMLTextAreaElement = renderer.createElement('textarea')
      // Prevent zooming on iOS
      renderer.setStyle(fakeElem, 'font-size', '12pt')
      // Reset box model
      renderer.setStyle(fakeElem, 'border', '0')
      renderer.setStyle(fakeElem, 'padding', '0')
      renderer.setStyle(fakeElem, 'margin', '0')
      // Move element out of screen horizontally
      renderer.setStyle(fakeElem, 'position', 'fixed')
      renderer.setStyle(fakeElem, isRTL ? 'right' : 'left', '-9999px')
      // Move element to the same position vertically
      renderer.setStyle(fakeElem, 'top', (this.window.pageYOffset || this.document.documentElement.scrollTop) + 'px')
      renderer.setAttribute(fakeElem, 'readonly', '')

      fakeElem.value = textToCopy

      this.document.body.appendChild(fakeElem)
      fakeElem.focus()
      fakeElem.select()

      // safari 10.0 introduced programmatic cut and copy support yay. so only not working for old safari versions.
      // https://developer.apple.com/library/content/releasenotes/General/WhatsNewInSafari/Articles/Safari_10_0.html
      if (this.document.execCommand('copy')) {
        copyWorked = true
        this.logger.debug('copy worked')
      }

      // remove fakeElem from dom
      this.document.body.removeChild(fakeElem)
    }

    return copyWorked
  }
}
