import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { Logger, LoggerService } from '@maprix/core'
import { Observable, Subscription } from 'rxjs'

@Component({
  selector: 'scs-embed-iframe',
  templateUrl: './embed-iframe.component.html',
  styleUrls: ['./embed-iframe.component.scss'],
})
export class EmbedIframeComponent implements OnInit, OnDestroy {
  @Input() htmlString: Observable<string>
  @Output() complete: EventEmitter<boolean> = new EventEmitter<boolean>()

  innerHtml: SafeHtml
  showTranscluded = true

  private logger: Logger
  private subscription: Subscription

  constructor(private domSanitizer: DomSanitizer, loggerService: LoggerService) {
    this.logger = loggerService.getInstance('OEmbedComponent')
  }

  ngOnInit() {
    // subscribe to data
    if (this.htmlString) {
      this.subscription = this.htmlString.subscribe(
        htmlString => {
          this.logger.debug('got html for iframe: %O', htmlString)
          if (htmlString) {
            this.showTranscluded = false
          }
          // carefully master polo - XSS risk
          this.innerHtml = this.domSanitizer.bypassSecurityTrustHtml(htmlString)
        },
        error => {
          this.logger.debug('nice')
          this.logger.error(error)
          this.complete.emit(true)
        },
        () => {
          this.logger.debug('html response complete')
          this.complete.emit(true)
        }
      )
    } else {
      this.logger.warn('Make shure to provide the htmlString as observable input')
    }
  }

  ngOnDestroy() {
    // tear down
    this.subscription.unsubscribe()
  }
}
