import { MobileViewDirective } from '../directives/mobile-view.directive'
export class TableMobileViewModel {
  id: string | undefined

  // ng2 header template
  template: any

  constructor(view: MobileViewDirective) {
    this.template = view.template
    this.id = view.id
  }
}
