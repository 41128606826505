import { NgModule, Optional, SkipSelf } from '@angular/core'
import { ExpertiseService } from './expertise.service'

@NgModule({
  imports: [],
  providers: [ExpertiseService],
})
export class ExpertiseModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ExpertiseModule
  ) {
    if (parentModule) {
      throw new Error('ExpertiseModule is already loaded. Import it only once')
    }
  }
}
