import { Injectable } from '@angular/core'
import { Headers, RequestOptionsArgs, ResponseContentType } from '@angular/http'
import { SafeUrl } from '@angular/platform-browser'
import { Logger, LoggerService, RuntimeConfiguration } from '@maprix/core'
import { from, Observable } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { PrototypeResource } from '../../models/resource/prototype-resource.model'
import { MicroService } from '../config/runtime-configuration/micro-service.enum'
import { AppHttpResponse } from '../http/app-http-response.model'
import { AppHttp } from '../http/app-http.service'
import { DataObject } from './data-object.model'
import { FileUploadValidationResult } from './file-upload-validation-result.model'
import { InvalidFile } from './invalid-file.model'

/**
 * maxSize The maximum size of the file in bytes
 * supportedFileTypes A map where the key is the file suffix and the value is the mapping Mime Type
 */
interface ValidationOptions {
  maxSize?: number
  supportedFileTypes?: { [key: string]: string }
}

export interface MinMax {
  min: number
  max: number
}

export interface MinMaxInit extends MinMax {
  init: number
}

export const MIN_MAX_RESOURCES: MinMaxInit[] = [
  { max: 1, min: 1, init: 1 },
  { max: 3, min: 2, init: 3 },
  { max: 9, min: 3, init: 4 },
]

@Injectable()
export class FileUploadService {
  static SUPPORTED_MIME_TYPES = { jpg: 'image/jpeg', jpeg: 'image/jpeg', png: 'image/png' }
  private static CONTENT_TYPE_HEADER = 'Content-Type'
  private static DEFAULT_MAX_SIZE: number = 8 * 1024 * 1024

  private logger: Logger
  private baseUrl: string
  private baseUrlCors: string

  static fileListToFileArray(files: FileList): File[] {
    const fileArr: File[] = []
    // tslint:disable-next-line
    for (let i = 0; i < files.length; i++) {
      fileArr.push(files[i])
    }
    return fileArr
  }

  static validate(files: File[], validationOptions?: ValidationOptions): FileUploadValidationResult {
    const validFiles: File[] = []
    const invalidFiles: InvalidFile[] = []
    files.forEach(file => {
      if (
        FileUploadService.isSupportedFileType(
          file,
          validationOptions ? validationOptions.supportedFileTypes : undefined
        ) &&
        FileUploadService.isFileSizeOK(file, validationOptions ? validationOptions.maxSize : undefined)
      ) {
        // file is valid
        validFiles.push(file)
      } else {
        if (
          !FileUploadService.isSupportedFileType(
            file,
            validationOptions ? validationOptions.supportedFileTypes : undefined
          )
        ) {
          ;(<InvalidFile>file).errorKey = 'FILE_TYPE'
        }
        if (!FileUploadService.isFileSizeOK(file, validationOptions ? validationOptions.maxSize : undefined)) {
          ;(<InvalidFile>file).errorKey = 'FILE_SIZE'
        }
        invalidFiles.push(<InvalidFile>file)
      }
    })

    return { validFiles, invalidFiles }
  }

  static validateFileList(fileList: FileList): FileUploadValidationResult {
    const files = FileUploadService.fileListToFileArray(fileList)
    return FileUploadService.validate(files)
  }

  static isValidationResultValid(validationResult: FileUploadValidationResult): boolean {
    return validationResult.invalidFiles && validationResult.invalidFiles.length === 0
  }

  static isSupportedFileType(file: File, supportedFileTypes?: { [key: string]: string }): boolean {
    const guessedType = FileUploadService.getGuessedFileType(file)
    supportedFileTypes = supportedFileTypes || FileUploadService.SUPPORTED_MIME_TYPES
    return supportedFileTypes[guessedType] !== undefined
  }

  static isFileSizeOK(file: File, maxSizeByte?: number): boolean {
    maxSizeByte = maxSizeByte || FileUploadService.DEFAULT_MAX_SIZE
    return file.size <= maxSizeByte
  }

  private static getGuessedMimeType(file: Blob | File): string | null {
    if (file instanceof Blob) {
      return (<Blob>file).type
    } else if (<any>file instanceof File) {
      return FileUploadService.SUPPORTED_MIME_TYPES[FileUploadService.getGuessedFileType(file)]
    } else {
      return null
    }
  }

  private static getGuessedFileType(file: File): string {
    const names = file.name.split('.')
    return names[names.length - 1].toLowerCase()
  }

  constructor(loggerService: LoggerService, private appHttp: AppHttp, runtimeConfiguration: RuntimeConfiguration) {
    this.logger = loggerService.getInstance('FileUploadService')
    this.baseUrl = runtimeConfiguration.getUrlForRestService(MicroService.MAIN)
    this.baseUrlCors = `${runtimeConfiguration.getUrlForRestService(MicroService.CORSPROXY)}secure/`
  }

  download(url: string): Observable<DataObject | null> {
    const dynamicCorsUrl = `${this.baseUrlCors}${url}`
    return this.downloadInternal(dynamicCorsUrl)
  }

  downloadDirect(url: string): Observable<DataObject | null> {
    return this.downloadInternal(url)
  }

  upload(
    fileUploadValidationResult: FileUploadValidationResult,
    urlPath: string
  ): Observable<AppHttpResponse<PrototypeResource>> {
    return from(fileUploadValidationResult.validFiles).pipe(
      mergeMap(file => {
        return this.uploadSingle<PrototypeResource>({ data: file }, urlPath)
      })
    )
  }

  toObjectUrl(fileUploadValidationResult: FileUploadValidationResult): SafeUrl[] {
    const objectUrls: SafeUrl[] = []
    fileUploadValidationResult.validFiles.forEach((file: File) => {
      objectUrls.push(this.toObjectUrlSingle(file))
    })

    return objectUrls
  }

  revokeObjectUrl(objectUrl: string | null) {
    if (objectUrl) {
      this.logger.debug('Removing object url %s', objectUrl)
      URL.revokeObjectURL(objectUrl)
    }
  }

  toObjectUrlSingle(file: File | Blob): string {
    const url = URL.createObjectURL(file)
    this.logger.debug('Created objectUrl : %s', url)
    return url
  }

  uploadMultiPart<T>(dataObject: DataObject[], urlPath: string): Observable<AppHttpResponse<T>> {
    const form: FormData = new FormData()
    dataObject.forEach((value: DataObject, index: number) => {
      form.append(`formData${index}`, value.data)
    })

    const options: RequestOptionsArgs = {}

    return this.appHttp.post<T>(this.baseUrl + urlPath, form, options)
  }

  uploadSingle<T>(dataObject: DataObject, urlPath: string): Observable<AppHttpResponse<T>> {
    const options: RequestOptionsArgs = {}
    if (dataObject.contentType) {
      options.headers = new Headers({ [FileUploadService.CONTENT_TYPE_HEADER]: dataObject.contentType })
    } else {
      const mimeType: string | null = FileUploadService.getGuessedMimeType(dataObject.data)

      if (mimeType !== null) {
        options.headers = new Headers({ [FileUploadService.CONTENT_TYPE_HEADER]: mimeType })
      }
    }
    return this.appHttp.post<T>(this.baseUrl + urlPath, dataObject.data, options)
  }

  private downloadInternal(url: string): Observable<DataObject | null> {
    return this.appHttp.get<Blob>(url, { responseType: ResponseContentType.Blob }).pipe(
      map(response => {
        const contentType =
          response.originalResponse.headers &&
          response.originalResponse.headers.get(FileUploadService.CONTENT_TYPE_HEADER)
        const downloadedFile: DataObject = {
          data: response.body,
          contentType,
        }
        return downloadedFile
      })
    )
  }
}
