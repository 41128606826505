import { animate, state, style, transition, trigger } from '@angular/animations'
import { DOCUMENT } from '@angular/common'
import { Component, Inject, OnDestroy } from '@angular/core'
import { Logger, LoggerService, TranslateService } from '@maprix/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { MenuItem } from '../../models/menu/menu-item.model'
import {
  CONTACT_MENU_ITEM,
  FAQ_MENU_ITEM,
  FOOTER_SECOND_ROW_ITEMS,
  LOGGED_IN_FOOTER_SECOND_ROW_ITEMS,
  PRIVACY_POLICY_MENU_ITEM,
  RELEASE_NOTES_MENU_ITEM,
  TERMS_MENU_ITEM,
  TOUR_MENU_ITEM,
} from '../../models/menu/menu-items'
import { AuthService } from '../../services/auth/auth.service'

@Component({
  selector: 'scs-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [
    trigger('footerState', [
      state('inactive', style({ transform: 'scale(1)', opacity: 0 })),
      state('active', style({ transform: 'scale(1)', opacity: 1 })),
      transition('inactive => active', animate('100ms ease-in')),
      transition('active => inactive', animate('100ms ease-out')),
      transition('void => inactive', [style({ transform: 'scale(0)', opacity: 0 }), animate(100)]),
      transition('inactive => void', [animate(100, style({ transform: 'scale(1)', opacity: 1 }))]),
      transition('void => active', [style({ transform: 'scale(0)', opacity: 0 }), animate(200)]),
    ]),
  ],
})
export class FooterComponent implements OnDestroy {
  // firstRowMenuItems: MenuItem[] = [TOUR_MENU_ITEM, FAQ_MENU_ITEM, RELEASE_NOTES_MENU_ITEM]
  firstRowMenuItems: MenuItem[] = [TOUR_MENU_ITEM, TERMS_MENU_ITEM]
  secondRowMenuItems: MenuItem[] = []
  scKey: string | null

  private logger: Logger
  private scsCounter = 0
  private document: Document
  private onDestroy: Subject<void> = new Subject()

  constructor(loggerService: LoggerService, @Inject(DOCUMENT) document: Document, authService: AuthService, translateService: TranslateService) {
    this.logger = loggerService.getInstance('FooterComponent')
    this.document = document
    translateService.get('MENU.TERMSURL').subscribe((terms) => {
      this.firstRowMenuItems[1].path = terms;
    });

    authService.authUserChanges.pipe(takeUntil(this.onDestroy)).subscribe(authUser => {
      this.secondRowMenuItems = authUser ? LOGGED_IN_FOOTER_SECOND_ROW_ITEMS : FOOTER_SECOND_ROW_ITEMS
    })
  }

  ngOnDestroy(): void {
    this.onDestroy.next()
    this.onDestroy.complete()
  }

  openScDialog() {
    this.scsCounter++
    if (this.scsCounter === 10) {
      this.scKey =
        '\u0044\u0065\u0076\u0065\u006c\u006f\u0070\u0065\u0064 \u0062\u0079 ' +
        '\u0073\u0068\u0069\u0066\u0074\u0063\u006f\u0064\u0065 \u0047\u006d\u0062\u0048'
      setTimeout(() => {
        this.scsCounter = 0
        this.scKey = null
      }, 10000)
    }
  }
}
