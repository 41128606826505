import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { LoggerService, ScrollToService } from '@maprix/core'
import { Observable } from 'rxjs'
import { Regex } from '../../../shared/helpers/regex'
import { HttpError } from '../../../shared/models/http/http-error'
import { ActionType } from '../models/action-type.enum'
import { LoginStateData } from '../models/login-state-data'
import { LoginType } from '../models/login-type.enum'
import { ResetableLoginStepWithFormComponent } from './base/resetable-login-step-with-form.component'

@Component({
  selector: 'scs-login-start',
  templateUrl: 'start.component.html',
})
export class LoginStartComponent extends ResetableLoginStepWithFormComponent implements OnInit {
  @Input() data: LoginStateData
  @Input() worker$: Observable<any>
  // TODO TYPINGS SafeHtml, relates to this issue https://github.com/webpack/webpack/issues/2977#issuecomment-245887841
  // (on cli repo https://github.com/angular/angular-cli/issues/2034)
  @Input() trustedTermsHtml: any

  // two way binding for error
  @Input() error: HttpError | string
  @Output() errorChange: EventEmitter<HttpError | string> = new EventEmitter<HttpError | string>()

  @Output() next: EventEmitter<null | ActionType> = new EventEmitter<null | ActionType>()

  form: FormGroup
  actionType: any = ActionType
  loginType: any = LoginType

  constructor(loggerService: LoggerService, formBuilder: FormBuilder, private scrollToService: ScrollToService) {
    super(loggerService.getInstance('LoginStartComponent'), formBuilder)
  }

  ngOnInit() {
    super.ngOnInit()
    this.scrollToService.scrollTo()
  }

  buildForm(): FormGroup {
    const email: string | undefined | null = this.data.loginData.email
    const form: FormGroup = this.formBuilder.group({
      email: [email, Validators.compose([Validators.required, Validators.pattern(Regex.PATTERN_EMAIL)])],
    })

    return form
  }

  fillViewDataToModel(actionType?: ActionType): void {
    if (actionType === ActionType.PASSWORD) {
      this.data.loginData.email = this.form.get('email').value
    }
  }

  onNext(actionType: ActionType): void {
    super.onNext(actionType)
  }
}
