import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core'
import { Logger, LoggerService } from '@maprix/core'
import { PrototypeType } from '../../../../shared/models/prototype/prototype-type.enum'
import { UploadPrototype } from '../../../../shared/models/prototype/prototype.model'
@Component({
  selector: 'scs-upload-step-type',
  templateUrl: './upload-step-type.component.html',
  styleUrls: ['./upload-step-type.component.scss'],
})
export class UploadStepTypeComponent implements OnInit {
  @HostBinding('class') classes = 'wizard-step wizard-step--type'

  @Input() prototype: UploadPrototype
  @Output() revalidate: EventEmitter<void> = new EventEmitter<void>()
  @Output() proceed: EventEmitter<void> = new EventEmitter<void>()

  showContinueButton = false

  simpleImages: any = [
    {
      imageSmall: {
        retrievalUrl: '/assets/img/upload/upload_simple.png',
      },
    },
  ]

  variantImages: any = [
    {
      imageSmall: {
        retrievalUrl: '/assets/img/upload/upload_variant_A.png',
      },
    },
    {
      imageSmall: {
        retrievalUrl: '/assets/img/upload/upload_variant_B_.png',
      },
    },
  ]

  sequenceImages: any = [
    {
      imageSmall: {
        retrievalUrl: '/assets/img/upload/upload_sequence.png',
      },
    },
    {
      imageSmall: {
        retrievalUrl: '/assets/img/upload/upload_sequence.png',
      },
    },
    {
      imageSmall: {
        retrievalUrl: '/assets/img/upload/upload_sequence.png',
      },
    },
  ]

  private logger: Logger

  constructor(loggerService: LoggerService) {
    this.logger = loggerService.getInstance('UploadStepTypeComponent')
  }

  ngOnInit() {
    if (this.prototype.type !== null) {
      this.showContinueButton = true
    }
  }

  changeType(type: PrototypeType) {
    this.prototype.type = type
    this.revalidate.emit()
    setTimeout(() => {
      this.proceed.emit()
    }, 500)
  }
}
