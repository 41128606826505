import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChange } from '@angular/core'
import * as dragula from 'dragula'
import { DragulaService } from './dragula.service'

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[dragula]',
})
export class DragulaDirective implements OnInit, OnChanges {
  // tslint:disable-next-line
  @Input('dragula') public bag: string
  @Input() dragulaModel: any
  private container: any
  private drake: any

  constructor(el: ElementRef, private dragulaService: DragulaService) {
    this.container = el.nativeElement
  }

  ngOnInit(): void {
    // console.log(this.bag);
    const bag = this.dragulaService.find(this.bag)
    const checkModel = () => {
      if (this.dragulaModel) {
        if (this.drake.models) {
          this.drake.models.push(this.dragulaModel)
        } else {
          this.drake.models = [this.dragulaModel]
        }
      }
    }
    if (bag) {
      this.drake = bag.drake
      checkModel()
      this.drake.containers.push(this.container)
    } else {
      this.drake = dragula({
        containers: [this.container],
      })
      checkModel()
      this.dragulaService.add(this.bag, this.drake)
    }
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    // console.log('dragula.directive: ngOnChanges');
    // console.log(changes);
    if (changes && changes['dragulaModel']) {
      if (this.drake) {
        if (this.drake.models) {
          const modelIndex = this.drake.models.indexOf(changes['dragulaModel'].previousValue)
          this.drake.models.splice(modelIndex, 1, changes['dragulaModel'].currentValue)
        } else {
          this.drake.models = [changes['dragulaModel'].currentValue]
        }
      }
    }
  }
}
