import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core'

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[scs-bg-image]',
})
export class BgImageDirective implements OnInit, OnChanges {
  // tslint:disable-next-line
  @Input('scs-bg-image') bgImage: string

  private el: HTMLElement

  constructor(el: ElementRef, private renderer: Renderer2) {
    this.el = el.nativeElement
  }

  ngOnInit() {
    this.updateComponent()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['bgImage'] && changes['bgImage'].currentValue) {
      this.updateComponent()
    }
  }

  private updateComponent() {
    this.renderer.setStyle(this.el, 'backgroundImage', `url('${this.bgImage}')`)
  }
}
