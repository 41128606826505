import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Params, Router } from '@angular/router'
import { LoggerService, ScrollToService, TranslateService } from '@maprix/core'
import { Observable } from 'rxjs'
import { pickQueryParams } from '../../../shared/helpers/utils'
import { HttpError } from '../../../shared/models/http/http-error'
import { ActionType } from '../models/action-type.enum'
import { LoginStateData } from '../models/login-state-data'
import { BaseLoginStepComponent } from './base/base-login-step.component'

@Component({
  selector: 'scs-login-saml-sso',
  templateUrl: 'login-saml-sso.component.html',
})
export class LoginSamlSsoComponent extends BaseLoginStepComponent implements OnInit {
  private static SAML_SSO_FORCE_LOGIN_PARAM = 'forceLogin'

  @Input() data: LoginStateData

  // two way binding for error
  @Input() error: HttpError | string
  @Output() errorChange: EventEmitter<HttpError | string> = new EventEmitter<HttpError | string>()

  @Output() next: EventEmitter<ActionType | null> = new EventEmitter<ActionType | null>()
  @Output() back: EventEmitter<null> = new EventEmitter<null>()

  errorOthers: HttpError | null
  title: string

  constructor(
    loggerService: LoggerService,
    private router: Router,
    private translateService: TranslateService,
    private scrollToService: ScrollToService
  ) {
    super(loggerService.getInstance('LoginSamlSso'))
  }

  ngOnInit(): void {
    // check if forceLogin is present to skip this additional step
    const params: Params = pickQueryParams(
      this.router.routerState.snapshot.root.queryParams,
      LoginSamlSsoComponent.SAML_SSO_FORCE_LOGIN_PARAM
    )
    if (params && params[LoginSamlSsoComponent.SAML_SSO_FORCE_LOGIN_PARAM] === 'true') {
      this.logger.debug('forceLogin is present. skip this step.')
      this.onNext()
    } else {
      if (this.data.loginInfo) {
        let obs: Observable<string | string[]>
        if (this.data.loginInfo.existingAccount && this.data.loginInfo.user && this.data.loginInfo.user.givenName) {
          obs = this.translateService.get('LOGIN.SAML_SSO.TITLE_EXISTING', {
            givenName: this.data.loginInfo.user.givenName,
            orgName: this.data.loginInfo.organization.name,
          })
        } else {
          obs = this.translateService.get('LOGIN.SAML_SSO.TITLE_NON_EXISITNG', {
            orgName: this.data.loginInfo.organization.name,
          })
        }
        obs.subscribe(title => (this.title = <string>title))
      } else {
        throw new Error('data.loginInfo must defined')
      }

      this.scrollToService.scrollTo()
    }
  }

  onNext(): void {
    super.onNext()
  }
}
