import { Offset } from './offset.model'

export class ImageDimensionsAndOffset {
  width: number
  height: number
  offset: Offset

  // offsetChange: Offset;

  constructor(width: number, height: number, x: number, y: number) {
    // xChange: number, yChange: number
    this.width = width
    this.height = height
    const offset: Offset = new Offset()
    offset.x = x
    offset.y = y
    this.offset = offset

    // let offsetChange: Offset = new Offset();
    // offsetChange.x = xChange;
    // offsetChange.y = yChange;
    // this.offsetChange = offsetChange;
  }
}
