import { MenuItem } from '@maprix/components'
import { MenuAction } from './menu-actions'

// multiple used menu items
// TODO MW LOW use runtime configuration for the links
export const TOUR_MENU_ITEM: MenuItem = { key: 'MENU.TOUR', path: 'https://rready.com/feedback', isExternal: true }
export const FAQ_MENU_ITEM: MenuItem = { key: 'MENU.FAQ', path: 'https://www.looping.com/faq/', isExternal: true }
export const TERMS_MENU_ITEM: MenuItem = { key: 'MENU.TERMS', path: 'https://www.swisscom.ch/en/business/enterprise/footer/legal-aspects.html', isExternal: true }
export const RELEASE_NOTES_MENU_ITEM: MenuItem = {
  key: 'MENU.RELEASE_NOTES',
  path: 'https://www.looping.com/release-notes/',
  isExternal: true,
}

export const LOGIN_MENU_ITEM: MenuItem = { key: 'MENU.LOGIN_JOIN', path: '/login' }
export const LOGOUT_MENU_ITEM: MenuItem = { key: 'MENU.LOGOUT', path: null, isAction: true, action: MenuAction.LOGOUT }

export const COMMUNITY_MENU_ITEM: MenuItem = { key: 'MENU.COMMUNITY', path: '/community' }
export const UPLOAD_MENU_ITEM: MenuItem = { key: 'MENU.UPLOAD', path: '/upload' }
export const FEEDBACK_MENU_ITEM: MenuItem = { key: 'MENU.FEEDBACK', path: '/feedback' }
export const ADMIN_MENU_ITEM: MenuItem = { key: 'MENU.ADMIN', path: '/admin' }
export const OLD_SALES_MENU_ITEM: MenuItem = { key: 'MENU.SALES', path: '/old/sales', isExternal: true }
export const ORGANIZATIONS_MENU_ITEM: MenuItem = { key: 'MENU.ORGANIZATIONS', path: '/organizations' }
export const PROFILE_MENU_ITEM: MenuItem = { key: 'MENU.PROFILE', path: '/profile' }
export const CONTACT_MENU_ITEM: MenuItem = { key: 'MENU.CONTACT', path: '/contact' }
export const PRIVACY_POLICY_MENU_ITEM: MenuItem = { key: 'MENU.PRIVACY_POLICY', path: '/privacy-policy' }
export const TRIAL_MENU_ITEM: MenuItem = { key: 'MENU.TRIAL', path: '/trial' }
export const VERIFY_MENU_ITEM: MenuItem = { key: 'MENU.VERIFY', path: '/verify' }

export const DEFAULT_MENU_ITEMS: MenuItem[] = [UPLOAD_MENU_ITEM, FEEDBACK_MENU_ITEM, COMMUNITY_MENU_ITEM]

export const LOGGED_IN_MENU_ITEMS: MenuItem[] = [UPLOAD_MENU_ITEM, FEEDBACK_MENU_ITEM, COMMUNITY_MENU_ITEM]

export const ENTERPRISE_MENU_ITEMS: MenuItem[] = [TOUR_MENU_ITEM, TERMS_MENU_ITEM, TRIAL_MENU_ITEM]

export const FOOTER_SECOND_ROW_ITEMS: MenuItem[] = [CONTACT_MENU_ITEM]

// export const LOGGED_IN_FOOTER_SECOND_ROW_ITEMS: MenuItem[] = [
//   TERMS_MENU_ITEM,
//   PRIVACY_POLICY_MENU_ITEM,
//   CONTACT_MENU_ITEM,
// ]

export const LOGGED_IN_FOOTER_SECOND_ROW_ITEMS: MenuItem[] = [
  PRIVACY_POLICY_MENU_ITEM,
  CONTACT_MENU_ITEM,
]

export const SALES_MENU_ITEMS: MenuItem[] = [OLD_SALES_MENU_ITEM, ORGANIZATIONS_MENU_ITEM]

// used to display the current active route on xs-devices
export const AVAILABLE_MENU_ROUTES: MenuItem[] = [
  UPLOAD_MENU_ITEM,
  FEEDBACK_MENU_ITEM,
  COMMUNITY_MENU_ITEM,
  TOUR_MENU_ITEM,
  TERMS_MENU_ITEM,
  PROFILE_MENU_ITEM,
  OLD_SALES_MENU_ITEM,
  ORGANIZATIONS_MENU_ITEM,
  ADMIN_MENU_ITEM,
  FAQ_MENU_ITEM,
  CONTACT_MENU_ITEM,
  PRIVACY_POLICY_MENU_ITEM,
  LOGIN_MENU_ITEM,
  { key: 'MENU.RESULTS', path: '/results' },
  { key: 'MENU.PROFILE_SETTINGS', path: '/profile/settings' },
  { key: 'MENU.STYLEGUIDE', path: '/styleguide' },
  { key: 'MENU.HOME', path: '/home' },
  { key: 'MENU.TRIAL', path: '/trial' },
  { key: 'MENU.ADMIN_OVERVIEW', path: '/admin/overview' },
  { key: 'MENU.ADMIN_MEMBERS', path: '/admin/members' },
  { key: 'MENU.ADMIN_PROTOTYPES', path: '/admin/prototypes' },
  { key: 'MENU.ADMIN_SETTINGS', path: '/admin/settings' },
  { key: 'MENU.ADMIN_COMMUNITY', path: '/admin/community' },
]

export function removeLeadingSlash(menuItem: MenuItem): string {
  // remove leading slash
  return menuItem.path.substring(1)
}
