import { animate, style, transition, trigger } from '@angular/animations'
import { DEFAULT_ANIMATION_DURATION } from './config.animations'

export const fadeInOutAnimation = trigger('fadeInOutAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(DEFAULT_ANIMATION_DURATION + 'ms ease-in-out', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate(DEFAULT_ANIMATION_DURATION + 'ms ease-in-out', style({ opacity: 0 })),
  ]),
])
